import React from "react";
import { Icon } from "../../components";

export const DisabledByDefaultFilledRounded = ({ ...props }) => (
  <Icon {...props}>
    <path d="M12 13.4l2.9 2.9c.2.2.4.3.7.3s.5-.1.7-.3c.2-.2.3-.4.3-.7s-.1-.5-.3-.7L13.4 12l2.9-2.9c.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3s-.5.1-.7.3L12 10.6 9.1 7.7c-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7 0 .3.1.5.3.7l2.9 2.9-2.9 2.9c-.2.2-.3.4-.3.7s.1.5.3.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3l2.9-2.9zM5 21c-.5 0-1-.2-1.4-.6S3 19.6 3 19V5c0-.6.2-1 .6-1.4S4.5 3 5 3h14c.6 0 1 .2 1.4.6s.6.8.6 1.4v14c0 .6-.2 1-.6 1.4s-.8.6-1.4.6H5z" />
  </Icon>
);

export const DisabledByDefaultRounded = ({ ...props }) => (
  <Icon {...props}>
    <path d="M8.4 17l3.6-3.6 3.6 3.6 1.4-1.4-3.6-3.6L17 8.4 15.6 7 12 10.6 8.4 7 7 8.4l3.6 3.6L7 15.6 8.4 17zM5 21c-.5 0-1-.2-1.4-.6S3 19.6 3 19V5c0-.6.2-1 .6-1.4S4.5 3 5 3h14c.6 0 1 .2 1.4.6s.6.8.6 1.4v14c0 .6-.2 1-.6 1.4s-.8.6-1.4.6H5zm0-2h14V5H5v14zM5 5v14V5z" />
  </Icon>
);
