import React, { useCallback, useMemo } from "react";
import { Button, Col, Row, Select as AntSelect, SelectProps as AntSelectProps, Tag, Tooltip } from "antd";
import styled from "styled-components";
import { isEmpty } from "lodash";
import {
  CancelFilledRounded,
  CheckBoxRounded,
  CheckBoxOutlineRounded,
  DisabledByDefaultFilledRounded,
  ExpandMoreOutlinedRounded,
  Flaky,
} from "../../fixtures/icons";
import { DefaultOptionType } from "antd/lib/select";

export interface SelectProps<T> extends AntSelectProps<T> {
  showSelectAll?: Boolean;
}

const menuItemSelectedIcon = ({ isSelected }: any) =>
  (isSelected && <CheckBoxRounded style={{ fontSize: "18px" }} />) || (
    <CheckBoxOutlineRounded style={{ fontSize: "18px" }} />
  );

export const Select = styled(
  ({
    className,
    clearIcon,
    disabled,
    options,
    onChange,
    removeIcon,
    showSelectAll,
    suffixIcon,
    ...props
  }: SelectProps<any>) => {
    const isDisabled = disabled || props.loading;
    const selectAllExcept = useCallback(
      (except: any) => {
        if (!options || isEmpty(options)) return;

        if (onChange)
          onChange(
            options.filter(({ value }) => value !== except).map(({ value }) => value),
            [] as any
          );
      },
      [onChange, options]
    );
    const selectAll = useCallback(
      () =>
        onChange?.(
          options?.filter(({ value }) => value !== "all").map(({ value }) => value),
          [] as any
        ),
      [onChange, options]
    );
    const selectNone = useCallback(() => onChange?.([], [] as any), [onChange]);

    const optionElements = useMemo(
      () =>
        options?.map(({ label, value, tag, ...rest }) => ({
          disabled: isDisabled,
          label:
            props.mode === "multiple" ? (
              <Row style={{ width: "100%" }}>
                <Col flex="1 1 auto">{label}</Col>
                {tag !== undefined && tag !== null && (
                  <Col flex="0 0 auto" className="ant-select-item-option-extras">
                    <Tag style={{ fontSize: 11 }}>{tag}</Tag>
                  </Col>
                )}
                <Col flex="0 0 auto" className="ant-select-item-option-extras">
                  <Tooltip placement="left" title={`Select All Except "${label}"`} mouseLeaveDelay={0}>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        selectAllExcept(value);
                      }}
                      size="small"
                      type="link"
                      color="currentColor"
                      icon={<Flaky style={{ fontSize: "1.25em" }} />}
                    />
                  </Tooltip>
                </Col>
              </Row>
            ) : (
              label
            ),
          filterValue: label,
          value,
          ...rest,
        })),
      [props.mode, isDisabled, options, selectAllExcept]
    );

    const dropdownRender = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => (
      <>
        {options?.length && showSelectAll && props.mode === "multiple" && (
          <div className="ant-select-item-toggle-all">
            {!props.value?.length ? (
              <Button type="primary" disabled={isDisabled} onClick={() => selectAll()} size="small">
                Select All
              </Button>
            ) : (
              <Button type="primary" disabled={isDisabled} onClick={() => selectNone()} size="small">
                Unselect All
              </Button>
            )}
          </div>
        )}
        {menu}
      </>
    );

    return (
      <AntSelect
        className={className}
        clearIcon={clearIcon || <CancelFilledRounded style={{ fontSize: "18px" }} />}
        dropdownRender={dropdownRender}
        filterOption={(inputValue: string, option: DefaultOptionType | undefined) =>
          (option?.filterValue ?? "").toString().toLowerCase().includes(inputValue.toLowerCase())
        }
        menuItemSelectedIcon={menuItemSelectedIcon}
        onChange={onChange}
        options={optionElements}
        popupClassName={className}
        removeIcon={removeIcon || <DisabledByDefaultFilledRounded style={{ fontSize: "16px" }} />}
        suffixIcon={suffixIcon || <ExpandMoreOutlinedRounded style={{ fontSize: "22px" }} />}
        {...props}
      />
    );
  }
)`
  .ant-select-item-toggle-all {
    border-bottom: solid 2px #ddd;
    padding: 5px 12px 9px;
  }

  .ant-select-item {
    .ant-row {
      width: 100%;
    }
    .item-option-extras {
      display: none;
    }
  }

  .ant-col,
  .ant-select-selection-item-remove {
    align-items: center;
    display: flex;
  }

  .ant-btn {
    align-items: center;
    display: inline-flex;
    gap: 0.5em;
  }

  .ant-select-item-option-state {
    align-items: center;
    display: flex;
    font-size: 1em;
  }

  .ant-select-selection-item {
    align-items: center;

    .ant-select-item-option-extras {
      display: none;
    }
  }

  .ant-select-selector {
    padding-right: 30px;
  }

  .ant-select-arrow,
  .ant-select-clear {
    right: 2px;
  }

  .ant-select-clear {
    margin: 0;
    transform: translate(-50%, -50%);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
