import React, { HTMLAttributes, useEffect, useMemo } from "react";
import {
  Button,
  Form,
  InputNumber,
  Layout,
  Menu,
  message,
  Popover,
  Space,
} from "antd";
import styled from "styled-components";
import { Route, Routes, useNavigate, useParams } from "react-router";
import { InfoCircleOutlined } from "@ant-design/icons";
import { StorePriorityListSettings } from "./StorePriorityListSettings";
import { FacilitiesSettings } from "./FacilitiesSettings";
import { SettingsApi } from "../../api/core/Settings";
import { useForm } from "antd/lib/form/Form";

const { Sider } = Layout;
const { Content } = Layout;

const BatchSettings = () => {
  const [form] = useForm();

  const { data, isFetching: settingsLoading } = SettingsApi.useGetSettingsQuery({});

  useEffect(() => {
    form.setFieldsValue(data?.batches);
  }, [form, data]);

  const [updateSettings, { data: updateData, isSuccess: isUpdateSuccess, isLoading: updateLoading }] 
    = SettingsApi.useUpdateSettingsMutation();

  useEffect(() => {
    if (isUpdateSuccess) {
      form.setFieldsValue(updateData?.batches);
    }
  }, [form, updateData, isUpdateSuccess]);

  const loading = settingsLoading || updateLoading;

  return (
    <Form
      form={form}
      name="batchSettings"
      onFinish={(values) =>
        updateSettings({ batches: { ...values } }).unwrap().then(response => {
          message.success("Settings saved!");
          form.setFieldsValue(response.batches); // doesn't appear to work...
        })}
    >
      <Form.Item label="Snooze Time">
        <Space>
          <Form.Item noStyle name="snoozeTime" rules={[{ required: true }]}>
            <InputNumber disabled={loading} />
          </Form.Item>

          <Popover
            content={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <span>
                <b>Snooze Time</b> is the time in <b>minutes</b> a batch can go without a scan
                before being set to idle.
              </span>
            }
          >
            <InfoCircleOutlined rev="" />
          </Popover>
        </Space>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={loading} disabled={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

interface SettingsPageProps {
  className?: string;
}

const SettingsPageContainer = styled.div`
  padding: 20px;

  .ant-layout-content {
    padding: 20px;
    background-color: white;
  }
`;

const SettingsPageLayout = (props: HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();
  const { page } = useParams() as any;

  const content = useMemo(() => {
    switch (page) {
      case "facilities":
        return <FacilitiesSettings />;
      case "batches":
        return <BatchSettings />;
      case "stores":
      default:
        return <StorePriorityListSettings />;
    }
  }, [page]);

  return (
    <SettingsPageContainer>
      <Layout className={props.className}>
        <Sider theme="light">
          <Menu
            mode="inline"
            selectedKeys={[page]}
            defaultSelectedKeys={[page]}
            onSelect={({ key }) => navigate(`/settings/${key}`)}
          >
            <Menu.Item key="facilities">Facilities Settings</Menu.Item>
            <Menu.Item key="stores">Store Priority</Menu.Item>
            <Menu.Item key="batches">Batch Settings</Menu.Item>
          </Menu>
        </Sider>
        <Content>{content}</Content>
      </Layout>
    </SettingsPageContainer>
  );
};

export const SettingsPage = ({ className }: SettingsPageProps) => (
  <Routes>
    <Route 
      path="/:page" 
      element={<SettingsPageLayout className={className} />} 
    />
    <Route 
      path="/" 
      element={<SettingsPageLayout className={className} />} 
    />
  </Routes>
);
