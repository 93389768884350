import React from "react";
import { isArray, isFunction } from "lodash";
import { readableColor, parseToRgb, toColorString } from "polished";

export type ColorType = string | (({ value } : { value: string }) => string);

export interface TagCellRendererProps {
  value: string | string[];
  color?: ColorType;
  label?: string;
}

function TagCellRendererInternal(props: { value: string } & TagCellRendererProps) {
  const { value, color, label } = props;

  let backgroundColor;
  try {
    backgroundColor = parseToRgb(
      (isFunction(color) ? color({ value }) : color) || "lime"
    );
  } catch (e) {
    backgroundColor = parseToRgb("lime");
  }

  let humanReadable;
  try {
    humanReadable = isFunction(label) ? label({ value }) : label || value;
  } catch (e) {
    humanReadable = value;
  }

  const textColor = readableColor(toColorString(backgroundColor));
  if (!value || /^\s*$/.test(value)) return null;

  return (
    <span 
      key={value}
      className="ant-tag ant-tag-has-color" 
      style={{ color: `${textColor}`, backgroundColor: `${toColorString(backgroundColor)}` }}
    >
      {humanReadable}
    </span>
  );
}

export function TagCellRenderer(props: TagCellRendererProps) {
  const { value, color, label } = props;

  if (!value) {
    return null;
  }

  if (!isArray(value)) {
    return TagCellRendererInternal({ value, color, label });
  }

  return (
    <>
      { value.map(v => 
        TagCellRendererInternal({ value: v, color })
      )}
    </>
  );
}
