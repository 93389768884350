import { useCallback, useEffect, useState, useMemo } from "react";
import BUC, { BatchUnderConstructionInitEventArgs, BatchUnderConstructionItemsChangedEventArgs, BatchUnderConstructionResetEventArgs } from ".";
import { BatchUnderConstructionState } from "./BatchUnderConstructionAPIImpl";
import ILineItemData from "./ILineItemData";

export type useBatchUnderConstructionState = 
  Partial<BatchUnderConstructionState> & { isInitialized: boolean };

export const useBatchUnderConstruction = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(BUC.isInitialized());
  const [facilityId, setFacilityId] = useState<string | undefined>(BUC.getFacilityId());
  const [shipByDate, setShipByDate] = useState<Date | undefined>(BUC.getShipByDate());
  const [lineItems, setLineItems] = useState<ILineItemData[] | undefined>(BUC.getLineItems());

  const onReset = useCallback((e:CustomEvent<BatchUnderConstructionResetEventArgs>) => {
    setIsInitialized(BUC.isInitialized());
    setFacilityId(BUC.getFacilityId());
    setShipByDate(BUC.getShipByDate());
    setLineItems(BUC.getLineItems());
  }, [setIsInitialized, setFacilityId, setShipByDate, setLineItems]);

  const onInit = useCallback((e:CustomEvent<BatchUnderConstructionInitEventArgs>) => {
    setIsInitialized(BUC.isInitialized());
    setFacilityId(BUC.getFacilityId());
    setShipByDate(BUC.getShipByDate());
    setLineItems(BUC.getLineItems());
  }, [setIsInitialized, setFacilityId, setShipByDate, setLineItems]);

  const onItemsChanged = 
    useCallback((e:CustomEvent<BatchUnderConstructionItemsChangedEventArgs>) => {
      setLineItems(BUC.getLineItems());
    }, [setLineItems]);

  useEffect(() => {
    BUC.addEventListener('reset', onReset);
    BUC.addEventListener('init', onInit);
    BUC.addEventListener('items-changed', onItemsChanged);

    return () => {
      BUC.removeEventListener('reset', onReset);
      BUC.removeEventListener('init', onInit);
      BUC.removeEventListener('items-changed', onItemsChanged);
    };
  }, [onInit, onReset, onItemsChanged]);

  const state = useMemo<useBatchUnderConstructionState>(() => {
    return {
      isInitialized,
      facilityId,
      shipByDate,
      lineItems
    };
  }, [isInitialized, facilityId, shipByDate, lineItems]);
  
  return state;
};

export default useBatchUnderConstructionState;
