import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Spin } from "../../../../../components/Spin";
import CreateBatchUnderConstructionModalStepProps from "./CreateBatchUnderConstructionModalStepProps";
import BUC from "../../../../../api/BatchUnderConstruction";

export interface SubmitStepProps extends CreateBatchUnderConstructionModalStepProps {
}

// TO DO: Replace mock implementation once we have a proper API to submit to
export const SubmitStep = (props: SubmitStepProps) => {
  const { update, submit } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("Mock submission to the server...");

  useEffect(() => {
    // mock a succesful server response
    const tmr = window.setTimeout(() => {
      setIsLoading(false);
      setMessage("Mock Submission Successful! Batch Id: B-XXXXXXXX");
      BUC.reset();
      update({ result: { cancelled: false, ziftId: "B-MOCK" } });
    }, 1000);

    return () => {
      window.clearTimeout(tmr);
    };
  });

  const contentStyle:CSSProperties = useMemo(() => ({
    minHeight: '50px'
  }), []);

  return (
    <>
      <Modal.Body>
        <Spin spinning={isLoading} tip={message}>
          <p style={contentStyle}>
            {!isLoading && message}
          </p>
        </Spin>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="primary"  
          onClick={() => submit()} 
          disabled={isLoading}
          children={<>Done</>}
        />
      </Modal.Footer>  
    </>
  );
};

export default SubmitStep;
