import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "../utils";

export enum PrintArea {
  Front = "front",
  Back = "back",
  Neckhit = "neckhit",
  WrapAround = "wraparound",
  LeftSleeve = "lsleeve",
  RightSleeve = "rsleeve",
  LeftLeg = "leftleg",
  RightLeg = "rightleg",
  HighBack = "highback"
}

export interface Print {
  id: string;
  lineItemId?: string | null;
  area: PrintArea;
  score?: number | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

export const PrintDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate
});

export const deserializePrint = deserialize<Print>(PrintDeserializationSchema);
export const deserializePrintsArray = deserializeArrayOf<Print>(PrintDeserializationSchema);
