import { camelCase, isArray, isString } from "lodash";
import { depthFirstTraversal, VisitFunction } from "./depthFirstTraversal";

export function camelCaseKeys(root: any) {
  if (isString(root)) {
    return camelCase(root);
  }
  
  const dest:any = isArray(root) ? [] : {};
  const map = new Map<any, any>(); // source => dest node mapping
  map.set(root, dest);

  const visit:VisitFunction = (curr, ctx) => {
    if (curr === root) {
      return;
    } 

    const name = camelCase(ctx.path.at(-1)!);
    const srcParent = ctx.visitStack.at(-2);
    const destParent = map.get(srcParent);
    
    if (Object(curr) !== curr) {
      destParent[name] = curr; // if it is a primitive, then just copy by value
    } else if (isArray(curr)) {
      destParent[name] = []; // to be populated by subsequent visitations
      map.set(curr, destParent[name]);
    } else {
      destParent[name] = {}; // to be populated by subsequent visitations
      map.set(curr, destParent[name]);
    }
  };
  
  depthFirstTraversal(root, visit);
  return dest;
}
