import { IToolPanelParams } from "ag-grid-community";
import React from "react";
import styled from "styled-components";
import { BatchScheduleManager } from "../../../../../pages/Batch/ManagePage/BatchScheduleManager";

const Container = styled.div`
  margin: 0px auto;
  padding: 10px;
`;

export interface BatchSchedulingToolPanelParams extends IToolPanelParams {
  hideSchedule?: boolean
  hideUnschedule?: boolean
}

export const BatchSchedulingToolPanel = (props: BatchSchedulingToolPanelParams) => {
  return (
    <Container>
      <BatchScheduleManager 
        api={props.api}
        hideSchedule={props.hideSchedule}
        hideUnschedule={props.hideUnschedule}
      />
    </Container>
  );
};

export default BatchSchedulingToolPanel;
