import { AgSortOrder } from "../AgGrid";
import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate, deserializeJson } from "../utils";

export interface Order {
  id: string;
  ziftId: string;
  shippingAddressId: string;
  createdAt: Date;
  updatedAt: Date;
  storeId?: string | null;
  lineItemsCount?: number | null;
  status?: OrderStatus | null; 
  shipBy?: Date | null;
  facilityId?: string | null;
  progress?: number | null;
  meta?: Object | null; // jsonb - TO DO: create interface that captures potential meta data
  erpId?: string | null;
  auditedAt?: Date | null;
  completedAt?: Date | null;
  boxes?: string[] | null;
  shippingService?: string | null;
  shippingCarrier?: string | null;
  printerModelCounts?: { [id: string] : string; } | null; // dictionary - printer model is index to counts
  externalId?: string | null;

  // TO DO: Create optional fields for the associated records like Facility
}

export const OrderDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate,
  shipBy: deserializeDate,
  auditedAt: deserializeDate,
  completedAt: deserializeDate
});

export const deserializeOrder = deserialize<Order>(OrderDeserializationSchema);
export const deserializeOrdersArray = deserializeArrayOf<Order>(OrderDeserializationSchema);

export enum OrderStatus {
  New = "new",
  Active = "active",
  Pending = "pending",
  Complete = "complete",
  Canceled = "canceled",
  Invalid = "invalid",
}

export interface OrderFilterInput {
  phone?: string | null;
  from?: Date | null;
  to?: Date | null;
  shipBefore?: Date | null;
  shipAfter?: Date | null;
  status?: Date | null;
  updatedBefore?: Date | null;
  updatedAfter?: Date | null;
}

export interface OrderSort {
  createdAt?: AgSortOrder | null;
  updatedAt?: AgSortOrder | null;
  status?: AgSortOrder | null;
  progress?: AgSortOrder | null;
  shipBy?: AgSortOrder | null;
  id?: AgSortOrder | null;
  ziftId?: AgSortOrder | null;
}

export interface Address {
  attention: string;
  city: string;
  company: string;
  countryCode: string;
  name: string;
  firstName: string;
  lastName?: string | null;
  phone?: string | null;
  provinceCode: string;
  street: string;
  postalCode: string;
  lat: number;
  lon: number;

  /* Legacy
  name_first: string;
  name_last: string;
  street1: string;
  street2: string;
  */
}

export enum OrderTimeColumn {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  ShipBy = 'ship_by',
  AuditedAt = 'audited_at',
  CompletedAt = 'completed_at'
}

export interface OrderStatusCounts {
  new: number;
  active: number;
  pending: number;
  complete: number;
  canceled: number;
  invalid: number;
}

export const EmptyOrderStatusCounts: Readonly<OrderStatusCounts> = Object.freeze({
  new: 0,
  active: 0,
  pending: 0,
  complete: 0,
  canceled: 0,
  invalid: 0
});

export interface OrderStats {
  timeColumn: OrderTimeColumn; // the time column used when generating these stats
  time: Date; // the date time (truncated as part the seriesResolution in the request) for this OrderStats
  from: Date; // all orders considered for computing these stats had to satisify: [time_column] >= [from]
  to: Date; // all orders considered for computing these stats had to satisfy: [time_column] < [to]
  storeIds: string[]; // all of the storeIds associated with the orders for this OrderStats block
  ordersCount: number; // the number of orders found in the specified date range with respects [time_column]
  lineItemsCount: number; // the number of associated line_items
  statusCount: OrderStatusCounts; // a break-down of the line_items in terms of their statuses
  firstOrderAt: Date | null; // the smallest [timeColumn] value amongst the Orders for this OrderStats block
  lastOrderAt: Date | null; // the greatest [timeColumn] value amongst the Orders for this OrderStats block
}

export const OrderStatsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  time: deserializeDate,
  from: deserializeDate,
  to: deserializeDate,
  statusCount: deserializeJson<OrderStatusCounts>,
  firstOrderAt: deserializeDate,
  lastOrderAt: deserializeDate
});

export const deserializeOrderStats = deserialize<OrderStats>(OrderStatsDeserializationSchema);
export const deserializeOrderStatsArray = deserializeArrayOf<OrderStats>(OrderStatsDeserializationSchema);
