import deserializeDate from "../utils/deserializeDate";
import { DeserializationSchema, deserialize, deserializeArrayOf } from "../utils/deserialize";
import { OrderStats, deserializeOrderStats } from "../Orders";

export interface Store_orderStats {
  ordersCount: number;
  lineItemsCount: number;
  activeCount: number;
  pendingCount: number;
  completeCount: number;
  canceledCount: number;
  invalidCount: number;
}

export interface Store {
  id: string;
  ziftId?: string | null;
  name?: string | null;
  color?: string | null;
  tagList?: string[] | null;
  createdAt: Date;
  updatedAt: Date;
  orderStats?: OrderStats | null;
}

export const storeDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate,
  orderStats: deserializeOrderStats
});

export const deserializeStore = deserialize<Store>(storeDeserializationSchema);
export const deserializeStoresArray = deserializeArrayOf<Store>(storeDeserializationSchema);
