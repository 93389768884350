import React, { useMemo } from "react";
import { GridOptions, GetRowIdParams } from "ag-grid-community";
import { isArray } from "lodash";
import { GetQuotumUsage_Include, QuotumUsage } from "../../../api/core";
import { DataGridProps, DataGrid } from "../../DataGrid";
import defaultColumnDefs from "./defaultColumnDefs";
import useQuotumUsageDatasource, { QuotumUsageDatasourceProps } from "./useQuotumUsageDatasource";

export interface QuotumUsageDataGridProps extends DataGridProps {
  include?: GetQuotumUsage_Include[]; 
}

export const QuotumUsageDataGrid = (props: QuotumUsageDataGridProps) => {
  const { plugins } = props;
  const options: QuotumUsageDatasourceProps = {};

  if (props.include) {
    options.include = props.include;
  }

  const datasource = useQuotumUsageDatasource({ options });

  const defaultGridOptions:GridOptions = useMemo(() => {
    return {
      columnDefs: defaultColumnDefs,
      serverSideDatasource: datasource,
      serverSideInfiniteScroll: true,
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => `${(param.data as QuotumUsage).quotumId}-${(param.data as QuotumUsage).date}`,
      animateRows: true,
      enableCellChangeFlash: true,
    };
  }, [datasource]);

  const gridOptionsArray:GridOptions[] = useMemo(() => {
    if (isArray(props.gridOptions)) {
      return [defaultGridOptions, ...props.gridOptions];
    } if (props.gridOptions) {
      return [defaultGridOptions, props.gridOptions];
    } 
    return [defaultGridOptions];
    
  }, [defaultGridOptions, props.gridOptions]);

  return (
    <DataGrid
      gridOptions={gridOptionsArray} 
      plugins={plugins}
    />
  );
};

export default QuotumUsageDataGrid;
