import CoreApi from '../CoreApi';
import { transformResponse, transformErrorResponse, snakeCaseKeys, prepareFilter, prepareSort } from '../utils';
import { FindOrders, FindOrdersVariables, deserializeFindOrders } from './FindOrders';
import { GetOrderStats, GetOrderStatsVariables, deserializeGetOrderStats } from './GetOrderStats';
import { GetOrders, GetOrdersVariables, GetOrders_AliasMap, deserializeGetOrders } from './GetOrders';
import { SyncOrder, SyncOrdersVariables, deserializeSyncOrder } from './SyncOrder';

// Extends the CoreApi with /orders endpoints
export const OrdersApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<GetOrders, GetOrdersVariables>({
      query: (params) => ({
        url: '/orders',
        method: "POST",
        body: {
          ...snakeCaseKeys(params),
          filter: params?.filter && prepareFilter(params.filter, GetOrders_AliasMap),
          sort: params?.sort && prepareSort(params.sort, GetOrders_AliasMap),
        }
      }),
      // TO DO: normalize the data
      transformResponse: transformResponse(deserializeGetOrders),
      transformErrorResponse: transformErrorResponse("getOrders"),
    }),

    getOrderStats: builder.query<GetOrderStats, GetOrderStatsVariables>({
      query: (params) => ({
        url: '/orders/statsV2',
        method: "GET",
        params: {
          ...snakeCaseKeys(params),
          from: params.from?.toISOString(),
          to: params.to?.toISOString()
        }
      }),
      transformResponse: transformResponse(deserializeGetOrderStats),
      transformErrorResponse: transformErrorResponse("getOrderStats"),
    }),

    findOrders: builder.query<FindOrders, FindOrdersVariables>({
      query: ({ id }) => ({
        url: `/orders/find/${id}`,
        method: "GET"
      }),
      transformResponse: transformResponse(deserializeFindOrders),
      transformErrorResponse: transformErrorResponse("findOrders"),
    }),

    syncOrder: builder.query<SyncOrder, SyncOrdersVariables>({
      query: ({ id }) => ({
        url: `/orders/d365/${id}`,
        method: "POST"
      }),
      transformResponse: transformResponse(deserializeSyncOrder),
      transformErrorResponse: transformErrorResponse("syncOrder"),
    }),
  }),
});

export default OrdersApi;
