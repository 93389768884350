import { ApplyColumnStateParams, ColumnApi, ColumnState } from "ag-grid-community";
import { isFunction } from "lodash";
import { useCallback, useMemo, useRef } from "react";
import IDataGridPlugin, { IDataGridPluginHook } from "./IDataGridPlugin";

export interface useColumnStatePluginParams {
  /** The initial filter model to apply when the grid becomes ready */
  initialState?: ApplyColumnStateParams | 
    ((state:ColumnState[], columnApi: ColumnApi) => ApplyColumnStateParams);
}

export interface ISetColumnSortPlugin extends IDataGridPlugin {
  getColumnState: () => ColumnState[] | undefined;
  applyColumnState: (params:ApplyColumnStateParams) => boolean;
}

export const useSetColumnSortPlugin:
IDataGridPluginHook<ISetColumnSortPlugin, useColumnStatePluginParams> = (params) => {
  const { initialState } = params;

  const columnRef = useRef<ColumnApi>();

  const getColumnState = useCallback(() => {
    return columnRef.current?.getColumnState();
  }, []);

  const applyColumnState = useCallback((params:ApplyColumnStateParams) => {
    if (!columnRef.current) { return false; }
    return columnRef.current.applyColumnState(params);
  }, []);

  const plugin = useMemo<ISetColumnSortPlugin>(() => ({
    getColumnState,
    applyColumnState,
    onGridReady: (e) => {
      columnRef.current = e.columnApi;
      if (initialState) {
        const stateUpdate = isFunction(initialState) ? 
          initialState(getColumnState()!, columnRef.current!) : initialState;
        applyColumnState(stateUpdate);
      }
    },
  }), [getColumnState, applyColumnState, initialState]);

  return plugin;
};

export default useSetColumnSortPlugin;
