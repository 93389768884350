import React, { useMemo, useState } from "react";
import { Select, SelectProps } from '../../Select';
import { FacilitiesApi } from "../../../api/core";

interface FacilitySelectProps extends SelectProps<any> {
    mode?: any,
    value?: any,
    onChange?: any,
    internal?: boolean,
    disabled?: boolean,
    useCode?: boolean
}

export const FacilitySelect = ({
  internal,
  value,
  onChange,
  mode,
  disabled,
  useCode,
  ...props
}: FacilitySelectProps) => {
  const [dataLoading, setDataLoading] = useState(true);

  const { data, isFetching } = FacilitiesApi.useGetFacilitiesQuery({});

  const facilitySet = useMemo(() => {
    return internal ? 
      data?.rows.filter(facility => !facility.external) :
      data?.rows;
  }, [data?.rows, internal]);

  if (facilitySet && !isFetching && dataLoading) {
    setDataLoading(false);
  }

  const cleanValue = useMemo(() => {
    if (useCode) {
      return facilitySet?.find(facility => facility.id === value)?.code ?? value;
    }
    return value;
  }, [value, facilitySet, useCode]);

  return (
    <Select
      disabled={disabled ?? false}
      mode={mode}
      key="facility-id"
      placeholder="Select Facility"
      value={cleanValue}
      loading={dataLoading}
      dropdownMatchSelectWidth={false}
      onChange={onChange}
      virtual={false}
      optionFilterProp="children"
      showSearch
      allowClear
      options={facilitySet?.map(({ code, id, name }) => {
        const value = useCode ? code ?? id : id;
        return {
          label: name,
          key: value,
          value,
        };
      })}
      {...props}
    />
  );
};

FacilitySelect.defaultProps = {
  internal: false,
  onChange: (args: any) => {},
};
