import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { BlankDemandReportTable } from "../../../components/DemandReport";

export const BlankDemandReportPage = () => {
  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <BlankDemandReportTable />
    </ErrorBoundary>
  );
};
