import React from 'react';
import { isNil, isUndefined } from 'lodash';
import { Link } from 'react-router-dom';

export const LinkCellRenderer = ({ data, colDef: { field }, value } : any) => {
  // Since the grid will use the cell renderer for the column even for
  // aggregation, we may not have data present, so default to returning value
  // And also call `toString`, because aggregations give you a
  // {value,count,toString,toNumber} object.
  if (isUndefined(data)) return value?.toString();

  const fieldData = data[field];
  if (isNil(fieldData) || typeof fieldData !== "object") return value;
  return <Link to={fieldData?.link}>{value}</Link>;
};
