import { ICellRendererParams } from "ag-grid-enterprise";

export interface LocaleNumberCellRendererParams extends ICellRendererParams {
  locales?: Intl.LocalesArgument | string | string[];
  options?: Intl.NumberFormatOptions;
}

export const LocaleNumberCellRenderer = (params: LocaleNumberCellRendererParams) => {
  const numberValue:number = (params.value || 0);
  if (numberValue === 0) {
    return "-";
  } 
  return numberValue.toLocaleString(params.locales, params.options);
};

export default LocaleNumberCellRenderer;
