import { TimeseriesMetadata } from "../common";
import { DeserializationFunc, DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "../utils";

export interface GetBatchCountsVariables {
  groupBy?: GetBatchCountsGroupByColumn;
  timeColumn?: GetBatchCountsTimeColumn;
  filter?: GetBatchCountsFilterInput;
}

export enum GetBatchCountsGroupByColumn {
  LastConfirmPullUserId = "last_confirm_pull_user_id"
}

export enum GetBatchCountsTimeColumn {
  CreatedAt = "created_at",
  UpdatedAt = "updated_at",
  LastConfirmPullAt = "last_confirm_pull_at",
}

export interface GetBatchCountsFilterInput {
  from?: Date | null;
  to?: Date | null;
  facilityId?: string | string[] | null;
  facilityCode?: string | string[] | null;
}

export interface BatchCounts {
  time: Date;
  metadata: TimeseriesMetadata;
  count: number;
  data: BatchCountsMetadata;
  sum: number;
}

export interface BatchCountsMetadata {
  key: string;
  value: string;
}

export const BatchCountsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  from: deserializeDate,
  to: deserializeDate,
  time: deserializeDate
});

export const deserializeBatchCounts = 
  deserialize<BatchCounts>(BatchCountsDeserializationSchema);

export const deserializeLineItemCountsArray =
  deserializeArrayOf<BatchCounts>(BatchCountsDeserializationSchema);

export type GetBatchCounts = BatchCounts[];
export const deserializeGetBatchCounts = 
  deserializeLineItemCountsArray as DeserializationFunc<GetBatchCounts>;
