import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { isEmpty } from "lodash";

interface TagsCellRendererProps extends ICellRendererParams {
  value: string[];
}

export const TagsCellRenderer = ({ value }: TagsCellRendererProps) => {
  if (isEmpty(value)) return null;

  const is_numeric = value.reduce((prev, curr) => prev && /^[0-9]+$/.test(curr), true);

  const data = is_numeric   
    ? value.map(v => parseInt(v, 10)).sort((a, b) => a - b)
    : value.slice(0).sort();

  return (
    <div>
      {data.map((tag) => (
        <div key={`tag_${tag}`} className="ant-tag">
          {tag}
        </div>    
      ))}
    </div>
  );
};
