import { BatchUnderConstructionEventType } from "./BatchUnderConstructionEventType";

export interface BatchUnderConstructionInitEventArgs {
}

export type BatchUnderConstructionInitEvent = CustomEvent<BatchUnderConstructionInitEventArgs>;

const __type: BatchUnderConstructionEventType = "init";
export const createBatchUnderConstructionInitEvent = () => 
  new CustomEvent<BatchUnderConstructionInitEventArgs>(__type);

export type BatchUnderConstructionInitEventListener = (e:BatchUnderConstructionInitEvent) => void;

export default BatchUnderConstructionInitEvent;
