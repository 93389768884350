import React from "react";
import { ErrorBoundary } from "@sentry/react";
import PageHeader from "./PageHeader";
import ErrorBoundaryFallback from "./ErrorBoundaryFallback";
import "./index.scss";
import { Route, Routes } from "react-router";

import { 
  HomePage,
  SummaryDemandReportPage,
  BatchDashboardPage, BatchManagePage, BatchPreviewPage, BinDemandReportPage,
  InsightsDashboardPage, InsightsExplorePage, LineItemDashboardPage, LineItemDemandPage, 
  LineItemManagePage, OrderDashboardPage, OrderManagePage, OutOfStockDemandReportPage, 
  PrintedDemandReportPage, Scheduler, SettingsPage, StoreManagePage, WaypointDemandReportPage,
  DetailDemandReportPage,
  ScansDataGridPage,
  BlankDemandReportPage,
  LineItemReportPage,
  BatchReportPage, 
  OrderReportPage,
  SyncPage,
  DuplicateOrderReportPage,
  ProductVariantPage
} from "../../pages";

import { ProgressDemandReportPage } from "../../pages/DemandReport/ProgressDemandReportPage";
import { CanceledReportPage } from "../../pages/DemandReport/CanceledDemandReportPage";
import { PullReportPage } from "../../pages/DemandReport/PullReportPage";
import { PrintReportPage } from "../../pages/DemandReport/PrintReportPage";
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import { ActivePrintReportPage } from "../../pages/DemandReport/ActivePrintReportPage";
import { AllocationReportPage } from "../../pages/DemandReport/AllocationReportPage";
import { AllocationSummaryPage } from "../../pages/DemandReport/AllocationSummaryPage";
import { PendingPrintReportPage } from "../../pages/DemandReport/PendingPrintDemandReportPage";

export const Page = () => {
  return (
    <div id="mad-page-container">
      <ToastContainer
        newestOnTop={false}
        hideProgressBar={false}
        autoClose={5000}
        pauseOnHover
        pauseOnFocusLoss
        closeOnClick={false}
        theme="light"
        position="top-right"
        draggable={false} // draggable = drag/swipe to remove toast
      />
      <PageHeader />
      <div id="mad-page-content">
        <ErrorBoundary fallback={ErrorBoundaryFallback}>
          <Routes>
            <Route 
              path="/" 
              element={<HomePage />} 
            />
            <Route
              path="/orders"
              element={<OrderManagePage />}
            />
            <Route
              path="/insights/orders"
              element={<OrderDashboardPage />}
            />
            <Route
              path="/line_items"
              element={<LineItemManagePage />}
            />
            <Route
              path="/insights/line_items"
              element={<LineItemDashboardPage />}
            />
            <Route
              path="/insights/demand"
              element={<LineItemDemandPage />}
            />
            <Route
              path="/insights/batches"
              element={<BatchDashboardPage />}
            />
            <Route
              path="/batches"
              element={<BatchManagePage />}
            />
            <Route
              path="/batches/preview"
              element={<BatchPreviewPage />}
            />
            <Route 
              path="/scans/*"
              element={<ScansDataGridPage />}
            />
            <Route 
              path="/schedules/*" 
              element={<Scheduler />} 
            />
            <Route
              path="/settings/*"
              element={<SettingsPage />}
            />
            <Route
              path="/stores"
              element={<StoreManagePage />}
            />
            <Route
              path="/insights"
              element={<InsightsDashboardPage />}
            />
            <Route
              path="/insights/explore"
              element={<InsightsExplorePage />}
            />
            <Route
              path="/reports/demand/summary"
              element={<SummaryDemandReportPage />}
            />
            <Route
              path="/reports/demand/printed"
              element={<PrintedDemandReportPage />}
            />
            <Route
              path="/reports/demand/oos"
              element={<OutOfStockDemandReportPage />}
            />
            <Route
              path="/reports/demand/bin"
              element={<BinDemandReportPage />}
            />
            <Route
              path="/reports/demand/waypoint"
              element={<WaypointDemandReportPage />}
            />
            <Route
              path="/reports/demand/detail"
              element={<DetailDemandReportPage />}
            />
            <Route
              path="/reports/demand/progress"
              element={<ProgressDemandReportPage />}
            />
            <Route
              path="/reports/demand/canceled"
              element={<CanceledReportPage />}
            />
            <Route
              path="/reports/pull"
              element={<PullReportPage />}
            />
            <Route
              path="/reports/batch"
              element={<BatchReportPage />}
            />
            <Route
              path="/reports/print"
              element={<PrintReportPage />}
            />
            <Route
              path="/reports/active_print"
              element={<ActivePrintReportPage />}
            />
            <Route
              path="/reports/blanks"
              element={<BlankDemandReportPage />}
            />
            <Route
              path="/reports/allocations/details"
              element={<AllocationReportPage />}
            />
            <Route
              path="/reports/allocations/summary"
              element={<AllocationSummaryPage />}
            />
            <Route
              path="/reports/ops/line_item"
              element={<LineItemReportPage />}
            />
            <Route
              path="/reports/ops/order"
              element={<OrderReportPage />}
            />
            <Route
              path="/reports/ops/order/duplicates"
              element={<DuplicateOrderReportPage />}
            />
            <Route
              path="/reports/pending_print"
              element={<PendingPrintReportPage />}
            />
            <Route
              path="/d365/sync"
              element={<SyncPage />}
            />
            <Route
              path="/d365/product"
              element={<ProductVariantPage />}
            />
          </Routes>
        </ErrorBoundary>
      </div>
    </div>
  );
};
