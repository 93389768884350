/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { isArray, sum } from "lodash";
import { Badge, Popover, Space } from "antd";

export interface ProgressValue {
  value: number;
  color?: string;
  title?: string;
  percentage?: number;
}

export interface ProgressProps {
  className?: string;
  total: number;
  value: number | ProgressValue | ProgressValue[];
  loading?: boolean;
  showDetails?: boolean;
  title?: string;
}

export const Progress = styled(
  ({ className, loading, title, total, value, showDetails }: ProgressProps) => {
    if (loading) return <LoadingOutlined />;

    const classNames = [className];
    const values = (isArray(value) ? value : [{ value }]) as ProgressValue[];

    if (showDetails) classNames.push("detailed");

    // Add all percentages to size the bars container
    const totalPercentage = sum(
      values.map(
        (progressValue) =>
          progressValue?.percentage ||
          (progressValue?.value / total) * 100.0 ||
          0.0
      )
    );
    const totalValue = sum(values.map(({ value: v }) => v));

    const popoverContent = values.map((progressValue) => {
      return (
        <div key={progressValue.title}>
          <Badge 
            overflowCount={Number.MAX_VALUE}
            count={progressValue?.value}
            style={{ color: '#000', backgroundColor: progressValue?.color }}
            text={progressValue?.title}
          />
        </div>
      );
    });

    const popoverContentWrapper = (
      <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
        {popoverContent}
      </Space>
    );

    const bars = values.map((progressValue) => {
      const percentage =
        progressValue?.percentage || (progressValue?.value / total) * 100.0;
      const barPercentage = (percentage / totalPercentage) * 100.0;
      const backgroundColor = progressValue?.color || "blue";
      return (
        <div
          key={`progress-bar-${progressValue.title}`}
          className="progress-bar"
          style={{ backgroundColor, width: `${barPercentage}%` }}
        >
          <span key="title" className="progress-bar-title">{progressValue?.title}</span>
          <span key="value" className="progress-bar-value">{progressValue?.value}</span>
        </div>
      );
    });

    return (
      <div className={classNames.join(" ")}>
        <div className="progress-header">{title}</div>
        <div className="progress-bars-outer">
          <Popover content={popoverContentWrapper} title={title}>
            <div
              key="progress-bars"
              className="progress-bars"
              style={{ width: `${totalPercentage}%` }}
            >
              {bars}   
            </div>
          </Popover>
        </div>
        <div className="progress-footer">
          <span className="progress-total-value">{totalValue}</span>
          <span className="progress-total">{total}</span>
          <span className="progress-total-percentage">
            {totalPercentage.toFixed(0)}%
          </span>
        </div>
      </div>
    );
  }
)`
  position: relative;
  border: 1px solid #eee;
  padding: 4px;
  margin-bottom: 4px;
  font-size: 12px;

  .progress-bars-outer {
    background-color: #efefef;
    border-radius: 4px;
  }

  .progress-bars {
    display: flex;
    flex-direction: row;
    max-width: 100%;

    .progress-bar {
      height: 16px;
      line-height: 16px;
      padding: 0;
      max-width: 100%;
      flex-shrink: 1;
      flex-grow: 0;
      display: flex;

      &:first-of-type {
        border-radius: 4px 0px 0px 4px;
      }

      &:last-of-type {
        border-radius: 0px 4px 4px 0px;
      }

      > span {
        display: none;
        flex: 1;
      }

      .progress-bar-title {
        margin-right: 4px;
        &:after {
          content: ": ";
        }
      }

      .progress-bar-value {
        text-align: right;
      }
    }
  }

  .progress-header {
    font-weight: bold;
  }

  .progress-footer {
    display: flex;

    > span {
      flex: 1;
    }

    .progress-total {
      text-align: center;
    }

    .progress-total-percentage {
      text-align: right;
    }
  }
`;
