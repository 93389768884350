import React from "react";
import {
  ColDef, ICellRendererParams, KeyCreatorParams, SetFilterValuesFuncParams, ValueFormatterParams
} from "ag-grid-enterprise";
import { DateTimeCellRenderer, TagCellRenderer } from "../../Table/renderers";
import { BatchProgressCellRenderer, StoresCellRenderer, TagsCellRenderer } from "./renderers";
import { RowNumberCellRenderer } from "../../DataGrid/renderers";
import { Tag } from "antd";
import * as Sentry from "@sentry/browser";
import PinCellRenderer from "./renderers/PinCellRenderer";
import PinColumnHeaderTemplate from "./headers/PinColumnHeaderTemplate";
import { GetStores, Store, GetFacilities, Facility, BatchStatus } from "../../../api/core";
import { startCase, toLower } from "lodash";

export interface CreateBatchTableColDefsProps {
  facilitiesPromise: Promise<GetFacilities>;
  storesPromise: Promise<GetStores>;
  includeCompoundPriorityInSort: boolean;
  includeSchedulePinnedField?: boolean;
}

const createBatchTableColDefs = ({ facilitiesPromise, storesPromise, includeCompoundPriorityInSort,
  includeSchedulePinnedField }: CreateBatchTableColDefsProps): ColDef[] => {
  // statuses in the order we want them displayed in the widget (match floor view as close as possible)
  const orderedStatuses = [
    BatchStatus.New, BatchStatus.Pulled, BatchStatus.InProgress, 
    BatchStatus.BrandingComplete, BatchStatus.DeliveredToPrinter,
    BatchStatus.Complete, BatchStatus.Deleted, BatchStatus.Canceled];
  // append any other statuses to the end
  const allStatuses = orderedStatuses.concat(Object.values(BatchStatus).filter(s => !orderedStatuses.includes(s)));
  const sortIndexOffset = (includeSchedulePinnedField ? 1 : 0) + (includeCompoundPriorityInSort ? 1 : 0);
  const colDefs:(ColDef | null | undefined)[] = [
    {
      headerName: "#",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: RowNumberCellRenderer,
      width: 70,
      minWidth: 70,
      pinned: 'left'
    },
    (includeSchedulePinnedField && {
      headerName: "Pinned",
      field: 'schedulePinned',
      pinned: "left",
      sort: "desc",
      sortIndex: 0,
      filter: false,
      floatingFilter: false,
      width: 85,
      minWidth: 85,
      headerComponentParams: { template: PinColumnHeaderTemplate },
      cellRenderer: PinCellRenderer,
    }) || undefined,
    {
      field: 'status',
      width: 140,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: allStatuses.map(v => v.toLocaleLowerCase()),
        suppressSorting: true, // preserve order we provide, rather than alpha sort
        keyCreator: (params: KeyCreatorParams<BatchStatus>) => {
          return params.value;
        },
        valueFormatter: (params: ValueFormatterParams<BatchStatus>) => {
          switch (params.value) {
            case BatchStatus.New.toLocaleLowerCase():
              return "Batched"; // call new batched to match floor view
            default:
              return startCase(params.value);
          }
        }
      },
      cellRenderer: TagCellRenderer,
      cellRendererParams: {
        color: ({ value }: any) => {
          switch (value.toUpperCase()) {
            case BatchStatus.New:
              return "geekblue";
            case BatchStatus.InProgress:
              return "lime";
            case BatchStatus.Complete:
              return "green";
            default:
              return "blue";
          }
        },
        label: ({ value }: any) => {
          switch (value) {
            case BatchStatus.New:
              return "Batched"; // call new batched to match floor view
            default:
              return startCase(toLower(value));
          }
        }
      }
    },
    {
      field: 'compoundPriority',
      filter: 'agNumberColumnFilter',
      sort: includeCompoundPriorityInSort ? 'desc' : undefined,
      sortIndex: includeCompoundPriorityInSort ? (includeSchedulePinnedField ? 1 : 0) : undefined,
    },
    {
      field: 'progress',
      filter: 'agNumberColumnFilter',
      cellRenderer: BatchProgressCellRenderer,
      cellRendererParams: { showPrinted: false /*showPrintProgress*/ }
    },
    {
      field: 'stores',
      sortable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          // hacky solution until I can find a way to properly assign the filter a complex value
          // complex values are supported - but the success function for asynchronously loading values
          // only takes a string[]...
          return storesPromise.then(data => {
            const { rows } = data;
            return params.success(rows.map(r => JSON.stringify(r)));
          });
        },
        comparator: (a: any, b: any) => {
          const A: Store = JSON.parse(a);
          const B: Store = JSON.parse(b);
          if (!A.name && !B.name) { return 0; }
          if (!A.name) { return -1; }
          if (!B.name) { return 1; }
          return A.name.localeCompare(B.name);
        },
        // NOTE: once we upgrade to ag-grid version 29+, we can use the keyGenerator property
        // to generate an internal key value separate from the display value 
        valueFormatter: (params: ValueFormatterParams) => {
          const v : Store = JSON.parse(params.value);
          return v.name;
        }
      },
      cellRenderer: StoresCellRenderer
    },
    {
      field: 'minShipBy',
      filter: 'agDateColumnFilter',
      cellRenderer: DateTimeCellRenderer,
      cellRendererParams: {
        showTime: false,
        icon: ({ value }: any) => {
          if ((new Date(value)).getTime() < new Date().getTime()) {
            return "warning";
          }
          return undefined;
        },
      }
    },
    {
      field: 'ziftId',
      headerName: 'Batch Id',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'orderExternalIds',
      headerName: 'Sales Order',
      sort: "asc",
      sortIndex: sortIndexOffset,
      cellRenderer: TagsCellRenderer
    },
    {
      field: 'boxes',
      sort: "asc",
      sortIndex: sortIndexOffset + 1,
      cellRenderer: (params: ICellRendererParams) => {
        if (!params.value || !params.value.length) {
          return null;
        }

        const boxes = (params.value as string[]).join(", ");
        const total = params.data.orderBoxes?.length || 0;
        const tagStyle: React.CSSProperties = {
          margin: "0px"
        };

        return (
          <span>
            <Tag style={tagStyle}>{boxes}</Tag> of <Tag style={tagStyle}>{total}</Tag>
          </span>
        );
      }
    },
    {
      headerName: 'Batched Facility',
      field: 'autoFacility.name',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          facilitiesPromise.then(
            (data) => {
              params.success(
                data.rows.filter(f => f.name).map(f => f.name!)
              );
            },
            (reason) => {
              const message = `Failed to load ${params.column.getColDef().field} filter values. ${reason}`;
              Sentry.captureMessage(message, 'error');
            }
          );
        },
      }
    },
    {
      field: 'note.content', filter: 'agTextColumnFilter', sortable: false
    },
    {
      field: 'schedule.date',
      filter: 'agDateColumnFilter',
      cellRenderer: DateTimeCellRenderer,
      cellRendererParams: { showTime: false }
    },
    {
      field: 'schedule.facility.name',
      headerName: 'Schedule Facility Name',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          facilitiesPromise.then(
            (data) => {
              params.success(
                data.rows.filter(f => f.name).map(f => f.name!)
              );
            },
            (reason) => {
              const message = `Failed to load ${params.column.getColDef().field} filter values. ${reason}`;
              Sentry.captureMessage(message, 'error');
            }
          );
        }
      }
    },
    {
      field: 'orderBoxes',
      cellRenderer: TagsCellRenderer
    },
    {
      field: 'flags', hide: true, sortable: false, cellRenderer: TagsCellRenderer
    },
    {
      field: 'type', hide: true, filter: 'agTextColumnFilter', sortable: false, cellRenderer: TagCellRenderer
    },
    {
      headerName: 'Batched Facility Id',
      field: 'autoFacility.id',
      hide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          facilitiesPromise.then(
            (data) => {
              // While Set Filters will supposedly work with complex data,
              // apparently they haven't bothered to update the SetFilterValuesFuncParams
              // interface to support them... so we need to get a bit hacky and store the
              // json of each object. We can then deserialize in the KeyCreator and ValueFormatter
              // functions...
              // https://stackoverflow.com/questions/72948039/how-to-asynchronously-load-complex-set-filter-values
              params.success(data.rows.map(f => JSON.stringify(f)));
            },
            (reason) => {
              const message = `Failed to load ${params.column.getColDef().field} filter values. ${reason}`;
              Sentry.captureMessage(message, 'error');
            }
          );
        },
        keyCreator: (params: KeyCreatorParams) => {
          const v: Facility = JSON.parse(params.value);
          return v.id;
        },
        valueFormatter: (params: ValueFormatterParams) => {
          const v: Facility = JSON.parse(params.value);
          return `[${v.name}] ${v.id}`;
        }
      }
    },
    {
      field: 'schedule.id', filter: 'agTextColumnFilter', hide: true
    },
    {
      field: 'priority', filter: 'agNumberColumnFilter'
    },
    {
      field: 'createdAt', filter: 'agDateColumnFilter', cellRenderer: DateTimeCellRenderer
    },
    {
      field: 'maxShipBy',
      filter: 'agDateColumnFilter',
      cellRenderer: DateTimeCellRenderer,
      cellRendererParams: {
        showTime: false,
        icon: ({ value }: any) => {
          if ((new Date(value)).getTime() < new Date().getTime()) {
            return "warning";
          }
          return undefined;
        },
      }
    },
    {
      field: 'updatedAt', filter: 'agDateColumnFilter', cellRenderer: DateTimeCellRenderer
    },
    {
      field: 'itemsCount', filter: 'agNumberColumnFilter'
    },
    {
      field: 'printsCount', filter: 'agNumberColumnFilter'
    },
    {
      field: 'completedItemsCount', filter: 'agNumberColumnFilter'
    },
    {
      field: 'lastConfirmPullAt',
      filter: 'agDateColumnFilter',
      cellRenderer: DateTimeCellRenderer,
      hide: true
    },
    {
      field: 'lastConfirmPullUserId',
      filter: 'agTextColumnFilter',
      hide: true
    },
    {
      field: 'scheduledAt',
      filter: 'agDateColumnFilter',
      cellRenderer: DateTimeCellRenderer,
      hide: true
    },
    {
      field: 'scheduledUserId',
      filter: 'agTextColumnFilter',
      hide: true
    },
    {
      field: 'pullBox',
      filter: 'agTextColumnFilter',
      hide: true
    },
    {
      field: 'idle',
      filter: 'agSetColumnFilter',
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? 'Yes' : 'No';
      },
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          params.success(['true', 'false']);
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value === 'true' ? 'Yes' : 'No';
        }
      },
      hide: true
    }
  ];

  return colDefs.filter(_ => !!_) as [ColDef];
};

export default createBatchTableColDefs;
