import React from "react";
import { Layout } from "antd";
import { AuthButton } from "..";
import { Logo } from "../..";
import { useIsAuthenticated } from "@azure/msal-react";

export interface AuthenticatedParams {
}

export const Authenticated = (props: React.PropsWithChildren<AuthenticatedParams>) => {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return (<>{props.children}</>);
  }

  return (
    <Layout.Content
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
      }}
    >
      <div style={{ textAlign: "center", color: "white" }}>
        <div style={{ width: "15vw" }}>
          <Logo />
        </div>
        <br />

        <AuthButton />
      </div>
    </Layout.Content>
  );
};
