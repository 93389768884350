import { AgGridDatasourceProps, GetLineItems, GetLineItemsVariables, GetLineItemsVariables_Options, GetLineItems_Include, LineItemsApi, useAgGridDatasource } from "../../../api/core";

export interface LineItemsDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetLineItemsVariables_Options, GetLineItemsVariables, GetLineItems>, "endpoint"> {
    include?: GetLineItems_Include[] | null | undefined;
  }

export const DefaultLineItemsDatasourceProps = Object.freeze<LineItemsDatasourceProps>({
  options: {
    count: true,
    include: ["store", "order", "blank"]
  }
});

export function useLineItemsDatasource(props: LineItemsDatasourceProps = DefaultLineItemsDatasourceProps) {
  return useAgGridDatasource<GetLineItemsVariables_Options>({
    ...props,
    endpoint: LineItemsApi.endpoints.getLineItems,
    options: {
      ...DefaultLineItemsDatasourceProps.options,
      ...props.options
    },
  });
}

export default useLineItemsDatasource;
