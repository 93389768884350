import React from "react";
import { isUndefined } from "lodash";

export function ProgressCellRenderer({ value }: any) {
  if (isUndefined(value)) return null;

  const pct = Math.floor(value * 100.0);
  let status;
  if (value > 0.0 && value < 1.0) status = "active";
  else if (value >= 1.0) status = "success";
  return (
    <div 
      style={{ minWidth: "100px", paddingRight: "12px" }} 
      className={`ant-progress ant-progress-line ant-progress-status-${status} ant-progress-show-info ant-progress-small`}
    >
      <div className="ant-progress-outer">
        <div className="ant-progress-inner">
          <div className="ant-progress-bg" style={{ width: `${pct}%`, height: "12px" }} />
        </div>
      </div>
      <span className="ant-progress-text" title={`${pct}%`}>{pct}%</span>
    </div>
  );
}
