import CoreApi from '../CoreApi';
import { GetLineItems_Input_AliasMap, type GetLineItems, type GetLineItemsVariables, deserializeGetLineItems } from './GetLineItems';
import { prepareFilter, prepareSort, transformResponse, transformErrorResponse, snakeCaseKeys } from '../utils';
import { GetLineItemCounts, GetLineItemCountsVariables, deserializeGetLineItemCounts } from './GetLineItemCounts';
import { GetLineItemWaypoints, GetLineItemWaypointsVariables, deserializeGetLineItemWaypoints } from './GetLineItemWaypoints';
import { GetLineItemStats, GetLineItemStatsVariables, deserializeGetLineItemStats } from './GetLineItemStats';

// Extend the CoreApi with /line_items endpoints
export const LineItemsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({

    getLineItems: builder.query<GetLineItems, GetLineItemsVariables>({
      query: (params) => ({
        url: '/line_items',
        method: "POST",
        // TO DO: modify the filter + sort on the server side so that everything can be snake_cased
        // currently, there is a mix of snake_case and camelCase expected for different things.
        // TO DO: modify the filter + sort on the server side so that everything can be snake_cased
        // currently, there is a mix of snake_case and camelCase expected for different things.
        body: {
          ...params,
          filter: params?.filter && prepareFilter(params.filter, GetLineItems_Input_AliasMap),
          sort: params?.sort && prepareSort(params.sort, GetLineItems_Input_AliasMap),
        }
      }),
      transformResponse: transformResponse(deserializeGetLineItems),
      transformErrorResponse: transformErrorResponse("getLineItems"),
    }),

    getLineItemCounts: builder.query<GetLineItemCounts, GetLineItemCountsVariables>({
      query: ({ groupBy, timeColumn, filter }) => ({
        url: '/line_items/counts',
        method: "GET",
        params: snakeCaseKeys({
          groupBy,
          timeColumn,
          ...(filter && {
            ...filter,
            from: filter.from?.toISOString(),
            to: filter.to?.toISOString(),
            shipBefore: filter.shipBefore?.toISOString(),
            shipAfter: filter.shipAfter?.toISOString(),
            completedBefore: filter.completedBefore?.toISOString(),
            completedAfter: filter.completedAfter?.toISOString(),
            printedBefore: filter.printedBefore?.toISOString(),
            printedAfter: filter.printedAfter?.toISOString(),
            updatedBefore: filter.updatedBefore?.toISOString(),
            updatedAfter: filter.updatedAfter?.toISOString(),
          })
        })
      }),
      transformResponse: transformResponse(deserializeGetLineItemCounts),
      transformErrorResponse: transformErrorResponse("getLineItemCounts"),
    }),

    getLineItemStats: builder.query<GetLineItemStats, GetLineItemStatsVariables>({
      query: (params) => ({
        url: '/line_items/stats',
        method: "GET",
        params: snakeCaseKeys({
          ...params,
          from: params.from?.toISOString(),
          to: params.to?.toISOString(),
          shipBefore: params.shipBefore?.toISOString(),
          shipAfter: params.shipAfter?.toISOString(),
        })
      }),
      transformResponse: transformResponse(deserializeGetLineItemStats),
      transformErrorResponse: transformErrorResponse("getLineItemStats"),
    }),

    getWaypoints: builder.query<GetLineItemWaypoints, GetLineItemWaypointsVariables>({
      query: (params) => ({
        url: '/line_items/waypoints',
        method: "GET",
        params: snakeCaseKeys({
          ...params,
          shipBefore: params.shipBefore?.toISOString(),
          shipAfter: params.shipAfter?.toISOString(),
        })
      }),
      transformResponse: transformResponse(deserializeGetLineItemWaypoints),
      transformErrorResponse: transformErrorResponse("getWaypoints"),
    }),

  }),
});

export default LineItemsApi;
