import deserialize, { DeserializationSchema, deserializeArrayOf } from "../utils/deserialize";
import { deserializeDate } from "../utils";
import { BatchStatus, BatchType, BatchFlag } from "./enums";

// This BaseBatch class maybe used in places like the Schedule model to avoid circular dependency
// issues that webpack 5 throws a hissy fit over (but which worked fine in webpack 4...)
export interface BaseBatch {
  id: string;
  ziftId?: string | null;
  schedulePinned?: boolean | null;
  scheduleId?: string | null;
  autoFacilityId?: string | null;
  status?: BatchStatus | null;
  type?: BatchType | null;
  priority?: number | null;
  operatorPriority?: number | null;
  compoundPriority?: number | null; // NOTE: a virtual / computed field, but not a join
  progress?: number | null;
  minShipBy?: Date | null;
  maxShipBy?: Date | null;
  createdAt: Date;
  updatedAt: Date;
  completedAt?: Date | null;
  itemsCount?: number | null;
  completedItemsCount?: number | null;
  boxes?: (string | null)[] | null;
  lastConfirmPullAt?: Date | null;
  lastConfirmPullUserId?: string | null;
  lastConfirmBrandedAt?: Date | null,
  lastConfirmDeliveredToPrinterAt?: Date | null,
  lastScanAt?: Date | null,
  flags?: BatchFlag[] | null;
  orderBoxes?: (string | null)[] | null;
  orderExternalIds?: (string | null)[] | null;
  scheduledAt?: Date | null;
  scheduledUserId?: string | null;
  pullBox?: string | null;
  idle?: boolean | null; // virtual field, but not a join
  printsScore?: number | null; // a double precision floating point value
  printsCount?: number | null; // an integer
  printerId?: string | null; 
}

export const baseBatchDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  minShipBy: deserializeDate,
  maxShipBy: deserializeDate,
  createdAt: deserializeDate,
  updatedAt: deserializeDate,
  completedAt: deserializeDate,
  lastConfirmPullAt: deserializeDate,
  lastConfirmBrandedAt: deserializeDate,
  lastConfirmDeliveredToPrinterAt: deserializeDate,
  lastScanAt: deserializeDate,
  scheduledAt: deserializeDate,
});

export const deserializeBaseBatch = deserialize<BaseBatch>(baseBatchDeserializationSchema);
export const deserializeBaseBatchArray = deserializeArrayOf<BaseBatch>(baseBatchDeserializationSchema);
