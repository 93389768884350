import CoreApi from '../CoreApi';
import { GetSettings, GetSettingsVariables, deserializeGetSettings } from './GetSettings';
import { snakeCaseKeys, transformResponse, transformErrorResponse } from '../utils';
import { UpdateSettings, UpdateSettingsVariables, deserializeUpdateSettings } from './UpdateSettings';

// Extends the CoreApi with /settings endpoints
export const SettingsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<GetSettings, GetSettingsVariables>({
      query: (params) => ({
        url: '/settings',
        method: 'GET'
      }),
      transformResponse: transformResponse(deserializeGetSettings),
      transformErrorResponse: transformErrorResponse("getSettings"),
    }),

    updateSettings: builder.mutation<UpdateSettings, UpdateSettingsVariables>({
      query: (params) => ({
        url: '/settings',
        method: 'PATCH',
        body: snakeCaseKeys(params)
      }),
      transformResponse: transformResponse(deserializeUpdateSettings),
      transformErrorResponse: transformErrorResponse("getSettings"),
    }),
  }),
});

export default SettingsApi;
