import IDataGridPlugin, { IDataGridPluginHook } from "./IDataGridPlugin";
import { useCallback, useMemo, useRef } from "react";
import { GridReadyEvent, ModelUpdatedEvent } from "ag-grid-community";

export interface IShowNoRowsOverlayPlugin extends IDataGridPlugin {
}

/**
 * The useShowNoRowsOverlayPlugin can be used to show the NoRowsOverlay when using the Server Side
 * Row Model (it should display automatically when using the Client Side Row Model).
 */
export const useShowNoRowsOverlayPlugin: IDataGridPluginHook<IShowNoRowsOverlayPlugin> = () => {
  const prevRowCount = useRef<number>(0);
  const onModelUpdated = useCallback((e:ModelUpdatedEvent) => {
    if (e.api.getDisplayedRowCount() === 0) {
      e.api.showNoRowsOverlay();
    } else if (prevRowCount.current === 0) {
      e.api.hideOverlay();
    }
    prevRowCount.current = e.api.getDisplayedRowCount();
  }, []);

  const onGridReady = useCallback((e:GridReadyEvent) => {
    e.api.addEventListener('modelUpdated', onModelUpdated);
    return () => {
      e.api.removeEventListener('modelUpdated', onModelUpdated);
    };
  }, [onModelUpdated]);

  const plugin = useMemo<IShowNoRowsOverlayPlugin>(() => ({
    onGridReady,
  }), [onGridReady]);

  return plugin;
};

export default useShowNoRowsOverlayPlugin;
