import { GridApi, IServerSideGetRowsParams } from "ag-grid-enterprise";
import * as Sentry from "@sentry/browser";
import { BatchesApi, GetBatches, GetBatchesVariables, GetBatchesVariables_Includes } from "../../../../api/core/Batches";
import { AgGridServerSideDatasource } from "../../../../api/core";
import { useCallback, useRef } from "react";
import { FilterChangedEvent } from "ag-grid-community";
import { useReEntrantLazyQuerySubscription } from "../../../../api/core/utils/useReEntrantLazyQuery";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";

export interface BatchServerSideDatasourceProps {
  includes?: GetBatchesVariables_Includes[];
  count?: boolean; // if true, then the datasource will fetch the count each time the filters change
}

// NOTE: Don't include any of the stats in the default includes; they are expensive
export const DefaultIncludes:GetBatchesVariables_Includes[] = [
  "auto_facility", "note", "schedule", "schedule_facility", "stores", "line_item_stats", "store_stats"
];

export const useBatchServerSideDatasource = (props: BatchServerSideDatasourceProps): AgGridServerSideDatasource => {
  const includes = props.includes || DefaultIncludes;
  const canCount = props.count;

  //const [trigger] = BatchesApi.useLazyGetBatchesQuery();
  const endpoint = BatchesApi.endpoints.getBatches;
  const [trigger] = useReEntrantLazyQuerySubscription<QueryDefinition<GetBatchesVariables, any, any, GetBatches, any>>(endpoint);
  const apiRef = useRef<GridApi>();
  const recomputeCount = useRef<boolean>(true);

  const onFilterChanged = useCallback((e: FilterChangedEvent) => {
    recomputeCount.current = true;
  }, []);

  return {
    getRows(params : IServerSideGetRowsParams) {
      if (apiRef.current !== params.api) {
        if (apiRef.current) {
          apiRef.current.removeEventListener('filterChanged', onFilterChanged);
        }
        recomputeCount.current = true;
        apiRef.current = params.api;
        apiRef.current.addEventListener('filterChanged', onFilterChanged);
      }

      const start = params.request.startRow || 0;
      const end = params.request.endRow || 0;
      const offset = start;
      const limit = (end > start) ? end - start : 0;

      // This is needed to cleanly implement the stores column def,
      // which has a complex data type. A little hacky - will need to contact support to see if
      // there isn't a better way to go about this.
      const filter = params.request.filterModel;
      if ("stores" in filter && filter.stores?.values) {
        const values = filter.stores.values.map((entry:string) => {
          const v = JSON.parse(entry);
          return v.id;
        });
        filter.stores.values = values;
        filter["stores.id"] = filter.stores;
        delete filter.stores;
      }

      const query = trigger({
        offset,
        limit,
        sort: params.request.sortModel, 
        filter,
        options: {
          include: includes,
          count: canCount && recomputeCount.current
        }
      }, false).unwrap();

      query.then((response) => {
        try {
          const { count = undefined, rows = [] } = response;

          let rowCount = count || undefined;
          if (rowCount === undefined) {
            if (limit > 0 && limit > rows.length) {
              rowCount = rows.length + offset;
            } else if (limit === 0) {
              rowCount = rows.length + offset;
            }
          } else {
            recomputeCount.current = false;
          }

          params.success({
            rowCount,
            rowData: rows
          });
        } catch (err: any) {
          //console.error(err);
          Sentry.captureException(err);
          params.fail();
        }
      }, (err) => {
        //console.error(err);
        Sentry.captureException(err);
        params.fail();
      });
    }, // getRows

    destroy: () => { /* cleanup here */ }
  };
};

export default useBatchServerSideDatasource;
