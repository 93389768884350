import { 
  ColDef,
  ColumnApi, 
  ColumnMovedEvent, 
  ColumnPinnedEvent, 
  ColumnPivotChangedEvent, 
  ColumnPivotModeChangedEvent, 
  ColumnResizedEvent, 
  ColumnState, 
  ColumnVisibleEvent, 
  DisplayedColumnsChangedEvent, 
  FilterChangedEvent, 
  GridApi, 
  GridColumnsChangedEvent, 
  GridReadyEvent, 
  SortChangedEvent 
} from "ag-grid-community";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IDataGridPlugin, IDataGridPluginHook } from "./IDataGridPlugin";
import * as Sentry from "@sentry/browser";
import { Convert, JsonParam, useQueryParams } from "../../../api/useQueryParams";

type FilterModel = { [key: string]: any };
const querySchema = {
  filterModel: Convert<FilterModel | null | undefined, any>(JsonParam, value => value, 
    value => value as FilterModel | null | undefined),
  columnState: Convert<ColumnState[] | null | undefined, any>(JsonParam, value => value,
    value => value as ColumnState[] | null | undefined)
};

type ColDefsMap = {
  [colId: string]: ColDef
};

const handleError = (action: () => void) => {
  try {
    action();
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const useGridStateSearchParamPlugin: IDataGridPluginHook = () => {
  const gridReady = useRef(false);
  const [api, setApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [initialColDefs, setInitialColDefs] = useState<ColDefsMap>();
  const [{ filterModel, columnState }, setQueryValues] = useQueryParams(querySchema);

  const saveFilterState = useCallback(() => {
    handleError(() => {
      const filter = api!.getFilterModel() || {};
      setQueryValues(prev => ({
        ...prev, 
        filterModel: Object.keys(filter).length ? filter : undefined 
      }));
    });
  }, [setQueryValues, api]);

  const saveColumnState = useCallback(() => {
    handleError(() => {
      const state = columnApi!.getColumnState();   

      // In order to avoid creating urls that are too long for the server-side to process,
      // resulting in 413 Errors, we will want to trim the column state information down to
      // only those fields that we care about.
      const minimized = state.map(cs => { 
        const initialCD = initialColDefs?.[cs.colId];
        if (!initialCD) { return undefined; }

        // null = clear, undefined = no change
        /* eslint-disable eqeqeq */
        const result: ColumnState = {
          colId: cs.colId,
          hide: (cs.hide != initialCD.hide) ? cs.hide || null : undefined,
          sort: (cs.sort != initialCD.sort) ? cs.sort || null : undefined,
          sortIndex: (cs.sortIndex != initialCD.sortIndex) ? cs.sortIndex || null : undefined,
          rowGroup: (cs.rowGroup != initialCD.rowGroup) && !!(cs.rowGroup || initialCD.rowGroup) 
            ? cs.rowGroup || null 
            : undefined,
          rowGroupIndex: (cs.rowGroupIndex != initialCD.rowGroupIndex) 
            ? cs.rowGroupIndex || null 
            : undefined,
          pinned: (cs.pinned != initialCD.pinned) ? cs.pinned || null : undefined,
          pivot: (cs.pivot != initialCD.pivot) && !!(cs.pivot || initialCD.pivot)
            ? cs.pivot || null 
            : undefined,
          pivotIndex: (cs.pivotIndex != initialCD.pivotIndex) ? cs.pivotIndex || null : undefined,
          width: (!cs.hide && cs.width != initialCD.width) ? cs.width : undefined,
          flex: (!cs.hide && cs.flex != initialCD.flex) ? cs.flex || null : undefined,
        };
        /* eslint-enable eqeqeq */

        return result;
      });

      setQueryValues(prev => ({ 
        ...prev, 
        columnState: minimized.filter(cs => cs && Object.keys(cs).length > 1) as ColumnState[]
      }));
    });
  }, [setQueryValues, columnApi, initialColDefs]);

  // After the grid is ready, the api and columnApi will be set
  // Perform any extension initialization here
  useEffect(() => {
    if (api && columnApi) {
      // Register Event Handlers
      const eventHandlers: {[key:string]: (e: any) => void} = {
        filterChanged: (_e: FilterChangedEvent) => saveFilterState(),
        sortChanged: (_e: SortChangedEvent) => saveColumnState(),
        columnResized: (_e: ColumnResizedEvent) => saveColumnState(),
        columnMoved: (_e: ColumnMovedEvent) => saveColumnState(),
        columnVisible: (_e: ColumnVisibleEvent) => saveColumnState(),
        columnPinned: (_e: ColumnPinnedEvent) => saveColumnState(),
        gridColumnsChanged: (_e: GridColumnsChangedEvent) => saveColumnState(),
        displayedColumnsChanged: (_e: DisplayedColumnsChangedEvent) => saveColumnState(),
        columnPivotModeChanged: (_e: ColumnPivotModeChangedEvent) => saveColumnState(),
        columnPivotChanged: (_e: ColumnPivotChangedEvent) => saveColumnState(),
      };

      Object.keys(eventHandlers).forEach(evt => {
        api.addEventListener(evt, eventHandlers[evt]);
      });

      return () => {
        Object.keys(eventHandlers).forEach(evt => {
          api.removeEventListener(evt, eventHandlers[evt]);
        });
      };
    } 
    
    return () => {};
  }, [api, columnApi, saveFilterState, saveColumnState]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const plugin: IDataGridPlugin = useMemo(() => ({
    onGridReady: (e: GridReadyEvent) => {
      gridReady.current = true;

      setApi(e.api);
      setColumnApi(e.columnApi);

      setInitialColDefs((_) => {
        const results: ColDefsMap = {};
        e.columnApi.getColumns()?.forEach(col => {
          if (!col) { return; }
          results[col.getColId()] = col.getColDef();
        });
        return results;
      });

      // Restore the filter model
      handleError(() => {
        if (filterModel) {
          e.api.setFilterModel(filterModel);
        }
      });

      // Restore column state
      handleError(() => {
        if (columnState) {
          e.columnApi.applyColumnState({
            applyOrder: true,
            state: columnState,
            defaultState: {
              hide: true
            }
          });
        }
      });
    }
  }), []);

  return plugin;
};

export default useGridStateSearchParamPlugin;
