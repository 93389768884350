import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "../utils";

export interface Note {
  id: string;
  content?: string | null;
  parentId?: string | null;
  parentType?: string | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

export type NoteContent = Partial<Note> & { content: string };

export const NoteDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate
});

export const deserializeNote = deserialize<Note>(NoteDeserializationSchema);
export const deserializeNotesArray = deserializeArrayOf<Note>(NoteDeserializationSchema);
