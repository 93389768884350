import React from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import 'bootstrap/dist/css/bootstrap.min.css';

const sentryEnvironment = process?.env?.SENTRY_ENVIRONMENT || 'prd';
Sentry.init({
  dsn: process?.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing(), new CaptureConsole()],
  tracesSampleRate: 0.7,
  environment: sentryEnvironment,
  release: process?.env.REACT_APP_VERSION,
  debug: sentryEnvironment === 'dev'
});

const render = (Component: any) =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(<Component />, document.getElementById("root"));

render(App);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
