import { AgGridDatasourceProps, BatchesApi, GetBatches, GetBatchesVariables, GetBatchesVariables_Includes, GetBatchesVariables_Options, useAgGridDatasource } from "../../../api/core";

export interface BatchesDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetBatchesVariables_Options, GetBatchesVariables, GetBatches>, "endpoint"> {
    include?: GetBatchesVariables_Includes[] | null | undefined;
  }

export const DefaultBatchesDatasourceProps = Object.freeze<BatchesDatasourceProps>({
  options: {
    count: true,
    include: ["stores"]
  }
});

export function useBatchesDatasource(props: BatchesDatasourceProps = DefaultBatchesDatasourceProps) {
  return useAgGridDatasource<GetBatchesVariables_Options>({
    ...props,
    endpoint: BatchesApi.endpoints.getBatches,
    options: {
      ...DefaultBatchesDatasourceProps.options,
      ...props.options
    },
  });
}

export default useBatchesDatasource;
