import React from "react";
import { Col as AntCol, ColProps as AntColProps } from "antd";
import PropTypes from "prop-types";

interface ColProps extends AntColProps {
  size?: "large" | "small";
  spans?: { [key: string]: number };
}

const sizeMap = {
  large: {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
    xxl: 6,
  },
  small: {
    xs: 8,
    sm: 6,
    md: 6,
    lg: 4,
    xl: 4,
    xxl: 2,
  },
} as Record<string, any>;

export const Col = ({ children, spans, size, ...props }: ColProps) => {
  const map = {
    ...sizeMap[size || "large"],
    ...spans,
  };
  return (
    <AntCol
      xs={map.xs}
      sm={map.sm}
      md={map.md}
      lg={map.lg}
      xl={map.xl}
      xxl={map.xxl}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </AntCol>
  );
};

Col.propTypes = {
  children: PropTypes.node,
};

Col.defaultProps = {
  children: null,
  size: "large",
  spans: undefined,
};
