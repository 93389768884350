import React, { useMemo } from "react";
import { Select, SelectProps } from "..";
import { compact, intersection, isEmpty, remove, uniq } from "lodash";
import Link from "antd/lib/typography/Link";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { DefaultOptionType } from "antd/lib/select";
import { Store, StoresApi } from "../../api/core";

interface StoreSelectProps extends SelectProps<any> {
  placeholder?: string;
  size?: SizeType;
  groupBy?: "tags";
  // filterOption does not seem to do what I want it to do.
  includeStore?: Function;
}

interface ToggleGroupParams {
  group: string;
  groups: { [key: string]: Store[] };
  value: string[];
}

export const toggleGroup = ({ group, groups, value }: ToggleGroupParams) => {
  const groupStoreIds = groups[group].map((store) => store.id);

  if (!isEmpty(value) && !isEmpty(intersection(value, groupStoreIds))) {
    return remove(value as any, (id) => !groupStoreIds.includes(id as string));
  }
  return uniq([...(value || []), ...groupStoreIds]);
};

export const StoreSelect = ({
  value,
  onChange,
  mode,
  groupBy,
  style,
  includeStore,
  placeholder,
  size,
  ...props
}: StoreSelectProps) => {

  const { data, isFetching } = StoresApi.useGetStoresQuery({});
  
  const sortLabelAscending = (a: DefaultOptionType, b: DefaultOptionType) =>
    a.label && b.label && a.label > b.label ? 1 : -1;

  const options: DefaultOptionType[] = useMemo(() => {
    if (!data?.rows) return [];

    if (groupBy === "tags") {
      const groups: { [key: string]: Store[] } = { untagged: [] };
      data?.rows?.forEach((store: Store) => {
        if (!includeStore || includeStore(store.id)) {
          if (!store?.tagList || isEmpty(store?.tagList))
            groups.untagged.push(store);
          else
            store?.tagList.forEach((tag: string) => {
              if (!groups[tag]) groups[tag] = [store];
              else groups[tag] = uniq([...groups[tag], store]);
            });
        }
      });

      return Object.keys(groups)
        .map((group) => ({
          label: (
            <span>
              <strong>{group}</strong>{" "}
              <Link
                onClick={() => {
                  if (onChange) onChange(toggleGroup({ group, groups, value }), null as any);
                }}
              >
                Select All
              </Link>
            </span>
          ),
          options: groups[group]
            .map(({ name, id }) => ({
              key: [group, id].join("-"),
              label: name,
              value: id,
            }))
            .sort(sortLabelAscending),
        }))
        .sort(sortLabelAscending);
    }

    return compact(
      (data?.rows
        .map(({ id, name }: Pick<Store, "id" | "name">) =>
          includeStore && includeStore(id)
            ? {
              label: name,
              value: id,
            }
            : undefined
        )
        .filter(_ => _ !== undefined) as DefaultOptionType[]
      ).sort(sortLabelAscending)
    );
  }, [data?.rows, groupBy, includeStore, onChange, value]);

  return (
    <Select
      key="store-id"
      placeholder={placeholder || "Select Store"}
      style={style}
      size={size}
      value={value}
      loading={isFetching}
      dropdownMatchSelectWidth={false}
      onChange={onChange}
      showSearch
      showArrow
      allowClear
      optionFilterProp="label"
      virtual={false}
      mode={mode}
      options={options}
      maxTagCount="responsive"
      maxTagPlaceholder="..."
      {...props}
    />
  );
};

StoreSelect.defaultProps = {
  groupBy: undefined,
  includeStore: () => true,
};
