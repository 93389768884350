import { DeserializationFunc } from "../utils";
import { Picksheet, deserializePicksheet } from "./common";

export interface CreatePicksheetVariables {
  lineItemIds: string[];
}

export type CreatePicksheet = Picksheet;
export const deserializeCreatePicksheet = 
  deserializePicksheet as DeserializationFunc<CreatePicksheet>;
