import React from "react";
import { OrdersApi } from "../api/core";
import { DateTime } from "luxon";

interface OrderCountProps {
  from?: Date;
  to?: Date;
  storeId?: string;
}

const Component = ({ from, to, storeId }: OrderCountProps) => {
  const { data } = OrdersApi.useGetOrderStatsQuery({ from, to, storeId }, {
    refetchOnMountOrArgChange: true
  });

  return <span>{data?.ordersCount} </span>;
};

Component.defaultProps = {
  storeId: "",
  from: DateTime.now().startOf('day').toJSDate(),
  to: DateTime.now().endOf('day').toJSDate(),
};

export const OrderCount = Component;
