import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "../utils";

export interface Bin {
  id: string;
  ziftId?: string | null;
  createdAt: Date;
  updatedAt: Date;
  externalId?: string | null;
}

export const BinDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate
});
  
export const deserializeBin = deserialize<Bin>(BinDeserializationSchema);
export const deserializeBinArray = deserializeArrayOf<Bin>(BinDeserializationSchema);
