import { xor } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

interface CheckListOption {
  title: any;
  value: string;
}

interface CheckListOnChangeCallback {
  (value: Array<any> | null): void;
}

interface CheckListProps {
  className?: string;
  options: CheckListOption[];
  value?: Array<any> | null;
  onChange?: CheckListOnChangeCallback;
}

const UnstyledCheckList = ({ className, options, value, onChange }: CheckListProps) => {
  const [values, setValues] = useState<any>([]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  const toggle = useCallback(
    (v: any) => {
      const newValues = xor(values, [v]);
      setValues(newValues);
      if (onChange) onChange(newValues);
    },
    [values, onChange]
  );

  const optionElements = options.map((option) => (
    <span
      key={`option_${option.title}`}
      className={values?.includes(option?.value) ? "active" : "inactive"}
      onClick={() => toggle(option?.value)}
    >
      {option?.title}
    </span>
  ));

  return (
    <div className={className}>
      <b>Legend</b>
      {optionElements}
    </div>
  );
};

UnstyledCheckList.defaultProps = {
  className: "",
  value: [],
  onChange: () => {},
};

export const CheckList = styled(React.memo(UnstyledCheckList))`
  display: flex;
  flex-direction: column;

  > span {
    background: #fafafa;
    padding: 3px;
    margin-bottom: 2px;
    border-radius: 6px;
    cursor: pointer;

    &.active {
      background: aqua;
    }
  }
`;
