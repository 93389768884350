import React, { SVGProps } from "react";

export interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string;
  color?: string;
  style?: React.CSSProperties;
}

export const Icon = ({ size, color, height, style, children, width, ...props }: IconProps) => {
  return (
    <svg
      role="img"
      aria-hidden="true"
      fill={color ?? "currentColor"}
      focusable="false"
      height={size ?? height ?? "1em"}
      width={size ?? width ?? "1em"}
      viewBox="0 0 24 24"
      style={{
        pointerEvents: "none",
        ...style,
      }}
      {...props}
    >
      {children}
    </svg>
  );
};
