import { AgGridDatasourceProps, GetQuotumUsage, GetQuotumUsageVariables, GetQuotumUsageVariables_Options, GetQuotumUsage_Include, QuotumUsageApi, useAgGridDatasource } from "../../../api/core";

export interface QuotumUsageDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetQuotumUsageVariables_Options, GetQuotumUsageVariables, GetQuotumUsage>, "endpoint"> {
    include?: GetQuotumUsage_Include[] | null | undefined;
  }

export const DefaultQuotumUsageDatasourceProps = Object.freeze<QuotumUsageDatasourceProps>({
  options: {
    include: ['quotum', 'store']
  }
});

export function useQuotumUsageDatasource(props: QuotumUsageDatasourceProps = DefaultQuotumUsageDatasourceProps) {
  return useAgGridDatasource<GetQuotumUsageVariables_Options>({
    ...props,
    endpoint: QuotumUsageApi.endpoints.getQuotumUsage,
    options: {
      count: true,
      ...DefaultQuotumUsageDatasourceProps.options,
      ...props.options
    },
  });
}

export default useQuotumUsageDatasource;
