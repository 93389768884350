import React, { useEffect, useState } from "react";
import {
  AutoAwesomeOutlinedRounded,
  ColorModeDarkOutlinedRounded,
  ColorModeLightOutlinedRounded,
  FilterDramaOutlinedRounded,
} from "../../../fixtures/icons";
import "./themes/lightTheme.scss";
import "./themes/darkTheme.scss";
import "./index.scss";

export enum Themes {
  lightMode = "light",
  darkMode = "dark",
}

export const ThemeColorStorageKey = "theme:mode";

export const ThemeColorModeToggle = () => {
  const prefersDark =
    localStorage.getItem(ThemeColorStorageKey) === Themes.darkMode ||
    (!localStorage.getItem(ThemeColorStorageKey) && window.matchMedia("(prefers-color-scheme: dark)").matches);

  const [theme, setTheme] = useState(prefersDark ? Themes.darkMode : Themes.lightMode);

  const toggleTheme = () => {
    if (theme === Themes.lightMode) {
      setTheme(Themes.darkMode);
    } else {
      setTheme(Themes.lightMode);
    }
  };

  useEffect(() => {
    localStorage.setItem(ThemeColorStorageKey, theme);
    document.body.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <div id="theme-toggle" onClick={toggleTheme}>
      <div className="theme-toggle theme-toggle-light">
        <div className="theme-toggle-icon theme-toggle-icon-sun">
          <ColorModeLightOutlinedRounded />
        </div>
        <div className="theme-toggle-icon theme-toggle-icon-cloud">
          <FilterDramaOutlinedRounded />
        </div>
      </div>
      <div className="theme-toggle theme-toggle-dark">
        <div className="theme-toggle-icon theme-toggle-icon-moon">
          <ColorModeDarkOutlinedRounded />
        </div>
        <div className="theme-toggle-icon theme-toggle-icon-stars">
          <AutoAwesomeOutlinedRounded />
        </div>
      </div>
    </div>
  );
};
