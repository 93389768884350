import { Facility, deserializeFacilitiesArray } from "./common";
import deserialize, { DeserializationSchema } from "../utils/deserialize";
import { AgGridQueryArgs, AgQueryResponse } from "../AgGrid";

export interface GetFacilities extends AgQueryResponse<Facility> {}

export interface GetFacilitiesVariables extends AgGridQueryArgs {}

export const GetFacilitiesDeserializationsSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeFacilitiesArray,
});

export const deserializeGetFacilities = 
  deserialize<GetFacilities>(GetFacilitiesDeserializationsSchema);
