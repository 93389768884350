import React from "react";
import cuphead from "./cuphead.gif";

export const HomePage = () => {
  return (
    <div style={{ margin: "auto", textAlign: "center" }}>
      What to do here? Some kind of dashboard perhaps?
      <br />
      <img alt="cuphead" src={cuphead} style={{ width: "25%", height: "25%" }} />
    </div>
  );
};
