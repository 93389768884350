import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { RefObject, useCallback, useRef, useState } from "react";
import IDataGridPlugin, { IDataGridPluginHook } from "./IDataGridPlugin";

export interface IGridRefPlugin extends IDataGridPlugin {
  apiRef: RefObject<GridApi | undefined>,
  colApiRef: RefObject<ColumnApi | undefined>,
  ready: Readonly<boolean>
}

export const useGridRefPlugin: IDataGridPluginHook<IGridRefPlugin> = () => {
  const apiRef = useRef<GridApi>();
  const colApiRef = useRef<ColumnApi>();
  const [ready, setReady] = useState<boolean>(false);

  const onGridReady = useCallback((e: GridReadyEvent) => {
    apiRef.current = e.api;
    colApiRef.current = e.columnApi;
    setReady(true);
  }, [setReady]);

  return { apiRef, colApiRef, ready, onGridReady };
};

export default useGridRefPlugin;
