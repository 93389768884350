// JSON.stringify doesn't turn an undefined value into a string
// also - whenever a value is undefined we won't serialize it, but

import { isBoolean, isNumber, isString } from "lodash";
import { startOfDayLocal } from "../../helpers";

// will instead remove it from the URL
export function defaultSerialize(value: Exclude<any, undefined>) {
  return JSON.stringify(value);
}

export function defaultDeserialize(data: string): Exclude<any, undefined> {
  return JSON.parse(data);
}

export const stringParamSerialize = (value: Readonly<string | null>) => defaultSerialize(value);

export const stringParamDeserialize = (data: string): string | null => {
  const result = defaultDeserialize(data);
  if (result !== null && !isString(result)) {
    throw new Error("Not a string!");
  }
  return result;
};

export const numberParamSerialize = (value: Readonly<number | null>) => defaultSerialize(value);

export const numberParamDeserialize = (data: string): number | null => {
  const result = defaultDeserialize(data);
  if (result !== null && !isNumber(result)) {
    return NaN;
  }
  return result;
};

export const booleanParamSerialize = (value: Readonly<boolean | null>) => defaultSerialize(value);

export const booleanParamDeserialize = (data: string): boolean | null => {
  const result = defaultDeserialize(data);
  if (!isBoolean(result)) { return !!result; }
  return result;
};

// serialize using the format YYYY-MM-DD
export const dateParamSerialize = (value: Readonly<Date | null>) => {
  if (value === null) {
    return 'null';
  } 
  
  const yyyy = value.getUTCFullYear().toString(10).padStart(4, "0");
  const mm = (value.getUTCMonth() + 1).toString(10).padStart(2, "0"); // only the month starts at 0
  const dd = value.getUTCDate().toString(10).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;  
};

export const dateParamDeserialize = (data: string): Date | null => {
  if (data === 'null') { return null; }
  const parts = data.split("-") as [string, string, string];
  const [yyyy, mm, dd] = parts.map(p => parseInt(p, 10));
  return new Date(Date.UTC(yyyy, mm - 1, dd));
};

// serialize using the format YYYY-MM-DD
export const dateLocalParamSerialize = (value: Readonly<Date | null>) => {
  if (value === null) { return 'null'; }
  const newValue = startOfDayLocal(value);
  const yyyy = newValue.getFullYear().toString(10).padStart(4, "0");
  const mm = (newValue.getMonth() + 1).toString(10).padStart(2, "0"); // only the month starts at 0
  const dd = newValue.getDate().toString(10).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;  
};

export const dateLocalParamDeserialize = (data: string): Date | null => {
  if (data === 'null') { return null; }
  const parts = data.split("-") as [string, string, string];
  const [yyyy, mm, dd] = parts.map(p => parseInt(p, 10));
  return new Date(yyyy, mm - 1, dd);
};

export const dateTimeParamSerialize = (value: Readonly<Date | null>) => {
  return value?.toISOString() || 'null';
};

export const dateTimeParamDeserialize = (data: string): Date | null => {
  if (data === 'null') { return null; }
  return new Date(data);
};

// JSON.stringify will convert undefined array entries to "null"
export const arrayParamSerialize = (value: ReadonlyArray<string | null> | null) => {
  if (value && !Array.isArray(value)) {
    // for consistency with the replaced api and to be more forgiving, we will convert to an array
    value = [value.toString()];
  }
  return defaultSerialize(value);
};

export const arrayParamDeserialize = (data: string): (string | null)[] | null => {
  const result = defaultDeserialize(data);
  if (result !== null && !Array.isArray(result)) {
    throw new Error("Not an array");
  }
  return result;
};

export const stringArrayParamSerialize = (value: ReadonlyArray<string> | null) => {
  return arrayParamSerialize(value);
};

export const stringArrayParamDeserialize = (data: string): string[] | null => {
  // NOTE: This cast is only accurate if we can guaranteed there are no null entries in the serialized array
  return arrayParamDeserialize(data) as string[] | null; 
};

export const jsonParamSerialize = (value: Readonly<any> | null) => 
  defaultSerialize(value);

export const jsonParamDeserialize = (data: string): Exclude<any, undefined> => 
  defaultDeserialize(data);

export const objectParamSerialize = (value: Readonly<{ [key: string]: string | null } | null>) =>
  defaultSerialize(value);

export const objectParamDeserialize = (data: string): { [key: string]: string | null } | null =>
  defaultDeserialize(data);
