import React from "react";
import { Table } from "../../../components";
import { sortBy } from "lodash";
import { GetLineItemCountsFilterInput, GetLineItemCountsTimeColumn, LineItemsApi, StoresApi } from "../../../api/core";
import { DateTime } from "luxon";

const columnWidth = { store_name: 300, default: 100 };

interface LineItemDemantTableProps {
  filter?: GetLineItemCountsFilterInput;
  timeColumn?: GetLineItemCountsTimeColumn;
}

export const LineItemDemandTable = ({
  filter,
  timeColumn,
}: LineItemDemantTableProps) => {

  const { data: stores, isFetching: isFetchingStores } = StoresApi.useGetStoresQuery({});

  const { data: counts, isFetching: isFetchingCounts } = LineItemsApi.useGetLineItemCountsQuery({ 
    filter, timeColumn, groupBy: "store_id"
  });

  const sanitizedData =
    !isFetchingCounts && !isFetchingStores && stores && counts ? sortBy(counts, "time").map((row) => {
      const {
        time,
        value: count,
      } = row;

      const zonedTime = DateTime.fromJSDate(time, { zone: 'local' }).toFormat("MM/dd");
      const store_id = row.data.key === 'store_id' ? row.data.value : undefined;
      const store = stores.rows.find((store) => store.id === store_id);

      return {
        store_name: store?.name,
        [zonedTime]: {
          // TO DO: compute URL for the /line_items (with filtering) that would show the line items for this row
          // The below is what API was more or less computing as the URI. I'm leaving this incorrect value here 
          // until we have time to fix it, as the report doesn't format properly if we return an undefined link
          // presently.
          link: store_id ? `/line_items?store_id=${encodeURIComponent(store_id)}` : undefined, 
          value: count,
        },
      };
    }) : [];

  const groupedData = {} as any;
  const types = {} as any;

  sanitizedData.forEach((row: any) => {
    const id = row.store_name as string;
    groupedData[id] = {
      ...(groupedData[id] || {}),
      ...row,
    };
    Object.keys(groupedData[id]).forEach((key) => {
      if (key.match(/^\d/)) {
        types[key] = "IntegerLink";
      }
    });
  });

  return (
    <Table
      id="lineItemDemand"
      data={Object.values(groupedData)}
      loading={isFetchingCounts || isFetchingStores}
      defaultColumnOrder={["store_name"]}
      columnWidth={columnWidth}
      types={types}
      emptyText={0}
      rowTotals
      colTotals
      sidebar
    />
  );
};

LineItemDemandTable.defaultProps = {
  filter: {},
  timeColumn: GetLineItemCountsTimeColumn.ShipBy,
};
