import React from "react";
import { FallbackRender } from "@sentry/react/types/errorboundary";

const fallbackStyle: React.CSSProperties = {
  textAlign: "center"
};

const errorStyle: React.CSSProperties = {
  border: "1px black",
  backgroundColor: "white",
  fontFamily: "monospace"
};

export const ErrorBoundaryFallback: FallbackRender = (errorData) => (
  <div style={fallbackStyle}>
    <h1>An Unexpected Error Occurred</h1>
    <div style={errorStyle}>
      <h4>{errorData.error.message}</h4>
    </div>
    <br />
    <button type="button" onClick={e => errorData.resetError()}>
      Refresh
    </button>
  </div>
);

export default ErrorBoundaryFallback;
