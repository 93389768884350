import React from 'react';
import { isDate } from 'lodash';
import { DateTime } from 'luxon';
import { toAbsHumanDuration } from '../util';

// Mocking `Date` can be painful, so let's consistently use this instead of
// `new Date()` to make life easy.
export function now(): Date {
  return new Date();
}

export interface DateFormatOptions {
  showTime?: boolean;
  appendDistance?: boolean;
  onlyDistance?: boolean;
  html?: boolean;
}

export const isValidDate = (date: Date) =>
  isDate(date) && date.toString() !== 'Invalid Date';

interface DateResult {
  valid: boolean;
  date: Date;
}

const isValidDateMultiPass = (value: Date | string | undefined | null): DateResult => {
  const invalid: DateResult = { valid: false, date: now() };

  if (!value) { return invalid; }
  let date = isDate(value) ? value : new Date(value);
  if (!isValidDate(date)) { date = new Date(value); }
  if (!isValidDate(date)) { return invalid; }

  return { valid: true, date };
};

export function formatTwoDigitDate(
  value: Date | string | undefined,
  timeZone: string | undefined = undefined,
  showTime: boolean = false
) {
  const result = isValidDateMultiPass(value);
  if (result.valid === false) {
    return '';
  }

  const intlOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZoneName: 'short'
  };

  if (showTime) {
    intlOptions.hour = "2-digit";
    intlOptions.minute = "2-digit";
    intlOptions.second = "2-digit";
  }

  if (timeZone) {
    intlOptions.timeZone = timeZone;
  }

  return Intl.DateTimeFormat('en-US', intlOptions).format(result.date);
}

export function formatDate(
  value: Date | string | undefined | null,
  options?: DateFormatOptions
) {
  const result = isValidDateMultiPass(value);
  if (result.valid === false) {
    return options?.html === false ? 'Never' : <span>Never</span>;
  }

  const intlOptions: Intl.DateTimeFormatOptions = {
    dateStyle: 'short',
    timeStyle: 'short',
  };

  if (!options?.showTime) { delete intlOptions.timeStyle; }

  let displayValue = Intl.DateTimeFormat('en-US', intlOptions).format(result.date);
  if (options?.appendDistance) { 
    displayValue += ` (${toAbsHumanDuration(DateTime.fromJSDate(result.date), DateTime.now())})`; 
  }
  if (options?.onlyDistance) { 
    displayValue = toAbsHumanDuration(DateTime.fromJSDate(result.date), DateTime.now());
  }

  return options?.html === false ? (
    displayValue
  ) : (
    <time role="time" dateTime={result.date.toISOString()}>
      {displayValue}
    </time>
  );
}

export function areArraysEqual<T>(a: T[], b: T[]) {
  if (a.length === b.length) {
    return a.every((element, index) => {
      if (element === b[index]) {
        return true;
      }
      return false;
    });
  }
  return false;
}

export function hashCode(value: string) {
  let hash = 0;
  for (let i = 0; i < value.length; i++) {
    const code = value.charCodeAt(i); 
    /* eslint-disable no-bitwise */
    hash = ((hash << 5) - hash) + code;
    hash &= hash;
    /* eslint-enable no-bitwise */
  }
  return hash;
}

export function startOfDayLocal(value: Date) {
  return DateTime.fromJSDate(value).toLocal().startOf("day").toJSDate();
}
