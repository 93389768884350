import { URLSearchParams } from "url";
import QueryParamConfig from "./QueryParamConfig";

export function withDefault<T>(config: Readonly<QueryParamConfig<T>>, 
  defaultValue: Exclude<T, undefined | null>)
  : Readonly<QueryParamConfig<Exclude<T, undefined | null>>> {

  return {
    decode: (key: string, params: URLSearchParams) => {
      const value = config.decode(key, params);
      if (value === undefined || value === null) {
        return defaultValue;
      }
      return value as Exclude<T, undefined | null>;
    },
    encode: config.encode
  };
}

export default withDefault;
