import { ScheduleIdentifier } from "../Schedules";
import deserialize, { DeserializationSchema } from "../utils/deserialize";
import { FloorviewSort, deserializeFloorviewSortsArray } from "./common";

export interface GetFloorviewSorts {
  scheduleId: string;
  isDefault: boolean; // is it the default sort, or a custom one?
  sorts: Partial<FloorviewSort>[]
}

export const GetFloorviewSortsDeserializationSchema: Readonly<DeserializationSchema> = 
  Object.freeze({
    sorts: deserializeFloorviewSortsArray
  });

export const deserializeGetFloorviewSorts = 
  deserialize<GetFloorviewSorts>(GetFloorviewSortsDeserializationSchema);

export type GetFloorviewSortsVariables = ScheduleIdentifier;
