import React, { useState } from "react";
import { SiderProps as AntSiderProps } from "antd/lib/layout";
import { Layout } from "antd";
import styled from "styled-components";
import { ResizableBox } from "react-resizable";

const { Sider: AntSider } = Layout;

interface SiderProps extends AntSiderProps {
  resizable?: boolean;
}

export const Sider = styled(
  ({ children, resizable = true, ...props }: SiderProps) => {
    const [width, setWidth] = useState(300);
    const resizer = resizable ? (
      <ResizableBox
        width={width}
        height={100}
        minConstraints={[125, 0]}
        onResize={(e, { size }) => setWidth(size.width)}
      />
    ) : undefined;
    return (
      <AntSider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...{
          theme: "light",
          width,
          ...props,
        }}
      >
        {children}

        {resizer}
      </AntSider>
    );
  }
)`
  transition: none !important;
  overflow-x: hidden;
  border-right: 1px lightgray solid;
  height: 100%;

  .react-resizable {
    height: 0 !important;
  }

  .react-resizable-handle {
    position: absolute;
    right: 0;
    top: 0;
    cursor: col-resize;
    width: 5px;
    height: 100%;
    display: block;

    &:hover {
      background: lightgray;
    }
  }
`;
