import { ColDef, ColGroupDef } from "ag-grid-community";
import { TagCellRenderer } from "../../DataGrid/renderers/TagCellRenderer";
import { DateTimeCellRenderer, ProgressCellRenderer, RowNumberCellRenderer } from "../../DataGrid/renderers";
import { LineItemStatus } from "../../../api/core";

export const defaultColumnDefs: /*ReadonlyArray */ (ColDef | ColGroupDef)[] = [
  {
    headerName: "#",
    sortable: false,
    filter: false,
    floatingFilter: false,
    cellRenderer: RowNumberCellRenderer,
    width: 75,
    pinned: 'left'
  },
  {
    field: 'id',
    hide: true,
    filter: 'text'
  },
  {
    field: 'ziftId',
    filter: 'text'
  },
  {
    field: 'status',
    filter: 'set',
    filterParams: {
      values: Object.values(LineItemStatus).map(v => v.toLocaleLowerCase()),
    },
    cellRenderer: TagCellRenderer
  },
  {
    field: 'progress',
    filter: 'number',
    hide: true,
    cellRenderer: ProgressCellRenderer,
  },
  {
    field: 'store.name',
    headerName: 'Store::Name',
    filter: 'text'
  },
  {
    field: 'order.ziftId',
    headerName: 'Order::Zift Id',
    filter: 'text'
  },
  {
    field: 'order.shipBy',
    headerName: 'Order::Ship By',
    filter: 'date',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'compoundPriority',
    headerName: 'Compound Priority',
    filter: 'number'
  },
  {
    field: 'blank.fingerprint',
    headerName: 'Blank::Fingerprint',
    filter: 'text',
  },
  {
    field: 'lastScanAt',
    headerName: 'Last Scan At',
    filter: 'date',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    filter: 'date',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    filter: 'date',
    cellRenderer: DateTimeCellRenderer
  }
];

export default defaultColumnDefs;
