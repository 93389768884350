import React, { ChangeEvent, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Form, Row, Col, Input } from "antd";
import { isEmpty } from "lodash";
import { DatePicker } from "../../DatePicker";
import { DateTime } from "luxon";
import { OrderFilterInput } from "../../../api/core";

const { RangePicker } = DatePicker;

type OrderFilterProps = {
  setFilter: Function;
  filter: OrderFilterInput;
  only?: string[];
  except?: string[];
};

const defaultProps = {
  only: [] as string[],
  except: [] as string[],
};

export const OrderFilter = ({
  only,
  except,
  filter,
  setFilter,
}: OrderFilterProps) => {
  const [fromLocal, setFromLocal] = useState<DateTime | null | undefined>(null);
  const [toLocal, setToLocal] = useState<DateTime | null | undefined>(null);

  useEffect(() => {
    setFromLocal(filter.from && DateTime.fromJSDate(filter.from as Date).startOf('day'));
  }, [filter.from, setFromLocal]);

  useEffect(() => {
    setToLocal(filter.to && DateTime.fromJSDate(filter.to as Date)
      .minus({ days: 1 })
      .endOf('day'));
  }, [filter.to, setToLocal]);

  useEffect(() => {
    if (fromLocal && toLocal && fromLocal > toLocal) {
      setFromLocal(toLocal);
      setToLocal(fromLocal);
    }
  }, [fromLocal, toLocal, setFromLocal, setToLocal]);

  const updateFilter = useDebouncedCallback((value) => setFilter(value), 500);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    updateFilter({ ...filter, phone: target.value });
  };

  const handleDateChange = (dates: any, dateStrings: String[]) => {
    const from:Date|null = dates && dates[0] && DateTime.fromJSDate(dates[0])
      .startOf('day')
      .toUTC()
      .toJSDate();

    // round up to the next day
    const to:Date|null = dates && dates[1] && DateTime.fromJSDate(dates[1])
      .plus({ days: 1 })
      .startOf('day')
      .toUTC()
      .toJSDate();

    updateFilter({ ...filter, from, to });
  };

  const filterComponents: { [index: string]: any } = {
    phone: {
      label: "Phone Number",
      render: (
        <Input
          allowClear
          value={filter?.phone || undefined}
          onChange={handleChange}
          placeholder="(888) 888 888"
        />
      ),
    },
    dateRange: {
      label: "Date Range",
      render: (
        <RangePicker
          value={[fromLocal?.toJSDate(), toLocal?.toJSDate()] as [any, any]}
          defaultValue={[
            DateTime.local().startOf('day').toJSDate(), 
            DateTime.local().endOf('day').toJSDate()
          ]}
          ranges={{
            Today: [
              DateTime.local().startOf('day').toJSDate(), 
              DateTime.local().endOf('day').toJSDate()
            ],
            "This Month": [
              DateTime.local().startOf('month').toJSDate(),
              DateTime.local().endOf('month').toJSDate(),
            ],
            "This Week": [
              DateTime.local().startOf('week').toJSDate(),
              DateTime.local().endOf('week').toJSDate(),
            ],
            "This Year": [
              DateTime.local().startOf('year').toJSDate(),
              DateTime.local().endOf('year').toJSDate(),
            ],
          }}
          onChange={handleDateChange}
        />
      ),
    },
  };

  return (
    <Form
      style={{
        padding: 12,
      }}
    >
      <Row>
        <Col span={8}>
          <Input.Group compact>
            {Object.keys(filterComponents).map((name) => {
              if (
                (!isEmpty(only) && !only?.includes(name)) ||
                (!isEmpty(except) && except?.includes(name))
              )
                return;

              const { render, label } = filterComponents[name];
              // eslint-disable-next-line consistent-return
              return (
                <Form.Item key={name} name={name} label={label}>
                  {render}
                </Form.Item>
              );
            })}
          </Input.Group>
        </Col>
      </Row>
    </Form>
  );
};

OrderFilter.defaultProps = defaultProps;
