import { DateTimeResolution } from "../common";
import { DeserializationSchema, deserialize, deserializeDate } from "../utils";

export interface GetBatchStatsVariables {
  from?: Date | null;
  to?: Date | null;
  seriesResolution?: DateTimeResolution | null;
}

export interface GetBatchStats {
  from: Date;
  to: Date;
  newCount: number;
  inProgressCount: number;
  completeCount: number;
  completedCount: number;
}

export const GetBatchStatsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  from: deserializeDate,
  to: deserializeDate
});

export const deserializeGetBatchStats = 
  deserialize<GetBatchStats>(GetBatchStatsDeserializationSchema);
