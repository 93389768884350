import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { SubscriptionOptions } from "@reduxjs/toolkit/dist/query/core/apiState";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import { ApiEndpointQuery } from "@reduxjs/toolkit/dist/query/core/module";
import { LazyQueryTrigger, QueryHooks } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { DependencyList, useCallback, useEffect, useMemo, useRef } from "react";
import { batch, shallowEqual, useDispatch } from "react-redux";

// A react-redux internal function copied over for this custom query hook
export function useShallowStableValue<T>(value: T) {
  const cache = useRef(value);
  useEffect(() => {
    if (!shallowEqual(cache.current, value)) {
      cache.current = value;
    }
  }, [value]);

  return shallowEqual(cache.current, value) ? cache.current : value;
}

// a react-redux internal function copied over for this custom query hook
const unstable__sideEffectsInRender = false;
const usePossiblyImmediateEffect: (
  effect: () => void | undefined,
  deps?: DependencyList
) => void = unstable__sideEffectsInRender ? (cb) => cb() : useEffect;

export interface ReEntrantLazyQueryArgs extends SubscriptionOptions {
  endpoint: ApiEndpointQuery<any, any> & QueryHooks<any>;
}

// The built-in useLazyQuery hook only maintains a single promise
// This means that if you trigger multiple requests simultaneously, some of them might not be resolved
// properly... See https://github.com/reduxjs/redux-toolkit/issues/3706
// Pending pull request to fix this: https://github.com/reduxjs/redux-toolkit/pull/3709
// Once we are able to upgrade to a version with the fix in place, then go ahead and get rid of this custom hook
export function useReEntrantLazyQuerySubscription<
  QD extends QueryDefinition<any, any, any, any, any> = QueryDefinition<any, any, any, any, any>
>(
  endpoint: ApiEndpointQuery<QD, any> & QueryHooks<QD>, 
  options?: SubscriptionOptions
): readonly [LazyQueryTrigger<QD>] {
  const { 
    refetchOnReconnect = false,
    refetchOnFocus = false,
    pollingInterval = 0,
  } = options || {};

  const { initiate } = endpoint;
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();
  const promisesRef = useRef<Set<QueryActionCreatorResult<any>>>(new Set<QueryActionCreatorResult<any>>());

  const stableSubscriptionOptions = useShallowStableValue({
    refetchOnReconnect,
    refetchOnFocus,
    pollingInterval,
  });

  usePossiblyImmediateEffect(() => {
    promisesRef.current?.forEach(p => {
      const lastSubscriptionOptions = p.subscriptionOptions;
      if (stableSubscriptionOptions !== lastSubscriptionOptions) {
        p.updateSubscriptionOptions(
          stableSubscriptionOptions
        );
      }
    });
  }, [stableSubscriptionOptions]);

  const subscriptionOptionsRef = useRef(stableSubscriptionOptions);
  usePossiblyImmediateEffect(() => {
    subscriptionOptionsRef.current = stableSubscriptionOptions;
  }, [stableSubscriptionOptions]);

  const trigger = useCallback(
    (arg: any, preferCacheValue = false) => {
      let promise: QueryActionCreatorResult<QD>;

      batch(() => {
        promise = dispatch(
          initiate(arg, {
            subscriptionOptions: subscriptionOptionsRef.current,
            forceRefetch: !preferCacheValue,
          })
        );

        promisesRef.current.add(promise);
        
        promise.then(
          response => { promisesRef.current.delete(promise); return response; },
          reason => { promisesRef.current.delete(promise); throw reason; }
        );
      });

      return promise!;
    },
    [dispatch, initiate]
  );

  /* cleanup on unmount */
  useEffect(() => {
    return () => {
      /* eslint-disable react-hooks/exhaustive-deps */
      promisesRef?.current?.forEach(p => p.unsubscribe());
      /* eslint-enable react-hooks/exhaustive-deps */
    };
  }, []);

  /* if "cleanup on unmount" was triggered from a fast refresh, we want to reinstate the query 
  useEffect(() => {
    if (arg !== UNINITIALIZED_VALUE && !promiseRef.current) {
      trigger(arg, true)
    }
  }, [arg, trigger])
  */

  return useMemo(() => [trigger] as const, [trigger]);
}
