import React from "react";
import { Icon } from "../../components";

const warningStyle: React.CSSProperties = { position: "relative", top: "-0.075em" };

export const WarningOutlinedRounded = ({ ...props }) => (
  <Icon style={warningStyle} {...props}>
    <path d="M2.7 21c-.2 0-.4 0-.5-.1-.2-.1-.3-.2-.4-.4s-.1-.3-.1-.5 0-.3.1-.5l9.2-16c.1-.2.2-.3.4-.4.3-.1.4-.1.6-.1.2 0 .3 0 .5.1s.3.2.4.4l9.2 16c.1.2.1.3.1.5s-.1.3-.1.5c-.1.1-.2.3-.4.4-.1.1-.2.1-.4.1H2.7zm1.8-2h15.1L12 6 4.5 19zm7.5-1c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7s-.1-.5-.3-.7-.4-.3-.7-.3-.5.1-.7.3-.3.4-.3.7.1.5.3.7c.2.2.4.3.7.3zm0-3c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7v-3c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3-.5.1-.7.3-.3.4-.3.7v3c0 .3.1.5.3.7.2.2.4.3.7.3z" />
  </Icon>
);

export const WarningRounded = ({ ...props }) => (
  <Icon style={warningStyle} {...props}>
    <path d="M2.7 21c-.2 0-.4 0-.5-.1-.2-.1-.3-.2-.4-.4s-.1-.3-.1-.5 0-.3.1-.5l9.2-16c.1-.2.2-.3.4-.4.3-.1.4-.1.6-.1.2 0 .3 0 .5.1s.3.2.4.4l9.2 16c.1.2.1.3.1.5s-.1.3-.1.5c-.1.1-.2.3-.4.4-.1.1-.2.1-.4.1H2.7zm9.3-3c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7s-.1-.5-.3-.7-.4-.3-.7-.3-.5.1-.7.3-.3.4-.3.7.1.5.3.7c.2.2.4.3.7.3zm0-3c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7v-3c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3-.5.1-.7.3-.3.4-.3.7v3c0 .3.1.5.3.7.2.2.4.3.7.3z" />
  </Icon>
);
