import React from "react";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import {
  ConveyorBeltOutlinedRounded,
  EventNoteOutlinedRounded,
  InsightsRounded,
  LabProfileOutlinedRounded,
  LaundryOutlinedRounded,
  LocalMallOutlinedRounded,
  LogoutRounded,
  ShareLocationOutlinedRounded,
  StorefrontOutlinedRounded,
} from "../../../fixtures/icons";
import { Link } from "react-router-dom";
import { AuthButton } from "../../Auth";
import { IssuesCloseOutlined } from "@ant-design/icons";

export const items: ItemType[] = [
  { label: "Orders", key: "/orders", icon: <LocalMallOutlinedRounded /> },
  { label: "Line Items", key: "/line_items", icon: <LaundryOutlinedRounded /> },
  {
    label: "Schedules",
    key: "schedules", // never called directly, so not a path (cannot duplicate calendar child)
    icon: <EventNoteOutlinedRounded />,
    children: [
      { label: "Calendar", key: "/schedules" },
      { label: "Floor", key: "/schedules/view/today" },
    ],
  },
  { label: "Batches", key: "/batches", icon: <ConveyorBeltOutlinedRounded /> },
  { label: "Scans", key: "/scans", icon: <ShareLocationOutlinedRounded /> },
  { label: "Stores", key: "/stores", icon: <StorefrontOutlinedRounded /> },
  {
    label: "Insights",
    key: "/insights",
    icon: <InsightsRounded />,
    children: [
      { label: "Orders", key: "/insights/orders" },
      { label: "Line Items", key: "/insights/line_items" },
      { label: "Batches", key: "/insights/batches" },
      { label: "Demand", key: "/insights/demand" },
    ],
  },
  {
    label: "Reports",
    key: "/reports",
    icon: <LabProfileOutlinedRounded />,
    children: [
      {
        label: "Demand",
        key: "/demand",
        children: [
          { label: "Summary", key: "/reports/demand/summary" },
          { label: "Printed", key: "/reports/demand/printed" },
          { label: "Out of Stock", key: "/reports/demand/oos" },
          { label: "Canceled", key: "/reports/demand/canceled" },
          { label: "Active Units to Print", key: "/reports/active_print" },
          { label: "Bin", key: "/reports/demand/bin" },
          { label: "Waypoint", key: "/reports/demand/waypoint" },
          { label: "Progress", key: "/reports/demand/progress" },
        ]
      },
      { label: "Pull", key: "/reports/pull" },
      { label: "Print", key: "/reports/print" },
      { label: "Pending Print", key: "/reports/pending_print" },
      { label: "Blanks", key: "/reports/blanks" },
      { label: "Allocations", key: "/reports/allocations/summary" },
      {
        label: "Operational Reports",
        key: "ops",
        children: [
          { label: "Active/Pending", key: "/reports/ops/line_item" },
          { label: "Invalid", key: "/reports/ops/order" },
          { label: "Duplicates", key: "/reports/ops/order/duplicates" }
        ]
      }
    ],
  },
  {
    label: "D365",
    key: "/d365",
    icon: <IssuesCloseOutlined />,
    children: [
      { label: "Sync", key: "/d365/sync" },
      { label: "Product", key: "/d365/product" }
    ]
  },
  /* Disabled until we can add security around the menu item */
  /*{ label: "Settings",
    key: "/settings",
    icon: <SettingFilled />,
    children: [
      { label: "Facilities", key: "/settings/facilities" },
      { label: "Store Priority", key: "/settings/store_priority" },
      { label: "Batches", key: "/settings/batches" },

      //
      //{ label: "Themes",
        //key: "/settings/themes",
        //children: [
          //{ label: "Light", key: "/settings/themes/light" },
          //{ label: "Dark", key: "/settings/themes/dark" }
       //]
      //}
      //
    ]
  },*/
  { type: "divider", style: { flex: 1, order: 8 } },
  { label: <AuthButton type={Link} />, key: "/logout", icon: <LogoutRounded /> },
];

export default items;
