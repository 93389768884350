import React, { useEffect, useState } from "react";
import { Button, Form, InputNumber, Select, Slider, Switch } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { isEmpty, isNil } from "lodash";
import { DatePicker } from "../../DatePicker";
import { FacilitySelect, StoreSelect } from "../..";
import { 
  ArrayParam, BooleanParam, DateTimeParam, NumberParam, useQueryParams, withDefault 
} from "../../../api/useQueryParams";
import { BatchStatus } from "../../../api/core";
import { DateTime } from "luxon";

const { RangePicker } = DatePicker;
const { Option } = Select;

type BatchFilterProps = {
  onChange?: Function;
  onReload?: Function;
  loading?: boolean;
  only?: string[];
  except?: string[];
} & typeof defaultProps;

const defaultProps = {
  onChange: (filter: BatchFilterInput) => {},
  onReload: (filter: BatchFilterInput) => {},
  loading: false,
  only: [] as string[],
  except: [] as string[],
};

export interface BatchFilterInput {
  from?: any | null;
  to?: any | null;
  shipFrom?: any | null;
  shipTo?: any | null;
  status?: BatchStatus[] | null;
  minProgress?: number | null;
  maxProgress?: number | null;
  minItems?: number | null;
  maxItems?: number | null;
  minCompletedItems?: number | null;
  maxCompletedItems?: number | null;
  storeId?: string | null;
  storeIds?: string[] | null;
  scheduleId?: string | null;
  scheduleIds?: string[] | null;
  scheduleFacilityId?: string | null;
  scheduleFacilityIds?: string[] | null;
  scheduled?: boolean | null;
  scheduledFor?: any | null;
  autoFacilityId?: string | null;
  autoFacilityIds?: string[] | null;
  fillScheduleId?: string | null;
}

const filterParams = {
  from: DateTimeParam,
  to: DateTimeParam,
  shipFrom: DateTimeParam,
  shipTo: DateTimeParam,
  status: ArrayParam,
  limit: withDefault(NumberParam, 200),
  minProgress: NumberParam,
  maxProgress: NumberParam,
  scheduled: BooleanParam,
  autoFacilityIds: ArrayParam,
  scheduleFacilityIds: ArrayParam,
  storeIds: ArrayParam,
};

// Maintain empty filter object so we don't have to use `replace` on
// `setFilter`, because that just wipes out the entire query and not just
// params on `filter`
const emptyFilter = { ...filterParams } as any;
Object.keys(emptyFilter).forEach((key) => {
  emptyFilter[key] = undefined;
});

export const BatchFilter = ({
  only,
  except,
  onChange,
  onReload,
  loading,
}: BatchFilterProps) => {
  const [progress, setProgress] = useState<[number, number]>([0, 100]);
  const [filter, setFilter] = useQueryParams(filterParams);

  const updateFilter = (newFilter: any) => {
    setFilter((currentFilter) => {
      const updatedFilter = {
        ...currentFilter,
        ...newFilter,
      };
      onChange(updatedFilter);
      return updatedFilter;
    });
  };

  const handleDateChange = (dates: any, dateStrings: String[]) => {
    updateFilter({
      from: dates && dates[0] ? DateTime.fromJSDate(dates[0]).startOf('day').toJSDate() : null,
      to: dates && dates[1] ? DateTime.fromJSDate(dates[1]).endOf('day').toJSDate() : null,
    });
  };

  const handleShipByChange = (dates: any, dateStrings: String[]) => {
    updateFilter({
      shipFrom: dates && dates[0] ? DateTime.fromJSDate(dates[0]).startOf('day').toJSDate() : null,
      shipTo: dates && dates[1] ? DateTime.fromJSDate(dates[1]).endOf('day').toJSDate() : null,
    });
  };

  useEffect(() => {
    onChange(filter as any);
  }, [onChange, filter]);

  const filterComponents: { [index: string]: any } = {
    autoFacility: {
      label: "Auto Facility",
      render: (
        <FacilitySelect
          mode="multiple"
          value={filter?.autoFacilityIds}
          onChange={(autoFacilityIds: string | string[]) => {
            updateFilter({ autoFacilityIds });
          }}
        />
      ),
    },
    scheduleFacility: {
      label: "Schedule Facility",
      render: (
        <FacilitySelect
          mode="multiple"
          value={filter?.scheduleFacilityIds}
          onChange={(scheduleFacilityIds: string | string[]) => {
            updateFilter({ scheduleFacilityIds });
          }}
        />
      ),
    },
    storeId: {
      label: "Store",
      render: (
        <StoreSelect
          value={filter?.storeIds}
          mode="multiple"
          onChange={(storeIds: string | string[]) => {
            updateFilter({ storeIds });
          }}
        />
      ),
    },
    dateRange: {
      label: "Creation Date",
      render: (
        <RangePicker
          placeholder={["Created After", "Created Before"]}
          allowClear
          allowEmpty={[true, true]}
          value={[filter?.from, filter?.to] as [any, any]}
          ranges={{
            Today: [DateTime.now().startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()],
            "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
            "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
            "This Year": [DateTime.now().startOf('year').toJSDate(), DateTime.now().endOf('year').toJSDate()],
          }}
          onChange={handleDateChange}
        />
      ),
    },
    shipByRange: {
      label: "Ship By Date",
      render: (
        <RangePicker
          placeholder={["Ship After", "Ship Before"]}
          allowClear
          allowEmpty={[true, true]}
          value={[filter?.shipFrom, filter?.shipTo] as [any, any]}
          ranges={{
            "Before Today": [null, DateTime.now().endOf('day').toJSDate()],
            "Next Week": [
              DateTime.now().plus({ weeks: 1 }).startOf('week').toJSDate(),
              DateTime.now().plus({ weeks: 1 }).endOf('week').toJSDate(),
            ],
          }}
          onChange={handleShipByChange}
        />
      ),
    },
    status: {
      label: "Status",
      render: (
        <Select
          key="status"
          mode="multiple"
          dropdownMatchSelectWidth={false}
          value={filter?.status as string[]}
          onChange={(status) => updateFilter({ status })}
        >
          {Object.keys(BatchStatus).map((key) => (
            <Option key={key} value={key}>
              {key}
            </Option>
          ))}
        </Select>
      ),
    },
    scheduled: {
      label: "Show Scheduled Batches",
      render: (
        <Switch
          checked={isNil(filter?.scheduled)}
          onChange={(checked: boolean) => {
            updateFilter({ scheduled: checked ? undefined : false });
          }}
        />
      ),
    },
    progress: {
      label: "Progress",
      render: (
        <Slider
          range
          min={0}
          max={100}
          value={progress}
          onChange={setProgress}
          onAfterChange={(value: [number, number]) => {
            updateFilter({
              minProgress: value[0],
              maxProgress: value[1],
            });
          }}
        />
      ),
    },
    limit: {
      label: "Limit",
      render: (
        <div>
          <InputNumber
            min={0}
            max={1000}
            value={filter?.limit || 200}
            onChange={(limit) => updateFilter({ limit })}
          />
        </div>
      ),
    },
  };

  const filterItems = Object.keys(filterComponents).map((name) => {
    if (
      (!isEmpty(only) && !only.includes(name)) ||
      (!isEmpty(except) && except.includes(name))
    )
      return undefined;

    const { render, label } = filterComponents[name];
    // Note: don't give Form.Item a `name` if it's purely used for
    // visuals or it'll screw w/ the context.
    return (
      <Form.Item key={label} label={label} style={{ marginBottom: 10 }}>
        {render}
      </Form.Item>
    );
  });

  return (
    <Form layout="vertical">
      <Form.Item>
        <Button
          loading={loading}
          disabled={loading}
          icon={<ReloadOutlined />}
          onClick={() => onReload(filter as any)}
        >
          Refresh
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          disabled={isEmpty(filter)}
          onClick={() => setFilter(emptyFilter)}
        >
          Reset Filter
        </Button>
      </Form.Item>
      {filterItems}
    </Form>
  );
};

BatchFilter.defaultProps = defaultProps;
