import CoreApi from '../CoreApi';
import { GetFacilities, GetFacilitiesVariables, deserializeGetFacilities } from './GetFacilities';
import { transformResponse, transformErrorResponse } from '../utils';
import { UpdateFacilityCapacity, UpdateFacilityCapacityVariables, deserializeUpdateFacilityCapacity } from './UpdateFacilityCapacity';

// Extend the CoreApi with /facilities endpoints
export const FacilitiesApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getFacilities: builder.query<GetFacilities, GetFacilitiesVariables>({
      query: (params) => ({
        method: "POST",
        url: "/facilities",
        body: params
      }),
      transformResponse: transformResponse(deserializeGetFacilities),
      transformErrorResponse: transformErrorResponse("getFacilities"),
    }),

    updateFacilityCapacity: builder.mutation<UpdateFacilityCapacity, UpdateFacilityCapacityVariables>({
      query: (params) => ({
        method: "PATCH",
        url: "/facilities",
        body: params
      }),
      transformResponse: transformResponse(deserializeUpdateFacilityCapacity),
      transformErrorResponse: transformErrorResponse("getFacilities"),
    }),
  }),
});

export default FacilitiesApi;
