import { Facility } from "../Facilities";
import { NoteContent } from "../Notes";
import { Schedule, ScheduleIdentifier, deserializeSchedule } from "../Schedules";
import { DeserializationSchema, deserialize } from "../utils";
import { BatchFilterInputV2 } from "./BatchFilterInputV2";

export interface ScheduleBatches_Warning {
  name: string;
  message: string;
}

export interface ScheduleBatchesVariables {
  filter: BatchFilterInputV2;
  schedule: ScheduleIdentifier;
  note?: NoteContent | null;
  initial: string;
  onlyCheckForWarnings?: boolean | null;
  overrideRecordLimitWarning?: boolean | null;
  overrideReschedulingWarning?: boolean | null;
  overrideModificationOfPastScheduleWarning?: boolean | null;
  overrideMismatchedFacilityWarning?: boolean | null;
}

export interface ScheduleBatches {
  updatedIds: string[];
  schedule: Schedule & { facility: Facility };
  warnings: ScheduleBatches_Warning[];
}

export const ScheduleBatchesDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  schedule: deserializeSchedule
});

export const deserializeScheduleBatches = 
  deserialize<ScheduleBatches>(ScheduleBatchesDeserializationSchema);
