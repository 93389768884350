import React, { useMemo } from "react";
import OrdersDataGrid from "../../../components/Order/DataGrid/OrdersDataGrid";
import { useGridStateSearchParamPlugin } from "../../../components/DataGrid/plugins";

export const OrderManagePage = () => {
  const gridStatePlugin = useGridStateSearchParamPlugin();

  const plugins = useMemo(() => [gridStatePlugin], [gridStatePlugin]);
  return (
    <OrdersDataGrid plugins={plugins} />
  );
};
