import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "../utils";

export interface Blank {
  id: string;
  ziftId?: string | null;
  name?: string | null;
  status?: string | null;
  department?: string | null; // TO DO: we should probably rename the column as departmentId
  fingerprint?: string | null;
  size?: string | null;
  color?: string | null;
  sky?: string | null;
  weight?: number | null;
  createdAt: Date;
  updatedAt: Date;
}

export const BlankDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate
});

export const deserializeBlank = deserialize<Blank>(BlankDeserializationSchema);
export const deserializeBlanksArray = deserializeArrayOf<Blank>(BlankDeserializationSchema);

export interface BlankStats {
  fingerprint: string;
  facilityName: string;
  date: Date;
  count: number;
}

export const BlankStatsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  date: deserializeDate
});

export const deserializeBlankStats = deserialize<BlankStats>(BlankDeserializationSchema);
export const deserializeBlankStatsArray = deserializeArrayOf<BlankStats>(BlankDeserializationSchema);
