import { AgDateFilter, AgGridQueryArgs, AgGridQueryArgs_Options, AgQueryResponse, AgTextOrSetFilter } from "../AgGrid";
import deserialize, { DeserializationSchema } from "../utils/deserialize";
import { Scan, deserializeScansArray } from "./common";

export interface GetScans extends AgQueryResponse<Scan> {}

export const GetScansDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeScansArray
});

export const deserializeGetScans = deserialize<GetScans>(GetScansDeserializationSchema);

export type GetScans_Include = 'facility' | 'line_item';
export interface GetScansVariables_Options extends AgGridQueryArgs_Options {
  include?: GetScans_Include[] | null | undefined;
}

export interface GetScansVariables extends AgGridQueryArgs<GetScansVariables_Options, ScanFilterInput> {}

export interface ScanFilterInput {
  id?: AgTextOrSetFilter | null;
  lineItemId?: AgTextOrSetFilter | null;
  facilityId?: AgTextOrSetFilter | null;
  hostname?: AgTextOrSetFilter | null;
  waypoint?: AgTextOrSetFilter | null;
  time?: AgDateFilter | null;
}

export interface ScanSortInput {
  id?: string | null;
  lineItemId?: string | null;
  facilityId?: string | null;
  hostname?: string | null;
  waypoint?: string | null;
  time?: string | null;
}
