import React, { useMemo } from "react";
import { Cascader, SelectProps } from "antd";
import { DefaultOptionType } from "antd/lib/cascader";
import { LocationsApi } from "../../../api/core";

interface LocationFacilitySelectProps extends SelectProps<any> {
  value?: string[],
  onChange?: (facilityIds: string[]) => void,
  disabled?: boolean,
  useCode?: boolean,
  allowClear?: boolean,
  placeholder?: string;
}

export const LocationFacilitySelect = ({
  value,
  onChange,
  disabled,
  useCode,
  allowClear,
  placeholder
}: LocationFacilitySelectProps) => {
  
  const { data, isFetching: loading } = LocationsApi.useGetLocationsQuery({
    options: {
      include: ['facilities']
    }
  });

  const options = useMemo(() => {
    return data?.rows.filter(location => location.facilities?.length).map(location => {
      if (location.facilities?.length === 1) {
        return {
          label: location.facilities[0].name || 'unknown',
          value: useCode ? location.facilities[0].code : location.facilities[0].id,
        };
      }
      return {
        label: location.name || 'unknown',
        value: useCode ? location.code : location.id,
        children: location.facilities?.map(facility => ({
          label: facility.name,
          value: useCode ? facility.code : facility.id
        }))
      };
    }).sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }) || [];
  }, [data, useCode]);

  const internalOnChange = (_value: (string | number)[][], selectOptions: DefaultOptionType[][]) => {
    if (!onChange) {
      return;
    }

    const facilityIds = selectOptions.reduce<(string | number)[]>((acc, current) => {
      // last element in the current path
      const lastElement = current[current.length - 1];
      // if element is a leaf node, use its value
      if (!lastElement.children) {
        acc.push(lastElement.value ?? '');
      }
      else {
        // locations can only be one level deep, so use direct child values
        lastElement.children.forEach(child => {
          acc.push(child.value ?? '');
        });
      }
      return acc;
    }, []);

    onChange(facilityIds?.filter((element): element is string => element !== null) ?? []);
  };

  const internalValue = useMemo(() => {
    if (!value) {
      return [];
    }
    return options.reduce<(string | number)[][]>((acc, current) => {
      if (!current.children) {
        if (current.value && value.includes(current.value)) {
          acc.push([current.value]);
        }
      } else {
        // shenanigans to make TypeScript content there are no null elements
        const childValues = current.children.map(child => child.value).filter((element): element is string => element !== null);
        // if all children are included, use just the parent value
        if (childValues.every(v => value.includes(v))) {
          acc.push([current.value]);
        } else {
          // if some children are included, use the parent and child value
          // locations can only be one level deep, so no need to dig deeper
          value.filter(v => childValues.includes(v)).forEach(v => {
            acc.push([current.value, v]);
          });
        }
      }
      return acc;
    }, []);
  }, [value, options]);

  return (
    <Cascader
      placeholder={placeholder || "Select Facility"}
      allowClear={allowClear ?? false}
      value={internalValue}
      disabled={disabled ?? false}
      loading={loading}
      style={{ width: '100%' }}
      options={options}
      onChange={internalOnChange}
      multiple
      maxTagCount="responsive"
      maxTagPlaceholder="..."
    />
  );
};
