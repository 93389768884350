import { GetSchedules_AliasMap, ScheduleIdentifier } from "../Schedules";
import { Store, deserializeStoresArray } from "../Stores";
import { DeserializationFunc } from "../utils/deserialize";

export type GetFloorviewStores = Store[];

export const deserializeGetFloorviewStores = 
  deserializeStoresArray as DeserializationFunc<GetFloorviewStores>;

export interface GetFloorviewStoresVariables {
  schedule: ScheduleIdentifier;
}

export const GetFloorviewStores_AliasMap = GetSchedules_AliasMap;
