import React, { Component } from "react";
import { IStatusPanelParams } from "ag-grid-enterprise";
import { ModelUpdatedEvent } from "ag-grid-community";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 8px 0px; 
  margin: 0px 4px;
`;

const Label = styled.label`
  margin-right: 3px;
`;

const Value = styled.span`
  color: var(--ag-foreground-color, #000);
`;

interface DisplayedRowCountComponentState {
  count?: number;
}

// This feels like it's over complicated... but for w/e reason a simplified functional component
// w/ the same functionality was only intermittently working... 
// See https://stackoverflow.com/questions/72990040/aggrid-statusbar-rendering-timeout-not-created-within-1000ms
export class DisplayedRowCountComponent 
  extends Component<IStatusPanelParams, DisplayedRowCountComponentState> {

  constructor(params: IStatusPanelParams) {
    super(params);
    this.onModelUpdated = this.onModelUpdated.bind(this);
    this.state = {};
  }

  componentDidMount() {
    this.props.api.addEventListener('modelUpdated', this.onModelUpdated);
    this.setState({ 
      count: this.props.api.getDisplayedRowCount()
    });
  }

  componentWillUnmount() {
    this.props.api.removeEventListener('modelUpdated', this.onModelUpdated);
  }

  onModelUpdated(e: ModelUpdatedEvent) {
    this.setState({
      count: e.api.getDisplayedRowCount()
    });
  }

  render() {
    if (this.state.count === undefined) {
      return null;
    }

    return (
      <Wrapper>
        <Label>Rows:</Label>
        <Value>{this.state.count}</Value>
      </Wrapper>
    );
  }
}
