import { GridApi, ISizeColumnsToFitParams } from "ag-grid-community/dist/lib/gridApi";
import { useCallback, useEffect, useMemo, useRef } from "react";
import IDataGridPlugin, { IDataGridPluginHook } from "./IDataGridPlugin";

export interface useSizeColumnsToFitPluginParams extends ISizeColumnsToFitParams {
  sizeToFitOnPagination?: boolean;
}

export interface ISizeColumnsToFitPlugin extends IDataGridPlugin {
  sizeColumnsToFit: () => void;
}

export const useSizeColumnsToFitPlugin:
IDataGridPluginHook<ISizeColumnsToFitPlugin, useSizeColumnsToFitPluginParams | void> = (params) => {
  const apiRef = useRef<GridApi>();

  const sizeColumnsToFit = useCallback(() => {
    apiRef.current?.sizeColumnsToFit(params || undefined);
  }, [params]);

  useEffect(() => {
    if (params?.sizeToFitOnPagination) {
      apiRef.current?.addEventListener('paginationChanged', sizeColumnsToFit);
    }

    return () => {
      if (params?.sizeToFitOnPagination) {
        apiRef.current?.removeEventListener('paginationChanged', sizeColumnsToFit);
      }
    };
  }, [params?.sizeToFitOnPagination, sizeColumnsToFit]);
  
  const plugin = useMemo<ISizeColumnsToFitPlugin>(() => ({
    onGridReady: (e) => {
      apiRef.current = e.api;
      sizeColumnsToFit();

      if (params?.sizeToFitOnPagination) {
        apiRef.current?.addEventListener('paginationChanged', sizeColumnsToFit);
      }
    },
    sizeColumnsToFit
  }), [params?.sizeToFitOnPagination, sizeColumnsToFit]);

  return plugin;
};
