import deserialize, { DeserializationSchema } from "../utils/deserialize";

export interface Settings {
  batches?: BatchSettings;
}

export interface BatchSettings {
  snoozeTime?: number;
}

export const SettingsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({});
export const deserializeSettings = deserialize<Settings>(SettingsDeserializationSchema);
