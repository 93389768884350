import { URLSearchParams } from "url";
import QueryParamConfig from "./QueryParamConfig";

export function Convert<IN, IN_INTERNAL, OUT = IN, OUT_INTERNAL = IN_INTERNAL>(
  config: Readonly<QueryParamConfig<IN_INTERNAL, OUT_INTERNAL>>, 
  convertOnEncode: (value: IN) => IN_INTERNAL | undefined, 
  convertOnDecode: (value: OUT_INTERNAL | undefined) => OUT | undefined): QueryParamConfig<IN, OUT> 
{

  return {
    encode: (key: string, value: IN | undefined, params: URLSearchParams) => {
      if (value === undefined) {
        params.delete(key);
      } else {
        config.encode(key, convertOnEncode(value), params);
      }
    },
    decode: (key: string, params: URLSearchParams) => {
      return convertOnDecode(config.decode(key, params));
    }
  };
}

export function Cast<IN, IN_INTERNAL, OUT = IN, OUT_INTERNAL = IN_INTERNAL>(
  config: Readonly<QueryParamConfig<IN_INTERNAL, OUT_INTERNAL>>) {
    
  return Convert<IN, IN_INTERNAL, OUT, OUT_INTERNAL>(
    config,
    value => value as unknown as IN_INTERNAL | undefined,
    data => data as unknown as OUT | undefined
  );
}
