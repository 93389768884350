import { ColDef, ColGroupDef } from "ag-grid-community";
import { DateTimeCellRenderer, RowNumberCellRenderer, TagCellRenderer } from "../../components/DataGrid/renderers";
import { Waypoint } from "../../api/core";

export const ScansColDefs: /*ReadonlyArray */ (ColDef | ColGroupDef)[] = [
  {
    headerName: "#",
    sortable: false,
    filter: false,
    floatingFilter: false,
    cellRenderer: RowNumberCellRenderer,
    width: 75,
    pinned: 'left'
  },
  {
    field: 'id',
    hide: true,
    filter: 'text'
  },
  {
    field: 'lineItemId',
    filter: 'text'
  },
  {
    field: 'facilityId',
    filter: 'text'
  },
  {
    field: 'hostname',
    filter: 'text'
  },
  {
    field: 'waypoint',
    filter: 'set',
    filterParams: {
      values: Object.values(Waypoint).map(v => v.toLocaleLowerCase()),
    },
    cellRenderer: TagCellRenderer
  },
  {
    field: 'time',
    filter: 'date',
    cellRenderer: DateTimeCellRenderer
  },
];

export default ScansColDefs;
