import React, { useLayoutEffect, useState } from "react";

export function useHTMLElementRefSize<T extends HTMLElement>(ref: React.MutableRefObject<T | null>) 
{
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  useLayoutEffect(() => {
    const resizeListener = () => {
      if (ref.current) {
        // Get the styles
        const styles = window.getComputedStyle(ref.current);
        const rect = ref.current.getBoundingClientRect();

        // The size without padding and border
        const newHeight = 
          rect.height - parseFloat(styles.paddingTop) - parseFloat(styles.paddingBottom);
        const newWidth = 
          rect.width - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);

        setWidth(newWidth);
        setHeight(newHeight);
      } else {
        setWidth(undefined);
        setHeight(undefined);
      }
    };

    // set resize listener
    window.addEventListener('resize', resizeListener);
    resizeListener(); // go ahead and initialize the values

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };

  }, [ref]);

  return { width, height };
}

export default useHTMLElementRefSize;
