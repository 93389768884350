import { isArray } from "lodash";

export const DefaultRootName = "[root]";

export interface DepthFirstTraversalOptions<TCustom = any> {
  // if true (default), then the algorithm will iterate over any and all array entries
  traverseArrayEntries: boolean; 

  // pre (default) = visit the root before the children. post = visit all the children and then the root.
  order: "pre" | "post"; 

  // If order = "pre", then this function is used to determine if the children will be visited after
  //  the parent node. This parameter will be ignored if order != "pre".
  skipChildren?: (parent: Readonly<any>, context: Readonly<VisitContext<TCustom>>) => boolean;

  // You may optionally provide a name for the root. This name will appear as the first entry within
  // context.path. This doesn't effect the functionality, but it maybe helpful for debugging.
  rootName?: string;
}

export interface VisitContext<TCustom = any> {
  path: string[];
  options: DepthFirstTraversalOptions;
  visited: Set<any>;
  visitStack: any[];
  custom: TCustom;
}

// Clone the source node, and apply any transformations as needed.
export type VisitFunction<TCustom = any> 
  = (current: Readonly<any>, context: Readonly<VisitContext<TCustom>>) => void;

/**
 * Performs a Depth First Traversal over all of the objects in the graph, starting from <source>.
 * Properties are not visited in any particular order.
 * @param source 
 * @param visit 
 * @param options 
 */
export function depthFirstTraversal<TCustom = any>(source: any, visit: VisitFunction<TCustom>, 
  custom?: TCustom, options?: Partial<DepthFirstTraversalOptions<TCustom>>) {

  const realOptions: DepthFirstTraversalOptions<TCustom> = {
    traverseArrayEntries: true,
    order: "pre",
    ...options
  };

  const visited = new Set<any>([source]); 
  const visitStack = [source];
  const path: string[] = [realOptions.rootName ?? DefaultRootName];

  const ctx: VisitContext = {
    path,
    options: realOptions,
    visited,
    visitStack,
    custom
  };

  __DepthFirstTraversal<TCustom>(source, visit, ctx);
}

// performs a depth-first traversal of the source object, visiting every property.
// First the root/source is visited and then its child properties, in no particular order. 
function __DepthFirstTraversal<TCustom = any>(source: any, visit: VisitFunction<TCustom>, context: VisitContext<TCustom>) {

  // assume that the context has already been updated prior to this internal call being made
  if (context.options.order === "pre") {
    visit(source, context);
    if (context.options.skipChildren?.(source, context)) {
      return;
    }
  }

  // check to see if the source is a primitive type. If so, we are done.
  // NOTE: the source could be undefined/null. 
  if (Object(source) !== source) { 
    if (context.options.order === "post") {
      visit(source, context);
    }
    return;
  }

  if (!context.options.traverseArrayEntries && isArray(source)) {
    if (context.options.order === "post") {
      visit(source, context);
    }
    return;
  }

  // visit any child nodes
  Object.keys(source).forEach(field => {
    const curr = source[field];

    if (context.visited.has(curr)) { 
      // We have already traversed through it via some other reference
      return; 
    } if (Object(curr) === curr) {
      // it is not a primitive, and this is our first time traversing to it 
      // register it to prevent re-iterating over the same object in the event that there
      // is a loop in the object graph.
      context.visited.add(curr);
    }

    context.visitStack.push(curr);
    context.path.push(field);
    __DepthFirstTraversal(curr, visit, context);
    context.path.pop();
    context.visitStack.pop();
  });

  if (context.options.order === "post") {
    visit(source, context);
  }
}

export default depthFirstTraversal;
