import React, { useState } from "react";
import { Collapse, Form, Layout, Select } from "antd";
import { LineItemFilter, Sider } from "../../../components";
import { LineItemDemandTable } from "./LineItemDemandTable";
import { StringParam, useQueryParam, withDefault } from "../../../api/useQueryParams";
import { GetLineItemCountsTimeColumn } from "../../../api/core";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

export const LineItemDemandPage = () => {
  const [filter, setFilter] = useState({});
  const [timeColumn, setTimeColumn] = useQueryParam(
    "timeColumn",
    withDefault(StringParam, GetLineItemCountsTimeColumn.ShipBy as string)
  );

  return (
    <Layout>
      <Sider>
        <Collapse
          style={{ border: 0 }}
          defaultActiveKey={["filter", "settings"]}
        >
          <Panel style={{ border: 0 }} header="Settings" key="settings">
            <Form.Item
              key="time-column"
              label="Pivot By"
              style={{ marginBottom: 10 }}
            >
              <Select
                key="time-column-select"
                dropdownMatchSelectWidth={false}
                value={timeColumn}
                onChange={(value) => setTimeColumn(value)}
              >
                {Object.keys(GetLineItemCountsTimeColumn).map((key) => (
                  <Option
                    key={key}
                    value={(GetLineItemCountsTimeColumn as any)[key]}
                  >
                    {key}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Panel>
          <Panel style={{ border: 0 }} header="Filter" key="filter">
            <LineItemFilter onChange={setFilter} />
          </Panel>
        </Collapse>
      </Sider>
      <Content>
        <LineItemDemandTable filter={filter} timeColumn={timeColumn as GetLineItemCountsTimeColumn} />
      </Content>
    </Layout>
  );
};
