import React, { ChangeEvent, useMemo, useState } from "react";
import { Button, Card, Input, Space } from "antd";

const { TextArea } = Input;

// regex to find all characters to be treated as separators
const regexSpace = /[\s,;]+/g;
// regex to find all supported characters supported (everything else is removed)
const regex = /[^\w-_ ]+/g;

export interface D365SyncStepOneParams {
  onFinish: (ids: string[]) => void
}

export const D365SyncStepOne = (props: D365SyncStepOneParams) => {
  const [inputIds, setInputIds] = useState('');

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputIds(e.target.value);
  };

  const isDisabled = useMemo(() => {
    return inputIds.length === 0;
  }, [inputIds]);

  const onFinish = () => {
    const spaceFixedInput = inputIds.replace(regexSpace, ' ');
    const cleanInput = spaceFixedInput.replace(regex, '');
    const ids = cleanInput.split(' ');
    const uniqueIds = ids.reduce((accumulator: string[], current) => {
      if (
        !accumulator.some(
          (item) => item === current,
        )
      ) {
        accumulator.push(current);
      }
      return accumulator;
    }, []).filter(id => id.length > 0);

    props.onFinish(uniqueIds);
  };

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card title="Instructions">
          <ul>
            <li>Type or paste ids for the orders you wish to sync into the input area.</li>
            <li>ZIFT ids and external ids are both supported.</li>
            <li>Ids may be either comma separated or one-per-line.</li>
            <li>External ids will search for all orders in the case of a split order.</li>
          </ul>
        </Card>
        <TextArea value={inputIds} onChange={onChange} rows={6} />
        <Button type="primary" disabled={isDisabled} onClick={onFinish}>Continue</Button>
      </Space>
    </>
  );
};
