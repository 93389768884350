import { useCallback, useDebugValue, useMemo } from "react";
import { QueryParamConfig } from ".";
import * as RRD from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { useSearchParams } from "./useSearchParams";

export type useQueryParamResults<T> = [
  value: Readonly<T>,
  update: (value: Readonly<T>) => void
];

/**
 * A wrapper around react-router's useQueryParams that can be used to read/update a single
 * search parameter
 */
export function useQueryParam<T>(key: string, config: Readonly<QueryParamConfig<T>>)
  : useQueryParamResults<T> {

  // NOTE: Temporary work-around using local copy-modified version of the useSearchParams func
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Decode the current value of the specified query parameter
   */
  const value: Readonly<T> = useMemo(() => {
    try {
      return config.decode(key, searchParams);
    } catch (err) {
      Sentry.captureException(err);
      return undefined as any;
    }
  }, [key, config, searchParams]);

  useDebugValue(value, (v) => `${key}: ${JSON.stringify(v)}`);

  /**
   * An update function that write out a new value to the query param in the url
   */
  const update = useCallback((newValue: Readonly<T>) => {
    setSearchParams((prev) => {
      const newSearchParams = RRD.createSearchParams(prev);
      config.encode(key, newValue, newSearchParams);
      return newSearchParams;
    }, { replace: true });
  }, [key, config, setSearchParams]);

  return [value, update];
}

export default useQueryParam;
