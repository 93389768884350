import { IToolPanelParams, SelectionChangedEvent } from "ag-grid-community";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FieldValues, SubmitHandler, useForm, useFormState } from "react-hook-form";
import * as Sentry from "@sentry/browser";
import { Spin } from "antd";
import { Batch, BatchesApi } from "../../../../../api/core";

const Container = styled.div`
  margin: 0px auto;
  padding: 10px;
`;

const Error = styled.p`
  color: red;
  font-weight: bold;
  padding: 2px;
  border: red 1px dotted;
`;

const SuccessMessage = styled.p`
  color: green;
  font-weight: bold;
  padding: 2px;
  border: green 1px dotted;
`;

const textAreaStyle: React.CSSProperties = {
  width: '100%',
  height: '150px'
};

const submitStyle: React.CSSProperties = {
  width: '100%',
  margin: '5px 0px'
};

interface BulkEditNotesFieldValues extends FieldValues {
  note: string;
}

export const BulkEditNotesToolPanel = (props: IToolPanelParams) => {

  const [selected, setSelected] = 
    useState<Batch[]>(props.api.getSelectedRows());
  const [selectedCountAtSubmit, setSelectedCountAtSubmit] = useState<number>(0);
  const [submitCount, setSubmitCount] = useState<number>(0);
  const { register, handleSubmit, reset, control } = useForm<BulkEditNotesFieldValues>();
  const { errors, isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({ control });
  const [updateBatches] = BatchesApi.useUpdateBatchesMutation();

  const [serverError, setServerError] = useState<any>();
  useEffect(() => {
    if (serverError) {
      Sentry.captureException(serverError);
    }
  }, [serverError]);

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    setSelected(e.api.getSelectedRows());
  };

  useEffect(() => {
    props.api.addEventListener('selectionChanged', onSelectionChanged);

    return () => {
      props.api.removeEventListener('selectionChanged', onSelectionChanged);
    };
  }, [props?.api]);

  const onSaveSubmit: SubmitHandler<BulkEditNotesFieldValues> = async (data, e) => {
    setServerError(undefined);
    setSubmitCount(submitCount + 1);
    setSelectedCountAtSubmit(selected.length);

    const submitted = selected.map(b => b.id);

    return updateBatches({
      noteOnly: true,
      batches: submitted.map(id => ({
        id,
        note: {
          content: data.note.trim() // NOTE: even if we don't trim it, the back-end will
        },
      }))
    }).then(response => {
      props.api.forEachNode((node, ndx) => {
        if (submitted.indexOf(node.data.id) !== -1) {
          node.updateData({ ...node.data, note: { content: data.note } });
        }
      });

      return response;
    }, (err) => {
      setServerError(err);
      return err;
    });
  };

  useEffect(() => {
    // for some reason, react-hook-form will set both isSubmitting and isSubmitted 
    // to true at the same time... it is thus necessary to check both flags to know 
    // if everything is done processing...
    let timer: number;
    if (!isSubmitting && isSubmitted) {
      timer = window.setTimeout(() => {
        reset({ keepValues: true, keepDefaultValues: true });
        setServerError(undefined);
      }, 1500);
    }

    return () => { 
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isSubmitting, isSubmitted, reset]); 

  const subHeader = useMemo(() => {
    if (isSubmitting) {
      return <h4>Saving {selectedCountAtSubmit} {selectedCountAtSubmit === 1 ? 'batches' : 'batches'}...</h4>;
    }
    switch (selected?.length) {
      case 0: return <h4>No batches are selected...</h4>;
      case 1: return <h4>There is 1 selected batch</h4>;
      default: return <h4>{`There are ${selected.length} selected batches`}</h4>;
    }
  }, [isSubmitting, selectedCountAtSubmit, selected?.length]);

  return (
    <Container>
      <Spin spinning={isSubmitting}>
        <h2>Bulk Edit Notes</h2>
        { subHeader }    

        { !!selected?.length && (
        <>
          <form onSubmit={handleSubmit(onSaveSubmit)}>
            <label htmlFor="Note">
              <textarea 
                style={textAreaStyle} 
                {...register("note", { 
                  required: { value: true, message: 'Note required' },
                  pattern: { value: /[^\s]/, message: 'Note cannot be empty' } 
                })}
              />
            </label>
            { errors?.note && (
            <Error>{errors.note.message}</Error>
            )}

            <button
              type="submit"
              style={submitStyle}
              disabled={!selected?.length}
            >
              Save { selected?.length > 1 ? 'Notes' : 'Note' }  
            </button>
          </form>
        </>
        )}

        { isSubmitted && isSubmitSuccessful && (
          <SuccessMessage>Successfully Updated The Selected Batch(es)</SuccessMessage>
        )}

        { serverError && (
        <>
          <Error>{serverError.message}</Error>
        </>
        )}
      </Spin>
    </Container>
  );
};

export default BulkEditNotesToolPanel;
