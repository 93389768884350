import React, { useEffect } from "react";
import { Col, Spin, Tag } from "antd";
import { OrdersApi } from "../../../../api/core";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

export enum OrderSyncStatus {
  Pending, Loading, Confirming, Failing
}

export const FinalOrderSyncStates = [OrderSyncStatus.Confirming, OrderSyncStatus.Failing];

const actionColorMap: { [id: string] : string; } = {
  create: 'green',
  update: 'blue',
  cancel: 'red'
};

export interface OrderSyncParams {
  id: string,
  status: OrderSyncStatus,
  result?: OrderSyncResult,
  onChange: (id: string, status: OrderSyncStatus, result: OrderSyncResult) => void
}

export interface OrderSyncResult {
  message?: string,
  actions?: string[]
}

export const OrderSync = (props: OrderSyncParams) => {
  const { data } = OrdersApi.useSyncOrderQuery(
    { id: props.id }, 
    { skip: props.status === OrderSyncStatus.Pending }
  );

  const formatAction = (value: string | null | undefined) => {
    if (!value) {
      return '';
    }
    return value
      .split('_')
      .map(word => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase())
      .join(" ");
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const status = data.status === 'success' ? OrderSyncStatus.Confirming : OrderSyncStatus.Failing;
    const result = {
      id: data.id,
      message: data.message,
      actions: data.actions
    };

    props.onChange(props.id, status, result);
  }, [data, props.id, props]);
  
  return (
    <>
      <Col span={1}>
        {props.status === OrderSyncStatus.Failing &&
          <CloseCircleTwoTone twoToneColor="#cc0000" /> }
        {props.status === OrderSyncStatus.Confirming &&
          <CheckCircleTwoTone twoToneColor="#00cc00" /> }
        {props.status === OrderSyncStatus.Loading &&
          <Spin tip={OrderSyncStatus[props.status]} size="small" /> }
      </Col>
      <Col span={7}>{props.id}</Col>
      <Col span={6}>
        {props?.result?.actions?.map(a => (
          <Tag key={a} color={actionColorMap[a] || 'purple'}>{formatAction(a)}</Tag>
        ))}
      </Col>
      <Col span={10}>{props?.result?.message}</Col>
    </>
  );
};
