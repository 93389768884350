import { AgGridDatasourceProps, GetStores, GetStoresVariables, GetStoresVariables_Options, GetStoresVariables_Options_Includes, StoresApi, useAgGridDatasource } from "../../../api/core";

export interface StoreDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetStoresVariables_Options, GetStoresVariables, GetStores>, "endpoint"> {
    include?: GetStoresVariables_Options_Includes[] | null | undefined;
  }

export const DefaultStoreDatasourceProps = Object.freeze<StoreDatasourceProps>({
  options: {
    include: ["tag_list"]
  }
});

export function useStoreDatasource(props: StoreDatasourceProps = DefaultStoreDatasourceProps) {
  return useAgGridDatasource<GetStoresVariables_Options>({
    ...props,
    endpoint: StoresApi.endpoints.getStores,
    options: {
      ...DefaultStoreDatasourceProps.options,
      ...props.options
    },
  });
}

export default useStoreDatasource;
