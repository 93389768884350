/* eslint-disable max-classes-per-file */
import React, { ForwardedRef, useCallback, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColumnApi, FilterChangedEvent, GetContextMenuItemsParams, GridApi, GridOptions, GridReadyEvent, MenuItemDef, StoreUpdatedEvent } from "ag-grid-community";
import { DisplayedRowCountComponent } from "./components/statusBar/DisplayedRowCountComponent";
import { IDataGridPlugin } from "./plugins/IDataGridPlugin";
import * as Renderers from "./renderers";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";
import "./index.scss";
import defaultColDef from "./defaultColDef";
import { isArray, isFunction } from "lodash";
import { Spin } from "antd";

export interface DataGridProps {
  apiRef?: ForwardedRef<GridApi | null | undefined>,
  columnApiRef?: ForwardedRef<ColumnApi | null | undefined>,
  gridOptions?: GridOptions | GridOptions[],
  plugins?: IDataGridPlugin[]
}

const GridWrapper = styled.div`
  height: 100%;
`;

export const DataGrid = (props : DataGridProps) => {
  const [isExporting, setExporting] = useState(false);
  // store the original cache block size
  const [cacheBlockSize] = useState(() => {
    if (isArray(props.gridOptions)) {
      return props.gridOptions[0]?.cacheBlockSize ?? 25;
    }
    return props.gridOptions?.cacheBlockSize ?? 25;
  });
  /*  By default, AgGrid will retain all selections after applying a filter change,
    including those rows that have been filtered out! This is un-intuitive and makes for 
    a confusing, buggy user experience. Also, there isn't an efficient way to determine which 
    rows are still valid after changing the filters. As such, we are just going to unselect
    everything whenever the filters change.
  */
  const onFilterChanged = useCallback((params:FilterChangedEvent) => {
    params.api.deselectAll();
  }, []);

  // on store updated, export csv data - then remove listener and restore cache block size
  const storeUpdatedCsvListener = useCallback((params: StoreUpdatedEvent) => {
    params.api.exportDataAsCsv();
    params.api.removeEventListener("storeUpdated", storeUpdatedCsvListener);
    params.api.setCacheBlockSize(cacheBlockSize);
    setExporting(false);
  }, [cacheBlockSize]);

  // on store updated, export excel data - then remove listener and restore cache block size
  const storeUpdatedExcelListener = useCallback((params: StoreUpdatedEvent) => {
    params.api.exportDataAsExcel();
    params.api.removeEventListener("storeUpdated", storeUpdatedExcelListener);
    params.api.setCacheBlockSize(cacheBlockSize);
    setExporting(false);
  }, [cacheBlockSize]);

  // replace export in context menu with our server-side versions
  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const results: (string | MenuItemDef)[] = [...(params.defaultItems || [])].filter(r => r !== 'export');
    results.push(
      {
        name: 'Export',
        subMenu: [
          {
            name: 'CSV Export',
            action: () => {
              setExporting(true);
              params.api.addEventListener("storeUpdated", storeUpdatedCsvListener);
              params.api.setCacheBlockSize(Number.MAX_SAFE_INTEGER);
            }
          }, {
            name: 'Excel Export',
            action: () => {
              setExporting(true);
              params.api.addEventListener("storeUpdated", storeUpdatedExcelListener);
              params.api.setCacheBlockSize(Number.MAX_SAFE_INTEGER);
            }
          }
        ]
      }
    );
    return results;
  }, [storeUpdatedCsvListener, storeUpdatedExcelListener]);

  const gridOptions: GridOptions = useMemo(() => {
    const go: GridOptions = {
      blockLoadDebounceMillis: 100, // setting this above zero prevents an initial getRows call before the filter is set
      context: {},
      rowModelType: "serverSide",
      cacheBlockSize: 25,
      maxBlocksInCache: 40, // 25 * 40 = up to 1000 rows cached at a time
      groupDisplayType: "multipleColumns",
      rowSelection: "multiple",
      enableRangeSelection: true,
      defaultColDef,
      multiSortKey: 'ctrl',
      onFilterChanged,
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agSelectedRowCountComponent",
            align: 'right'
          },
          {
            statusPanel: DisplayedRowCountComponent,
            align: 'right'
          },
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            toolPanel: 'agColumnsToolPanel',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanelParams: {
              suppressPivotMode: true,
              suppressValues: true
            }
          }, 
          "filters",
        ] 
      },
      getContextMenuItems
    };

    // TO DO: Create a GridOptionsBuilder class to make merging the options cleaner
    // merge any supplied gridOptions from the props
    if (isArray(props.gridOptions)) {
      Object.assign(go, ...props.gridOptions);
    } else {
      Object.assign(go, props.gridOptions); 
    }

    // capture the serverSideDatasource (if applicable) within the context
    // The AgGrid api currently doesn't provide a means of looking up the datasource...
    // This is used (presently) by the TotalRowCountComponent to fetch the row count.
    if (!go.context) { go.context = {}; }
    Object.assign(go.context, { serverSideDatasource: go.serverSideDatasource });

    return go;
  }, [props.gridOptions, onFilterChanged, getContextMenuItems]);

  if (props.plugins) {
    gridOptions.onGridReady = (e: GridReadyEvent) => {
      if (props.apiRef) {
        if (isFunction(props.apiRef)) {
          props.apiRef(e.api);
        } else {
          props.apiRef.current = e.api;
        }
      }

      if (props.columnApiRef) {
        if (isFunction(props.columnApiRef)) {
          props.columnApiRef(e.columnApi);
        } else {
          props.columnApiRef.current = e.columnApi;
        }
      }

      if (isArray(props.gridOptions)) {
        props.gridOptions.forEach(_ => _.onGridReady?.(e));
      } else {
        props.gridOptions?.onGridReady?.(e);
      }

      props.plugins?.forEach(plugin => {
        try {
          plugin.onGridReady(e);
        } catch (err) {
          Sentry.captureException(err);
        }
      });
    };
  }

  return (
    <GridWrapper className="ag-theme-madengine">
      <Spin spinning={isExporting} tip="Please wait while your export is generated.">
        <AgGridReact
          className="ag-theme-madengine"
          gridOptions={gridOptions}
          components={{ 
            ...Object.values(Renderers),
          }}
        />
      </Spin>
    </GridWrapper>
  );
};
