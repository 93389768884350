import React, { useMemo } from "react";
import { Checkbox, Col } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

export interface OrderResultParams {
  ziftId: string,
  status: string | null | undefined,
  externalId: string | null | undefined,
  selected: boolean,
  onChange: (ziftId: string, selected: boolean) => void
}

export const OrderResult = (props: OrderResultParams) => {
  const onChange = (e: CheckboxChangeEvent) => {
    props.onChange(props.ziftId, e.target.checked);
  };

  const isDisabled = useMemo(() => {
    return props.status === 'invalid';
  }, [props.status]);

  const formatStatus = (value: string | null | undefined) => {
    if (!value) {
      return '';
    }
    return value
      .split('_')
      .map(word => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase())
      .join(" ");
  };

  return (
    <>
      <Col span={1}><Checkbox checked={props.selected} disabled={isDisabled} value={props.ziftId} onChange={onChange} /></Col>
      <Col span={9}>{props.ziftId}</Col>
      <Col span={4}>{formatStatus(props.status)}</Col>
      <Col span={10}>{props.externalId}</Col>
    </>
  );
};
