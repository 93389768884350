import React, { useMemo } from "react";
import { GetRowIdParams, GridOptions } from "ag-grid-enterprise";
import { DataGrid, DataGridProps } from "../../DataGrid";
import defaultColumnDefs from "./defaultColumnDefs";
import { isArray } from "lodash";
import { GetLineItems_Include, LineItem } from "../../../api/core";
import useLineItemsDatasource from "./useLineItemDatasource";

export interface LineItemDataGridProps extends DataGridProps {
  include?: GetLineItems_Include[]; 
}

export const LineItemDataGrid = (props: LineItemDataGridProps) => {
  const { plugins } = props;
  const options: LineItemDataGridProps = {};

  if (props.include) {
    options.include = props.include;
  }

  const datasource = useLineItemsDatasource({ options });

  const defaultGridOptions:GridOptions = useMemo(() => {
    return {
      columnDefs: defaultColumnDefs,
      serverSideDatasource: datasource,
      serverSideInfiniteScroll: true,
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => (param.data as LineItem).id,
      animateRows: true,
      enableCellChangeFlash: true,
    };
  }, [datasource]);

  const gridOptionsArray:GridOptions[] = useMemo(() => {
    if (isArray(props.gridOptions)) {
      return [defaultGridOptions, ...props.gridOptions];
    } if (props.gridOptions) {
      return [defaultGridOptions, props.gridOptions];
    } 
    return [defaultGridOptions];
    
  }, [defaultGridOptions, props.gridOptions]);

  return (
    <DataGrid
      gridOptions={gridOptionsArray} 
      plugins={plugins}
    />
  );
};

export default LineItemDataGrid;
