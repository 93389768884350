import { AgDateFilter, AgGridQueryArgs, AgGridQueryArgs_Options, AgQueryResponse } from "../AgGrid";
import { AliasMap, DeserializationSchema, deserialize } from "../utils";
import { QuotumUsage, deserializeQuotumUsageArray } from "./common";

export interface QuotumUsageFilterInput {
  date?: AgDateFilter | null;
}

export interface GetQuotumUsage extends AgQueryResponse<QuotumUsage> {}

export const GetQuotumUsageDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeQuotumUsageArray
});

export const deserializeGetQuotumUsage = deserialize<GetQuotumUsage>(GetQuotumUsageDeserializationSchema, "[GetQuotumUsage]");

export type GetQuotumUsage_Include = "quotum" | "store" | "facility";
export interface GetQuotumUsageVariables_Options extends AgGridQueryArgs_Options {
  include?: GetQuotumUsage_Include[] | null | undefined;
}

export interface GetQuotumUsageVariables 
  extends AgGridQueryArgs<GetQuotumUsageVariables_Options, QuotumUsageFilterInput> {}

export const GetQuotumUsage_Input_AliasMap: AliasMap = {
};
