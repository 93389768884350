import { DateTime } from "luxon";
import { compareDatesAsc } from "./compareDatesAsc";

export function sortTimeSeries(series: any, timeFormat: string = "HH:mm") {
  return series
    .slice()
    .map((row: any) => {
      if (typeof row.time == "string")
        return {
          ...row,
          time: new Date(row.time),
        };
      return row;
    })
    .sort((a: any, b: any) => compareDatesAsc(a.time, b.time))
    .map((row: any) => ({
      ...row,
      time: DateTime.fromJSDate(row.time).toFormat(timeFormat),
    }));
}
