import { useCallback, useDebugValue, useMemo } from "react";
import * as RRD from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { QueryParamConfig, QueryParamInputType, QueryParamOutputType } from ".";
import { useSearchParams } from "./useSearchParams";

export type useQueryParamsSchema<T> = { 
  [P in keyof T]: 
    QueryParamConfig<QueryParamInputType<T[P]>, QueryParamOutputType<T[P]>>
};

export function createSchema<T extends useQueryParamsSchema<T>>(schemaObject: T) {
  return schemaObject as Readonly<useQueryParamsSchema<T>>;
}

export type useQueryParamsValues<T extends useQueryParamsSchema<T>> = { 
  -readonly [P in keyof T]: QueryParamInputType<T[P]>;
};

export type useQueryParamsUpdateCallback<T extends useQueryParamsSchema<T>> = 
  (previous: useQueryParamsValues<T>) => useQueryParamsValues<T> | undefined;

export type useQueryParamsResults<T extends useQueryParamsSchema<T>> = [ 
  values: Readonly<useQueryParamsValues<T>>, 
  update: (callback: useQueryParamsUpdateCallback<T>) => void
];

/**
 * A wrapper around react-router v6's useSearchParams hook that will allow you to read and write
 * a set of url parameters. You must specify separate configuration for each url parameter. This
 * hook will in turn pass back an object with an entry for each url parameter whose value is the
 * current decoded value from the url. It will also return a function for assigning one or more
 * of these url parameters at once.
 */
export function useQueryParams<T extends useQueryParamsSchema<T>>(schema: Readonly<T>)
  : useQueryParamsResults<T> {

  // NOTE: Temporary work-around using local copy-modified version of the useSearchParams func
  const [searchParams, setSearchParams] = useSearchParams();
  
  const keys = useMemo(() => Object.keys(schema) as [keyof T], [schema]);
  const configs: Readonly<QueryParamConfig<any>>[] = useMemo(() => Object.values(schema), [schema]);

  /**
   * Compute an object containing the decoded values of all the parameters in the schema that
   * are present in the current location's search params. Only recompute if either our schema
   * changes or if there is an update to the search params.
   */
  const values: Readonly<useQueryParamsValues<T>> = useMemo(() => {
    return keys.reduce<useQueryParamsValues<T>>((result, key, ndx) => {
      try {
        result[key] = configs[ndx].decode(key as string, searchParams);
      } catch (err) { 
        Sentry.captureException(err);
      }
      return result;
    }, {} as useQueryParamsValues<T>);
  }, [keys, configs, searchParams]);

  useDebugValue(values, (v) => JSON.stringify(v));

  /**
   * An update function that can selectively update one or more search params at a time
   */
  const update = useCallback((callback: useQueryParamsUpdateCallback<T>) => {
    setSearchParams((prev) => {
      const newSearchParams = RRD.createSearchParams(prev);

      // don't use the memoized values variable, as then the update function
      // would need to recomputed everytime a value changes
      const prevValues = keys.reduce<useQueryParamsValues<T>>((result, key, ndx) => {
        try {
          result[key] = configs[ndx].decode(key as string, prev);
        } catch (err) { 
          Sentry.captureException(err);
        }
        return result;
      }, {} as useQueryParamsValues<T>);

      const newValues = callback(prevValues);
      if (newValues === undefined) {
        keys.forEach((key, ndx) => {
          configs[ndx].encode(key as string, undefined, newSearchParams);
        });
      } else {
        keys.forEach((key, ndx) => {
          const val = newValues[key];
          try {
            configs[ndx].encode(key as string, val as any | undefined, newSearchParams);
          } catch (err) {
            Sentry.captureException(err);
          }
        });
      }

      return newSearchParams;
    }, { replace: true });
  }, [keys, configs, setSearchParams]);

  return [values, update];
}

export default useQueryParams;
