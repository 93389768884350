import React from "react";
import { Grid, DatePicker, Sider, MetricCard } from "../../../components";
import { Form, Layout, Select } from "antd";
import {
  DateParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "../../../api/useQueryParams";
import ProCard from "@ant-design/pro-card";
import { GetMetricsResolution, MetricsSource } from "../../../api/core";
import { DateTime } from "luxon";

const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Option } = Select;
const { Col, Row } = Grid;

export const InsightsDashboardPage = React.memo(() => {
  const [filter, setFilter] = useQueryParams({
    from: withDefault(DateParam, DateTime.now().startOf('month').toJSDate()),
    to: withDefault(DateParam, DateTime.now().endOf('month').toJSDate()),
    resolution: withDefault(StringParam, "day"),
  });

  const handleDateChange = (dates: any, dateStrings: String[]) => {
    setFilter((value: any) => ({
      ...value,
      from: dates[0],
      to: dates[1],
    }));
  };

  const filterComponents = {
    resolution: {
      label: "Resolution",
      render: (
        <Select
          value={filter?.resolution?.toString()}
          onChange={(resolution) =>
            setFilter((filterValue) => ({ ...filterValue, resolution }))
          }
        >
          <Option value="hour">Hour</Option>
          <Option value="day">Day</Option>
          <Option value="week">Week</Option>
          <Option value="month">Month</Option>
          <Option value="year">Year</Option>
        </Select>
      ),
    },
    dateRange: {
      label: "Date Range",
      render: (
        <RangePicker
          placeholder={["From", "To"]}
          allowClear
          allowEmpty={[true, true]}
          value={[filter?.from, filter?.to] as [any, any]}
          ranges={{
            Today: [DateTime.now().startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()],
            "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
            "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
            "This Year": [DateTime.now().startOf('year').toJSDate(), DateTime.now().endOf('year').toJSDate()],
          }}
          onChange={handleDateChange}
        />
      ),
    },
  } as { [key: string]: any };

  const filterItems = Object.keys(filterComponents).map((name) => {
    const { render, label } = filterComponents[name];
    return (
      <Form.Item key={label} label={label} style={{ marginBottom: 10 }}>
        {render}
      </Form.Item>
    );
  });

  return (
    <Layout>
      <Sider>
        <Form
          layout="vertical"
          style={{
            padding: 12,
          }}
        >
          {filterItems}
        </Form>
      </Sider>

      <Content style={{ padding: 20 }}>
        <ProCard.Group title="Stats">
          <Row gutter={16}>
            <Col>
              <MetricCard
                title="Printed Items"
                groupBy={["facility_name"]}
                seriesField="facilityName"
                timeColumn="printed_at"
                source={MetricsSource.LineItems}
                from={filter?.from!}
                to={filter?.to!}
                resolution={filter?.resolution as GetMetricsResolution}
              />
            </Col>
            <Col>
              <MetricCard
                title="Items Ordered"
                groupBy={["store_name"]}
                seriesField="storeName"
                timeColumn="created_at"
                source={MetricsSource.LineItems}
                from={filter?.from!}
                to={filter?.to!}
                resolution={filter?.resolution as GetMetricsResolution}
              />
            </Col>
            <Col>
              <MetricCard
                title="Orders Created"
                groupBy={["store_name"]}
                seriesField="storeName"
                timeColumn="created_at"
                source={MetricsSource.Orders}
                from={filter?.from!}
                to={filter?.to!}
                resolution={filter?.resolution as GetMetricsResolution}
              />
            </Col>
          </Row>
        </ProCard.Group>
      </Content>
    </Layout>
  );
});
