import React from "react";
import { Icon } from "../../components";

export const LabelCheckedRounded = ({ ...props }) => (
  <Icon {...props}>
    <path d="M20.2 13.1l-3.5 5c-.2.3-.4.5-.7.6-.4.2-.7.3-1 .3H5c-.5 0-1-.2-1.4-.6S3 17.5 3 17V7c0-.5.2-1 .6-1.4C4 5.2 4.5 5 5 5h10c.3 0 .6.1.9.2.3.1.5.4.7.6l3.5 5c.2.4.4.7.4 1.2.1.4-.1.8-.3 1.1zM15 17l3.6-5L15 7H5v10h10zM5 7v10V7zm4.8 5.4l2.8-2.8c.2-.2.4-.3.7-.3.3 0 .5.1.7.3.2.2.3.4.3.7 0 .3-.1.5-.3.7l-3.5 3.5c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-1.4-1.4c-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7.2-.2.4-.3.7-.3.3 0 .5.1.7.3l.7.7z" />
  </Icon>
);

export const LabelOffRounded = ({ ...props }) => (
  <Icon {...props}>
    <path d="M18.5 15.6L17 14.1l1.6-2.1L15 7H9.9l-2-2H15c.3 0 .6.1.9.2.3.1.5.4.7.6l3.5 5c.2.4.4.7.4 1.2 0 .4-.1.8-.4 1.1l-1.6 2.5zM5 19c-.5 0-1-.2-1.4-.6S3 17.5 3 17V7c0-.2 0-.3.1-.5 0-.1.1-.3.2-.4L2.1 4.9c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7c.2-.2.4-.3.7-.3s.5.1.7.3l17 17c.2.2.3.4.3.7 0 .3-.1.5-.3.7-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-3.2-3.1c-.1.1-.3.1-.4.2H5zm9.2-2L5 7.8V17h9.2z" />
  </Icon>
);
