import { FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import { isNumber, isString } from "lodash";
import { DeserializationFunc } from "./deserialize";

export interface transformErrorResponseOptions<TData = any> {
  suppress?: (ep: ErrorPayload<TData>) => boolean;
}

export interface ErrorPayload<TData = any> {
  endpoint: string;
  status: number;
  originalStatus?: number; // only applicable in the event of a parse error
  statusText?: string;
  data?: TData | null;
  url?: string;
  message: string;

  /**
   * prevents automated error reporting from occurring
   * useful in cases where errors are expected and handled by the UI in a more elegant fashion
   */
  suppress?: boolean; 
}

export function isErrorPayload(val: any): val is ErrorPayload {
  if (Object(val) !== val) { return false; }
  return isString(val.endpoint) && isNumber(val.status) && isString(val.message)
    && (!val.statusText || isString(val.statusText)) && (!val.url || isString(val.url));
}

export function transformErrorResponse<TVariables = any, TData = any>(endpoint: string, 
  deserializeData?: DeserializationFunc<TData>, options?: transformErrorResponseOptions<TData>) 
{
  return (error: FetchBaseQueryError, meta: FetchBaseQueryMeta | undefined, args: TVariables) => {
    // NOTE: The fetchBaseQuery implementation doesn't provide good default error handling.
    // Also, the structure of the errors are a bit customized and not what you might expect.
    let response: ErrorPayload<TData> = {
      status: 0, // temporary value to satisfy Typescript
      statusText: meta?.response?.statusText,
      data: deserializeData ? deserializeData(error.data) : error.data as TData | undefined,
      url: meta?.request.url,
      message: meta?.response?.statusText || 'unknown',
      endpoint,
    };

    if (isNumber(error.status)) {
      response = { ...response, status: error.status };
      response.suppress = options?.suppress?.(response);
      return response;
    } 

    // a FetchBaseQuery error specific code
    // There will be an error property underneath error that is a string containing the error message
    response.message = (error.data as { message: string })?.message 
      || (error as { error:string}).error || meta?.response?.statusText || 'unknown';

    switch (error.status) {
      case 'FETCH_ERROR':
        response = { ...response, status: 400, statusText: "Bad Request" };
        response.suppress = options?.suppress?.(response);
        return response;

      case 'PARSING_ERROR':
        response = { 
          ...response,
          status: 422, 
          statusText: "Unprocessable Content",
          originalStatus: error.originalStatus, 
        };
        response.suppress = options?.suppress?.(response);
        return response;

      case 'TIMEOUT_ERROR':
        response = {
          ...response,
          status: 408,
          statusText: "Request Timeout"
        };
        response.suppress = options?.suppress?.(response);
        return response;

      case 'CUSTOM_ERROR':
        response = { 
          ...response,
          status: ((error.data as any)?.status as number) || 418,
          statusText: ((error.data as any)?.statusText as string) || "I'm a teapot",
          message: (error.data as any)?.message || "<Custom Error Message Placeholder>",
        };
        response.suppress = options?.suppress?.(response);
        return response;
        
      default: return error;
    }
  };
}
