import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import "./index.scss";
import { Alert, Drawer, Spin, Tooltip } from "antd";
import { get, isEmpty, isNil, keyBy, mapValues, sum } from "lodash";
import { BatchDetail, LoadingMessage, Tag } from "../../../components";
import { ArrayParam, StringArrayParam, StringParam, useQueryParam, withDefault } from "../../../api/useQueryParams";
import List, { ListRef } from "rc-virtual-list";
import { ScrollConfig } from "rc-virtual-list/lib/List";

import { ScheduleViewPageHeader } from "./ScheduleViewPageHeader";
import { ScheduleBatchItem } from "./ScheduleBatchItem";
import { isValidDate } from "../../../helpers";
import { Link } from "react-router-dom";
import { dateLocalParamDeserialize } from "../../../api/useQueryParams/Serialize";
import { isUuid } from "../../../util/isUuid";
import * as Sentry from "@sentry/browser";
import {
  CallSplitOutlinedRounded,
  CounterOneOutlinedRounded,
  DomainVerificationOutlinedRounded,
  DoneRounded,
  PauseRounded,
  PointScanRounded,
  ShelvesOutlinedRounded,
  WarningOutlinedRounded,
} from "../../../fixtures/icons";
import { Batch, BatchFilterInputV2, BatchStatus, EmptyLineItemStatusCount, LineItemStatusCount, ScheduleIdentifier } from "../../../api/core";
import { GetSettings, SettingsApi } from "../../../api/core/Settings";
import { FloorviewsApi, GetFloorviewBatches, GetFloorviewSchedule, GetFloorviewStores } from "../../../api/core/Floorviews";
import { useHTMLElementRefSize } from "../../../api";
import { ErrorBoundary } from "@sentry/react";
import ErrorBoundaryFallback from "../../../components/Page/ErrorBoundaryFallback";
import { DateTime } from "luxon";

export interface FloorViewBatch {
  batch: Batch;
  floorViewStatus: FloorViewStatus;
}

export enum FloorViewStatus {
  Batched = "BATCHED",
  Pulled = "PULLED",
  Branding = "BRANDING",
  BrandingComplete = "BRANDING_COMPLETE",
  DeliveredToPrinter = "DELIVERED_TO_PRINTER",
  Printing = "PRINTING",
  Drying = "DRYING",
  Packaging = "PACKAGING",
  Shipping = "SHIPPING",
  Complete = "COMPLETE",
  Deleted = "DELETED",
  Canceled = "CANCELED",
}

const overridableStatuses = [FloorViewStatus.Branding, FloorViewStatus.Pulled, FloorViewStatus.Batched];

interface ScheduleViewPageProps extends React.ComponentProps<any> {
  className?: string;
}

interface FloorviewData {
  settings: GetSettings;
  floorview: {
    schedule: GetFloorviewSchedule;
    stores: GetFloorviewStores;
    batches: GetFloorviewBatches;
  }
}

// eslint-disable-next-line prefer-arrow-callback
export const ScheduleViewPage = ({ className }: ScheduleViewPageProps) => {
  const navigate = useNavigate();

  const [updatedAt, setUpdatedAt] = useState<Date>(new Date());
  const [batchId, setBatchId] = useQueryParam("id", StringParam);
  const [stores, setStores] = useQueryParam("stores", StringArrayParam);
  const { date: dateParam, facilityId: facilityParam } = useParams() as { date: string; facilityId?: string };
  const [statusFilter, setStatusFilter] = useQueryParam(
    "status",
    withDefault(
      ArrayParam,
      Object.values(FloorViewStatus).filter(
        (e) => ![FloorViewStatus.Complete, FloorViewStatus.Deleted, FloorViewStatus.Canceled].includes(e)
      ) as string[]
    )
  );

  const listContainerRef = useRef<HTMLDivElement>(null);
  const listContainerSize = useHTMLElementRefSize(listContainerRef);
  const listRef = React.useRef<ListRef>(null);
  const eventTarget = React.useRef<EventTarget>(new EventTarget());
  const [searchStatus, setSearchStatus] = useState<boolean | undefined>();

  const setDate = (date: Date) => {
    let pathname = `/schedules/view/${DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")}`;
    if (facilityParam) {
      pathname += `/${facilityParam}`;
    }
    navigate({
      pathname,
      search: window.location.search,
    });
  };

  const date = useMemo(() => {
    let value = dateLocalParamDeserialize(dateParam);
    if (!value || !isValidDate(value)) {
      value = DateTime.now().toLocal().startOf('day').toJSDate();
    }
    return value;
  }, [dateParam]);

  const setFacility = (facility: string | undefined) => {
    let pathname = `/schedules/view/${dateParam}`;
    if (facility) {
      pathname += `/${facility}`;
    }
    navigate({
      pathname,
      search: window.location.search,
    });
  };

  const facility = useMemo(() => facilityParam, [facilityParam]);
  const facilityId = useMemo(() => (facility ? (isUuid(facility) ? facility : undefined) : undefined), [facility]);
  const facilityCode = useMemo(() => (facility ? (!isUuid(facility) ? facility : undefined) : undefined), [facility]);

  const batchFilter = useMemo<BatchFilterInputV2>(() => {
    const result: BatchFilterInputV2 = {};

    const storeIds = stores && (stores.filter((_) => _ != null) as string[]);
    if (storeIds && storeIds.length) {
      result.stores = {
        id: {
          filterType: "set",
          condition1: {
            values: storeIds,
          },
        }
      };
    }

    return result;
  }, [stores]);

  const abortController = useRef(new AbortController());
  const abort = useCallback(() => {
    abortController.current.abort();
    abortController.current = new AbortController();
  }, []);

  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<FloorviewData>();
  const [lastQueryId, setLastQueryId] = useState<number>(-1);
  const [settingsQueryTrigger] = SettingsApi.useLazyGetSettingsQuery();
  const [batchesQueryTrigger] = FloorviewsApi.useLazyGetFloorviewBatchesQuery();
  const [scheduleQueryTrigger] = FloorviewsApi.useLazyGetFloorviewScheduleQuery();
  const [storesQueryTrigger] = FloorviewsApi.useLazyGetFloorviewStoresQuery();

  const query = useCallback(() => {
    if (loading) {
      abort();
    }
    setLoading(true);

    const schedule:ScheduleIdentifier = {
      facilityId,
      facilityCode,
      date,
    };

    const settingsQuery = settingsQueryTrigger({});
    const batchesQuery = batchesQueryTrigger({ schedule, filter: batchFilter });
    const scheduleQuery = scheduleQueryTrigger({ schedule });
    const storesQuery = storesQueryTrigger({ schedule });
    const queries = [settingsQuery, batchesQuery, scheduleQuery, storesQuery];

    return new Promise<FloorviewData>((resolve, reject) => {
      abortController.current.signal.addEventListener('abort', () => {
        queries.every(_ => _.abort());
        reject(new Error("Abort"));
      });

      Promise.all(queries).then(async (_) => {
        abortController.current.signal.throwIfAborted();

        const data:FloorviewData = {
          settings: await settingsQuery.unwrap(),
          floorview: {
            batches: await batchesQuery.unwrap(),
            schedule: await scheduleQuery.unwrap(),
            stores: await storesQuery.unwrap()
          }
        };

        resolve(data);
      }).catch(err => {
        reject(err);
      });
    }).then((response) => {
      setLastQueryId((prev) => prev + 1);
      setLoading(false);
      setData(response);
      setError(undefined);
      setUpdatedAt(new Date());
    }, (reason) => { // NOTE: Don't clear the data on error
      setLastQueryId((prev) => prev + 1);
      setLoading(false);
      setError(reason);
      Sentry.captureException(reason);
      throw reason;
    });
  }, [
    batchFilter, date, facilityCode, facilityId, loading,
    abort, setData, setError, setLastQueryId, setLoading, setUpdatedAt,
    settingsQueryTrigger, batchesQueryTrigger, scheduleQueryTrigger, storesQueryTrigger
  ]);

  // keep track of whether or not this is the first time we've fetched the data for a particular
  // schedule. NOTE: do not include the lastQueryId in the dependency list as we don't want this
  // effect to trigger each time the query id changes

  /* eslint-disable react-hooks/exhaustive-deps */
  const [lastQueryIdOfPrevSchedule, setLastQueryIdOfPrevSchedule] = useState<number>(lastQueryId);
  useEffect(() => {
    setLastQueryIdOfPrevSchedule(lastQueryId);
    setData(undefined);
  }, [facilityId, facilityCode, date, /*lastQueryId,*/ setLastQueryIdOfPrevSchedule, setData]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const [lastBatchFilter, setLastBatchFilter] = useState<BatchFilterInputV2>(batchFilter);
  useEffect(() => {
    setLastBatchFilter(batchFilter);
  }, [batchFilter, setLastBatchFilter]);

  // After performing a query with a particular set of variables, keep doing so at regular
  // intervals of 30 seconds.
  const refreshTimer = useRef<number>();
  useEffect(() => {
    if (!loading && !isNil(date) && !(isNil(facilityId) && isNil(facilityCode))) {
      if (lastQueryId === lastQueryIdOfPrevSchedule || batchFilter !== lastBatchFilter) {
        // if either the schedule identifier changed or else if the batch filtering has changed,
        // then execute the query immediately
        query();
      } else {
        // if neither the schedule identifier has changed nor the batch filtering, then that means
        // that the only thing that changed was the lastQueryId. Wait 30 seconds and then query
        // again using the same parameters.
        refreshTimer.current = window.setTimeout(query, 30000);
      }
    }

    return () => {
      if (refreshTimer.current) {
        window.clearTimeout(refreshTimer.current);
        refreshTimer.current = undefined;
      }
    };
  }, [
    facilityId,
    facilityCode,
    date,
    batchFilter,
    lastBatchFilter,
    query,
    lastQueryId,
    lastQueryIdOfPrevSchedule,
    loading,
  ]);

  const schedule = useMemo(() => {
    return data?.floorview.schedule ?? null;
  }, [data?.floorview.schedule]);

  const batches = useMemo(() => data?.floorview.batches.rows || [], [data?.floorview.batches.rows]);

  const stats = (() => {
    if (isEmpty(batches))
      return {
        total: 0,
        pulled: 0,
        printed: 0,
        outOfStock: 0,
      };

    // Exclude batches with any shelf stock and screen printing
    const eligibleBatches = batches.filter(
      (batch) => batch?.status &&
        ![BatchStatus.Deleted].includes(batch.status) &&
        batch?.lineItemStats?.sourceCount?.shelf === 0 &&
        batch?.printer?.model !== "SP"
    );

    const total = sum(eligibleBatches.map((batch) => batch?.lineItemStats?.totalCount));

    const progressionStats = mapValues(
      keyBy(
        ["pulled", "printed"].map((name) => ({
          name,
          value: sum(eligibleBatches.map((batch) => get(batch?.lineItemStats?.progressionCount, name))) || 0,
        })),
        "name"
      ),
      "value"
    );

    const statusStats = mapValues(
      keyBy(
        ["outOfStock"].map((name) => ({
          name,
          value: sum(eligibleBatches.map((batch) => get(batch?.lineItemStats?.statusCount, name))) || 0,
        })),
        "name"
      ),
      "value"
    );

    return {
      total,
      pulled: 0,
      printed: 0,
      outOfStock: 0,
      ...progressionStats,
      ...statusStats,
    };
  })();

  const enumStatuses = useMemo(() => {
    return statusFilter.filter((element): element is string => element !== null) ?? [];
  }, [statusFilter]) as FloorViewStatus[];

  type PickedLineItemStatusCount = Pick<
    LineItemStatusCount,
    "pulled" | "branding" | "branded" | "printing" | "printed" | "packaging" | "packaged" | "shipping" | "shipped"
  >;

  const GetLineItemFloorViewStatus = useCallback((counts: PickedLineItemStatusCount, totalCount: number) => {
    if (counts.shipped > 0 || counts.shipping > 0) {
      return FloorViewStatus.Shipping;
    }
    if (counts.packaged > 0 || counts.packaging > 0) {
      return FloorViewStatus.Packaging;
    }
    if (counts.printed > 0) {
      return FloorViewStatus.Drying;
    }
    if (counts.printing > 0) {
      return FloorViewStatus.Printing;
    }
    if (counts.branding > 0 || counts.branded > 0) {
      return FloorViewStatus.Branding;
    }
    if (counts.pulled > 0) {
      return FloorViewStatus.Pulled;
    }
    return FloorViewStatus.Batched;
  }, []);

  const GetBatchFloorViewStatus = useCallback(
    (batch: Batch) => {
      const excludeCount = (batch.lineItemStats?.statusCount.defective ?? 0) + (batch.lineItemStats?.statusCount.outOfStock ?? 0);
      
      // this is the theoretical status based upon line items, which we may or may not choose to use
      const lineItemStatus = GetLineItemFloorViewStatus(
        batch.lineItemStats?.progressionCount ?? EmptyLineItemStatusCount, 
        (batch.lineItemStats?.totalCount ?? 0) - excludeCount
      );
      
      // Pulled > Branding > Branding Complete > Delivered to Printer > Printing > Packaging > Shipping > Complete
      if (batch.status === BatchStatus.Complete) {
        return FloorViewStatus.Complete;
      }
      if (batch.status === BatchStatus.Deleted) {
        return FloorViewStatus.Deleted;
      }
      if (batch.status === BatchStatus.Canceled) {
        return FloorViewStatus.Canceled;
      }

      if (batch.status === BatchStatus.New) {
        return FloorViewStatus.Batched;
      }
      if (batch.status === BatchStatus.BrandingComplete) {
        if (overridableStatuses.includes(lineItemStatus)) {
          return FloorViewStatus.BrandingComplete;
        }
        return lineItemStatus;
      }
      if (batch.status === BatchStatus.DeliveredToPrinter) {
        if (overridableStatuses.includes(lineItemStatus)) {
          return FloorViewStatus.DeliveredToPrinter;
        }
        return lineItemStatus;
      }
      return lineItemStatus;
    },
    [GetLineItemFloorViewStatus]
  );

  const floorViewBatches = useMemo(
    () =>
      batches.map<FloorViewBatch>((batch) => ({
        key: batch.id,
        batch,
        floorViewStatus: GetBatchFloorViewStatus(batch),
      })),
    [batches, GetBatchFloorViewStatus]
  );

  const filteredBatches = useMemo(() => {
    if (enumStatuses.length === 0) {
      return floorViewBatches;
    }
    return floorViewBatches.filter((composite) => enumStatuses.includes(composite.floorViewStatus));
  }, [floorViewBatches, enumStatuses]);

  // TO DO: query server for the collection of store ids + counts
  const allStoreIds = data?.floorview.stores.map((_) => _.id) || [];

  const minimumRowHeight = 200;
  const scrollToDuration = 1000;

  const [scrollToKey, setScrollToKey] = useState<string>();
  const [scrollToTimer, setScrollToTimer] = useState<number>(0);

  const onSearch = useCallback(
    (searchValue: string, e: any) => {
      const batchIndex = filteredBatches.findIndex((b) =>
        (b.batch.ziftId ?? "").toLowerCase().includes((searchValue ?? "").toLowerCase())
      );
      const { ziftId } = filteredBatches[batchIndex]?.batch ?? {};
      const matchNotFound = batchIndex === -1 || !ziftId;
      setSearchStatus(!matchNotFound);
      if (matchNotFound) {
        return;
      }
      const config: ScrollConfig = {
        index: batchIndex,
        offset: (listContainerSize.height! - minimumRowHeight) / 2, // center item vertically
      };

      listRef.current?.scrollTo(config);

      // NOTE: due to the nature of the virtual list, the target batch maybe in our data but
      // not rendered to the screen - and thus there will be no listener. Hence we additionally
      // need to track the scrollToKey and scrollToTimer outside of this event.
      eventTarget.current?.dispatchEvent(
        new CustomEvent("scrollTo", {
          detail: {
            index: batchIndex,
            ziftId,
          },
        })
      );

      setScrollToKey(ziftId);
      if (scrollToTimer) {
        clearTimeout(scrollToTimer);
      }

      setScrollToTimer(
        window.setTimeout(() => {
          setScrollToKey(undefined);
          setScrollToTimer(0);
        }, scrollToDuration)
      );
    },
    [filteredBatches, listContainerSize.height, scrollToTimer]
  );

  // cleanup timer on dispose
  useEffect(() => {
    return () => {
      if (scrollToTimer) {
        clearTimeout(scrollToTimer);
      }
    };
  });

  const getRowContent = useCallback(
    (item: FloorViewBatch, index: number, props: any) => {
      return (
        <ScheduleBatchItem
          index={index}
          key={`${item.batch.ziftId}`}
          batch={item.batch}
          floorViewStatus={item.floorViewStatus}
          onBatchClick={() => setBatchId(item.batch.id)}
          eventTarget={eventTarget.current}
          scrollToKey={scrollToKey}
          {...props}
        />
      );
    },
    [scrollToKey, setBatchId]
  );

  const idleTimeStr = useMemo(() => {
    if (!data?.settings?.batches?.snoozeTime) {
      return "--";
    }

    // NOTE: snoozeTime is in minutes
    const hours = Math.floor(data?.settings.batches.snoozeTime / 60);
    const mins = data?.settings.batches.snoozeTime % 60;

    let result = "";
    if (hours > 0) {
      result = `${hours} hr`;
      if (mins > 0) {
        result += ` ${mins} min`;
      }
    } else {
      result = `${mins} min`;
    }

    return result;
  }, [data?.settings?.batches?.snoozeTime]);

  return (
    <div id="schedule-floor">
      <ScheduleViewPageHeader
        batches={floorViewBatches}
        date={date}
        setDate={setDate}
        facility={facility}
        setFacility={setFacility}
        includeStore={(storeId: any) => allStoreIds.includes(storeId)}
        key="ScheduleViewPageHeader"
        loading={loading}
        onSearch={onSearch}
        schedule={data?.floorview.schedule}
        searchStatus={searchStatus}
        setStatusFilter={setStatusFilter}
        setStores={setStores}
        stats={stats}
        statusFilter={enumStatuses}
        stores={stores}
        updatedAt={updatedAt}
      />
      {error ? (
        <div className="schedule-floor-alert">
          <Alert key="alert" type="error" closable message={error.name} description={error.message} />
        </div>
      ) : (
        ""
      )}
      <div className="schedule-floor-batches" aria-label="grid" aria-readonly="true" ref={listContainerRef}>
        <Spin spinning={loading && !batches?.length} size="large">
          {(isNil(date) && (
            <div className="schedule-floor-alert">
              <Alert
                type="error"
                showIcon
                message="No date selected"
                description=" " // force larger layout
              />
            </div>
          )) ||
            (isNil(facility) && (
              <div className="schedule-floor-alert">
                <Alert
                  type="error"
                  showIcon
                  message="No facility selected"
                  description=" " // force larger layout
                />
              </div>
            )) ||
            (lastQueryIdOfPrevSchedule === lastQueryId && (
              <div className="schedule-floor-alert">{LoadingMessage.random()}</div>
            )) ||
            (filteredBatches.length && (
              <List<FloorViewBatch>
                ref={listRef}
                height={listContainerSize.height}
                itemHeight={minimumRowHeight}
                data={filteredBatches}
                itemKey="key"
                style={{ overflowY: "auto" }}
              >
                {getRowContent}
              </List>
            )) ||
            (batches?.length && (
              <div className="schedule-floor-alert">
                <Alert
                  type="error"
                  showIcon
                  message="No batches found for the selected statuses"
                  description=" " // force larger layout
                />
              </div>
            )) ||
            (schedule?.id && (
              <div className="schedule-floor-alert">
                <Alert
                  type="error"
                  showIcon
                  message={`No batches found for schedule ${schedule?.id}`}
                  description=" " // force larger layout
                />
              </div>
            )) ||
            (!loading && (
              <div className="schedule-floor-alert">
                <Alert
                  type="error"
                  showIcon
                  message={`No schedule exists for ${date.toLocaleDateString()}.`}
                  description=" " // force larger layout
                />
                <Link
                  to={`/batches?scheduleForFacilityId="${facility}"&scheduleForDate=${
                    DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")
                  }`}
                >
                  Click here to create a schedule.
                </Link>
              </div>
            ))}
        </Spin>
      </div>
      <Drawer
        key="drawer"
        open={!!batchId}
        width="60vw"
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
        }}
        onClose={() => setBatchId(undefined)}
      >
        <ErrorBoundary fallback={ErrorBoundaryFallback}>
          {batchId ? <BatchDetail id={batchId} isFloorView /> : null}
        </ErrorBoundary>
      </Drawer>
      <div id="schedule-floor-flag-legend" className="schedule-floor-flags">
        {[
          {
            color: "#20c997",
            icon: <DoneRounded />,
            keySuffix: "complete",
            label: "Complete",
            title: "Batch Complete",
          },
          {
            color: "#dc3545",
            icon: <WarningOutlinedRounded />,
            keySuffix: "stale",
            label: "Last scan +1 hour",
            title: "Last scan over 1 hour ago",
          },
          {
            color: "#6f42c1",
            icon: <PauseRounded />,
            keySuffix: "idle",
            label: `Last scan over ${idleTimeStr} ago`,
            title: "Idle",
          },
          {
            color: "#724d29",
            icon: <DomainVerificationOutlinedRounded />,
            keySuffix: "only-complete",
            label: "Only complete orders",
            title: "Only complete orders in batch",
          },
          {
            color: "#0d6efd",
            icon: <CounterOneOutlinedRounded />,
            keySuffix: "has-single-unit",
            label: "Has single unit orders",
            title: "Batch includes single unit orders",
          },
          {
            color: "#ffffff",
            icon: <CallSplitOutlinedRounded />,
            keySuffix: "has-split-orders",
            label: "Has split orders",
            title: "Batch includes split orders",
          },
          {
            color: "#fd7e14",
            icon: <PointScanRounded />,
            keySuffix: "neckhit-elegible",
            label: "Neckhit Eligible",
            title: "Batch includes neckhit eligible",
          },
          {
            color: "#ffff66",
            icon: <ShelvesOutlinedRounded />,
            keySuffix: "has-shelf-stock",
            label: "Has shelf stock",
            title: "Batch includes shelf stock items",
          },
        ].map((status) => (
          <Tooltip
            title={status.title}
            className="schedule-floor-flag"
            placement="top"
            mouseLeaveDelay={0}
            key={`schedule-floor-legend-status-${status.keySuffix}`}
          >
            <Tag className="schedule-floor-flag-icon" color={status.color || "#D9D9D9"}>
              {status.icon}
            </Tag>
            <span className="schedule-floor-flag-label">{status.label}</span>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
