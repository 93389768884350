import CoreApi from '../CoreApi';
import { snakeCaseKeys, transformResponse, transformErrorResponse } from '../utils';
import { GetQuotas, GetQuotasVariables, deserializeGetQuotas } from './GetQuotas';

// Extend the CoreApiw with /quotas endpoints
export const QuotasApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuotas: builder.query<GetQuotas, GetQuotasVariables>({
      // This serialize the query args for the purpose of generating a cache key
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          ...queryArgs,
          date: queryArgs.date?.toISOString().split('T')[0]
        };
      },
      query: (params) => ({
        url: '/quotas',
        method: "GET",
        params: {
          ...snakeCaseKeys(params),
          date: params.date?.toISOString().split("T")[0], // YYYY-MM-DD
        }
      }),
      transformResponse: transformResponse(deserializeGetQuotas),
      transformErrorResponse: transformErrorResponse("getQuotas"),
    }),
  }),
});

export default QuotasApi;
