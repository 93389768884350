import React, { useMemo } from "react";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { uniq } from "lodash";
import { DateTimeCellRenderer } from "../../../components/Table/renderers";
import { DatePicker, Sider } from "../../../components";
import { Form, Layout, Select } from "antd";
import {
  DateTimeParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "../../../api/useQueryParams";
import { MetricsApi } from "../../../api/core";
import { DateTime } from "luxon";

const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Option } = Select;

export const InsightsExplorePage = React.memo(() => {
  const [filter, setFilter] = useQueryParams({
    from: withDefault(DateTimeParam, DateTime.now().startOf('month').toJSDate()),
    to: withDefault(DateTimeParam, DateTime.now().endOf('month').toJSDate()),
    resolution: withDefault(StringParam, "day"),
  });

  const { data } = MetricsApi.useGetMetricsQuery({
    from: DateTime.fromJSDate(filter.from!).startOf('day').toJSDate(),
    to: DateTime.fromJSDate(filter?.to!).endOf('day').toJSDate()
  }, {
    refetchOnMountOrArgChange: true
  });

  const handleDateChange = (dates: any, dateStrings: String[]) => {
    setFilter((value: any) => ({
      ...value,
      from: dates[0],
      to: dates[1],
    }));
  };

  const rowData = useMemo(
    () =>
      data?.map(({ time, value, metadata }: any) => {
        const item = {
          time,
          value,
        } as { [key: string]: any };

        metadata?.keyValues.forEach((keyValue: any) => {
          item[keyValue.key] = keyValue.value;
        });

        return item;
      }),
    [data]
  );

  const defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
  };

  const columnDefs = useMemo(
    () =>
      uniq((rowData || []).map((row: any) => Object.keys(row)).flat()).map(
        (field: any) => {
          switch (field) {
            case "time":
              return {
                field,
                chartDataType: "time",
                cellRenderer: "DateTimeCellRenderer",
                cellRendererParams: {
                  showTime: filter?.resolution === "hour",
                },
              };
            case "value":
              return {
                field,
                chartDataType: "series",
              };
            default:
              return { field, chartDataType: "category" };
          }
        }
      ) as ColDef[],
    [filter.resolution, rowData]
  );

  const filterComponents = {
    resolution: {
      label: "Resolution",
      render: (
        <Select
          value={filter?.resolution!.toString()}
          onChange={(resolution) =>
            setFilter((filterValue) => ({ ...filterValue, resolution }))
          }
        >
          <Option value="hour">Hour</Option>
          <Option value="day">Day</Option>
          <Option value="week">Week</Option>
          <Option value="month">Month</Option>
          <Option value="year">Year</Option>
        </Select>
      ),
    },
    dateRange: {
      label: "Date Range",
      render: (
        <RangePicker
          placeholder={["From", "To"]}
          allowClear
          allowEmpty={[true, true]}
          value={[filter?.from, filter?.to] as [any, any]}
          ranges={{
            Today: [DateTime.now().startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()],
            "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
            "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
            "This Year": [DateTime.now().startOf('year').toJSDate(), DateTime.now().endOf('year').toJSDate()],
          }}
          onChange={handleDateChange}
        />
      ),
    },
  } as { [key: string]: any };

  const filterItems = Object.keys(filterComponents).map((name) => {
    const { render, label } = filterComponents[name];
    return (
      <Form.Item key={label} label={label} style={{ marginBottom: 10 }}>
        {render}
      </Form.Item>
    );
  });

  return (
    <>
      <Layout>
        <Sider>
          <Form
            layout="vertical"
            style={{
              padding: 12,
            }}
          >
            {filterItems}
          </Form>
        </Sider>

        <Content>
          <AgGridReact
            className="ag-theme-alpine"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rowData}
            enableCharts
            enableRangeSelection
            components={{ DateTimeCellRenderer }}
            sideBar
            rowGroupPanelShow="always"
          />
        </Content>
      </Layout>
    </>
  );
});
