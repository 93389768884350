import React, { useEffect, useState } from "react";
import { SortableList } from "../../components";
import { Button, PageHeader } from "antd";
import { AgSortOrder, StoresApi } from "../../api/core";

interface Item {
  key: string;
  value: string;
}

export const StorePriorityListSettings = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);

  const { data: stores, isFetching: loading } = StoresApi.useGetStoresQuery({
    sort: [{ colId: 'priority', sort: AgSortOrder.DESC }],
    limit: 0
  });

  useEffect(() => {
    if (stores) {
      const items = stores.rows.map<Item>(store => ({
        key: store.id,
        value: store.name!
      }));
      setItems(items);
    }
  }, [stores]);

  const [updateStores, { isLoading: isUpdating, isSuccess: isUpdateSuccess }] = 
    StoresApi.useUpdateStoresMutation();
  
  useEffect(() => {
    if (!isUpdating && isUpdateSuccess) {
      setDirty(false);
    }
  }, [isUpdating, isUpdateSuccess]);

  const saveChanges = () => {
    const stores = items.map((item, index) => ({
      id: item.key,
      priority: items.length - (index + 1),
    }));
    updateStores({ stores });
  };

  const onChange = (newItems: any) => {
    setItems(newItems);
    setDirty(true);
  };

  const buttons = [
    <Button disabled={!dirty} onClick={saveChanges} loading={isUpdating}>
      Save Changes
    </Button>,
  ];

  return (
    <>
      <PageHeader title="Store Priority" extra={buttons} />
      <SortableList loading={loading} onChange={onChange} items={items} />
    </>
  );
};
