import { isArray, isFunction } from "lodash";
import depthFirstTraversal, { VisitContext, VisitFunction } from "./depthFirstTraversal";

export type DeserializationFunc<TDest = any> = (value: any) => TDest;

export interface DeserializationSchema {
  [key: string]: DeserializationSchema | DeserializationFunc;
}

export interface DeserializationContext {
  results?: any; // the deserialized results
  deserializationMap: Map<any, DeserializationSchema | DeserializationFunc>; // links a source node to its corresponding node in the deserialization map
  skipChildren: Map<any, boolean>; // if set to true for a given node, the children won't be visited
  resultMap: Map<any, any>; // maps the source node to the dest node
}

const deserializeNode:VisitFunction<DeserializationContext> = (current, context) => {
  const srcParent = context.visitStack.at(-2); // this will be undefined if it is the root
  const parentDMap = context.custom.deserializationMap.get(srcParent);
  const destParent = context.custom.resultMap.get(srcParent);
  const deserializeOrMap = !isFunction(parentDMap) && parentDMap 
    ? parentDMap?.[context.path.at(-1) || ''] : undefined;

  let dest;
  if (isFunction(deserializeOrMap)) {
    dest = deserializeOrMap(current);
    context.custom.skipChildren.set(current, true);
  } else {
    if (Object(current) !== current) {
      dest = current; // this is a primitive - just copy as-is
    } else if (isArray(current)) {
      dest = []; // the children will be copied when they are visited
    } else {
      dest = {}; // the children will be copied when they are visited
    }

    if (deserializeOrMap) {
      if (destParent === undefined) {
        context.custom.deserializationMap.set(current, deserializeOrMap);
      } else {
        context.custom.deserializationMap.set(current, deserializeOrMap[context.path.at(-1)!]);
      }
    }
  }

  if (destParent === undefined) {
    context.custom.results = dest; // this is the root
  } else {
    destParent[context.path.at(-1)!] = dest;
  }

  if (Object(dest) === dest) {
    context.custom.resultMap.set(current, dest);
  }
};

function skipChildren(parent: Readonly<any>, context: Readonly<VisitContext<DeserializationContext>>) {
  return !!context.custom.skipChildren.get(parent);
}

export function deserialize<TDest = any, TSource = any>(schema: Readonly<DeserializationSchema>, rootName?: string) {
  return ((source: TSource) => {
    const data: DeserializationContext = {
      deserializationMap: new Map<any, DeserializationSchema>([[source, schema]]),
      skipChildren: new Map<any, boolean>(),
      resultMap: new Map<any, any>()
    };

    depthFirstTraversal(source, deserializeNode, data, { rootName, order: "pre", skipChildren });

    return data.results as TDest;
  }) as DeserializationFunc<TDest>;
}

export function deserializeArrayOf<TDest = any, TSource = any>(schema: Readonly<DeserializationSchema>, rootName?: string) {
  const d = deserialize(schema, rootName);
  return ((source: TSource[] | null | undefined) => {
    return source?.map(_ => d(_) as TDest);
  }) as DeserializationFunc<TDest[] | null | undefined>;
}

export function deserializeAs<TDest = any>(result: TDest) {
  return (value: any) => result;
}

export default deserialize;
