import React, { useState } from "react";
import { Layout } from "antd";
import { OrderFilter, OrderDashboard } from "../../../components";
import { DateTime } from "luxon";
import { GetOrderStatsVariables } from "../../../api/core";

type OrderDashboardFilter = GetOrderStatsVariables & { from: Date, to: Date };
export const OrderDashboardPage = () => {
  const [filter, setFilter] = useState<OrderDashboardFilter>({
    from: DateTime.local()
      .startOf('day')
      .toUTC()
      .toJSDate(),
      
    to: DateTime.local()
      .plus({ days: 1 })
      .startOf('day')
      .toUTC()
      .toJSDate()
  });

  return (
    <Layout>
      <div>
        <OrderFilter filter={filter} setFilter={setFilter} only={["dateRange"]} />
      </div>
      <div>
        <OrderDashboard filter={filter} />
      </div>
    </Layout>
  );
};
