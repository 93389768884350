import { DeserializationFunc } from "../utils";
import { BlankStats, deserializeBlankStatsArray } from "./common";

export interface GetBlankStatsVariables {
  lineItemFrom?: Date | null;
  lineItemTo?: Date | null;
  facilityId?: string | string[] | null;
  timezone?: string | null;
}

export type GetBlankStats = BlankStats[];
export const deserializeGetBlankStats = deserializeBlankStatsArray as DeserializationFunc<GetBlankStats>;
