import React, { useMemo } from "react";
import { GridOptions, ColDef } from "ag-grid-enterprise";
import { FirstDataRenderedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Checkbox, Form, Select, Spin, Tooltip } from "antd";
import styled from "styled-components";
import { ArrayParam, BooleanParam, StringParam, useQueryParam, withDefault } from "../../../api/useQueryParams";
import { LocationFacilitySelect } from "../../Facility";
import { LineItemStatus, LineItemsApi, OrderStatus, StoresApi } from "../../../api/core";
import { GetLineItemCountsFilterInput } from "../../../api/core/LineItems/GetLineItemCounts";
import { DateTime } from "luxon";

interface ProgressAggregate {
  value: number,
  name: string
}

export const ProgressDemandReportTable = () => {
  const today = DateTime.now().startOf('day').toJSDate();

  const options = [{
    label: <Tooltip title="Defective line items." placement="right">Defective</Tooltip>,
    value: 'defective'
  }, {
    label: <Tooltip title="Out of stock line items." placement="right">OOS</Tooltip>,
    value: 'out_of_stock'
  }, {
    label: <Tooltip title="Pending line items." placement="right">Pending</Tooltip>,
    value: 'pending'
  }, {
    label: <Tooltip title="Active line items." placement="right">Active</Tooltip>,
    value: 'active'
  }, {
    label: <Tooltip title="Line items printed." placement="right">Printed</Tooltip>,
    value: 'printed'
  }, {
    label: <Tooltip title="Line items shipped." placement="right">Shipped</Tooltip>,
    value: 'shipped'
  }];

  const [includeEarly, setIncludeEarly] = useQueryParam("includeEarly", withDefault(BooleanParam, true));
  const [includeLate, setIncludeLate] = useQueryParam("includeLate", withDefault(BooleanParam, true));
  const [mode, setMode] = useQueryParam("mode", withDefault(StringParam, "active"));
  const [facilityId, setFacilityId] = useQueryParam("facilityId", ArrayParam);
  
  const cleanCompletedBefore = useMemo(() => {
    if (mode === 'shipped') {
      return DateTime.fromJSDate(today).plus({ days: 1 }).toJSDate();
    }
    return undefined;
  }, [mode, today]);

  const cleanCompletedAfter = useMemo(() => {
    if (mode === 'shipped') {
      return today;
    }
    return undefined;
  }, [mode, today]);

  const cleanPrintedBefore = useMemo(() => {
    if (mode === 'printed') {
      return DateTime.fromJSDate(today).plus({ days: 1 }).toJSDate();
    }
    return undefined;
  }, [mode, today]);

  const cleanPrintedAfter = useMemo(() => {
    if (mode === 'printed') {
      return today;
    }
    return undefined;
  }, [mode, today]);

  const cleanShipBefore = useMemo(() => {
    if (!includeEarly) {
      return DateTime.fromJSDate(today).plus({ days: 1 }).toJSDate();
    }
    return undefined;
  }, [today, includeEarly]);

  const cleanShipAfter = useMemo(() => {
    if (!includeLate) {
      return today;
    }
    return undefined;
  }, [today, includeLate]);

  const cleanOrderStatus = useMemo(() => {
    if (mode === 'pending') {
      return [OrderStatus.Pending];
    }
    if (mode === 'printed') {
      return [OrderStatus.Active, OrderStatus.Complete];
    }
    if (mode === 'shipped') {
      return [OrderStatus.Complete];
    }
    return [OrderStatus.Active];
  }, [mode]);

  const cleanLineItemStatus = useMemo(() => {
    if (mode === 'active' || mode === 'pending') {
      return [LineItemStatus.Branded, LineItemStatus.Branding, LineItemStatus.New, LineItemStatus.Packaged,
        LineItemStatus.Packaging, LineItemStatus.Printed, LineItemStatus.Printing, LineItemStatus.Pulled,
        LineItemStatus.Shipped, LineItemStatus.Shipping];
    }
    if (mode === 'defective') {
      return [LineItemStatus.Defective];
    }
    if (mode === 'outOfStock') {
      return [LineItemStatus.OutOfStock];
    }
    return undefined;
  }, [mode]);

  const cleanFacilityId = useMemo(() => {
    const validFacilityId = facilityId?.filter((element): element is string => element !== null);
    return validFacilityId?.length ? validFacilityId : undefined;
  }, [facilityId]);

  const filter = useMemo<GetLineItemCountsFilterInput>(() => ({
    completedAfter: cleanCompletedAfter,
    completedBefore: cleanCompletedBefore,
    printedAfter: cleanPrintedAfter,
    printedBefore: cleanPrintedBefore,
    shipBefore: cleanShipBefore,
    shipAfter: cleanShipAfter,
    facilityId: cleanFacilityId,
    orderStatus: cleanOrderStatus,
    status: cleanLineItemStatus
  }), [
    cleanCompletedAfter, cleanCompletedBefore, cleanPrintedAfter, cleanPrintedBefore,
    cleanShipBefore, cleanShipAfter, cleanFacilityId, cleanOrderStatus, cleanLineItemStatus
  ]);

  const { data: stores, isFetching: isFetchingStores } = StoresApi.useGetStoresQuery({});

  const { data: counts, isFetching: isFetchingCounts } = LineItemsApi.useGetLineItemCountsQuery({ 
    filter, groupBy: "store_id" 
  }, {
    refetchOnMountOrArgChange: true
  });

  const numberValueFormatter = ({ value }: any) => {
    const numberValue = (value || 0);
    if (numberValue === 0) {
      return "-";
    } 
    return numberValue.toLocaleString();
  };

  const columnDefs: ColDef<ProgressAggregate>[] = useMemo(() => {
    return [{
      field: "name",
      headerName: "Store",
      sort: "asc",
      width: 200,
      cellStyle: (_params: any): any => ({
        textAlign: 'left', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold'
      })
    }, {
      field: "value",
      headerName: "Count",
      valueFormatter: numberValueFormatter,
      width: 100,
      cellStyle: (_params: any): any => ({
        textAlign: 'center', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold'
      })
    }];
  }, []);

  const rowData = useMemo(() => {
    const dataMap = counts?.reduce((accumulator, line) => {
      const storeId = line.data.key === 'store_id' ? line.data.value : undefined;
      if (storeId) {
        accumulator.set(storeId, (accumulator.get(storeId) ?? 0) + line.prints);
      }
      return accumulator;
    }, new Map<string, number>());

    const storeMap = counts?.reduce((accumulator, line) => {
      const storeId = line.data.key === 'store_id' ? line.data.value : undefined;
      const store = stores?.rows.find(s => s.id === storeId);
      if (store?.name && storeId && !accumulator.has(storeId)) {
        accumulator.set(storeId, store.name);
      }
      return accumulator;
    }, new Map<string, string>());

    return Array.from(dataMap?.keys() ?? []).map(key => {
      const row: ProgressAggregate = {
        value: dataMap?.get(key) ?? 0,
        name: storeMap?.get(key) ?? 'Unknown'
      };
      return row;
    }) ?? [];
  }, [counts, stores?.rows]);

  const pinnedBottomRowData = useMemo(() => {
    const total = rowData.reduce((accumulator, line) => {
      return accumulator + line.value;
    }, 0);

    return [{
      name: 'Total',
      value: total
    }];
  }, [rowData]);

  const gridOptions: GridOptions<ProgressAggregate> = useMemo(() => ({
    rowData,
    columnDefs,
    pinnedBottomRowData,
    // replace with actual styles later
    getRowStyle(params) {
      if ((params?.node?.rowPinned)) {
        if (params?.node?.rowIndex === 0) {
          return { background: '#71AF47' };
        }
        return { background: '#97C876' };
      }
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      } 
      return { background: '#E2EFDA' }; 
    },
    animateRows: true,
    enableCellChangeFlash: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.columnApi.autoSizeAllColumns();
    },
  }), [rowData, columnDefs, pinnedBottomRowData]);

  const FilterWrapper = styled.div`
    flex: 0 1 auto;
  `;

  const GridWrapper = styled.div`
    flex: 1 1 auto; 
  `;

  const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
  `;

  return (
    <>
      <Wrapper>
        <FilterWrapper>
          <Form 
            size="small"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            layout="inline"
          >
            <Form.Item label="Facilities" style={{ minWidth: 240 }}>
              <LocationFacilitySelect 
                value={cleanFacilityId}
                onChange={(facilityId: string[]) => {
                  setFacilityId(facilityId);
                }}
              />
            </Form.Item>
            <Form.Item label="Status" style={{ minWidth: 240 }}>
              <Select
                options={options}
                onChange={(mode) => {
                  setMode(mode);
                }}
                value={mode}
              />
            </Form.Item>
            <Form.Item label="Include Late" style={{ minWidth: 240 }}>
              <Tooltip title="Include line items due to ship before today." placement="bottom">
                <Checkbox
                  onChange={(includeLate) => {
                    setIncludeLate(includeLate.target.checked);
                  }}
                  checked={includeLate}
                />
              </Tooltip>
            </Form.Item>
            <Form.Item label="Include Early" style={{ minWidth: 240 }}>
              <Tooltip title="Include line items due to ship after today." placement="bottom">
                <Checkbox
                  onChange={(includeEarly) => {
                    setIncludeEarly(includeEarly.target.checked);
                  }}
                  checked={includeEarly}
                />
              </Tooltip>
            </Form.Item>
          </Form>
        </FilterWrapper>
        <GridWrapper>
          <Spin spinning={isFetchingCounts || isFetchingStores}>
            <AgGridReact
              className="ag-theme-madengine"
              gridOptions={gridOptions}
            />
          </Spin>
        </GridWrapper>
      </Wrapper>
    </>
  );
};
