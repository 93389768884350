import { ColDef, ColGroupDef } from "ag-grid-community";
import { RowNumberCellRenderer } from "../../DataGrid/renderers";

export const defaultColumnDefs: (ColDef | ColGroupDef)[] = [
  {
    headerName: "#",
    sortable: false,
    filter: false,
    floatingFilter: false,
    cellRenderer: RowNumberCellRenderer,
    width: 75,
    pinned: 'left'
  },
  {
    field: 'id',
    hide: true,
    filter: 'text'
  },
  {
    field: 'ziftId',
    filter: 'text'
  }
];

export default defaultColumnDefs;
