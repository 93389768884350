import ModalResults from "./ModalResults";
import ModalState from "./ModalState";

export type ModalEventType = 'showing' | 'shown' | 'hiding' | 'hidden' | 'update';

export interface ModalEventTarget {
  /**
   * Allows a step to listen for, respond to, or even cancel certain events
   */
  addEventListener: (type: ModalEventType, 
    listener: (e?:any) => void, 
    options?: EventListenerOptions) => void;

  /**
   * Removes a previously added event listener
   */
  removeEventListener: (type: ModalEventType, 
    listener: (e?:any) => void, 
    options?: EventListenerOptions) => void;
}

const _showingEventType: ModalEventType = 'showing';
export type ShowingEventArgs<TModalResults extends ModalResults> = Readonly<ModalState<TModalResults>>;
export function createShowingEvent<TModalResults extends ModalResults>(state: Readonly<ModalState<TModalResults>>) {
  return new CustomEvent<ShowingEventArgs<TModalResults>>(_showingEventType, { detail: state });
}

const _shownEventType: ModalEventType = 'shown';
export type ShownEventArgs<TModalResults extends ModalResults> = Readonly<ModalState<TModalResults>>;
export function createShownEvent<TModalResults extends ModalResults>(state: Readonly<ModalState<TModalResults>>) {
  return new CustomEvent<ShownEventArgs<TModalResults>>(_shownEventType, { detail: state });
}

const _hidingEventType: ModalEventType = 'hiding';
export type HidingEventArgs<TModalResults extends ModalResults> = Readonly<ModalState<TModalResults>>;
export function createHidingEvent<TModalResults extends ModalResults>(state: Readonly<ModalState<TModalResults>>) {
  return new CustomEvent<HidingEventArgs<TModalResults>>(_hidingEventType, { detail: state });
}

const _hiddenEventType: ModalEventType = 'hidden';
export type HiddenEventArgs<TModalResults extends ModalResults> = Readonly<ModalState<TModalResults>>;
export function createHiddenEvent<TModalResults extends ModalResults>(state: Readonly<ModalState<TModalResults>>) {
  return new CustomEvent<HiddenEventArgs<TModalResults>>(_hiddenEventType, { detail: state });
}

const _updateEventType: ModalEventType = 'update';
export type UpdateEventArgs<TModalResults extends ModalResults> = Readonly<ModalState<TModalResults>>;
export function createUpdateEvent<TModalResults extends ModalResults>(state: Readonly<ModalState<TModalResults>>) {
  return new CustomEvent<UpdateEventArgs<TModalResults>>(_updateEventType, { detail: state });
}
