import React from "react";
import { Routes, Route } from "react-router";
import { ScheduleManagePage } from "./ManagePage";
import { SchedulePage } from "./Page";
import { ScheduleViewPage } from "./ViewPage";

export function Scheduler() {
  return (
    <Routes>
      <Route
        path="/details/:date/:facilityId"
        element={<SchedulePage />}
      />
      <Route
        path="/details/:date"
        element={<SchedulePage />}
      />
      <Route
        path="/view/:date/:facilityId"
        element={<ScheduleViewPage />}
      />
      <Route
        path="/view/:date"
        element={<ScheduleViewPage />}
      />
      <Route
        path="/"
        element={<ScheduleManagePage />}
      />
    </Routes>
  );
}

export default Scheduler;
