import { DateTime, Duration, DurationObjectUnits } from "luxon";

// Luxon's default <Duration>.toHuman() implementation is a bit wanting atm
// See: https://github.com/moment/luxon/issues/1134

// Solution Credit: https://github.com/moment/luxon/issues/1134#issuecomment-1668033880
export const toAbsHumanDuration = (start: DateTime, end: DateTime): string => {
  // Better Duration.toHuman support https://github.com/moment/luxon/issues/1134
  const duration = end.diff(start).shiftTo('days', 'hours', 'minutes', 'seconds').toObject();
  const prefix = start > end ? 'in ' : '';
  const suffix = end > start ? ' ago' : '';

  if ('seconds' in duration) {
    duration.seconds = Math.round(duration.seconds!);
  }

  const cleanedDuration = Object.fromEntries(
    Object.entries(duration)
      .filter(([_key, value]) => value !== 0)
      .map(([key, value]) => [key, Math.abs(value)])
  ) as DurationObjectUnits;

  if (Object.keys(cleanedDuration).length === 0) {
    cleanedDuration.seconds = 0;
  }

  const human = Duration.fromObject(cleanedDuration).toHuman();
  return `${prefix}${human}${suffix}`;
};
