import React, { useMemo } from "react";
import { ColDef, GridOptions } from "ag-grid-enterprise";
import { FirstDataRenderedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import { Facility, FacilitiesApi } from "../../api/core";

export const FacilitiesSettings = () => {

  const { data, refetch } = FacilitiesApi.useGetFacilitiesQuery({});

  const [updateFacilities] = FacilitiesApi.useUpdateFacilityCapacityMutation();

  const rowData = useMemo(() => {
    return data?.rows ?? [];
  }, [data]);

  const defaultColDef: ColDef<Facility> = useMemo(() => ({
    sortable: true,
    width: 200
  }), []);

  const columnDefs: ColDef<Facility>[] = useMemo(() => {
    return [{
      field: "name",
      headerName: "Name",
      sort: 'asc',
      width: 400
    }, 
    {
      field: "code",
    },
    {
      field: "capacity",
      headerName: "Capacity",
      editable: true,
      valueSetter: (params) => {
        const newValue = parseInt(params.newValue, 10);
        if (isNaN(newValue) || newValue < 0) { return false; }

        // initiate an asynchronous update 
        // given the current setup - the easiest way to keep the grid in sync is to just refetch
        updateFacilities({
          facilities: [
            {
              id: params.data.id,
              capacity: newValue
            },
          ],
        }).unwrap().then(_ => {
          refetch();
        }).catch(_ => {
          refetch();
        });

        // assume that the above will succeed and update the row accordingly
        params.data.capacity = newValue;
        return true;
      }
    }];
  }, [updateFacilities, refetch]);

  const GridWrapper = styled.div`
    height: 100%;
    min-height: 600px;
  `;

  const gridOptions: GridOptions<Facility> = useMemo(() => ({
    rowData,
    columnDefs,
    defaultColDef,
    animateRows: true,
    enableCellChangeFlash: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.columnApi.autoSizeAllColumns();
    },
  }), [rowData, columnDefs, defaultColDef]);

  return (
    <>
      <GridWrapper className="ag-theme-madengine">
        <AgGridReact 
          className="ag-theme-madengine"
          gridOptions={gridOptions}
        />
      </GridWrapper>
    </>
  );
};
