import { camelCase } from "lodash";
import deserialize, { DeserializationSchema, deserializeArrayOf } from "../utils/deserialize";

export enum FloorviewSortDirection {
  Ascending = 'asc',
  Descending = 'desc'
}

export interface FloorviewSort {
  id: string;
  schedule_id: string;
  order: number;
  field: string;
  direction: FloorviewSortDirection;
}

export const FloorviewSortDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  field: (snakeCaseFieldName: string) => {
    return camelCase(snakeCaseFieldName);
  },
});

export const deserializeFloorviewSort = 
  deserialize<FloorviewSort>(FloorviewSortDeserializationSchema);

export const deserializeFloorviewSortsArray =
  deserializeArrayOf<FloorviewSort>(FloorviewSortDeserializationSchema);
