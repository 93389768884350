import CoreApi from '../CoreApi';
import { prepareFilter, prepareSort, transformResponse, transformErrorResponse, snakeCaseKeys } from '../utils';
import { GetScans, GetScansVariables, deserializeGetScans } from './GetScans';

// Extends the CoreApi with /scans endpoints
export const ScansApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getScans: builder.query<GetScans, GetScansVariables>({
      query: (params) => ({
        url: '/scans',
        method: "POST",
        body: {
          ...snakeCaseKeys(params),
          filter: params?.filter && prepareFilter(params.filter, undefined),
          sort: params?.sort && prepareSort(params.sort, undefined),
        }
      }),
      transformResponse: transformResponse(deserializeGetScans),
      transformErrorResponse: transformErrorResponse("getScans"),
    }),
  }),
});

export default ScansApi;
