import { Store, deserializeStore } from "../Stores";
import deserializeDate from "../utils/deserializeDate";
import { DeserializationSchema, deserialize, deserializeArrayOf } from "../utils/deserialize";
import { Blank, deserializeBlank } from "../Blanks";
import { Order, deserializeOrder } from "../Orders";
import { Printer, deserializePrinter } from "../Printers";
import { Facility, deserializeFacility } from "../Facilities";
import { Bin, deserializeBin } from "../Bins";
import { Batch, deserializeBatch } from "../Batches/Batch";
import { BinSection } from "../BinSections";

export enum Waypoint {
  None = "none",
  New = "new",
  Batch = "batch",
  Pull = "pull",
  PrintFront = "print_front",
  DryFront = "dry_front",
  PrintBack = "print_back",
  DryBack = "dry_back",
  Package = "package",
  Postage = "postage",
  ShipConfirm = "ship_confirm",
  Bin = "bin",
  OutOfStock = "oos",
  Branding = "branding",
  QualityControlIssue = "qc_issue",
  SetBox = "set_box",
  Receive = "receive",
  Reprocess = "reprocess",
  HoldUnit = "hold_unit",
  PrintNeckhit = "print_neckhit",
  DryNeckhit = "dry_neckhit",
  PrintRightSleeve = "print_rsleeve",
  DryRightSleeve = "dry_rsleeve",
  PrintLeftSleeve = "print_lsleeve",
  DryLeftSleeve = "dry_lsleeve",
  PrintWrapAround = "print_wraparound",
  DryWrapAround = "dry_wraparound",
  PrintHighBack = "print_highback",
  DryHighBack = "dry_highback",
  PrintLeftLeg = "print_leftleg",
  DryLeftLeg = "dry_leftleg",
  PrintRightLeg = "print_rightleg",
  DryRightLeg = "dry_rightleg"
}

export interface LineItem {
  id: string;
  ziftId?: string | null;
  zift_status?: ZiftStatus | null;
  status?: LineItemStatus | null;
  progress?: number | null;
  lastScanAt?: Date | null;
  createdAt: Date;
  updatedAt: Date;
  auditedAt?: Date | null;
  printedAt?: Date | null;
  printerId?: string | null;
  printerModel?: string | null;
  printsCount?: number | null;
  printsScore?: number | null;
  waypoint?: Waypoint | null;
  meta?: Object | null; // TO DO: test this out. It's a jsonb column type in the database.
  compoundPriority?: number | null;
  isNeckhitEligible?: boolean | null;
  blankOnly?: boolean | null;
  box?: string | null;
  erpId?: string | null;
  source?: string | null;
  orderId?: string | null;
  blankId?: string | null;
  storeId?: string | null;
  binId?: string | null;

  bin?: Bin | null;
  blank?: Blank | null;
  facility?: Facility | null; // there is one facility through its order
  order?: Order | null;
  printer?: Printer | null;
  store?: Store | null;
  batch?: Batch | null;
  binSection?: BinSection | null;
  binFacility?: Facility | null;
}

export const lineItemDeserializationSchema: DeserializationSchema = Object.freeze({
  lastScanAt: deserializeDate,
  createdAt: deserializeDate,
  updatedAt: deserializeDate,
  auditedAt: deserializeDate,
  printedAt: deserializeDate,

  // TO DO: meta

  bin: deserializeBin,
  blank: deserializeBlank,
  facility: deserializeFacility,
  order: deserializeOrder,
  printer: deserializePrinter,
  store: deserializeStore,
  batch: deserializeBatch
});

export const deserializeLineItem = deserialize<LineItem>(lineItemDeserializationSchema);
export const deserializeLineItemsArray = deserializeArrayOf<LineItem>(lineItemDeserializationSchema);

export enum LineItemStatus {
  Branded = "branded",
  Branding = "branding",
  Canceled = "canceled",
  Defective = "defective",
  Deleted = "deleted",
  New = "new",
  OutOfStock = "out_of_stock",
  Packaged = "packaged",
  Packaging = "packaging",
  Printed = "printed",
  Printing = "printing",
  Pulled = "pulled",
  Shipped = "shipped",
  Shipping = "shipping",
}

export enum ZiftStatus {
  New = "new",
  Invalid = "invalid",
  Pending = "pending",
  Active = "active",
  Cancelled = "cancelled",
  Complete = "complete"
}
