import flatten from "flat";
import { isArray, isEmpty, isNil, isUndefined, uniq } from "lodash";

// Note: Table is calling `onSort` w/ a Map now, but we're still persisting it
// as an object in query params, so the insertion order might _still_ not be
// honored.
export const sortMapToArray = (sortMap: Map<string, string>) => {
  if (isUndefined(sortMap) || isEmpty(sortMap)) return [];
  const sortArray: Array<{ [key: string]: string }> = [];
  sortMap.forEach((value, key) => {
    sortArray.push({ [key]: value });
  });
  return sortArray;
};

// Gather unique keys from all values
export const gatherKeys = (data: any, render: any = {}) => {
  const allKeys = uniq(
    data
      .map((row: any) =>
        Object.keys(row).map((key) => {
          // Don't flatten `{value, link}` or `{value, render}`
          // Also don't flatten arrays anymore if there's a renderer
          // configuration for the column.
          if (
            !isNil(row[key]) &&
            typeof row[key] === "object" &&
            (!isArray(row[key]) || !render[key]) &&
            !["Date"].includes(row[key].constructor.name)
          ) {
            if (!isUndefined(row[key].value) && row[key].link) return key;

            return Object.keys(flatten(row[key])).map((k) =>
              [key, k].flat().join(".")
            );
          }

          return key;
        })
      )
      .flat(10)
      .filter((v: any) => !isNil(v))
  );

  // Filter root objects if their keys have been flattened
  return allKeys.filter((v: any) =>
    isEmpty(allKeys.filter((vv: any) => vv.match(new RegExp(`^${v}.`))))
  );
};

export const SortOrderMap: any = {
  desc: "DESC",
  asc: "ASC",
  DESC: "desc",
  ASC: "asc",
};
