import { NoteContent } from "../Notes";
import { DeserializationSchema, deserialize } from "../utils";
import { Batch, deserializeBatchArray } from "./Batch";

export interface UpdateBatchesVariables {
  batches: UpdateBatchesVariables_BatchSettings[];
  noteOnly: boolean;
}

export interface UpdateBatchesVariables_BatchSettings {
  id: string;
  scheduleId?: string;
  scheduleFacilityId?: string;
  scheduleDate?: Date;
  schedulePinned?: boolean;
  note?: NoteContent | null;
}

export interface UpdateBatches {
  totalCount: number;
  rows: Batch[];
}

export const UpdateBatchesDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  batches: deserializeBatchArray
});

export const deserializeUpdateBatches = 
  deserialize<UpdateBatches>(UpdateBatchesDeserializationSchema);
