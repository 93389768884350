import deserializeDate from "../utils/deserializeDate";
import { DeserializationFunc, DeserializationSchema, deserialize, deserializeAs } from "../utils/deserialize";
import { Store, deserializeStore } from "../Stores";
import { Facility, deserializeFacility } from "../Facilities";
import { isArray } from "lodash";
import { QuotumUsage, deserializeQuotumUsageArray } from "../QuotumUsage";

export type QuotaType = 'ItemQuotum' | 'PrintQuotum';
export type QuotaParentType = 'Store' | 'Facility';
export type QuotaParent = Store | Facility;
export type QuotaStatus = 'created';

export interface Quotum {
  id: string;
  value: number;
  type: QuotaType;
  parentId: string;
  parentType: QuotaParentType;
  createdAt: Date;
  updatedAt: Date;
  status: QuotaStatus;

  usage?: QuotumUsage[];
  parent?: Store | Facility;
}

export const quotumDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate,
  usage: deserializeQuotumUsageArray,
  parent: deserializeAs(undefined), // the parent is polymorphic and must be handled separately
});

const __innerDeserializeQuotum = deserialize<Quotum>(quotumDeserializationSchema);

// Since there is a polymorphic element (the parent), we'll need a custom deserialization function
export const deserializeQuotum: DeserializationFunc<Quotum> = (value: any) => {
  const quotum = __innerDeserializeQuotum(value);
  if (value?.parent) {
    switch (quotum.parentType) {
      case 'Facility': 
        quotum.parent = deserializeFacility(value.parent);
        break;
      case 'Store':
        quotum.parent = deserializeStore(value.parent);
        break;
    }
  }
  return quotum;
};

export const deserializeQuotumArray: DeserializationFunc<Quotum[] | undefined> = (value: any) => {
  let result: Quotum[] | undefined;
  if (isArray(value)) {
    result = value.map(deserializeQuotum);
  }
  return result;
};
