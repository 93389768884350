import CoreApi from '../CoreApi';
import { prepareFilter, prepareSort, transformResponse, transformErrorResponse } from '../utils';
import { GetLocations, GetLocationsVariables, deserializeGetLocations } from './GetLocations';

// Extend the CoreApi with /locations endpoints
export const LocationsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query<GetLocations, GetLocationsVariables>({
      query: (params) => ({
        url: '/locations',
        method: "POST",
        body: {
          ...params,
          filter: params?.filter && prepareFilter(params.filter),
          sort: params?.sort && prepareSort(params.sort),
        }
      }),
      transformResponse: transformResponse(deserializeGetLocations),
      transformErrorResponse: transformErrorResponse("getLocations"),
    }),
  }),
});

export default LocationsApi;
