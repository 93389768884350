import { OrdersApi, GetOrders, GetOrdersVariables, useAgGridDatasource, AgGridDatasourceProps, GetOrdersVariables_Options } from "../../../api/core";

export interface OrdersDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetOrdersVariables_Options, GetOrdersVariables, GetOrders>, "endpoint"> {}

export function useOrdersDatasource(props: OrdersDatasourceProps) {
  return useAgGridDatasource<GetOrdersVariables_Options>({
    ...props,
    endpoint: OrdersApi.endpoints.getOrders,
    options: {
      count: true,
      include: ['facility', 'store'],
      ...props.options
    },
  });
}

export default useOrdersDatasource;
