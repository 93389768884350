import deserializeDate from "../utils/deserializeDate";
import deserialize, { DeserializationSchema, deserializeArrayOf } from "../utils/deserialize";

export interface Printer {
  id: string;
  model: string;
  createdAt: Date;
  updatedAt: Date;
}

export const printerDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate
});

export const deserializePrinter = deserialize<Printer>(printerDeserializationSchema);
export const deserializePrinterArray = deserializeArrayOf<Printer>(printerDeserializationSchema);
