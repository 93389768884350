import CoreApi from "../CoreApi";
import { prepareFilter, prepareSort, transformErrorResponse, transformResponse } from "../utils";
import { GetQuotumUsage, GetQuotumUsageVariables, GetQuotumUsage_Input_AliasMap, deserializeGetQuotumUsage } from "./GetQuotumUsage";

export const QuotumUsageApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuotumUsage: builder.query<GetQuotumUsage, GetQuotumUsageVariables>({
      query: (params) => ({
        url: '/quotum_usage',
        method: "POST",
        body: {
          ...params,
          filter: params?.filter && prepareFilter(params.filter, GetQuotumUsage_Input_AliasMap),
          sort: params?.sort && prepareSort(params.sort, GetQuotumUsage_Input_AliasMap),
        }
      }),
      transformResponse: transformResponse(deserializeGetQuotumUsage),
      transformErrorResponse: transformErrorResponse("getQuotumUsage"),
    }),
  }),
});

export default QuotumUsageApi;
