import React from "react";
import { IStatusPanelParams } from "ag-grid-enterprise";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

const Wrapper = styled.div`
  padding: 8px 0px; 
  margin: 0px 4px;
`;

interface FloorViewLinkComponentParams extends IStatusPanelParams {
  date?: Date;
  facilityId?: string;
}

interface FloorViewLinkComponentState {
  params: FloorViewLinkComponentParams;
}

// for w/e reason AgGrid doesn't handle functional components well in the status bar...
export class FloorViewLinkComponent 
  extends React.Component<FloorViewLinkComponentParams, FloorViewLinkComponentState> {

  constructor(params: IStatusPanelParams) {
    super(params);
    this.state = {
      params
    };
  }

  render() {
    const { date, facilityId } = this.state.params;

    if (!date) {
      return (
        <Wrapper>
          Missing Date...
        </Wrapper>
      );
    }

    const dtStr = DateTime.fromJSDate(date).toUTC().toISODate();    
    if (!facilityId) {
      return (
        <Wrapper>
          <Link to={`/schedules/view/${dtStr}`} target="_blank">
            Floor View
          </Link>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Link to={`/schedules/view/${dtStr}/${facilityId}`} target="_blank">
          Floor View
        </Link>
      </Wrapper>
    );
  }
}
