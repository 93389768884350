import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { BatchReportTable } from "../../../components/DemandReport/BatchTable";

export const BatchReportPage = () => {
  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <BatchReportTable />
    </ErrorBoundary>
  );
};
