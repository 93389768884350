import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { DemandReportTable } from "../../../components/DemandReport/Table";
import { LineItemStatus, ZiftStatus } from "../../../api/core";

export const ActivePrintReportPage = () => {
  const ziftStatus = [
    ZiftStatus.Active
  ];
  
  const printedStatuses = [
    LineItemStatus.New,
    LineItemStatus.OutOfStock,
    LineItemStatus.Pulled,
    LineItemStatus.Branding,
    LineItemStatus.Branded
  ];

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <DemandReportTable ziftStatus={ziftStatus} status={printedStatuses} hasSumRow />
    </ErrorBoundary>
  );
};
