import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import BSModal from 'react-bootstrap/Modal';
import ModalProps from "./ModalProps";
import ModalState from "./ModalState";
import ModalResults from "./ModalResults";
import { ModalStepProps, UpdateStateArg } from "./ModalStep";
import { createHiddenEvent, createHidingEvent, createShowingEvent, createShownEvent, createUpdateEvent, ModalEventType } from "./ModalEvents";
import { isFunction } from "lodash";
import { ErrorBoundary } from "@sentry/react";
import ErrorBoundaryFallback from "../Page/ErrorBoundaryFallback";

import "./modals.scss";

export function Modal<TModalResults extends ModalResults = ModalResults, 
  TModalState extends ModalState<TModalResults> = ModalState<TModalResults>
  >(props: ModalProps<TModalResults, TModalState>): JSX.Element {

  const { initState, open, close, step, transition, title, onHidden, onShown, onUpdate, 
    onEntering: parentOnEntering, onEntered: parentOnEntered, onExiting: parentOnExiting, 
    onExited: parentOnExited, ...rest } = props;

  const eventTarget = useMemo(() => new EventTarget(), []);

  // may need to expand this to permit initialization of other fields via a factory function
  const [state, setState] = useState<TModalState>(() => {
    const s = {
      eventTarget, // NOTE: This instance should never change once the component is mounted
      visible: false,
      results: undefined
    } as TModalState;

    if (isFunction(initState)) {
      const t = initState?.() || {};
      Object.assign(s, t);
    } else if (initState) {
      Object.assign(s, initState);
    }

    return s;
  });

  const { visible } = state;

  const modalRef = useRef<any>();

  const addEventListener = useCallback((type: ModalEventType,
    listener: (e?: any) => void, options?: EventListenerOptions) => {
    eventTarget.addEventListener(type, listener, options);
  }, [eventTarget]);

  const removeEventListener = useCallback((type: ModalEventType,
    listener: (e?: any) => void, options?: EventListenerOptions) => {
    eventTarget.removeEventListener(type, listener, options);
  }, [eventTarget]);

  // invoke the onHidden callback whenever the modal's hidden event is triggered
  useEffect(() => {
    onHidden && addEventListener("hidden", onHidden);
    return () => {
      onHidden && removeEventListener("hidden", onHidden);
    };
  }, [addEventListener, removeEventListener, onHidden]);

  // invoke the onShown callback whenever the modal's shown event is triggered
  useEffect(() => {
    onShown && addEventListener("shown", onShown);
    return () => {
      onShown && removeEventListener("shown", onShown);
    };
  }, [addEventListener, removeEventListener, onShown]);

  // invoke the onUpdate callback whenever the modal's update event is triggered
  useEffect(() => {
    onUpdate && addEventListener("update", onUpdate);
    return () => {
      onUpdate && removeEventListener("update", onUpdate);
    };
  }, [addEventListener, removeEventListener, onUpdate]);

  const update = useCallback((arg: UpdateStateArg<TModalResults>) => {
    setState(prev => {
      return ({ ...prev, ...(isFunction(arg) ? arg(prev) : arg) });
    });
  }, [setState]);

  /** Whenever the modal state changes, trigger the update event */
  useEffect(() => {
    eventTarget.dispatchEvent(createUpdateEvent<TModalResults>(state));
  }, [eventTarget, state]);

  /** Whenever the modal becomes visible or hidden, trigger the appropriate event */
  /* NOTE: We do NOT want to execute this everytime the state changes - only when visible changes. 
    So do NOT include state in the deps array. Rather, state will be updated because visible is
    part of the state */ 
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (visible) {
      eventTarget.dispatchEvent(createShownEvent<TModalResults>(state));
    } else {
      eventTarget.dispatchEvent(createHiddenEvent<TModalResults>(state));
    }
  }, [visible, eventTarget]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const stepProps = useMemo<ModalStepProps<TModalResults, TModalState>>(() => ({
    state,
    transition,
    update,
    close,
    addEventListener,
    removeEventListener
  }), [
    state, transition, update, close, addEventListener, removeEventListener
  ]);

  const modalContent = useMemo(() => {
    if (!visible || !step) { return (<></>); }
    return React.createElement(step, stepProps);
  }, [step, visible, stepProps]);

  const onEntering = useCallback((node: HTMLElement, isAppearing: boolean) => {
    setState(prev => ({ ...prev, visible: true })); 
    parentOnEntering?.(node, isAppearing);
    eventTarget.dispatchEvent(createShowingEvent(state));
  }, [eventTarget, state, setState, parentOnEntering]);

  const onEntered = useCallback((node: HTMLElement, isAppearing: boolean) => {
    parentOnEntered?.(node, isAppearing);
    eventTarget.dispatchEvent(createShownEvent(state));
  }, [eventTarget, state, parentOnEntered]);

  const onExiting = useCallback((node: HTMLElement) => {
    parentOnExiting?.(node);
    eventTarget.dispatchEvent(createHidingEvent(state));
  }, [eventTarget, state, parentOnExiting]);

  const onExited = useCallback((node: HTMLElement) => {
    setState(prev => ({ ...prev, visible: false }));
    parentOnExited?.(node);
    eventTarget.dispatchEvent(createHiddenEvent(state));
  }, [eventTarget, state, setState, parentOnExited]);

  return (
    <BSModal
      ref={modalRef}
      centered
      show={open}
      onHide={() => { close(); }}
      backdrop="static"
      scrollable
      {...rest}
      onEntering={onEntering}
      onEntered={onEntered}
      onExiting={onExiting}
      onExited={onExited}
    >
      <BSModal.Header closeButton>
        <BSModal.Title>
          {title}
        </BSModal.Title>
      </BSModal.Header>
      <ErrorBoundary fallback={ErrorBoundaryFallback}>
        {modalContent}
      </ErrorBoundary>
    </BSModal>
  );
}

export default Modal;
