import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import BatchScheduleManagerModalProps, { BatchScheduleManagerModalEventType, BatchScheduleManagerModalState, BatchScheduleManagerModalStepProps, UpdateStateArg } from "./BatchScheduleManagerModalProps";
import { createCancelEvent, createHiddenEvent, createResetEvent, createResultEvent, createShownEvent, createSubmitEvent } from "./ModalEvents";
import { isFunction } from "lodash";
import SubmitStep from "./SubmitStep";
import { ErrorBoundary } from "@sentry/react";
import ErrorBoundaryFallback from "../../../../components/Page/ErrorBoundaryFallback";

import "./modals.scss";

const modalSteps = [SubmitStep];
/* eslint-disable @typescript-eslint/no-unused-vars */
const STEP_SUBMIT = 0;
/* eslint-enable @typescript-eslint/no-unused-vars */

export function BatchScheduleManagerModal(props: BatchScheduleManagerModalProps): JSX.Element {
  const { show, hide, api, selectMode, schedule, onMount, onUnmount } = props; 

  const [state, setState] = useState<BatchScheduleManagerModalState>({
    step: 0,
    visible: false,
    api,
    selectMode,
    schedule,
    note: undefined,
    errors: [],
    warnings: [],
    overrides: [],
    result: undefined
  });

  const { visible } = state;

  const eventTarget = useMemo(() => new EventTarget(), []);

  // provide a way for outter components to register for the events of the modal
  useEffect(() => {
    onMount?.(eventTarget);
    return () => {
      onUnmount?.(eventTarget);
    };
  }, [onMount, onUnmount, eventTarget]);

  const cancel = useCallback((reason?:any) => {
    setState(prev => {
      return {
        ...prev,
        result: {
          cancelled: true,
          cancellationReason: reason,
          ziftIds: undefined,
          schedule: undefined
        }
      };
    });
    hide?.();    
  }, [setState, hide]);

  const addEventListener = useCallback((type: BatchScheduleManagerModalEventType, 
    listener: (e?:any) => void, options?: EventListenerOptions) => {
    eventTarget.addEventListener(type, listener, options);
  }, [eventTarget]);

  const removeEventListener = useCallback((type: BatchScheduleManagerModalEventType, 
    listener: (e?:any) => void, options?: EventListenerOptions) => {
    eventTarget.removeEventListener(type, listener, options);
  }, [eventTarget]);

  const update = useCallback((arg: UpdateStateArg) => {
    setState(prev => {
      return ({ ...prev, ...(isFunction(arg) ? arg(prev) : arg) });
    });
  }, [setState]);

  const submit = useCallback((update?: UpdateStateArg) => {
    // if we are on the final step, just stay there while the modal closes
    const isOnFinalStep = state.step === modalSteps.length - 1;
    const nextStep = isOnFinalStep ? state.step : state.step + 1;

    let updatedState:BatchScheduleManagerModalState;
    setState((prev) => {
      updatedState = { ...prev, ...(isFunction(update) ? update(prev) : update), step: nextStep };
      return updatedState;
    });
    eventTarget.dispatchEvent(createSubmitEvent(updatedState!));

    if (isOnFinalStep) {
      hide?.();
    }
  }, [state, setState, eventTarget, hide]);

  const reset = useCallback(() => {
    setState({
      step: -1,
      visible: state.visible,
      api,
      selectMode,
      schedule,
      note: undefined,
      errors: [],
      warnings: [],
      overrides: [],
      result: undefined
    });
  }, [state.visible, api, selectMode, schedule, setState]);
  
  // reset the modal whenever it is requested that it be shown
  useEffect(() => {
    if (show) {
      reset();
    }
    update({ visible: show });
  }, [show, reset, update]);
  
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (state.step === -1) {
      setState(prev => {
        return { ...prev, step: 0 };
      });
      eventTarget.dispatchEvent(createResetEvent(state));
    } else if (state.step > 0) {
      eventTarget.dispatchEvent(createSubmitEvent(state));
    }
  }, [state.step]);

  useEffect(() => {
    if (visible) {
      eventTarget.dispatchEvent(createShownEvent(state));
    } else {
      eventTarget.dispatchEvent(createHiddenEvent(state));
    }
  }, [visible, eventTarget]);

  useEffect(() => {
    if (!state.result) { return; }
    if (state.result.cancelled) {
      eventTarget.dispatchEvent(createCancelEvent(state));
    } else {
      eventTarget.dispatchEvent(createResultEvent(state));
    }
  }, [state.result, eventTarget]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const stepProps = useMemo<BatchScheduleManagerModalStepProps>(() => ({
    state,
    update,
    submit,
    reset,
    cancel, 
    addEventListener,
    removeEventListener
  }), [
    state, update, submit, reset, cancel, addEventListener, removeEventListener
  ]);

  const modalContent = useMemo(() => {
    if (!visible) {
      return (<></>);
    }

    let ndx = state.step;
    if (state.step < 0) {
      ndx = 0; // this can occur when reseting; for a split second the modal maybe visible but without content
    } else if (state.step >= modalSteps.length) {
      ndx = modalSteps.length - 1; // doesn't happen right now - but just in case
    }

    return React.createElement(modalSteps[ndx], stepProps);
  }, [state.step, visible, stepProps]);

  return (
    <Modal 
      centered 
      show={visible} 
      onHide={() => { cancel(); }}
      backdrop="static"
      dialogClassName="create-batch-modal"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="prefix">Confirm</span>
          <span className="suffix">{schedule ? "Scheduling" : "Unscheduling"}</span>
        </Modal.Title>
      </Modal.Header>
      <ErrorBoundary fallback={ErrorBoundaryFallback}>
        { modalContent }
      </ErrorBoundary>
    </Modal>
  );
}

export default BatchScheduleManagerModal;
