import React from "react";
import { Progress } from "antd";
import { Batch } from "../../../api/core";

type BatchProgressProps = {
  batch: Pick<Batch, "progress">;
  // Override progress value
  value?: number;
};

export const BatchProgress = ({
  value,
  batch: { progress },
}: BatchProgressProps) => (
  <Progress
    percent={Math.trunc((value || progress || 0) * 100.0)}
    size="small"
    status={(value || progress || 0) < 1 ? "active" : "normal"}
  />
);

BatchProgress.defaultProps = {
  value: undefined,
};
