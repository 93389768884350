import { ColDef, ColGroupDef } from "ag-grid-community";
import { RowNumberCellRenderer } from "../../../DataGrid/renderers";
import { LocaleNumberCellRenderer } from "../../../DataGrid/renderers/LocaleNumberCellRenderer";

export const orderStatsColumnDefs: /*ReadonlyArray */ (ColDef | ColGroupDef)[] = [
  {
    headerName: "#",
    sortable: false,
    filter: false,
    floatingFilter: false,
    cellRenderer: RowNumberCellRenderer,
    width: 75,
    pinned: 'left'
  },
  {
    field: 'id',
    hide: true,
    //filter: 'text'
  },
  {
    field: 'name',
    //filter: 'text'
  },
  {
    field: 'orderStats.ordersCount',
    headerName: 'Orders',
    filter: 'number',
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: 'orderStats.lineItemsCount',
    headerName: 'Line Items',
    filter: 'number',
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: 'orderStats.statusCount.active',
    headerName: "Active",
    filter: 'number',
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: 'orderStats.statusCount.pending',
    headerName: "Pending",
    filter: 'number',
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: 'orderStats.statusCount.complete',
    headerName: "Complete",
    filter: 'number',
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: 'orderStats.statusCount.canceled',
    headerName: "Canceled",
    filter: 'number',
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: 'orderStats.statusCount.invalid',
    headerName: "Invalid",
    filter: 'number',
    cellRenderer: LocaleNumberCellRenderer,
  }
];

export default orderStatsColumnDefs;
