import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { DemandReportTable } from "../../../components/DemandReport/Table";
import { LineItemStatus, ZiftStatus } from "../../../api/core";

export const OutOfStockDemandReportPage = () => {
  const ziftStatus = [
    ZiftStatus.Active,
    ZiftStatus.Pending
  ];
  const outOfStockStatuses = [
    LineItemStatus.OutOfStock
  ];

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <DemandReportTable ziftStatus={ziftStatus} status={outOfStockStatuses} hasSumRow />
    </ErrorBoundary>
  );
};
