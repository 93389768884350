import React, { MouseEventHandler, useCallback, useEffect, useMemo, cloneElement, ReactElement } from "react";
import { get, isEmpty, startCase, toLower } from "lodash";
import { Typography, Tooltip } from "antd";
import { formatDate } from "../../../helpers";
import { FloorViewStatus } from ".";
import { Tag } from "../../../components";
import { InfoCircleFilled } from "@ant-design/icons";
import {
  CallSplitOutlinedRounded,
  ContentCopyOutlinedRounded,
  CounterOneOutlinedRounded,
  DomainVerificationOutlinedRounded,
  DoneRounded,
  LabelCheckedRounded,
  LabelOffRounded,
  NavigateNextRounded,
  PauseRounded,
  PointScanRounded,
  PrintConnectRounded,
  PrintDisabledRounded,
  PushPin,
  ShelvesOutlinedRounded,
  WarningOutlinedRounded,
} from "../../../fixtures/icons";
import { Batch, BatchFlag, BatchStatus, EmptyLineItemSourceCount, EmptyLineItemStatusCount, LineItemStatus } from "../../../api/core";
import { DateTime } from "luxon";

const { Text } = Typography;

enum StatusIconState {
  Complete,
  Warning,
  Idle,
}

/* eslint-disable react/require-default-props */
export interface ScheduleBatchItemProps {
  index: number;
  className?: string;
  batch: Batch;
  floorViewStatus: FloorViewStatus;
  style?: any;
  onBatchClick?: MouseEventHandler<any>;
  eventTarget?: EventTarget; // used to propogate parent events to child in rc-virtual-list
  scrollToKey?: string; // the current batch that we are scrolling to at the time of render
}

export interface ScheduleBatchItemFlagProps {
  active: boolean;
  color: string;
  icon: ReactElement<any>;
  iconSize?: string;
  keySuffix: string;
  title: string;
}

export interface ScheduleBatchItemStepProps {
  description?: React.ReactNode;
  index: number;
  key?: string;
  stepNumber: number;
  stepPercent: number;
  title?: React.ReactNode;
}

const highlightDuration = 5000;

// We are supporting forwarded refs for integration with rc-virtual-list
export const ScheduleBatchItem = React.forwardRef(
  (props: ScheduleBatchItemProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { batch, onBatchClick, eventTarget, scrollToKey, floorViewStatus, index } = props;
    const [scrolledTo, setScrolledTo] = React.useState<boolean>(false);
    const scrolledToTimer = React.useRef<number>(0);

    const onScrollTo = useCallback(
      (e: CustomEvent) => {
        if (scrolledToTimer.current) {
          window.clearTimeout(scrolledToTimer.current);
          scrolledToTimer.current = 0;
          setScrolledTo(false);
        }

        if (e.detail.ziftId === batch.ziftId) {
          setScrolledTo(true);
          scrolledToTimer.current = window.setTimeout(() => {
            setScrolledTo(false);
          }, highlightDuration);
        }
      },
      [setScrolledTo, batch?.ziftId]
    );

    useEffect(() => {
      if (scrolledToTimer.current) {
        window.clearTimeout(scrolledToTimer.current);
        scrolledToTimer.current = 0;
      }
    }, []);

    useEffect(() => {
      eventTarget?.addEventListener("scrollTo", onScrollTo as EventListener);
      return () => eventTarget?.removeEventListener("scrollTo", onScrollTo as EventListener);
    }, [eventTarget, onScrollTo]);

    // if the scrollTo event fires prior to this item being rendered, the scrollToKey will
    // be passed in the props. When we initially render, if the scrollToKey matches the batchId,
    // then trigger the event locally.
    useEffect(() => {
      if (scrollToKey) {
        onScrollTo(
          new CustomEvent("scrollTo", {
            detail: {
              ziftId: scrollToKey,
            },
          })
        );
      }
    });

    const {
      boxes,
      createdAt,
      flags,
      lastConfirmBrandedAt,
      lastConfirmDeliveredToPrinterAt,
      lastScanAt,
      minShipBy,
      note,
      orderBoxes,
      orderExternalIds,
      printer,
      progress,
      schedulePinned,
      scheduledUserId,
      lineItemStats,
      stores,
      type,
      ziftId,
    } = batch;

    const { 
      totalCount = 0, 
      progressionCount = EmptyLineItemStatusCount, 
      statusCount = EmptyLineItemStatusCount,
      sourceCount = EmptyLineItemSourceCount,
    } = lineItemStats ?? {};

    const statusIconState = useMemo(() => {
      if ((batch.progress ?? 0) >= 1.0 || batch.status === BatchStatus.Complete) {
        return StatusIconState.Complete;
      }
      if (batch.idle) {
        return StatusIconState.Idle;
      }
      if ((progress ?? 0) < 1.0 && (!lastScanAt || (lastScanAt && DateTime.now().diff(DateTime.fromJSDate(lastScanAt)).minutes > 60))) {
        return StatusIconState.Warning;
      }
      return null;
    }, [batch.progress, batch.idle, batch.status, lastScanAt, progress]);

    const statusIconClassName = useMemo(() => {
      if (statusIconState === StatusIconState.Complete) {
        return "complete";
      }
      if (statusIconState === StatusIconState.Idle) {
        return "idle";
      }
      if (statusIconState === StatusIconState.Warning) {
        return "warning";
      }
      return "";
    }, [statusIconState]);

    const progressSteps = useMemo(() => {
      let currentIndex = 0;
      let lastIndex = 0;
      let stepPercent = 0;

      const stepTitles: Partial<{ [key in LineItemStatus]: string }> = {
        [LineItemStatus.Printed]: "Drying",
        [LineItemStatus.Packaged]: "Packaged (Freight)",
      };

      const ignoreSteps = new Set<LineItemStatus>([
        LineItemStatus.New,
        LineItemStatus.Branded,
        LineItemStatus.Packaging,
        LineItemStatus.Shipping,
        LineItemStatus.OutOfStock,
        LineItemStatus.Defective,
      ]);

      const filteredSteps = Object.keys(progressionCount)
        .filter((status) => {
          return (
            // the status from core is presented as "out_of_stock", as represented in LineItemStatus
            // the value in the status count is converted to "outOfStock" as per our snake-to-camel conversion
            !status.match(/^__/) && !ignoreSteps.has(status as LineItemStatus) && status !== "outOfStock"
          );
        })
        .map((status, index) => {
          const count = get(progressionCount, status);
          const stepNumber = index + 1;

          const excludeCount = statusCount.defective + statusCount.outOfStock;
          const actualCount = totalCount - excludeCount;

          const isComplete = count >= actualCount;
          const isProcessing = count > 0;

          if (count >= actualCount) {
            stepPercent = (count / actualCount) * 100.0;
          }

          if (count > 0) {
            currentIndex = index;
          }

          lastIndex = index;

          return {
            description: `${count} / ${actualCount}`,
            index,
            key: `${batch.id}-step-${index}`,
            stepNumber,
            stepPercent,
            title: stepTitles[status as LineItemStatus] || startCase(status),
            isComplete,
            isProcessing,
          };
        });

      return filteredSteps.map(({ description, index, key, stepNumber, title, isComplete, isProcessing }) => {
        // The general rule is to highlight only the right-most step with at least one quantity.
        // If the rightmost step is complete, highlight it anyway, unless it is the final step.
        let stateOfStatus = "waiting";
        if (index === lastIndex && isComplete) {
          stateOfStatus = "complete";
        } else if (index === currentIndex) {
          stateOfStatus = "processing";
        } else if (isComplete) {
          stateOfStatus = "complete";
        } else if (isProcessing && title === stepTitles[LineItemStatus.Packaged]) {
          // This is inconsistent, but stakeholders specicially want it this way.
          stateOfStatus = "processing";
        }

        const stepClassPrefix = "schedule-floor-batch-progress-step";
        const stepClassNames = [stepClassPrefix, `${stepClassPrefix}-${stateOfStatus}`];
        return (
          <div className={stepClassNames.join(" ")} data-status={stateOfStatus} key={key}>
            <div className={`${stepClassPrefix}-status-container`}>
              <div className={`${stepClassPrefix}-status`}>{isComplete ? <DoneRounded /> : stepNumber}</div>
            </div>
            <div className={`${stepClassPrefix}-title`}>{title}</div>
            <div className={`${stepClassPrefix}-description`}>{description}</div>
          </div>
        );
      });
    }, [batch.id, progressionCount, totalCount, statusCount]);

    const freightInfo = useMemo(() => {
      return type !== "FreightBatch" ? (
        "--"
      ) : (
        <table>
          <tbody>
            <tr>
              <th>Box:</th>
              <td>
                {boxes?.join(", ")} / {orderBoxes?.length}
              </td>
            </tr>
            <tr>
              <th>SO:</th>
              <td>{orderExternalIds?.join(", ")}</td>
            </tr>
          </tbody>
        </table>
      );
    }, [boxes, orderBoxes, orderExternalIds, type]);

    const clickStyles = useMemo(
      () => ({
        cursor: onBatchClick ? "pointer" : "inherit",
      }),
      [onBatchClick]
    );

    const shipByDate = formatDate(minShipBy);
    const shipByDateDistance = formatDate(minShipBy, { onlyDistance: true });
    const lastScanDate = formatDate(lastScanAt);
    const lastScanDateDistance = formatDate(lastScanAt, { onlyDistance: true });
    const creationDate = formatDate(createdAt);
    const creationDateDistance = formatDate(createdAt, { onlyDistance: true });

    const itemClassPrefix = "schedule-floor-batch";
    const itemClassNames = [`${itemClassPrefix}-container`, `${itemClassPrefix}-${statusIconClassName}`];
    if (scrolledTo) {
      itemClassNames.push(`${itemClassPrefix}-scrolled-to`);
    }

    const itemFlags = useMemo(() => {
      return [
        {
          active: Boolean(schedulePinned),
          color: "#000",
          icon: <PushPin />,
          keySuffix: "pinned",
          title: "Pinned",
        },
        {
          active: statusIconState === StatusIconState.Complete,
          color: "#20c997",
          icon: <DoneRounded />,
          keySuffix: "complete",
          title: "Batch Complete",
        },
        {
          active: statusIconState === StatusIconState.Warning,
          color: "#dc3545",
          icon: <WarningOutlinedRounded />,
          keySuffix: "stale",
          title: "Last scan over 1 hour ago",
        },
        {
          active: statusIconState === StatusIconState.Idle,
          color: "#6f42c1",
          icon: <PauseRounded />,
          keySuffix: "idle",
          title: "Idle",
        },
        {
          active: Boolean(flags?.includes(BatchFlag.CompleteOrders)),
          color: "#724d29",
          icon: <DomainVerificationOutlinedRounded />,
          keySuffix: "only-complete",
          title: "Only complete orders in batch",
        },
        {
          active: Boolean(flags?.includes(BatchFlag.SingleItemOrders)),
          color: "#0d6efd",
          icon: <CounterOneOutlinedRounded />,
          keySuffix: "has-single-unit",
          title: "Batch includes single unit orders",
        },
        {
          active: Boolean(flags?.includes(BatchFlag.MultiItemOrders)),
          color: "#ffffff",
          icon: <CallSplitOutlinedRounded />,
          keySuffix: "has-split-orders",
          title: "Batch includes split orders",
        },
        {
          active: Boolean(flags?.includes(BatchFlag.IsNeckhitEligible)),
          color: "#fd7e14",
          icon: <PointScanRounded />,
          keySuffix: "neckhit-elegible",
          title: "Batch includes neckhit eligible",
        },
        {
          active: Boolean(sourceCount?.shelf > 0),
          color: "#ffff66",
          icon: <ShelvesOutlinedRounded />,
          keySuffix: "has-shelf-stock",
          title: "Batch includes shelf stock items",
        },
      ].map(
        ({ active, color, icon, iconSize, keySuffix, title }: ScheduleBatchItemFlagProps) =>
          active && (
            <Tooltip
              align={{ offset: [-5, 0] }}
              className="schedule-floor-batch-flag"
              key={`schedule-floor-batch-flag-${keySuffix}`}
              mouseLeaveDelay={0}
              placement="right"
              title={title}
            >
              <Tag color={color!}>{cloneElement(icon, { size: iconSize ?? "16px" })}</Tag>
            </Tooltip>
          )
      );
    }, [flags, statusIconState, schedulePinned, sourceCount]);

    const storesList = useMemo(() => {
      if (!stores) {
        return "--";
      }
      return stores.map(({ id, color, name }) => (
        <Tag key={id} color={color!}>
          {name}
        </Tag>
      ));
    }, [stores]);

    const listItemDate = (date: string | JSX.Element, distance: string | JSX.Element) => {
      return (
        <span className="schedule-floor-batch-detail-text">
          {date}
          <span className="schedule-floor-batch-detail-subtext">{distance}</span>
        </span>
      );
    };

    const dataProps = {
      "data-scrolledto": scrolledTo,
      "data-status": statusIconClassName,
    };

    const isBranded = Boolean(lastConfirmBrandedAt);
    const isDeliveredToPrinter = Boolean(lastConfirmDeliveredToPrinterAt);
    const batchStatusExtras = (
      <div className="schedule-floor-batch-status-substatuses">
        <Tooltip
          align={{ offset: [0, -15] }}
          placement="bottom"
          title={`Branding Completed At: ${formatDate(lastConfirmBrandedAt, { html: false, showTime: true })}`}
        >
          <div className={`schedule-floor-batch-status-substatus-${isBranded ? "on" : "off"}`}>
            {isBranded ? <LabelCheckedRounded /> : <LabelOffRounded />}
          </div>
        </Tooltip>
        <Tooltip
          align={{ offset: [0, -15] }}
          placement="bottom"
          title={`Delivered To Printer At: ${formatDate(lastConfirmDeliveredToPrinterAt, {
            html: false,
            showTime: true,
          })}`}
        >
          <div className={`schedule-floor-batch-status-substatus-${isDeliveredToPrinter ? "on" : "off"}`}>
            {isDeliveredToPrinter ? <PrintConnectRounded /> : <PrintDisabledRounded />}
          </div>
        </Tooltip>
      </div>
    );

    return (
      <>
        <div className="schedule-floor-batch-gutter schedule-floor-batch-reference" ref={ref} />
        <div className="schedule-floor-batch-divider" />
        <div className="schedule-floor-batch-gutter" />
        <div className="schedule-floor-batch-cell schedule-floor-batch-flags" {...dataProps}>
          {itemFlags}
        </div>
        {[
          {
            content: index + 1,
            key: "index",
            label: "Index",
          },
          {
            content: ziftId,
            copyable: true,
            key: "ziftId",
            label: "Batch ID",
          },
          {
            content: startCase(toLower(floorViewStatus)),
            key: "status",
            label: "Status",
            extras: batchStatusExtras,
          },
          {
            content: batch?.pullBox,
            key: "bin",
            label: "Bin",
            title: "Tote Bin / Pull Box",
          },
          {
            content: freightInfo,
            key: "freight",
            label: "Freight",
          },
          {
            content: `${((progress ?? 0) * 100.0).toFixed(0)}%`,
            key: "progress",
            label: "Progress",
          },
          {
            content: listItemDate(creationDate, creationDateDistance),
            key: "created-on",
            label: "Batched Date",
          },
          {
            content: listItemDate(lastScanDate, lastScanDateDistance),
            key: "last-scan",
            label: "Last Scan",
          },
          {
            content: listItemDate(shipByDate, shipByDateDistance),
            key: "ship-by",
            label: "Ship By",
          },
          {
            content: printer?.model,
            key: "printer",
            label: "Printer",
          },
          {
            content: scheduledUserId,
            key: "scheduled-by",
            label: "By",
            title: "Scheduled By",
          },
          {
            content: storesList,
            key: "stores",
            label: "Stores",
          },
          {
            content: statusCount.outOfStock,
            key: "out-of-stock",
            label: "OOS",
            title: "Out of Stock",
          },
          {
            content: statusCount.defective,
            key: "defective",
            label: "QC",
            title: "Defective",
          },
        ].map(({ content, copyable, extras, key, label, title }, index, list) => {
          const classPrefix = "schedule-floor-batch-detail";
          const classNames = ["schedule-floor-batch-cell", classPrefix, `${classPrefix}-${key}`];
          if (index === list.length - 1) {
            classNames.push(`${classPrefix}-last`);
          }
          return (
            <Tooltip align={{ offset: [0, -15] }} key={`${classPrefix}-${key}`} placement="bottom" title={title}>
              <div className={classNames.join(" ")} {...dataProps}>
                <Text
                  className={`${classPrefix}-label`}
                  copyable={
                    copyable
                      ? { icon: [<ContentCopyOutlinedRounded />, <DoneRounded />], text: ziftId?.toString() }
                      : false
                  }
                >
                  {label}
                </Text>
                <div className={`${classPrefix}-value horizontal-scroll-on-hover`}>{content ?? "--"}</div>
                {Boolean(extras) && <div className={`${classPrefix}-extras`}>{extras}</div>}
              </div>
            </Tooltip>
          );
        })}
        <div
          className="schedule-floor-batch-cell schedule-floor-batch-trigger-details"
          {...dataProps}
          onClick={onBatchClick}
          style={clickStyles}
        >
          <NavigateNextRounded />
        </div>
        <div
          className="schedule-floor-batch-cell schedule-floor-batch-extra schedule-floor-batch-progress"
          {...dataProps}
        >
          {progressSteps}
        </div>
        <div
          className="schedule-floor-batch-cell schedule-floor-batch-extra schedule-floor-batch-notes"
          data-has_note={!isEmpty(note?.content)}
          {...dataProps}
        >
          <InfoCircleFilled className="schedule-floor-batch-notes-icon" />
          <Text className="schedule-floor-batch-notes-text" ellipsis>
            {!isEmpty(note?.content) ? note?.content : "--"}
          </Text>
        </div>
        <div className="schedule-floor-batch-divider" />
      </>
    );
  }
);
