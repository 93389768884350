import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { isUndefined } from "lodash";
import "./BatchProgressCellRenderer.scss";
import { Batch } from "../../../../api/core";

interface BatchProgressCellRendererProps extends ICellRendererParams {
  data: Batch;
  showPrinted?: boolean;
}

export function BatchProgressCellRenderer({
  value,
  data,
  showPrinted,
}: BatchProgressCellRendererProps) {
  if (isUndefined(value)) return null;

  // Do we have stats?
  let pct = Math.ceil(value * 100.0);
  let stats: JSX.Element | JSX.Element[] = <></>;
  const lineItemStats = data?.lineItemStats;

  if (
    showPrinted &&
    lineItemStats?.progressionCount?.printed &&
    lineItemStats?.totalCount
  ) {
    pct = Math.ceil(
      (lineItemStats?.progressionCount?.printed / lineItemStats.totalCount) *
        100.0
    );
    stats = (
      <div className="ant-progress-over">
        <b>
          {lineItemStats.progressionCount.printed} / {lineItemStats.totalCount}
        </b> 
        Printed
      </div>
    );
  }

  return (
    <div 
      style={{ minWidth: "100px", paddingRight: "12px" }} 
      className="ant-progress ant-progress-line ant-progress-status-active ant-progress-show-info ant-progress-small"
    >
      <div className="ant-progress-outer">
        <div className="ant-progress-inner">
          <div className="ant-progress-bg" style={{ width: `${pct}%`, height: "12px" }} />
        </div>
        {stats}
      </div>
      <span className="ant-progress-text" title={`${pct}%`}>
        {pct}%
      </span>
    </div>
  );
}

BatchProgressCellRenderer.defaultProps = {
  showPrinted: false
};
