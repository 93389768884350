import deserialize, { DeserializationSchema } from "../utils/deserialize";
import { Facility, deserializeFacilitiesArray } from "./common";

export type FacilityIdAndCapacity = Pick<Facility, "id" | "capacity">;

export interface UpdateFacilityCapacity {
  facilities: Facility[];
}

export interface UpdateFacilityCapacityVariables {
  facilities: FacilityIdAndCapacity[];
}

export const UpdateFacilityCapacityDeserializationSchema: Readonly<DeserializationSchema> = 
  Object.freeze({
    facilities: deserializeFacilitiesArray
  });

export const deserializeUpdateFacilityCapacity = 
  deserialize<UpdateFacilityCapacity>(UpdateFacilityCapacityDeserializationSchema);
