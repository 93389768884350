import React, { useCallback, useMemo, useState } from "react";
import { Modal, Button, Result } from "antd";
import { LoadingMessage, LineItemDataGrid } from "../../../components";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { BatchUnderConstructionAPI } from "../../../api/BatchUnderConstruction";
import ILineItemData from "../../../api/BatchUnderConstruction/ILineItemData";
import { GetContextMenuItemsParams, GridOptions, MenuItemDef } from "ag-grid-enterprise";
import { useGridStateSearchParamPlugin } from "../../../components/DataGrid/plugins";
import { PicksheetsApi } from "../../../api/core/Picksheets";

export const LineItemManagePage = () => {
  const [picksheetModalVisible, setPicksheetModalVisible] = useState<boolean>(false);
  const [addToBatchModalVisible, setAddToBatchModalVisible] = useState<boolean>(false);
  const [addToBatchLoading, setAddToBatchLoading] = useState<boolean>(false);
  const [addToBatchError, setAddToBatchError] = useState<String>("");
  const gridStatePlugin = useGridStateSearchParamPlugin();

  const [
    createPicksheetMutation,
    {
      error: createPicksheetError,
      data: createPicksheetData,
      isLoading: picksheetLoading,
    },
  ] = PicksheetsApi.useCreatePicksheetMutation();

  const createPicksheet = useCallback((lineItemIds:string[]) => {
    createPicksheetMutation({ lineItemIds });
    setPicksheetModalVisible(true);
  }, [createPicksheetMutation, setPicksheetModalVisible]);

  const picksheetStatusMessage = useMemo(() => {
    if (picksheetLoading) return LoadingMessage.random();
    return createPicksheetError
      ? "Picksheet creation failed."
      : "Picksheet ready!";
  }, [createPicksheetError, picksheetLoading]);

  const addLineItemsToCurrentBatch = useCallback((lineItems:ILineItemData[]) => {
    setAddToBatchError("");
    setAddToBatchLoading(true);
    setAddToBatchModalVisible(true);

    try {
      BatchUnderConstructionAPI.add(lineItems);
    } catch (error: any) {
      setAddToBatchError(error.message);
    }

    setAddToBatchLoading(false);
  }, [setAddToBatchLoading, setAddToBatchModalVisible, setAddToBatchError]);

  const addToBatchStatusMessage = useMemo(() => {
    if (addToBatchLoading) return LoadingMessage.random();
    return addToBatchError || "Line Items added to Batch!";
  }, [addToBatchError, addToBatchLoading]);

  const gridOptions:GridOptions = useMemo(() => ({
    getContextMenuItems: (params:GetContextMenuItemsParams):(string|MenuItemDef)[] => {
      const selectedCount = params.api.getSelectedNodes().length;
      
      return [
        ...params.defaultItems!,
        {
          name: 'Create Picksheet For Selected LineItems',
          disabled: !selectedCount,
          action: () => createPicksheet(params.api.getSelectedNodes().map(_ => _.id) as string[])
        },
        {
          name: 'Add To Batch Preview',
          disabled: !selectedCount,
          action: () => addLineItemsToCurrentBatch(
            params.api.getSelectedNodes().map(_ => ({
              ziftId: _.data.ziftId,
              orderId: _.data.orderId,
              shipByDate: _.data.shipBy
            }))
          )
        }
      ];
    },
    animateRows: true,
    enableCellChangeFlash: true,
  }), [createPicksheet, addLineItemsToCurrentBatch]);

  return (
    <>
      <LineItemDataGrid
        gridOptions={gridOptions}
        plugins={[gridStatePlugin]}
      />

      <Modal
        open={picksheetModalVisible}
        closable
        centered
        footer={null}
        style={{ textAlign: "center" }}
        onCancel={() => setPicksheetModalVisible(false)}
      >
        <Result
          status={createPicksheetError ? "warning" : "success"}
          icon={picksheetLoading ? <LoadingOutlined /> : null}
          title={picksheetStatusMessage}
        >
          {!picksheetLoading && !createPicksheetError ? (
            <Button
              loading={picksheetLoading}
              disabled={picksheetLoading}
              target="_blank"
              href={createPicksheetData?.pdfUrl}
              type="primary"
              icon={<DownloadOutlined />}
              size="large"
            >
              Download
            </Button>
          ) : null}
        </Result>
      </Modal>

      <Modal
        open={addToBatchModalVisible}
        closable
        centered
        footer={null}
        style={{ textAlign: "center" }}
        onCancel={() => setAddToBatchModalVisible(false)}
      >
        <Result
          status={addToBatchError ? "warning" : "success"}
          icon={addToBatchLoading ? <LoadingOutlined /> : null}
          title={addToBatchStatusMessage}
        />
      </Modal>
    </>
  );
};
