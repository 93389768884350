/* eslint-disable max-classes-per-file */
import React, { useMemo } from "react";
import { DataGrid, DataGridProps } from "../../DataGrid";
import { Button, Drawer } from "antd";
import { BatchDetail } from "..";
import { TagsCellRenderer, BatchProgressCellRenderer, StoresCellRenderer } from "./renderers";
import useBatchServerSideDatasource from "./hooks/useBatchServerSideDatasource";
import * as renderers from "../../Table/renderers";
import "./index.scss";
import createBatchTableColDefs from './createBatchTableColDefs';
import { GridOptions, ColDef, GetRowIdParams, ICellRendererParams } from "ag-grid-enterprise";
import BatchSchedulingToolPanel from "./components/toolPanels/BatchSchedulingToolPanel";
import defaultColDef from "./defaultColDef";
import BulkEditNotesToolPanel from "./components/toolPanels/BulkEditNotesToolPanel";
import { isArray } from "lodash";
import { Batch } from "../../../api/core/Batches";
import { GetFacilities, GetStores } from "../../../api/core";
import { ErrorBoundary } from "@sentry/react";
import ErrorBoundaryFallback from "../../Page/ErrorBoundaryFallback";

export interface BatchTableProps extends DataGridProps {
  activeBatchId?: string | null;
  setActiveBatchId?: (batchId: string | null | undefined) => void;
  hideSchedule?: boolean;
  hideUnschedule?: boolean;
  facilities: Promise<GetFacilities>,
  stores: Promise<GetStores>
}

const BatchTableAgGrid = (props: BatchTableProps) => {
  const { plugins, hideSchedule, hideUnschedule, setActiveBatchId, facilities, stores } = props;  

  const colDefs = useMemo(() => {
    const cols = createBatchTableColDefs({
      facilitiesPromise: facilities, //facilitiesQueryTrigger({ limit: 0 }).unwrap(), // TO DO: should we just pass in the trigger itself?
      storesPromise: stores, //storesQueryTrigger({ limit: 0 }).unwrap(), // TO DO: should we just pass in the trigger itself?
      // if we are hiding schedule, we are on the scheduler and want to sort by compound priority
      includeCompoundPriorityInSort: hideSchedule ?? false,
      // additionally, if we are on the scheduler then we want to show and default sort by the schedulePinned field
      includeSchedulePinnedField: hideSchedule ?? false
    });

    const actionsColDef: ColDef = {
      field: undefined,
      headerName: "Actions",
      sortable: false,
      cellRenderer: ActionsColumnCellRenderer(setActiveBatchId),
    };

    cols.push(actionsColDef); 
    return cols;
  }, [facilities, stores, setActiveBatchId, hideSchedule]);
  const datasource = useBatchServerSideDatasource({ count: true });

  const gridOptions: GridOptions<Batch>[] = useMemo(() => {
    const go: GridOptions<Batch> = {
      context: {},
      rowModelType: "serverSide",
      serverSideDatasource: datasource,
      serverSideInfiniteScroll: true,
      blockLoadDebounceMillis: 100,
      cacheBlockSize: 25,
      groupDisplayType: "multipleColumns",
      rowSelection: "multiple",
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      defaultColDef,
      multiSortKey: 'ctrl',
      columnDefs: colDefs,
      getRowId: (params: GetRowIdParams<Batch>) => params.data.id,
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            toolPanel: 'agColumnsToolPanel',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanelParams: {
              suppressPivotMode: true,
              suppressValues: true
            },
            width: 320
          }, 
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: true,
              suppressFilterSearch: true,
            },
            width: 320
          }, 
          {
            id: 'batchScheduling',
            labelDefault: 'Scheduling',
            labelKey: 'batchScheduling',
            iconKey: 'columns',
            toolPanel: BatchSchedulingToolPanel,
            toolPanelParams: {
              hideSchedule,
              hideUnschedule
            },
            width: 480
          },
          {
            id: 'bulkEditNotes',
            labelDefault: 'Edit Notes',
            labelKey: 'bulkEditNotes',
            iconKey: 'paste',
            toolPanel: BulkEditNotesToolPanel,
            width: 320
          }
        ] 
      },
      components: {
        ...Object.values(renderers),
        BatchProgressCellRenderer,
        StoresCellRenderer,
        TagsCellRenderer,
      },
      animateRows: true,
      enableCellChangeFlash: true
    };

    if (!props.gridOptions) {
      return [go];
    } if (isArray(props.gridOptions)) {
      return [go, ...props.gridOptions];
    } 
    return [go, props.gridOptions];
    
  }, [colDefs, props.gridOptions, datasource, hideSchedule, hideUnschedule]);

  return (<DataGrid gridOptions={gridOptions} plugins={plugins} />);
};

const BatchDetails = (props: BatchTableProps) => {
  const { activeBatchId, setActiveBatchId } = props;
  return (
    <Drawer
      open={!!activeBatchId}
      width="60vw"
      onClose={() => setActiveBatchId?.(undefined)}
    >
      {activeBatchId ? <BatchDetail id={activeBatchId} /> : undefined}
    </Drawer>
  );
};

export const BatchTable = (props : BatchTableProps) => {
  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback}>
      <BatchTableAgGrid {...props} />
      <BatchDetails {...props} />
    </ErrorBoundary>
  );
};

function ActionsColumnCellRenderer(setActiveBatchId: ((batchId: string | null | undefined) => void) | undefined): any {
  return (params: ICellRendererParams) => (
    <>
      <Button size="small" onClick={() => setActiveBatchId?.(params.data.id)} key="actions-batch-details">
        Details
      </Button>
    </>
  );
}
