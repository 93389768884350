import React, { useMemo } from "react";
import { GetRowIdParams, GridOptions } from "ag-grid-enterprise";
import { DataGrid, DataGridProps } from "../../DataGrid";
import { isArray } from "lodash";
import { Batch, GetBatchesVariables_Includes } from "../../../api/core";
import useBatchesDatasource, { BatchesDatasourceProps } from "./useBatchDataSource";
import defaultColumnDefs from "./defaultColumnDefs";

export interface BatchDataGridProps extends DataGridProps {
  include?: GetBatchesVariables_Includes[]; 
}

export const BatchDataGrid = (props: BatchDataGridProps) => {
  const { plugins } = props;
  const options: BatchesDatasourceProps = {};

  if (props.include) {
    options.include = props.include;
  }

  const datasource = useBatchesDatasource({ options });

  const defaultGridOptions:GridOptions = useMemo(() => {
    return {
      columnDefs: defaultColumnDefs,
      serverSideDatasource: datasource,
      serverSideInfiniteScroll: true,
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => (param.data as Batch).id,
      animateRows: true,
      enableCellChangeFlash: true,
    };
  }, [datasource]);

  const gridOptionsArray:GridOptions[] = useMemo(() => {
    if (isArray(props.gridOptions)) {
      return [defaultGridOptions, ...props.gridOptions];
    } if (props.gridOptions) {
      return [defaultGridOptions, props.gridOptions];
    } 
    return [defaultGridOptions];
    
  }, [defaultGridOptions, props.gridOptions]);

  return (
    <DataGrid
      gridOptions={gridOptionsArray} 
      plugins={plugins}
    />
  );
};

export default BatchDataGrid;
