import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import BUC from "../../../../../api/BatchUnderConstruction";

import "./index.scss";
import SelectPrinterStep from "./SelectPrinterStep";
import SubmitStep from "./SubmitStep";

import CreateBatchUnderConstructionModalStepProps, { 
  CreateBatchUnderConstructionModalEventType, 
  CreateBatchUnderConstructionModalState, 
  UpdateStateArg
} from "./CreateBatchUnderConstructionModalStepProps";
import { isFunction } from "lodash";
import { 
  createCancelEvent, createHiddenEvent, createResetEvent, createShownEvent, createSubmitEvent 
} from "./Events";

export interface CreateBatchUnderConstructionModalProps {
  isVisible?: boolean;
  hide?: () => void;
}

const modalSteps = [SelectPrinterStep, SubmitStep];
/* eslint-disable @typescript-eslint/no-unused-vars */
const STEP_SELECT_PRINTER = 0;
const STEP_SUBMIT = 1;
/* eslint-enable @typescript-eslint/no-unused-vars */

export const CreateBatchUnderConstructionModal = (props: CreateBatchUnderConstructionModalProps) => 
{
  const { isVisible, hide } = props; 
  const eventTarget = useMemo(() => new EventTarget(), []);
  const [state, setState] = useState<CreateBatchUnderConstructionModalState>({
    step: 0,
    facilityId: BUC.getFacilityId(),
    shipByDate: BUC.getShipByDate(),
    lineItems: BUC.getLineItems()
  });

  const cancel = useCallback((reason?:any) => {
    setState(prev => ({
      ...prev,
      result: {
        cancelled: true,
        cancellationReason: reason
      }
    }));
    
    eventTarget.dispatchEvent(createCancelEvent());
    hide?.();    
  }, [setState, hide, eventTarget]);

  const addEventListener = useCallback((type: CreateBatchUnderConstructionModalEventType, 
    listener: (e?:any) => void, options?: EventListenerOptions) => {
    eventTarget.addEventListener(type, listener, options);
  }, [eventTarget]);

  const removeEventListener = useCallback((type: CreateBatchUnderConstructionModalEventType, 
    listener: (e?:any) => void, options?: EventListenerOptions) => {
    eventTarget.removeEventListener(type, listener, options);
  }, [eventTarget]);

  const update = useCallback((arg: UpdateStateArg) => {
    setState(prev => ({ ...prev, ...(isFunction(arg) ? arg(prev) : arg) }));
  }, [setState]);

  const submit = useCallback((update?: UpdateStateArg) => {
    // if we are on the final step, just stay there while the modal closes
    const isOnFinalStep = state.step === modalSteps.length - 1;
    const nextStep = isOnFinalStep ? state.step : state.step + 1;

    setState((prev) => {
      return { ...prev, ...(isFunction(update) ? update(prev) : update), step: nextStep };
    });
    eventTarget.dispatchEvent(createSubmitEvent());

    if (isOnFinalStep) {
      hide?.();
    }
  }, [state, setState, eventTarget, hide]);

  const reset = useCallback(() => {
    setState({ 
      step: 0,
      facilityId: BUC.getFacilityId(),
      shipByDate: BUC.getShipByDate(),
      lineItems: BUC.getLineItems(),
      result: undefined
    });

    eventTarget.dispatchEvent(createResetEvent());
  }, [setState, eventTarget]);

  const onBUCChanged = useCallback(() => {
    if (isVisible && state.step < STEP_SUBMIT) {
      reset();
    } else {
      // TO DO: Show a warning toast that the recent change did not make it in
    }
  }, [isVisible, state.step, reset]);

  useEffect(() => {
    if (isVisible) {
      BUC.addEventListener('init', onBUCChanged);
      BUC.addEventListener('reset', onBUCChanged);
      BUC.addEventListener('items-changed', onBUCChanged);
    }

    return () => {
      if (isVisible) {
        BUC.removeEventListener('init', onBUCChanged);
        BUC.removeEventListener('reset', onBUCChanged);
        BUC.removeEventListener('items-changed', onBUCChanged);
      }
    };
  }, [isVisible, reset, onBUCChanged]);

  useEffect(() => {
    if (isVisible) {
      eventTarget.dispatchEvent(createShownEvent());
      reset();
    } else {
      eventTarget.dispatchEvent(createHiddenEvent());
    }
  }, [isVisible, eventTarget, reset]);

  const stepProps = useMemo<CreateBatchUnderConstructionModalStepProps>(() => ({
    state,
    update,
    submit,
    reset,
    cancel, 
    addEventListener,
    removeEventListener
  }), [
    state, update, submit, reset, cancel, addEventListener, removeEventListener
  ]);

  const modalContent = useMemo(() => {
    if (!isVisible || state.step < 0 || state.step >= modalSteps.length) {
      return (<></>);
    }
    return React.createElement(modalSteps[state.step], stepProps);
  }, [state.step, isVisible, stepProps]);

  return (
    <Modal 
      centered 
      show={isVisible} 
      onHide={() => { cancel(); }}
      backdrop="static"
      dialogClassName="create-batch-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="prefix">Create</span>
          <span className="suffix">Batch</span>
        </Modal.Title>
      </Modal.Header>
      { modalContent }
    </Modal>
  );
};

export default CreateBatchUnderConstructionModal;
