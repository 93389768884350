import React, { useCallback, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { BsPinAngleFill } from "react-icons/bs";
import * as Sentry from '@sentry/browser';
import { Spin } from "../../../Spin";
import { BatchesApi } from "../../../../api/core";
import { toast } from "react-toastify";

import "./PinCellRenderer.scss";

export interface IPinCellRendererParams extends ICellRendererParams {
  readonly?: boolean;
}

export const PinCellRenderer = (params: IPinCellRendererParams) => {
  const { api, data } = params;
  const [busy, setBusy] = useState<boolean>(false);
  const [trigger] = BatchesApi.usePinBatchMutation();

  const setPin = useCallback((id: string, pin: boolean) => {
    setBusy(true);

    return trigger({
      batchId: id,
      pin
    }).unwrap().then(response => {
      setBusy(false);
      return response;
    }, reason => {
      setBusy(false);
      throw reason;
    });
  }, [trigger, setBusy]);

  const onClick = useCallback(() => {
    if (busy) { return; }

    const { id, schedulePinned: cached_pin } = data;
    setPin(id, !cached_pin).then(response => {
      const node = api.getRowNode(response.id);
      node?.updateData({ ...node?.data, schedulePinned: response.schedulePinned });
      api.refreshServerSide({ purge: true }); // gotta re-sort everything
    }, reason => {
      toast.error(reason?.message || JSON.stringify(reason));
      Sentry.captureException(reason);
    });
  }, [api, data, busy, setPin]);

  const classNames = [];
  if (params.value) {
    classNames.push("pinned");
  } else {
    classNames.push("not-pinned");
  }

  const pinnedClass = params.value ? "pinned" : "not-pinned";
  const editableClass = params.readonly ? "not-editable" : "editable";

  return (
    <div className={`pin-cell-renderer ${editableClass}`}>
      <Spin spinning={busy} tip="">
        <button
          type="button"
          className={`pin-button ${pinnedClass}`}
          disabled={!!params.readonly}
          onClick={onClick}
        >
          <BsPinAngleFill className={`icon ${pinnedClass}`} />
        </button>
      </Spin>
    </div>
  );
};

export default PinCellRenderer;
