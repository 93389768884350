import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Cascader } from 'antd';
import { AgSetFilterCondition, LocationsApi } from "../../../api/core";
import { IFilterParams } from "ag-grid-community";
import { IFilterReactComp } from "ag-grid-react";
import { DefaultOptionType } from "antd/lib/cascader";

interface LocalDateFilterParams extends IFilterParams {
  useCode?: boolean;
}

export default forwardRef((props: LocalDateFilterParams, ref) => {
  const isInitialRender = useRef(true);

  const [value, setValue] = useState<string[]>([]);

  const filterModel = useMemo<AgSetFilterCondition | null>(() => {
    // see if we can construct a valid condition
    let conditionFilter: AgSetFilterCondition | null = null;
    if (value.length) {
      conditionFilter = {
        filterType: 'set',
        values: value
      };
    }
    return conditionFilter;
  }, [value]);

  const { data, isFetching: loading } = LocationsApi.useGetLocationsQuery({
    limit: 0,
    options: {
      include: ['facilities']
    }
  });

  // convert data into cascader formatted options using either id or code as the key
  const options = useMemo(() => {
    return data?.rows.filter(location => location.facilities?.length).map(location => {
      if (location.facilities?.length === 1) {
        return {
          label: location.facilities[0].name || 'unknown',
          value: props.useCode ? location.facilities[0].code : location.facilities[0].id,
        };
      }
      return {
        label: location.name || 'unknown',
        value: props.useCode ? location.code : location.id,
        children: location.facilities?.map(facility => ({
          label: facility.name,
          value: props.useCode ? facility.code : facility.id
        }))
      };
    }).sort((a, b) => a.label.localeCompare(b.label)) || [];
  }, [data, props.useCode]);

  useImperativeHandle(ref, (): IFilterReactComp => ({
    // todo: implement if we care to support front end filtering
    doesFilterPass(_params) {
      return true;
    },

    isFilterActive() {
      return filterModel !== null;
    },

    getModel(): AgSetFilterCondition | null { 
      return filterModel;
    },

    setModel(model: AgSetFilterCondition | null) {
      if (model?.values) {
        setValue(model.values);
      } else {
        setValue([]);
      }
    }
  }), [filterModel]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    props.filterChangedCallback();
  }, [filterModel, props]);

  const onChange = useCallback((_value: (string | number)[][], selectOptions: DefaultOptionType[][]) => {
    const ids = selectOptions.reduce<(string | number)[]>((acc, current) => {
      // last element in the current path
      const lastElement = current[current.length - 1];
      // if element is a leaf node, use its value
      if (!lastElement.children) {
        acc.push(lastElement.value ?? '');
      }
      else {
        // locations can only be one level deep, so use direct child values
        lastElement.children.forEach(child => {
          acc.push(child.value ?? '');
        });
      }
      return acc;
    }, []);

    // store ids are always strings, but cascader can return numbers
    const stringIds = ids.filter((t): t is string => typeof t === 'string');
    setValue(stringIds);
  }, []);

  // convert simple list of ids to the format cascader expects for value
  const cascaderValue = useMemo(() => {
    if (!value) {
      return [];
    }
    return options.reduce<(string | number)[][]>((acc, current) => {
      if (!current.children) {
        if (current.value && value.includes(current.value)) {
          acc.push([current.value]);
        }
      } else {
        // shenanigans to make TypeScript content there are no null elements
        const childValues = current.children.map(child => child.value).filter((element): element is string => element !== null);
        // if all children are included, use just the parent value
        if (childValues.every(v => value.includes(v))) {
          acc.push([current.value]);
        } else {
          // if some children are included, use the parent and child value
          // locations can only be one level deep, so no need to dig deeper
          value.filter(v => childValues.includes(v)).forEach(v => {
            acc.push([current.value, v]);
          });
        }
      }
      return acc;
    }, []);
  }, [value, options]);

  // todo: style this to better match ag-grid
  return (
    <>
      <div className="ag-filter-body-wrapper ag-simple-filter-body-wrapper">
        <Cascader
          value={cascaderValue}
          onChange={onChange}
          loading={loading}
          options={options}
          multiple
        />
      </div>
    </>
  );
});
