import {
  PublicClientApplication,
} from "@azure/msal-browser";

const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000/";

export const msalClient = new PublicClientApplication({
  auth: {
    clientId: "51343670-e742-428d-b89f-b7616fd7a34c",
    redirectUri: `${baseUrl}login/callback`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
});

// TODO: integrate authentication token w/ RTK Query requests (the below was used for GraphQL)
/*
export const authLink = setContext(async (_, { headers }) => {
  const account =
    msalClient.getActiveAccount() || msalClient.getAllAccounts()[0];
  const request = {
    scopes: ["User.Read"],
    account,
  };

  try {
    const token = await msalClient.acquireTokenSilent(request);

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token?.idToken}` : "",
      },
    };
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      msalClient.acquireTokenPopup(request);
    }
  }

  return { headers };
});
*/

export * from "./Authenticated";
export * from "./Button";
export * from "./Provider";
