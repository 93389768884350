import React, { useRef, useEffect, useState } from "react";
import { Tree, Row, Col } from "antd";
import { registerInteraction } from "@antv/g2";
import { Column as AntColumn } from "@ant-design/charts";
import { isEmpty } from "lodash";

const filterSeries = (
  series: any,
  values: string[] | null,
  seriesField: string
) =>
  series
    .slice()
    .filter(
      (item: any) => values == null || values.includes(item[seriesField])
    );

export const Column = (props: any) => {
  const { data, seriesField } = props;
  const [series, setSeries] = useState(data);
  const [allKeys, setAllKeys] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<any>([]);
  const [activeKeys, setActiveKeys] = useState<any>(null);
  const [legend, setLegend] = useState<any>(null);
  const chartRef = useRef<any>();

  useEffect(() => {
    if (data) {
      const filtered = filterSeries(data, activeKeys, seriesField);
      setSeries(filtered);
    }
  }, [activeKeys, data, seriesField]);

  registerInteraction("element-click-filter", {
    start: [
      {
        trigger: "element:click",
        action: (context: any) => {
          const field = context.event.data.data[seriesField];
          setActiveKeys([field]);
        },
      },
    ],
  });

  const clearStates = () => {
    const chart = chartRef?.current?.getChart();
    if (chart) {
      chart.setState("active", () => true, false);
      chart.setState("selected", () => true, false);
      chart.setState("inactive", () => true, false);
    }
  };

  useEffect(() => {
    const highlight = (fields: any[]) => {
      if (!fields || fields.length === 0) return clearStates();

      const chart = chartRef?.current?.getChart();
      if (chart) {
        chart.setState(
          "active",
          (item: any) => fields.includes(item[seriesField]),
          true
        );
        chart.setState(
          "inactive",
          (item: any) => fields.includes(item[seriesField]),
          false
        );
        chart.setState(
          "active",
          (item: any) => !fields.includes(item[seriesField]),
          false
        );
        chart.setState(
          "inactive",
          (item: any) => !fields.includes(item[seriesField]),
          true
        );
      }

      return true;
    };

    highlight(selectedKeys);
  }, [seriesField, selectedKeys]);

  useEffect(() => {
    if (data)
      setAllKeys(
        Array.from(new Set<string>(data.map((item: any) => item[seriesField])))
      );
  }, [data, seriesField]);

  useEffect(() => {
    if (allKeys?.length > 0) {
      const treeData = [
        {
          key: "all",
          title: "all",
          children: allKeys.map((field: any) => ({
            key: field,
            title: field,
            children: [],
          })),
        },
      ];

      const fieldsTree = (
        <Tree
          checkable
          defaultExpandAll
          checkedKeys={activeKeys || allKeys}
          onSelect={(selected) => setSelectedKeys(selected)}
          onCheck={(checked) => setActiveKeys(checked)}
          treeData={treeData}
        />
      );

      setLegend(fieldsTree);
    }
  }, [data, allKeys, activeKeys, selectedKeys, seriesField]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const chart = !isEmpty(series) && (
    <AntColumn
      ref={chartRef}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      data={series}
      legend={false}
      interactions={[
        { type: "element-link" },
        { type: "element-click-filter" },
        { type: "element-highlight-by-color" },
      ]}
    />
  );

  return (
    <Row style={{ height: "100%" }}>
      <Col span={4}>{legend}</Col>
      <Col span={20}>{chart}</Col>
    </Row>
  );
};

Column.defaultProps = {
  seriesField: "group",
};
