import React, { useCallback, useMemo } from "react";
import { AgDateFilter, AgSetFilterCondition, AgTextFilterCondition, Batch } from "../../../api/core";
import { useSetFilterModelPlugin } from "../../DataGrid/plugins";
import { useLocation } from "react-router-dom";
import { BatchDataGrid } from "../../Batch";
import { ColDef, FirstDataRenderedEvent, GridOptions, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import LocalDateFilter from "../../DataGrid/filters/LocalDateFilter";
import { formatTwoDigitDate } from "../../../helpers";
import FacilityFilter from "../../DataGrid/filters/FacilityFilter";

export interface BatchReportDetailsFilterModel {
  'scheduleFacility.id'?: AgSetFilterCondition,
  'scheduleFacility.code'? : AgSetFilterCondition,
  'lastConfirmPullUserId'? : AgTextFilterCondition,
  'lastConfirmPullAt'?: AgDateFilter
}

export interface BatchReportTableProps {
  filterModel?: BatchReportDetailsFilterModel
}

export const BatchReportTable = (props : BatchReportTableProps) => {
  const state = useLocation().state as BatchReportDetailsFilterModel; 
  const filterModel: BatchReportDetailsFilterModel = { ...state, ...props.filterModel };
  const setFilterModelPlugin = useSetFilterModelPlugin({ filterModel });

  const dateValueGetter = (params : ValueGetterParams<Batch>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const date = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    return date ? formatTwoDigitDate(date, params.data?.scheduleFacility?.timezone || 'UTC') : null;
  };

  const upperCaseFormatter = useCallback((params: ValueFormatterParams<Batch, string>) => {
    return params.value?.toLocaleUpperCase();
  }, []);

  const defaultColDef: ColDef<Batch> = useMemo(() => ({
    sortable: true,
    cellStyle: (_params: any): any => ({
      border: '1px solid rgba(0, 0, 0, .1)', textAlign: 'left', fontWeight: 'bold'
    })
  }), []);

  const columnDefs: ColDef<Batch>[] = useMemo(() => {
    return [{
      field: 'ziftId',
      headerName: 'Batch ID',
      width: 250,
      sort: 'asc'
    }, {
      field: 'stores.0.name', // in practice, there is only one store per batch
      headerName: 'Store',
      width: 250,
    }, {
      field: 'scheduleFacility.name',
      headerName: 'Facility',
      width: 250,
    }, {
      field: 'itemsCount',
      headerName: 'Physical Units',
      width: 250
    }, {
      field: 'lastConfirmPullUserId',
      headerName: 'Pulled By User',
      width: 100,
      filter: 'agTextColumnFilter',
      valueFormatter: upperCaseFormatter
    }, {
      field: 'lastConfirmPullAt',
      headerName: 'Pulled At',
      valueGetter: dateValueGetter,
      width: 125,
      cellClass: 'dateFormat',
      filter: LocalDateFilter,
      filterParams: {
        timezoneField: 'schedule_facility.timezone'
      }
    }, {
      field: 'scheduleFacility.id',
      headerName: 'Facility Id',
      hide: true,
      filter: FacilityFilter,
    }, {
      field: 'scheduleFacility.code',
      headerName: 'Facility Code',
      hide: true,
      filter: FacilityFilter,
      filterParams: {
        useCode: true
      }
    }];
  }, [upperCaseFormatter]);

  const gridOptions: GridOptions<Batch> = useMemo(() => ({
    defaultColDef,
    columnDefs,
    getRowStyle: (params) => {
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      } 
      return { background: '#E2EFDA' }; 
    },
    animateRows: true,
    enableCellChangeFlash: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.columnApi.autoSizeAllColumns();
    }
  }), [columnDefs, defaultColDef]);

  return (
    <>
      <BatchDataGrid
        gridOptions={gridOptions}
        plugins={[setFilterModelPlugin]}
        include={['stores', 'schedule_facility']}
      />
    </>
  );
};
