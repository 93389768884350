import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { PendingPrintTable } from "../../../components/DemandReport";

export const PendingPrintReportPage = () => {

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <PendingPrintTable />
    </ErrorBoundary>
  );
};
