import { 
  AgSortOrder,
  AgDateFilter, 
  AgNumberFilter, 
  AgSetFilter, 
  AgTextFilter, 
  AgTextOrSetFilter, 
  AgQueryResponse,
  AgGridQueryArgs,
  AgGridQueryArgs_Options
} from "../AgGrid";
import { AliasMap } from "../utils";
import { LineItem, deserializeLineItemsArray } from "./common";
import deserialize, { DeserializationSchema } from "../utils/deserialize";

export interface LineItemFilterInputV2 {
  id?: AgTextFilter | null;
  waypoint?: AgSetFilter | null;
  status?: AgSetFilter | null;
  minProgress?: AgNumberFilter | null;
  maxProgress?: AgNumberFilter | null;
  orderStatus?: AgSetFilter | null;
  batchId?: AgTextFilter | null;
  blankId?: AgTextFilter | null;
  ziftId?: AgTextOrSetFilter | null;
  blank?: LineItemFilterV2_Blank | null;
  order?: LineItemFilterV2_Order | null;
  store?: LineItemFilterV2_Store | null;
  binId?: AgTextFilter | null;
  updatedAt?: AgDateFilter | null;
  createdAt?: AgDateFilter | null;
  lastScanAt?: AgDateFilter | null;
}

export interface LineItemFilterV2_Batch {
  batchId?: AgTextFilter | null;
}

export interface LineItemFilterV2_Blank {
  fingerprint?: AgTextFilter | null;
}

export interface LineItemFilterV2_Order {
  ziftId?: AgTextFilter | null;
  shipBy?: AgDateFilter | null;
  status?: AgSetFilter | null;
  facilityId?: AgSetFilter | AgTextFilter | null;
  storeId?: AgSetFilter | AgTextFilter | null;
}

export interface LineItemFilterV2_Store {
  name?: AgTextOrSetFilter | null;
}

export interface LineItemSortV2 {
  id?: AgSortOrder | null;
  createdAt?: AgSortOrder | null;
  updatedAt?: AgSortOrder | null;
  ziftId?: AgSortOrder | null;
  status?: AgSortOrder | null;
  progress?: AgSortOrder | null;
  lastScanAt?: AgSortOrder | null;
  orderShipBy?: AgSortOrder | null;
  compoundPriority?: AgSortOrder | null;
  blank?: LineItemSortV2_Blank | null;
  order?: LineItemSortV2_Order | null;
  store?: LineItemSortV2_Store | null;
}

export interface LineItemSortV2_Blank {
  fingerprint?: AgSortOrder | null;
}

export interface LineItemSortV2_Order {
  ziftId?: AgSortOrder | null;
  shipBy: AgSortOrder | null;
}

export interface LineItemSortV2_Store {
  name?: AgSortOrder | null;
}

export interface GetLineItems extends AgQueryResponse<LineItem> {}

export const GetLineItemsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeLineItemsArray
});

export const deserializeGetLineItems = deserialize<GetLineItems>(GetLineItemsDeserializationSchema);

// associated tables (some virtual) that can optionally be included for each row
export type GetLineItems_Include = "order" | "store" | "blank" | "stats" | "bin" | "facility" | "batch" | "bin_section" | "bin_facility";
export interface GetLineItemsVariables_Options extends AgGridQueryArgs_Options {
  include?: GetLineItems_Include[] | null | undefined;
}

export interface GetLineItemsVariables 
  extends AgGridQueryArgs<GetLineItemsVariables_Options, LineItemFilterInputV2> {}

// Used to process an input Filter.Sort directly from the AgGrid component into the format
// expected by the core service
export const GetLineItems_Input_AliasMap: AliasMap = {
  /*store: {
    '': 'stores',
  },
  order: {
    '': 'order',
  },
  blank: {
    '': 'blanks',
  }*/
};
