// NOTE: lodash's snakeCase method strips out any and all special characters
// like periods. There is no configuration option to prevent this as of v4 of lodash. 
// As such, I have written the below toSnakeCase method where we can override the isLetter logic.

export interface ToSnakeCaseOptions {
  // determines if a character should be treated as a letter, and thus part of a word
  // The algorithm breaks a string into words that are concatenated by underscores.
  isLetter: (character: string) => boolean;

  // determines if the letter should be the start of a new word, even if in the source string
  // the letter is immediately preceded by another letter. By default, any capitalized letter
  // will serve as the start of a new word.
  preWordBreak: (letter: string) => boolean;

  // determines if the letter should be the final letter of the current word, even if in the
  // source string the letter is immediately proceeded by another letter.
  postWordBreak: (letter: string) => boolean;
}

/**
 * By default, only alpha-numeric ASCII characters are considered to be letters
 * @param str 
 * @param index 
 */
export function toSnakeCase_isLetter(c: string) {
  return ('0' <= c && c <= '9') || ('A' <= c && c <= 'Z') || ('a' <= c && c <= 'z');
}

export function toSnakeCase_preWordBreak(c: string) {
  return ('A' <= c && c <= 'Z');
}

export function toSnakeCase_postWordBreak(c: string) {
  return false;
}

export function toSnakeCase(str: string, options?: Partial<ToSnakeCaseOptions>) {
  const { isLetter, preWordBreak, postWordBreak } = {
    isLetter: toSnakeCase_isLetter,
    preWordBreak: toSnakeCase_preWordBreak,
    postWordBreak: toSnakeCase_postWordBreak,
    ...options
  } as ToSnakeCaseOptions;
  
  let dest = "";
  let c = "";
  for (let srcNdx = 0; srcNdx < str.length; srcNdx++) {
    c = str[srcNdx];
    if (!isLetter(c)) { continue; }

    dest += dest.length ? "_" : "";
    dest += c.toLowerCase();
    if (postWordBreak(c)) { continue; }

    for (srcNdx++; srcNdx < str.length; srcNdx++) {
      c = str[srcNdx];
      if (!isLetter(c)) { break; }
      if (preWordBreak(c)) { srcNdx--; break; } // treat as a separate word
      dest += c.toLowerCase();
    }
  }

  return dest;
}

export default toSnakeCase;
