import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { DataGrid } from "../../components/DataGrid";
import { useGridStateSearchParamPlugin } from "../../components/DataGrid/plugins";
import ScansColDefs from "./ScansColDefs";
import useScansServerSideDatasource from "./useScansServerSideDatasource";

export const ScansDataGridPage = () => {
  const gridStateExtension = useGridStateSearchParamPlugin();
  const datasource = useScansServerSideDatasource();

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <DataGrid
        gridOptions={{ 
          columnDefs: ScansColDefs,  
          getRowId: (params) => params.data.id,
          serverSideInfiniteScroll: true,
          blockLoadDebounceMillis: 100,
          serverSideDatasource: datasource
        }}
        plugins={[gridStateExtension]}
      />
    </ErrorBoundary>
  );
};
