import { Batch, deserializeBatch } from "./Batch";
import { BatchEvent } from "./enums";

export type TransitionBatchEvent = Batch;

export interface TransitionBatchEventVariables {
  id: string;
  event: BatchEvent;
  initial?: string | null;
  pullBox?: string | null;
}

export const deserializeTransitionBatchEvent = deserializeBatch;
