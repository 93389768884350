import React, { useMemo } from "react";
import StoreDataGrid from "../../../components/Store/DataGrid";
import { IDataGridPlugin, useGridStateSearchParamPlugin } from "../../../components/DataGrid/plugins";

export const StoreManagePage = () => {
  const gridStateExtension = useGridStateSearchParamPlugin();
  const plugins:IDataGridPlugin[] = useMemo(() => [gridStateExtension], [gridStateExtension]);

  return (
    <StoreDataGrid plugins={plugins} />
  );
};
