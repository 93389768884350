import { BaseBatch, deserializeBaseBatchArray } from "../Batches/BaseBatch";
import { Facility, deserializeFacility } from "../Facilities";
import { Store, deserializeStoresArray } from "../Stores";
import { createSearchParams } from "react-router-dom";
import { isDate, isString } from "lodash";
import { DeserializationFunc, DeserializationSchema, deserialize, deserializeArrayOf } from "../utils/deserialize";
import deserializeDate from "../utils/deserializeDate";

// { id: string } | { facilityId: string, date: Date } | { facilityCode: string, date: Date};
export interface ScheduleIdentifier {
  id?: string;
  facilityId?: string;
  facilityCode?: string;
  date?: Date; 
}

function hasId(si: ScheduleIdentifier): si is { id: string } {
  return isString((si as {id:string}).id);
}

function hasFacilityIdAndDate(si: ScheduleIdentifier): si is { facilityId: string, date: Date } {
  const t = si as { facilityId: string, date: Date };
  return isString(t.facilityId) && isDate(t.date);
}

function hasFacilityCodeAndDate(si: ScheduleIdentifier): si is { facilityCode: string, date: Date } {
  const t = si as { facilityCode: string, date: Date };
  return isString(t.facilityCode) && isDate(t.date);
}

export const createScheduleIdentifierSearchParams = (identifier: ScheduleIdentifier) => {
  const sp = createSearchParams();
  if (hasId(identifier)) {
    sp.append('id', identifier.id);
  } else if (hasFacilityIdAndDate(identifier)) {
    sp.append('facility_id', identifier.facilityId);
    sp.append('date', identifier.date.toISOString().split("T")[0]);
  } else if (hasFacilityCodeAndDate(identifier)) {
    sp.append('facility_code', identifier.facilityCode);
    sp.append('date', identifier.date.toISOString().split("T")[0]);
  }
  return sp;
};

export interface Schedule {
  id: string;
  date: Date;
  facilityId: string;
  createdAt: Date;
  updatedAt: Date;
  scheduleStats?: ScheduleStats; // must include "line_item_stats"
  batches?: BaseBatch[]; // must include "batches"
  facility?: Facility; // must include "facilities" in the request
  stores?: Store[]; // must include "stores" (currently only returns the id - TO DO: Make dynamic selections possible)
}

export const scheduleDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  date: deserializeDate,
  createdAt: deserializeDate,
  updatedAt: deserializeDate,
  batches: deserializeBaseBatchArray,
  facility: deserializeFacility,
  stores: deserializeStoresArray,
});

export const deserializeSchedule:DeserializationFunc<Schedule> = 
  deserialize<Schedule>(scheduleDeserializationSchema, "[schedule]");

export const deserializeScheduleArray = 
  deserializeArrayOf<Schedule>(scheduleDeserializationSchema, "[schedules]");

export interface Schedule_stats {
  lineItems: number;
  batches: number;
  byStore: Schedule_stats_byStore[];
}

export interface ScheduleStats {
  schedule_id?: string | null;
  progress: number; // floating point between 0.0 and 1.0
  lineItemsCount: number;
  completedLineItemsCount: number;
  batchesCount: number;
  completedBatchesCount: number;
  printsCount: number;
  completedPrintsCount: number;
}

export const EmptyScheduleStatus: Readonly<ScheduleStats> = Object.freeze({
  progress: 0,
  lineItemsCount: 0,
  completedBatchesCount: 0,
  batchesCount: 0,
  completedLineItemsCount: 0,
  printsCount: 0,
  completedPrintsCount: 0
});

export interface Schedule_stats_byStore {
  storeId: string;
  lineItems: number;
  completedLineItems: number;
}
