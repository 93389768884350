import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";
import { DeserializationFunc } from "./deserialize";
import { camelCaseKeys } from "./camelCaseKeys";

export function transformResponse<TResponse, TVariables>(deserializer: DeserializationFunc<TResponse>) {
  return (serverResponse: any, meta: FetchBaseQueryMeta | undefined, args: TVariables) => {
    return deserializer(camelCaseKeys(serverResponse));
  };
}

export default transformResponse;
