import CoreApi from '../CoreApi';
import { transformResponse, transformErrorResponse, snakeCaseKeys } from '../utils';
import { GetMetrics, GetMetricsVariables, deserializeGetMetrics } from './GetMetrics';

// Extend the CoreApi with /metrics endpoints
export const MetricsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({

    getMetrics: builder.query<GetMetrics, GetMetricsVariables>({
      query: (params) => ({
        url: '/metrics',
        method: "GET",
        params: snakeCaseKeys({
          ...params,
          from: params.from?.toISOString(),
          to: params.to?.toISOString()
        })
      }),
      transformResponse: transformResponse(deserializeGetMetrics),
      transformErrorResponse: transformErrorResponse("getMetrics"),
    }),

  }),
});

export default MetricsApi;
