import { BatchUnderConstructionEventType } from "./BatchUnderConstructionEventType";
import { ILineItemData } from "./ILineItemData";

export interface BatchUnderConstructionItemsChangedEventArgs {
  addedItems: ReadonlyArray<ILineItemData>;
  removedItems: ReadonlyArray<ILineItemData>;
}

export type BatchUnderConstructionItemsChangedEvent = 
CustomEvent<BatchUnderConstructionItemsChangedEventArgs>;

const __type: BatchUnderConstructionEventType = "items-changed";
export const createBatchUnderConstructionItemsChangedEvent = 
  (removedItems: ReadonlyArray<ILineItemData>, addedItems: ReadonlyArray<ILineItemData>) => {
    return new CustomEvent<BatchUnderConstructionItemsChangedEventArgs>(__type, { detail: {
      removedItems, addedItems
    } });
  };

export type BatchUnderConstructionItemsChangedEventListener = 
  (e: BatchUnderConstructionItemsChangedEvent) => void;

export default BatchUnderConstructionItemsChangedEvent;
