import React from "react";
import { SetURLSearchParams, URLSearchParamsInit, createSearchParams, useLocation, useNavigate } from "react-router-dom";

/**
 * This is a copy-modified version of the useSearchParams function form the react-router-dom library
 * This is a temporary work-around that avoids creating a new setSearchParams function reference 
 * everytime the searchParams themselves change... Once this is fixed in the main library, go ahead
 * and remove this function.
 * 
 * TO DO: Add a unit test that executes against the react-router-dom library to check for this fix.
 * Once the test starts 'failing' due to the reference not changing, time to remove this local impl.
 * 
 * See https://github.com/remix-run/react-router/discussions/9851
 * PR to fix this: https://github.com/remix-run/react-router/pull/10740
 * 
 * @param defaultInit 
 * @returns 
 */
export function useSearchParams(
  defaultInit?: URLSearchParamsInit
): [URLSearchParams, SetURLSearchParams] {
  const defaultSearchParamsRef = React.useRef(createSearchParams(defaultInit));
  const hasSetSearchParamsRef = React.useRef(false);
  const searchParamsRef = React.useRef<URLSearchParams>(defaultSearchParamsRef.current);

  const location = useLocation();
  const searchParams = React.useMemo(() => {
    const searchParams = createSearchParams(location.search);

    if (!hasSetSearchParamsRef.current) {
      const keys = defaultSearchParamsRef.current.keys();
      let it = keys.next();
      while (!it.done) {
        const key = it.value;
        if (!searchParams.has(key)) {
          defaultSearchParamsRef.current.getAll(key).forEach((value) => {
            searchParams.append(key, value);
          });
        }
        it = keys.next();
      }
    }

    searchParamsRef.current = searchParams; 
    return searchParams;
  }, [location.search]);

  const navigate = useNavigate();
  const setSearchParams = React.useCallback<SetURLSearchParams>(
    (nextInit, navigateOpts) => {
      const newSearchParams = createSearchParams(
        typeof nextInit === "function" ? nextInit(searchParamsRef.current) : nextInit
      );
      hasSetSearchParamsRef.current = true;
      navigate(`?${newSearchParams}`, navigateOpts);
    },
    [navigate]
  );

  return [searchParams, setSearchParams];
}
