import React, { useMemo } from "react";
import { Alert, Button, Form, PageHeaderProps, Progress, Typography } from "antd";
import Search from "antd/lib/input/Search";
import { DateLocalPicker, FacilitySelect, StoreSelect } from "../../../components";
import { testId } from "../../../util/testId";
import ScheduleViewPageSubTitle from "./ScheduleViewPageSubTitle";
import { FloorViewBatch, FloorViewStatus } from ".";
import { NavigateBeforeRounded, NavigateNextRounded } from "../../../fixtures/icons";
import { FloorViewStatusSelect } from "./FloorViewStatusSelect";
import { Schedule } from "../../../api/core/Schedules";
import { DateTime } from "luxon";

export type StoresType = (string)[] | null | undefined;
export type SearchType = string | undefined;

export interface ScheduleViewPageHeaderProps extends Pick<PageHeaderProps, Exclude<keyof PageHeaderProps, "title">> {
  // the batches for display
  batches: Readonly<FloorViewBatch[]>;
  // filter by date
  date: Date;
  // set date
  setDate: (date: Date) => void;
  // filter by facility id or code
  facility?: string;
  // set facility id or code
  setFacility: (facility: string | undefined) => void;
  // a function for filtering which stores we can include in our drop-down to filter by
  includeStore: (storeId: string) => boolean;
  // whether or not the query is loading data from the server for the first time
  loading: boolean;
  // a function that is called when the user wants to perform a search
  onSearch: (ziftId: string, e: any) => void;
  // the schedules retrieved via the last query to the server
  schedule?: Readonly<Partial<Schedule>>;
  // simple status for search
  searchStatus?: boolean;
  // a function to set the status filter
  setStatusFilter: (status: Readonly<FloorViewStatus[]>) => void;
  // a function for setting the stores that we are filtering by
  setStores: (stores: Readonly<StoresType>) => void;
  // batch stats
  stats: Readonly<{
    total: number;
    pulled: number;
    printed: number;
    outOfStock: number;
  }>;
  // current status filter
  statusFilter: Readonly<FloorViewStatus[]>;
  // the list of stores that we are filtering by
  stores: Readonly<StoresType>;
  // The last time that the we polled the server for data
  updatedAt: Date;
}

export const ScheduleViewPageHeader = ({
  batches,
  date,
  setDate,
  facility,
  setFacility,
  includeStore,
  loading,
  onSearch,
  searchStatus,
  setStatusFilter,
  setStores,
  stats,
  statusFilter,
  stores,
  updatedAt,
}: ScheduleViewPageHeaderProps) => {
  
  const statusCounts = useMemo(() => {
    return batches.reduce((prev, curr) => {
      const status = curr.floorViewStatus;
      prev.set(status, (prev.get(status) ?? 0) + 1);
      return prev;
    }, new Map<FloorViewStatus, number>());
  }, [batches]);

  const dateLabel = (
    <>
      <Typography.Text className="schedule-floor-filter-label-text" ellipsis>
        Period
      </Typography.Text>
      <Button
        icon={<NavigateBeforeRounded style={{ fontSize: "22px" }} />}
        onClick={() => setDate(DateTime.fromJSDate(date).minus({ days: 1 }).toJSDate())}
        size="small"
        type="primary"
      />
      <Button
        icon={<NavigateNextRounded style={{ fontSize: "22px" }} />}
        onClick={() => setDate(DateTime.fromJSDate(date).plus({ days: 1 }).toJSDate())}
        size="small"
        type="primary"
      />
    </>
  );

  const searchField = useMemo(() => {
    const label = (
      <>
        Batch ID
        {searchStatus === false && <Alert type="error" showIcon message="No results were found" />}
      </>
    );
    const classNames = ["schedule-floor-filter-ziftId"];
    if (searchStatus === false) {
      classNames.push("schedule-floor-filter-error");
    }
    return (
      <Form.Item label={label} className={classNames.join(" ")}>
        <Search
          allowClear
          data-testid={testId("search")}
          enterButton
          htmlSize={26}
          key="search"
          maxLength={26}
          onSearch={onSearch}
          placeholder="Jump to Batch Id"
          style={{ width: "250px" }}
        />
      </Form.Item>
    );
  }, [onSearch, searchStatus]);

  return (
    <div id="schedule-floor-header">
      <Form id="schedule-floor-filters" layout="vertical">
        <Form.Item label="Facility">
          <FacilitySelect
            onChange={(facility: string) => setFacility(facility)}
            style={{ width: "200px" }}
            useCode
            value={facility}
          />
        </Form.Item>
        <Form.Item label={dateLabel}>
          <DateLocalPicker
            allowClear={false}
            onChange={(value) => setDate(value!)}
            placeholder="Select date"
            style={{ width: "150px" }}
            value={date}
          />
        </Form.Item>
        <Form.Item label="Status" className="schedule-floor-filter-status">
          <FloorViewStatusSelect
            dropdownStyle={{ width: "250px" }}
            onChange={setStatusFilter}
            statusCounts={statusCounts}
            style={{ width: "100%" }}
            value={statusFilter}
          />
        </Form.Item>
        <Form.Item label="Store" className="schedule-floor-filter-store">
          <StoreSelect
            dropdownStyle={{ width: "350px" }}
            includeStore={includeStore}
            key="store-select"
            mode="multiple"
            onChange={setStores}
            placeholder="Filter by Store(s)"
            showSelectAll
            style={{ width: "100%" }}
            value={stores}
          />
        </Form.Item>
        {searchField}
        <Form.Item label="Last Updated" className="schedule-floor-last-updated">
          {ScheduleViewPageSubTitle(loading, updatedAt)}
        </Form.Item>
        {facility && date && (
          <Form.Item>
            <Button
              href={`/reports/pull?facilityId=["${facility}"]&from=${DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")}&to=${DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")}`}
              size="small"
              type="primary"
            >
              Pull Report
            </Button>
          </Form.Item>
        )}
      </Form>

      <div id="schedule-floor-progress" key="stats">
        {Object.values([
          {
            keySuffix: "pulled",
            progressValue: stats.pulled,
            title: "Pulled",
          },
          {
            keySuffix: "printed",
            progressValue: stats.printed,
            title: "Printed",
          },
          {
            keySuffix: "out-of-stock",
            progressValue: stats.outOfStock,
            title: "Out Of Stock",
          },
        ]).map((stat) => (
          <div className="schedule-floor-progress" key={`schedule-floor-progress-${stat.keySuffix}`}>
            <div className="schedule-floor-progress-title">
              <span className="schedule-floor-progress-title-text">
                <strong>{stat.title}</strong> {stat.progressValue} / {stats.total}
              </span>
              <span className="schedule-floor-progress-title-togo">({stats.total - stat.progressValue} to go)</span>
            </div>
            <span className="schedule-floor-progress-bar">
              <Progress percent={Math.ceil((stat.progressValue / stats.total) * 100.0)} />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleViewPageHeader;
