import IBatchUnderConstructionAPI, { BatchUnderConstructionEventListener } from "./IBatchUnderConstructionAPI";
import { createBatchUnderConstructionResetEvent } from "./BatchUnderConstructionResetEvent";
import { createBatchUnderConstructionInitEvent } from "./BatchUnderConstructionInitEvent";
import { BatchUnderConstructionEventType } from "./BatchUnderConstructionEventType";

import BatchUnderConstructionItemsChangedEvent, { 
  createBatchUnderConstructionItemsChangedEvent 
} from "./BatchUnderConstructionItemsChangedEvent";

import ILineItemData from "./ILineItemData";
import * as Sentry from '@sentry/browser';
import _ from "lodash";

const StorageKey = 'buc'; // batch-under-construction;

export interface BatchUnderConstructionState {
  facilityId: string;
  shipByDate: Date;
  lineItems: ILineItemData[];
}

type UpdateStateArg = BatchUnderConstructionState  
  | ((prev: BatchUnderConstructionState | undefined) => BatchUnderConstructionState) 
  | undefined;

export class BatchUnderConstructionAPIImpl implements IBatchUnderConstructionAPI {
  // NOTE: This state must be kept in sync with LocalStorage via events
  private _storageKey: string;

  private _state: BatchUnderConstructionState | undefined;

  private _lineItemMap: { [key: string]: ILineItemData };

  private _eventTarget: EventTarget;

  isInitialized() { return !!this._state; }

  getLineItems() { return this._state?.lineItems.slice(0); }

  getLineItem(ziftId: string) { return this._lineItemMap[ziftId]; }

  getFacilityId() { return this._state?.facilityId; }

  getShipByDate() { return this._state?.shipByDate; }

  private _notifyReset() {
    // NOTE: Errors that occur within event listeners are not propogated back here
    this._eventTarget.dispatchEvent(
      createBatchUnderConstructionResetEvent()
    );
  }

  private _notifyInit() {
    // NOTE: Errors that occur within event listeners are not propogated back here
    this._eventTarget.dispatchEvent(
      createBatchUnderConstructionInitEvent()
    );
  }

  private _notifyItemsChanged(removed: ILineItemData[], added: ILineItemData[]) {
    // NOTE: Errors that occur within event listeners are not propogated back here
    this._eventTarget.dispatchEvent(
      createBatchUnderConstructionItemsChangedEvent(removed, added)
    );
  }

  private _notifyChange(prev: BatchUnderConstructionState | undefined, 
    curr: BatchUnderConstructionState | undefined) 
  {
    if (curr === undefined) {
      this._notifyReset();
    } else if (prev === undefined) {
      this._notifyInit();

      // since different tabs/windows will process broad cast event asynchronously, it should
      // be possible to both initialize AND add items to a batch-under-construction
      if (curr.lineItems.length) {
        this._notifyItemsChanged([], curr.lineItems.slice(0));
      }
    } else {
      // since different tabs/windows will potentially process broad cast events asynchronously,
      // it is possible that another tab/window has reset and re-initialized the batch-under-
      // construction before this one has processed the reset event
      if (prev.facilityId !== curr.facilityId || prev.shipByDate !== curr.shipByDate) {
        this._notifyReset();
        this._notifyInit();
      }

      // detect any removals or additions
      const removed = prev.lineItems
        .filter(li => !curr.lineItems.find(li2 => li2.ziftId === li.ziftId));

      const added = curr.lineItems
        .filter(li => !prev.lineItems.find(li2 => li2.ziftId === li.ziftId));

      if (removed.length || added.length) {
        this._notifyItemsChanged(removed, added);
      }
    }
  }

  // loads the state from a serialized string - such as from local storage or a
  private _loadState(oldData?: string | null, newData?: string | null) {
    const prev: BatchUnderConstructionState | undefined = 
      oldData ? JSON.parse(oldData) : this._state;

    const json = newData || localStorage.getItem(this._storageKey);

    if (json) {
      // TO DO: make use of the second 'reviver' parameter to parse dates
      // Also see if there is a similar parameter for the stringify function
      let updatedState = JSON.parse(json) as BatchUnderConstructionState | undefined;

      if (updatedState && (!updatedState.facilityId || !updatedState!.shipByDate 
        || !updatedState!.lineItems)) {
        Sentry.captureException(new Error("Invalid state in localStorage"));
        updatedState = undefined;
      }

      // JSON.parse doesn't know that we want shipByDate to be a Date object
      if (updatedState) {
        if (_.isString(updatedState.shipByDate)) {
          updatedState.shipByDate = new Date(updatedState.shipByDate);
        }

        for (let i = 0, curr; i < updatedState.lineItems.length; i++) {
          curr = updatedState.lineItems[i];
          if (_.isString(curr.shipByDate)) {
            curr.shipByDate = new Date(curr.shipByDate);
          }
        }
      }

      this._state = updatedState;
    } else {
      this._state = undefined;
    }

    this._notifyChange(prev, this._state);
  }

  private _updateState(stateArg: UpdateStateArg) {
    const prev = this._state;
    try {
      const newState = _.isFunction(stateArg) ? stateArg(this._state) : stateArg;
      if (newState) {
        localStorage.setItem(this._storageKey, JSON.stringify(newState));
      } else {
        localStorage.removeItem(this._storageKey);
      }
      
      this._state = newState; 
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }

    this._notifyChange(prev, this._state);
  }

  /* eslint-disable @typescript-eslint/no-useless-constructor */
  constructor(storageKey: string = StorageKey) {
    this._storageKey = storageKey;
    this._eventTarget = new EventTarget();
    this.onStorageEvent = this.onStorageEvent.bind(this);
    window.addEventListener('storage', this.onStorageEvent as unknown as EventListener);
    this._loadState();

    // maintain a map to lookup line items by ziftId quickly
    this._lineItemMap = {};
    this.addEventListener('reset', () => { this._lineItemMap = {}; });
    this.addEventListener('items-changed', (e:BatchUnderConstructionItemsChangedEvent) => {
      e.detail.removedItems.forEach(_ => { delete this._lineItemMap[_.ziftId]; });
      e.detail.addedItems.forEach(_ => { this._lineItemMap[_.ziftId] = _; });
    });
  }

  private onStorageEvent(e: StorageEvent): any {
    if (e.key === this._storageKey) {
      this._loadState(e.oldValue, e.newValue);
    }
  }

  reset() {
    this._updateState(undefined);
  }

  init(facilityId: string, shipByDate: Readonly<Date>) {
    if (!facilityId || /^ *$/.test(facilityId)) {
      throw new Error("FacilityId required");
    }
    
    if (!shipByDate) {
      throw new Error("ShipByDate required");
    }

    this.reset();

    this._updateState({
      facilityId,
      shipByDate,
      lineItems: [],
    });
  }

  private _assertInitialized() {
    if (!this.isInitialized()) {
      throw new Error("The batch-under-construction must be initialized to perform this operation");
    }
  }

  add(items: ILineItemData | ILineItemData[]) {
    this._assertInitialized();
    this._updateState(prev => {
      const next: BatchUnderConstructionState = {
        ...prev!,
        lineItems: prev!.lineItems.slice(0)
      };

      if (!Array.isArray(items)) {
        items = [items];
      }

      const newItemMap: { [key: string]: ILineItemData } = {};

      items.forEach(item => {
        if (item.shipByDate > this._state!.shipByDate) {
          throw new Error("Cannot add LineItem with shipByDate that exceeds the batch-under-construction's shipByDate");
        }

        if (this.getLineItem(item.ziftId) || newItemMap[item.ziftId]) {
          // skip adding multiples of any line items
          return;
        }

        const ndx = next.lineItems!.findIndex((curr) => curr.ziftId === item.ziftId);
        if (ndx < 0) {
          next.lineItems!.push({ ...item }); // copy the item - don't use the same reference
        }
      });

      return next;
    });
  }

  remove(ziftIds: string | string[]) {
    this._assertInitialized();
    this._updateState(prev => {
      const next: BatchUnderConstructionState = {
        ...prev!,
        lineItems: prev!.lineItems.slice(0)
      };

      if (!Array.isArray(ziftIds)) {
        ziftIds = [ziftIds];
      }

      ziftIds.forEach(ziftId => {
        const ndx = next.lineItems!.findIndex(i => i.ziftId === ziftId);
        if (ndx >= 0) {
          next.lineItems!.splice(ndx, 1);
        }
      });

      return next;
    });
  }

  async submit() {
    this._assertInitialized();
    const response = Promise.reject(new Error("Submit Not Implemented"));
    return response.then((response) => { this.reset(); return response; });
  }

  addEventListener(type: BatchUnderConstructionEventType, 
    listener: BatchUnderConstructionEventListener, 
    options?: boolean | EventListenerOptions | undefined) 
  {
    this._eventTarget.addEventListener(type, listener as unknown as EventListener, options);
  }

  removeEventListener(type: BatchUnderConstructionEventType, 
    listener: BatchUnderConstructionEventListener, 
    options?: boolean | EventListenerOptions | undefined)
  {
    this._eventTarget.removeEventListener(type, listener as unknown as EventListener, options);
  }
}

export default BatchUnderConstructionAPIImpl;
