import { QueryParamConfig, QueryParamConfigFactory } from "./QueryParamConfig";
import { 
  arrayParamSerialize, arrayParamDeserialize, booleanParamSerialize, booleanParamDeserialize, 
  dateParamSerialize, dateParamDeserialize, dateTimeParamSerialize, dateTimeParamDeserialize, 
  jsonParamSerialize, jsonParamDeserialize, objectParamSerialize, objectParamDeserialize, 
  numberParamSerialize, numberParamDeserialize, stringParamSerialize, stringParamDeserialize, 
  dateLocalParamSerialize, dateLocalParamDeserialize, stringArrayParamSerialize, stringArrayParamDeserialize 
} from "./Serialize";

export const ArrayParam: Readonly<QueryParamConfig<(string | null)[] | null | undefined>> = 
  QueryParamConfigFactory(arrayParamSerialize, arrayParamDeserialize);

export const BooleanParam: Readonly<QueryParamConfig<boolean | null | undefined>> = 
  QueryParamConfigFactory(booleanParamSerialize, booleanParamDeserialize);

export const DateParam: Readonly<QueryParamConfig<Date | null | undefined>> =
  QueryParamConfigFactory(dateParamSerialize, dateParamDeserialize);

export const DateLocalParam: Readonly<QueryParamConfig<Date | null | undefined>> =
  QueryParamConfigFactory(dateLocalParamSerialize, dateLocalParamDeserialize);

export const DateTimeParam: Readonly<QueryParamConfig<Date | null | undefined>> =
  QueryParamConfigFactory(dateTimeParamSerialize, dateTimeParamDeserialize);

export const JsonParam: Readonly<QueryParamConfig<any | null | undefined>> =
  QueryParamConfigFactory(jsonParamSerialize, jsonParamDeserialize);

export const ObjectParam: 
  Readonly<QueryParamConfig<{[key: string]: string | null} | null | undefined>> =
  QueryParamConfigFactory(objectParamSerialize, objectParamDeserialize);

export const NumberParam: Readonly<QueryParamConfig<number | null | undefined>> =
  QueryParamConfigFactory(numberParamSerialize, numberParamDeserialize);

export const StringArrayParam: Readonly<QueryParamConfig<string[] | null | undefined>> = 
  QueryParamConfigFactory(stringArrayParamSerialize, stringArrayParamDeserialize);

export const StringParam: Readonly<QueryParamConfig<string | null | undefined>> =
  QueryParamConfigFactory(stringParamSerialize, stringParamDeserialize);
