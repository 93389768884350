import { ColDef, ColGroupDef } from "ag-grid-community";
import { TagCellRenderer } from "../../DataGrid/renderers/TagCellRenderer";
import { DateTimeCellRenderer, RowNumberCellRenderer, TagsCellRenderer } from "../../DataGrid/renderers";

export const defaultColumnDefs: /*ReadonlyArray */ (ColDef | ColGroupDef)[] = [
  {
    headerName: "#",
    sortable: false,
    filter: false,
    floatingFilter: false,
    cellRenderer: RowNumberCellRenderer,
    width: 75,
    pinned: 'left'
  },
  {
    field: 'id',
    hide: true,
    filter: 'text'
  },
  {
    field: 'name',
    filter: 'text'
  },
  {
    field: 'ziftId',
    filter: 'text'
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    filter: 'date',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    filter: 'date',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'priority',
    filter: 'number'
  },
  {
    field: 'color',
    cellRenderer: TagCellRenderer,
    cellRendererParams: {
      color: ({ value }: any) => value,
    }
  }, 
  {
    field: 'tagList',
    cellRenderer: TagsCellRenderer
  }
];

export default defaultColumnDefs;
