import { AgGridQueryArgs, AgGridQueryArgs_Options, AgQueryResponse } from "../AgGrid";
import { DeserializationSchema, deserialize } from "../utils";
import { Printer, deserializePrinterArray } from "./common";

export interface GetPrinters extends AgQueryResponse<Printer> {}

export const GetPrintersDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializePrinterArray
});

export const deserializeGetPrinters = deserialize<GetPrinters>(GetPrintersDeserializationSchema);

export interface PrinterFilterInput {}

export interface GetPrintersVariables extends AgGridQueryArgs<GetPrintersVariables_Options, PrinterFilterInput> {}

export interface GetPrintersVariables_Options extends AgGridQueryArgs_Options {}
