import React from "react";
import { ModalState } from "./ModalState";
import ModalResults from "./ModalResults";
import { ModalEventTarget } from "./ModalEvents";
import BSModal from 'react-bootstrap/Modal';

export type UpdateStateFunc<TModalResults extends ModalResults> = 
  ((prev: ModalState<TModalResults>) => Omit<Partial<ModalState<TModalResults>>, "eventTarget">);

export type UpdateStateArg<TModalResults extends ModalResults> = 
  Omit<Partial<ModalState<TModalResults>>, "eventTarget"> | UpdateStateFunc<TModalResults>;
  
export interface ModalStepProps<TModalResults extends ModalResults,
  TModalState extends ModalState<TModalResults> = ModalState<TModalResults>
  > extends ModalEventTarget {

  /** The current modal state */
  state: Readonly<TModalState>;

  /** Updates the modal state */
  update: (arg: UpdateStateArg<TModalResults>, close?: boolean) => void;

  /** Transitions the modal to the specified step */
  transition: (step: ModalStep<TModalResults, TModalState, ModalStepProps<TModalResults, TModalState>>) => void;

  /** Closes the modal */
  close: () => void;
}

export type ModalStep<TModalResults extends ModalResults, 
  TModalState extends ModalState<TModalResults> = ModalState<TModalResults>,
  P extends ModalStepProps<TModalResults, TModalState> = ModalStepProps<TModalResults, TModalState>> 
  = React.FunctionComponent<P>;

export const ModalStepBody = BSModal.Body;
export const ModalStepFooter = BSModal.Footer;

export default ModalStep;
