import React, { useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { Form, Select, Spin } from "antd";
import styled from "styled-components";
import { DateLocalRangePicker } from "../../DateLocalRangePicker";
import { DateTime } from "luxon";
import { ArrayParam, DateLocalParam, StringParam, useQueryParam, useQueryParams, withDefault } from "../../../api/useQueryParams";
import { LocationFacilitySelect } from "../../Facility";
import { BatchesApi } from "../../../api/core";
import { GetBatchCountsGroupByColumn, GetBatchCountsTimeColumn } from "../../../api/core/Batches/GetBatchCounts";
import { ColDef, FirstDataRenderedEvent, GridOptions, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { Link } from "react-router-dom";
import { BatchReportDetailsFilterModel } from "../BatchTable";

class PullPair implements IPullPair {
  sum: number = 0;

  count: number = 0;
  
  add(other: IPullPair): PullPair {
    const newValue = new PullPair();
    newValue.sum = this.sum + other.sum;
    newValue.count = this.count + other.count;
    return newValue;
  }
}

interface IPullPair {
  count: number;
  sum: number;
}

interface PullAggregateEntry {
  dates: Map<string, PullPair>;
}

interface PullAggregateRow {
  name: string;
  total: PullPair;
  dates: Record<string, PullPair>;
  isTotal: boolean;
}

export const PullReportTable = () => {
  const now = DateTime.now().startOf('day');
  const nowString = now.setLocale("en-US").toLocaleString();

  const defaultStart = now.minus({ days: 6 }).toJSDate();
  const defaultEnd = now.plus({ days: 0 }).toJSDate();

  const [displayMode, setDisplayMode] = useQueryParam("displayMode", withDefault(StringParam, 'sum'));
  const [facilityCode, setFacilityCode] = useQueryParam("facilityCode", ArrayParam);
  const [filter, setFilter] = useQueryParams({
    from: withDefault(DateLocalParam, defaultStart),
    to: withDefault(DateLocalParam, defaultEnd)
  });
  
  const cleanFacilityCode = useMemo(() => {
    const validFacilityCode = facilityCode?.filter((element): element is string => element !== null);
    return validFacilityCode?.length ? validFacilityCode : undefined;
  }, [facilityCode]);

  const updateFilter = (newFilter: any) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      ...newFilter,
    }));
  };

  const handleDateChange = (dates: any) => {
    updateFilter({
      from: dates && dates[0] ? dates[0] : defaultStart,
      to: dates && dates[1] ? dates[1] : defaultEnd
    });
  };

  const pairValueGetter = useCallback((params: ValueGetterParams<PullAggregateRow>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const value = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    if (!value || (value.count === 0 && value.value === 0)) {
      return 0;
    }
    if (displayMode === 'sum') {
      return value.sum as number;
    }
    return value.count as number;
  }, [displayMode]);

  const pairValueFormatter = useCallback((params: ValueFormatterParams<PullAggregateRow, number>) => {
    if (!params.value || params.value === 0) {
      return "-";
    }
    return params.value.toLocaleString();
  }, []);

  const displayDates = useMemo(() => {
    const start = DateTime.fromJSDate(filter.from ?? defaultStart);
    const end = DateTime.fromJSDate(filter.to ?? defaultEnd).plus({ days: 1 });
    const length = end.diff(start, 'days').days;
    return Array.from({ length }, (_, i) => start.plus({ days: i }).toJSDate());
  }, [defaultStart, defaultEnd, filter.from, filter.to]);

  const defaultColDef: ColDef<PullAggregateRow> = useMemo(() => ({
    sortable: true,
    width: 80,
    cellStyle: (_params: any): any => ({
      border: '1px solid rgba(0, 0, 0, .1)', textAlign: 'center', fontWeight: 'bold'
    })
  }), []);

  const columnDefs: ColDef<PullAggregateRow>[] = useMemo(() => {
    return [{
      field: "name",
      headerName: "Pulled By User",
      sort: "asc",
      width: 200,
      cellStyle: (_params: any): any => ({
        textAlign: 'left', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold'
      })
    },
    ...displayDates.map(date => {
      const dateString = date.toLocaleDateString("en-US", { timeZone: 'utc' });
      return { 
        field: `dates.${dateString}`,
        headerName: date.toLocaleDateString("en-US", { timeZone: 'utc', day: 'numeric', month: 'numeric' }),
        valueGetter: pairValueGetter,
        valueFormatter: pairValueFormatter,
        cellRenderer: DatesCellRenderer(date, cleanFacilityCode),
        cellStyle: DatesCellStyle(dateString, nowString)
      };
    }), {
      field: "total",
      headerName: "Total",
      valueGetter: pairValueGetter,
      valueFormatter: pairValueFormatter
    }];
  }, [displayDates, nowString, cleanFacilityCode, pairValueFormatter, pairValueGetter]);

  const utcFrom = useMemo(() => {
    return DateTime.fromJSDate(displayDates[0]).minus({ minutes: displayDates[0].getTimezoneOffset() }).toJSDate();
  }, [displayDates]);

  const utcTo = useMemo(() => {
    // need to sample the adjusted date for timezone offset incase we cross a DST boundary
    const adjustedDate = DateTime.fromJSDate(displayDates.at(-1)!).plus({ days: 1 }).toJSDate();
    return DateTime.fromJSDate(adjustedDate).minus({ minutes: adjustedDate.getTimezoneOffset() }).toJSDate();
  }, [displayDates]);

  const { data, isFetching } = BatchesApi.useGetBatchCountsQuery({
    filter: {
      facilityCode: cleanFacilityCode,
      from: utcFrom,
      to: utcTo
    },
    timeColumn: GetBatchCountsTimeColumn.LastConfirmPullAt,
    groupBy: GetBatchCountsGroupByColumn.LastConfirmPullUserId
  }, {
    refetchOnMountOrArgChange: true
  });

  const rowData = useMemo(() => {
    const dataMap = data?.reduce((accumulator, line) => {
      const rawDate: Date = line.time;
      const stringDate = rawDate.toLocaleDateString("en-US", { timeZone: 'utc' });
      const groupBy = line.data.value;
      let entry = accumulator.get(groupBy);
      if (!entry) {
        entry = { 
          dates: new Map<string, PullPair>()
        };
        accumulator.set(groupBy, entry);
      }

      entry.dates.set(stringDate, (entry.dates.get(stringDate) ?? new PullPair()).add(line));

      return accumulator;
    }, new Map<string, PullAggregateEntry>());

    return Array.from(dataMap?.keys() ?? []).map(key => {
      const dateEntry = dataMap?.get(key);
      const total = Array.from(dateEntry?.dates.values() ?? [])
        .reduce((acc, current) => acc.add(current), new PullPair());
      const row: PullAggregateRow = {
        name: key,
        total,
        dates: Object.fromEntries(dateEntry?.dates ?? []),
        isTotal: false
      };
      return row;
    });
  }, [data]);

  const pinnedBottomRowData = useMemo(() => {
    const dateTotals = new Map(
      displayDates.map(date => [
        date.toLocaleDateString("en-US", { timeZone: 'utc' }), 
        rowData.reduce((acc, current) => 
          acc.add(current.dates[date.toLocaleDateString("en-US", { timeZone: 'utc' })] ?? new PullPair()), new PullPair())
      ])
    );

    const rows = [];

    const row: PullAggregateRow = {
      name: "Total",
      total: rowData.reduce((acc, current) => acc.add(current.total), new PullPair()),
      dates: Object.fromEntries(dateTotals),
      isTotal: true
    };
    rows.push(row);
    return rows;
  }, [displayDates, rowData]);

  const gridOptions: GridOptions<PullAggregateRow> = useMemo(() => ({
    rowData,
    pinnedBottomRowData,
    defaultColDef,
    columnDefs,
    getRowStyle(params) {
      if ((params?.node?.rowPinned)) {
        if (params?.node?.rowIndex === 0) {
          return { background: '#71AF47' };
        }
        return { background: '#97C876' };
      }
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      } 
      return { background: '#E2EFDA' }; 
    },
    animateRows: true,
    enableCellChangeFlash: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.columnApi.autoSizeAllColumns();
    },
  }), [rowData, pinnedBottomRowData, defaultColDef, columnDefs]);
  
  const FilterWrapper = styled.div`
    flex: 0 1 auto;
  `;

  const GridWrapper = styled.div`
    flex: 1 1 auto; 
  `;

  const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
  `;

  return (
    <>
      <Wrapper>
        <FilterWrapper>
          <Form 
            size="small"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            layout="inline"
          >
            <Form.Item label="Facilities" style={{ minWidth: 240 }}>
              <LocationFacilitySelect 
                useCode
                value={cleanFacilityCode}
                onChange={(facilityCode: string[]) => {
                  setFacilityCode(facilityCode);
                }}
              />
            </Form.Item>
            <Form.Item label="Display Mode" style={{ minWidth: 240 }}>
              <Select
                key="display-mode"
                placeholder="Select Display Mode"
                value={displayMode}
                onChange={setDisplayMode}
                options={[{ value: 'count', label: 'Batches' }, { value: 'sum', label: 'Units' }]}
              />
            </Form.Item>
            <Form.Item label="Display Date Range">
              <DateLocalRangePicker
                placeholder={["Start", "End"]}
                allowClear={false}
                allowEmpty={[false, false]}
                value={[filter.from, filter.to] as [any, any]}
                ranges={{
                  "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
                  "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
                }}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Form>
        </FilterWrapper>
        <GridWrapper>
          <Spin spinning={isFetching}>
            <AgGridReact
              className="ag-theme-madengine"
              gridOptions={gridOptions}
            />
          </Spin>
        </GridWrapper>
      </Wrapper>
    </>
  );
};

function DatesCellStyle(dateString: string, nowString: string): (params: any) => any {
  return (params: any): any => {
    if (dateString === nowString && !params?.node?.rowPinned) {
      return { background: 'rgba(0, 0, 0, .1)', textAlign: 'center', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold' };
    }
    return { textAlign: 'center', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold' };
  };
}

function DatesCellRenderer(date: Date, cleanFacilityCode: string[] | undefined) {
  return (c: ICellRendererParams<PullAggregateRow, number>) => {
    if (c.value === undefined || c.value === 0) {
      return '-';
    }
    const filters: BatchReportDetailsFilterModel = {};

    const dateTo = DateTime.fromJSDate(date).plus({ days: 1 }).toJSDate();
    filters.lastConfirmPullAt = {
      filterType: 'date',
      condition1: {
        type: 'inRange',
        dateFrom: date.toISOString(),
        dateTo: dateTo.toISOString(),
      },
      condition2: {
        type: 'notEqual',
        dateFrom: dateTo.toISOString()
      },
      operator: 'AND'
    };

    if (!c.node.data?.isTotal) {
      filters.lastConfirmPullUserId = {
        type: 'equals',
        filter: c.node.data?.name
      };
    } else {
      filters.lastConfirmPullUserId = {
        type: 'notBlank'
      };
    }

    if (cleanFacilityCode) {
      filters['scheduleFacility.code'] = {
        filterType: 'set',
        values: cleanFacilityCode
      };
    }

    return (<Link to="/reports/batch" state={filters}>{c?.valueFormatted}</Link>);
  };
}
