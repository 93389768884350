import React, { forwardRef, useImperativeHandle, useState } from "react";
import { TwitterPicker } from "react-color";

export const ColorEditor = forwardRef(({ value }: any, ref) => {
  const [color = value, setColor] = useState<string>();

  useImperativeHandle(ref, () => ({
    getValue() {
      return color;
    },

    isCancelBeforeStart() {
      return false;
    },

    isCancelAfterEnd() {
      return false;
    },

    isPopup() {
      return true;
    },
  }));

  return (
    <TwitterPicker
      color={color || "#000000"}
      colors={[
        "#D9D9D9",
        "#FFFF66",
        "#FFFFFF",
        "#5E8C31",
        "#FF6900",
        "#FCB900",
        "#7BDCB5",
        "#00D084",
        "#8ED1FC",
        "#0693E3",
        "#ABB8C3",
        "#EB144C",
        "#F78DA7",
        "#9900EF",
      ]}
      onChange={({ hex }) => setColor(hex)}
    />
  );
});
