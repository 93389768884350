import { isArray } from "lodash";
import { depthFirstTraversal, VisitFunction } from "./depthFirstTraversal";
import toSnakeCase, { ToSnakeCaseOptions } from "./toSnakeCase";

export function snakeCaseKeys(root: Readonly<any>, options?: Partial<ToSnakeCaseOptions>) {
  if (root == null) { return root; }

  if (Object(root) !== root) {
    throw Error("Object expected");
  }
  
  const dest:any = isArray(root) ? [] : {};
  const map = new Map<any, any>(); // source => dest node mapping
  map.set(root, dest);

  const visit:VisitFunction = (curr, ctx) => {
    if (curr === root) {
      return;
    } 

    const name = toSnakeCase(ctx.path.at(-1)!, options);
    const srcParent = ctx.visitStack.at(-2);
    const destParent = map.get(srcParent);
    
    if (Object(curr) !== curr) {
      destParent[name] = curr; // if it is a primitive, then just copy by value
    } else if (isArray(curr)) {
      destParent[name] = new Array(curr.length); // to be populated by subsequent visitations
      map.set(curr, destParent[name]);
    } else {
      destParent[name] = {}; // to be populated by subsequent visitations
      map.set(curr, destParent[name]);
    }    
  };
  
  depthFirstTraversal(root, visit);
  return dest;
}
