import React, { ReactNode, Ref, useMemo, useRef } from "react";
import { useHTMLElementRefSize } from "../../api";

export interface SplitPaneProps {
  direction: 'vertical' | 'horizontal', // top-to-bottom and left-to-right
  children: [ReactNode, ReactNode]
}

export const SplitPane = React.forwardRef<HTMLDivElement, SplitPaneProps>(
  (props: SplitPaneProps, ref: Ref<HTMLDivElement>) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const parentSize = useHTMLElementRefSize(parentRef);
    const childRef = useRef<HTMLDivElement>(null);
    const childSize = useHTMLElementRefSize(childRef);

    const parentStyle: React.CSSProperties = useMemo(() => ({
      width: "100%",
      height: "100%",
      margin: "0px",
      padding: "0px"
    }), []);

    const secondChildStyle: React.CSSProperties = React.useMemo(() => {
      if (props.direction === 'horizontal') {
        if (!parentSize.width || !childSize.width) {
          return {};
        }
        return {
          width: `${parentSize.width - childSize.width}px`,
          height: '100%'
        };
      } 
      if (!parentSize.height || !childSize.height) {
        return {};
      }
      return {
        width: '100%',
        height: `${parentSize.height - childSize.height}px`
      };
      
    }, [props.direction, parentSize, childSize]);

    return (
      <div ref={parentRef} style={parentStyle}>
        <div ref={childRef}>{ props.children[0] }</div>
        <div style={secondChildStyle}>{ props.children[1] }</div>
      </div>
    );
  }
);

export default SplitPane;
