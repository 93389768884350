import React, { useMemo } from "react";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import { DatePicker } from "../DatePicker";
import { startOfDayLocal } from "../../helpers";

export const DateLocalPicker = (props: PickerDateProps<Date>) => {
  const cleanValue = useMemo(() => {
    if (props.value === undefined || props.value === null) {
      return props.value;
    }
    return startOfDayLocal(props.value);
  }, [props.value]);

  return (
    <DatePicker
      {...props}
      value={cleanValue}
    />
  );
};
