import React from "react";
import { ICellRendererParams } from "ag-grid-enterprise";

export interface RowNumberCellRendererParams extends ICellRendererParams {
}

export const RowNumberCellRenderer = (params: RowNumberCellRendererParams) => (
  <div>
    {params.rowIndex + 1}
  </div>
);
