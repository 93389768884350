import React from "react";
import "antd/dist/antd.css";
import "@ant-design/pro-card/dist/card.css";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { Authenticated, AuthProvider } from "./components/Auth";
import { IntlProvider } from "react-intl";
import { LicenseManager } from "ag-grid-enterprise";
import { Page } from "./components/Page";
import { store } from './redux/store';
import { Provider } from 'react-redux';

// Set ag-grid enterprice license. I don't consider this a secret :)
LicenseManager.setLicenseKey(
  /* eslint-disable max-len */
  "Using_this_{AG_Grid}_Enterprise_key_{AG-063962}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Mad_Engine_Global}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Mad_Engine_Platform}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Mad_Engine_Platform}_need_to_be_licensed___{Mad_Engine_Platform}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{27_September_2025}____[v3]_[01]_MTc1ODkyNzYwMDAwMA==9fe7a6d42cdb58b66d08a53dc1beab5e"
);

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AuthProvider>
          <IntlProvider locale="en">
            <Router>
              <Routes>
                <Route path="/login/callback" />
                <Route 
                  path="/*" 
                  element={(
                    <Authenticated>
                      <Page />
                    </Authenticated>
                    )}
                />
              </Routes>
            </Router>
          </IntlProvider>
        </AuthProvider>
      </Provider> 
    </React.StrictMode>
  );
}

export default App;
