import { Facility, deserializeFacility } from "../Facilities";
import { LineItem, deserializeLineItem } from "../LineItems";
import deserialize, { DeserializationSchema, deserializeArrayOf } from "../utils/deserialize";
import deserializeDate from "../utils/deserializeDate";

export interface Scan {
  id: string;
  lineItemId?: string | null;
  facilityId?: string | null;
  hostname?: string | null;
  waypoint?: string | null;
  time?: Date;

  facility: Facility;
  LineItem: LineItem;
}

export const ScanDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  time: deserializeDate,
  facility: deserializeFacility,
  lineItem: deserializeLineItem
});

export const deserializeScan = deserialize<Scan>(ScanDeserializationSchema);
export const deserializeScansArray = deserializeArrayOf<Scan>(ScanDeserializationSchema);
