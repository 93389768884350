import CoreApi from '../CoreApi';
import { prepareFilter, prepareSort, transformResponse, transformErrorResponse, snakeCaseKeys, ErrorPayload } from '../utils';
import { GetBatches, GetBatchesVariables, GetBatches_AliasMap, deserializeGetBatches } from './GetBatches';
import { TransitionBatchEvent, TransitionBatchEventVariables, deserializeTransitionBatchEvent } from './TransitionBatchEvent';
import { PinBatch, PinBatchVariables, deserializePinBatch } from './PinBatch';
import { ScheduleBatches, ScheduleBatchesVariables, deserializeScheduleBatches } from './ScheduleBatches';
import { UpdateBatches, UpdateBatchesVariables, deserializeUpdateBatches } from './UpdateBatches';
import { GetBatchStats, GetBatchStatsVariables, deserializeGetBatchStats } from './GetBatchStats';
import { GetBatchCounts, GetBatchCountsVariables, deserializeGetBatchCounts } from './GetBatchCounts';

// Extend the CoreApi with the /batches endpoints
export const BatchesApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getBatches: builder.query<GetBatches, GetBatchesVariables>({
      query: (params) => ({
        url: '/batches',
        method: "POST",
        body: {
          ...snakeCaseKeys(params),
          filter: params?.filter && prepareFilter(params.filter, GetBatches_AliasMap),
          sort: params?.sort && prepareSort(params.sort, GetBatches_AliasMap),
        }
      }),
      // TO DO: normalize the data
      transformResponse: transformResponse(deserializeGetBatches),
      transformErrorResponse: transformErrorResponse("getBatches"),
    }),

    getBatchCounts: builder.query<GetBatchCounts, GetBatchCountsVariables>({
      query: ({ groupBy, timeColumn, filter }) => ({
        url: '/batches/counts',
        method: "GET",
        params: snakeCaseKeys({
          groupBy,
          timeColumn,
          ...(filter && {
            ...filter,
            from: filter.from?.toISOString(),
            to: filter.to?.toISOString(),
          })
        })
      }),
      transformResponse: transformResponse(deserializeGetBatchCounts),
      transformErrorResponse: transformErrorResponse("getBatchCounts"),
    }),

    getBatchStats: builder.query<GetBatchStats, GetBatchStatsVariables>({
      query: (params) => ({
        url: '/batches/stats',
        method: "GET",
        params: snakeCaseKeys({
          ...params,
          from: params.from?.toISOString(),
          to: params.to?.toISOString()
        })
      }),
      transformResponse: transformResponse(deserializeGetBatchStats),
      transformErrorResponse: transformErrorResponse("getBatchStats"),
    }),

    scheduleBatches: builder.mutation<ScheduleBatches, ScheduleBatchesVariables>({
      query: (params) => ({
        url: `/batches/scheduleV2`,
        method: "PATCH",
        body: { 
          ...snakeCaseKeys(params),
          schedule: {
            facility_id: params.schedule.facilityId,
            facility_code: params.schedule.facilityCode,
            date: params.schedule.date?.toISOString().split("T")[0] 
          },
          filter: params?.filter && prepareFilter(params.filter, GetBatches_AliasMap),
        }
      }),
      transformResponse: transformResponse(deserializeScheduleBatches),

      // for this particular request - it is expected that the request fail with warnings
      transformErrorResponse: transformErrorResponse<ScheduleBatchesVariables, ScheduleBatches>(
        "scheduleBatches", deserializeScheduleBatches, { 
          suppress: (ep: ErrorPayload<ScheduleBatches>) => {
            // 403 === Forbidden. This is the expected status when there are unhandled warnings.
            return ep.status === 403 && !!(ep.data?.warnings?.length);
          }
        }),
    }),

    transitionBatchEvent: builder.mutation<TransitionBatchEvent, TransitionBatchEventVariables>({
      query: ({ id, event, initial, pullBox: pull_box }) => ({
        url: `/batches/${id}/${event}`,
        method: "PATCH",
        body: { initial, pull_box }
      }),
      transformResponse: transformResponse(deserializeTransitionBatchEvent),
      transformErrorResponse: transformErrorResponse("transitionBatchEvent"),
    }),

    pinBatch: builder.mutation<PinBatch, PinBatchVariables>({
      query: ({ batchId, pin }) => ({
        url: `/batches/${batchId}/pin`,
        method: "PATCH",
        body: { pin }
      }),
      transformResponse: transformResponse(deserializePinBatch),
      transformErrorResponse: transformErrorResponse("pinBatch"),
    }),

    updateBatches: builder.mutation<UpdateBatches, UpdateBatchesVariables>({
      query: (params) => ({
        url: `/batches`,
        method: "PATCH",
        body: snakeCaseKeys({
          batches: params.batches.map(b => ({
            ...b,
            scheduleDate: b.scheduleDate?.toISOString().split("T")[0] 
          }))
        })
      }),
      transformResponse: transformResponse(deserializeUpdateBatches),
      transformErrorResponse: transformErrorResponse("updateBatches"),
    }),
  }),
});

export default BatchesApi;
