import { URLSearchParams } from "url";
import * as Sentry from "@sentry/browser";

export interface QueryParamConfig<IN, OUT = IN> {
  /** 
   * A function to encode a value and add it to the params under the specified key.
   * If the value is undefined, then the key should be removed from the params.
   */
  encode: (key: string, value: Readonly<IN> | undefined, params: URLSearchParams) => void;
  /**
   * A function to decode an URL encoded string and convert it back to the parameter type T
   * If the key is not found, then it should return undefined
   */
  decode: (key: string, params: URLSearchParams) => OUT | undefined;
}

// NOTE: While convulted... this entire thing is necessary to extract the proper type. Specifically,
// this is necessary so typescript doesn't drop null from the set of possible values... 
// Seems like it maybe a bug. We should return to see if we can't clean this up later, or else
// submit a Typescript bug report.
/* eslint-disable @typescript-eslint/no-unused-vars */
export type QueryParamInputType<T> = 
  T extends QueryParamConfig<infer IN, infer _> 
    ? null extends IN 
      ? IN | undefined
      : T extends Readonly<QueryParamConfig<infer IN, infer _>> 
        ? null extends IN
          ? IN | undefined
          : IN | undefined
        : IN | undefined
    : T extends Readonly<QueryParamConfig<infer IN, infer _>> 
      ? null extends IN
        ? IN | undefined
        : IN | undefined
      : any;

/*
type inputTypeTest1 = QueryParamConfig<number | null>;
type inputParamTypeTest1 = QueryParamInputType<inputTypeTest1>;
type inputTypeTest2 = Readonly<QueryParamConfig<number | null>>;
type inputParamTypeTest2 = QueryParamInputType<inputTypeTest2>;
*/
  
// However, this much simpler expression works for the OUT parameter just fine... wdf???
/* eslint-disable @typescript-eslint/no-unused-vars */
export type QueryParamOutputType<T> =
  T extends Readonly<QueryParamConfig<infer _, infer OUT>> ? OUT | undefined 
  : T extends QueryParamConfig<infer _, infer OUT> ? OUT | undefined : any;

/*
type outputTypeTest1 = QueryParamConfig<number | null>;
type outParamTypeTest1 = QueryParamOutputType<outputTypeTest1>;
type outputTypeTest2 = Readonly<QueryParamConfig<number | null>>;
type outputParamTypeTest2 = QueryParamOutputType<outputTypeTest2>;
*/

export function QueryParamConfigFactory<T>(serialize: (value: T | null) => string,
  deserialize: (data: string) => T | null): QueryParamConfig<T | null | undefined> {

  return ({
    encode: (key: string, value: T | null | undefined, params: URLSearchParams) => {
      if (value === undefined) {
        params.delete(key); 
      } else {
        params.set(key, serialize(value));
      }
    },
    decode: (key: string, params: URLSearchParams) => {
      if (params.has(key)) {
        try {
          return deserialize(params.get(key)!);
        } catch (err) {
          Sentry.captureException(err);
        }
      }      
      return undefined;
    }
  });
}

export default QueryParamConfig;
