import { GridApi } from "ag-grid-community";
import { useCallback, useMemo, useRef } from "react";
import IDataGridPlugin, { IDataGridPluginHook } from "./IDataGridPlugin";

export interface useSetFilterModelPluginParams {
  /** The initial filter model to apply when the grid becomes ready */
  filterModel?: any;
}

export interface ISetFilterModelPlugin extends IDataGridPlugin {
  getFilterModel: () => any;
  setFilterModel: (filterModel:any) => void;
}

export const useSetFilterModelPlugin:
IDataGridPluginHook<ISetFilterModelPlugin, useSetFilterModelPluginParams> = (params) => {
  const apiRef = useRef<GridApi>();

  const getFilterModel = useCallback(() => {
    return apiRef.current?.getFilterModel();
  }, []);

  const setFilterModel = useCallback((filterModel:any) => {
    apiRef.current?.setFilterModel(filterModel);
  }, []);

  const plugin = useMemo<ISetFilterModelPlugin>(() => ({
    getFilterModel,
    setFilterModel,
    onGridReady: (e) => {
      apiRef.current = e.api;
      if (params?.filterModel) {
        setFilterModel(params.filterModel);
      }
    },
  }), [getFilterModel, setFilterModel, params?.filterModel]);

  return plugin;
};

export default useSetFilterModelPlugin;
