import CoreApi from '../CoreApi';
import { transformResponse, transformErrorResponse, snakeCaseKeys } from '../utils';
import { GetBlankStats, GetBlankStatsVariables, deserializeGetBlankStats } from './GetBlankStats';
import { GetBlanks, GetBlanksVariables, deserializeGetBlanks } from './GetBlanks';

// Extend the CoreApi with /blanks endpoints
export const BlanksApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({

    getBlanks: builder.query<GetBlanks, GetBlanksVariables>({
      query: (params) => ({
        method: "GET",
        url: "/blanks",
        params
      }),
      transformResponse: transformResponse(deserializeGetBlanks),
      transformErrorResponse: transformErrorResponse("getBlanks"),
    }),

    getBlankStats: builder.query<GetBlankStats, GetBlankStatsVariables>({
      query: (params) => ({
        method: "GET",
        url: "/blanks/stats",
        params: snakeCaseKeys({
          ...params,
          lineItemFrom: params.lineItemFrom?.toISOString(),
          lineItemTo: params.lineItemTo?.toISOString()
        })
      }),
      transformResponse: transformResponse(deserializeGetBlankStats),
      transformErrorResponse: transformErrorResponse("getBlankStats"),
    }),

  }),
});

export default BlanksApi;
