import React from "react";
import { Icon } from "../../components";

export const PrintConnectRounded = ({ ...props }) => (
  <Icon {...props}>
    <path d="M22.5 15.2c0 .3-.1.5-.3.7l-3.5 3.5c-.2.2-.4.3-.7.3s-.5-.1-.7-.3L15.8 18c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7c.2-.2.4-.3.7-.3s.5.1.7.3l.7.7 2.8-2.8c.2-.2.4-.3.7-.3.3 0 .5.1.7.3.3.2.4.5.4.7zM8 15v4h5.1c.1.4.3.7.4 1 .2.3.4.7.6 1H7c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7v-3H3c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7v-5c0-.8.3-1.6.9-2.1S4.2 8 5 8h1V5c0-.5.2-1 .6-1.4S7.5 3 8 3h8c.5 0 1 .2 1.4.6s.6.9.6 1.4v3h1c.8 0 1.6.3 2.1.9s.9 1.3.9 2.1v.8c-.3-.2-.6-.3-1-.4-.3-.1-.7-.2-1-.2V11c0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3H5c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7v4h2v-1c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h7.5c-.3.3-.5.6-.7.9-.2.3-.4.7-.5 1.1H8zm0-7h8V5H8v3z" />
  </Icon>
);

export const PrintDisabledRounded = ({ ...props }) => (
  <Icon {...props}>
    <path d="M3.5 3.5c-.2-.2-.4-.3-.7-.3s-.5.1-.7.3c-.2.2-.3.5-.3.7s.1.5.3.7L5.2 8H5c-.8 0-1.5.3-2.1.9S2 10.2 2 11v5c0 .3.1.5.3.7.2.2.4.3.7.3h3v2c0 .5.2 1 .6 1.4s.9.6 1.4.6h8c.5 0 1-.2 1.4-.6l.1-.1 1.6 1.6c.2.2.4.3.7.3.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-17-17zM6 13v2H4v-4c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h2.2l3 3H6zm10 6H8v-4h4.2l3.8 3.8v.2zm6-8v5c0 .3-.1.5-.3.7-.2.2-.4.3-.7.3h-1.2l-2-2H20v-4c0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3h-6.2l-2-2H16V5H7.8l-2-2H17c.3 0 .5.1.7.3.2.2.3.4.3.7v4h1c.8 0 1.6.3 2.1.9s.9 1.3.9 2.1zm-4 1.5c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7s.1-.5.3-.7c.2-.2.4-.3.7-.3s.5.1.7.3c.2.2.3.4.3.7s-.1.5-.3.7c-.2.2-.4.3-.7.3z" />
  </Icon>
);
