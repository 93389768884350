import React, { useCallback, useState } from "react";
import { ColumnApi } from "ag-grid-community";
import Modal, { ModalStep, ModalStepProps } from "../../../../Modal";
import MainStep from "./MainStep";
import ModalResults from "./ModalResults";
import ModalState from "./ModalState";

export interface FloorviewSaveSortModalParams {
  open?: boolean;
  close: () => void;
  columnApi: ColumnApi;
  scheduleIds: string[];
}

export const FloorviewSaveSortModal = (params: FloorviewSaveSortModalParams) => {
  const { scheduleIds, columnApi } = params;

  // NOTE: We need to use a factory function here vs just passing in MainStep,
  // else it will confuse MainStep for the factory function!
  const [step, setStep] = useState<ModalStep<ModalResults, ModalState, 
    ModalStepProps<ModalResults, ModalState>>>(() => MainStep);

  const initState = useCallback(():ModalState => {
    return {
      scheduleIds,
      columnApi
    } as ModalState;
  }, [scheduleIds, columnApi]);

  return (
    <Modal<ModalResults, ModalState>
      initState={initState}
      title={(
        <>
          <span className="prefix">Save</span>
          <span className="suffix">Sort Order</span>
        </>
      )}
      open={params.open ?? false}
      close={() => params.close()}
      step={step}
      transition={next => setStep(next)}
    />
  );
};

export default FloorviewSaveSortModal;
