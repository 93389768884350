import { DateTimeResolution } from "../common";
import { DeserializationSchema, deserialize } from "../utils";
import { OrderStats, OrderStatsDeserializationSchema, deserializeOrderStatsArray, } from "./common";

export interface GetOrderStatsVariables {
  from?: Date | null,
  to?: Date | null,
  timezone?: string | null,
  storeId?: string | null,
  seriesResolution?: DateTimeResolution | null,
}

export interface GetOrderStats extends OrderStats {
  seriesResolution: DateTimeResolution;
  series?: OrderStats[] | null;
}

export const GetOrderStatsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({ 
  ...OrderStatsDeserializationSchema,
  series: deserializeOrderStatsArray  
});

export const deserializeGetOrderStats = 
  deserialize<GetOrderStats>(GetOrderStatsDeserializationSchema);
