import { Menu } from 'antd';
import React, { useCallback, useState, useEffect, HTMLAttributes } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { MenuClickEventHandler, MenuInfo } from 'rc-menu/lib/interface';
import { ItemType } from "antd/lib/menu/hooks/useItems";
import Logo from './Logo';
import items from './items';
import { Link } from 'react-router-dom';
import { ThemeColorModeToggle } from '../ThemeColorModeToggle';

const pathSegment = /\/[^/?]*/g;

export interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {
}

export const PageHeader = (props: PageHeaderProps) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let key = '';
    let curr: ItemType[] = items;
    let prev: any;
    const parts = location.pathname.match(pathSegment) || [];

    for (let i = 0; i < parts?.length; i++) {
      const nextKey = key + parts[i];
      const ndx = curr.findIndex((item) => item?.key === nextKey);
      if (ndx === -1) { break; }
      key = nextKey;
      prev = curr[ndx];
      if (!prev.children) { break; }
      curr = prev.children;
    }
    setSelectedKeys([key]);
  }, [location]);

  const onMenuClick: MenuClickEventHandler = useCallback((e : MenuInfo) => {
    if (e.key === '/logout') {
      return; // the AuthButton component will handle logging out
    }
    navigate(e.key);
  }, [navigate]);

  return (
    <div id="mad-page-header" {...props}>
      <Link id="mad-nav-menu-logo" to="/">
        <Logo />
      </Link>
      <div id="mad-nav-menu-wrapper">
        <Menu 
          id="mad-nav-menu" 
          mode="horizontal" 
          //disabledOverflow
          items={items} 
          onClick={onMenuClick} 
          selectedKeys={selectedKeys}
        />
      </div>
      <ThemeColorModeToggle />
    </div>
  );  
};

export default PageHeader;
