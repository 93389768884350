import React, { useMemo } from "react";
import { DataGrid, DataGridProps } from "../../DataGrid";
import defaultColumnDefinitions from "./defaultColumnDefinitions";
import { GetRowIdParams, GridOptions } from "ag-grid-enterprise";
import useOrdersDatasource from "./useOrdersDatasource";
import { Order } from "../../../api/core";
import defaultColDef from "../../DataGrid/defaultColDef";
import { isArray } from "lodash";

export interface OrdersDataGridProps extends DataGridProps {
}

const OrdersDataGrid = (props: OrdersDataGridProps) => {
  const { plugins } = props;
  const datasource = useOrdersDatasource({});

  const gridOptions: GridOptions<Order>[] = useMemo(() => {
    const go: GridOptions<Order> = {
      context: {},
      rowModelType: "serverSide",
      serverSideDatasource: datasource,
      serverSideInfiniteScroll: true,
      blockLoadDebounceMillis: 100,
      groupDisplayType: "multipleColumns",
      rowSelection: "multiple",
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      defaultColDef,
      multiSortKey: 'ctrl',
      columnDefs: [...defaultColumnDefinitions],
      getRowId: (params: GetRowIdParams<Order>) => params.data.id,
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            toolPanel: 'agColumnsToolPanel',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanelParams: {
              suppressPivotMode: true,
              suppressValues: true
            },
            width: 320
          }, 
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: true,
              suppressFilterSearch: true,
            },
            width: 320
          }, 
        ] 
      },
      animateRows: true,
      enableCellChangeFlash: true
    };

    if (!props.gridOptions) {
      return [go];
    } if (isArray(props.gridOptions)) {
      return [go, ...props.gridOptions];
    } 
    return [go, props.gridOptions];
    
  }, [props.gridOptions, datasource]);

  return (<DataGrid gridOptions={gridOptions} plugins={plugins} />);
};

export default OrdersDataGrid;
