import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { LiveIndicator } from "../../../components";
import { toAbsHumanDuration } from "../../../util";
import { DateTime } from "luxon";

export const ScheduleViewPageSubTitle = (loading: boolean, updatedAt: Date) => {
  const [/*lastTick*/, setLastTick] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setLastTick(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [loading, updatedAt]);

  return (
    <>
      {loading ? (
        <>
          <LoadingOutlined spin /> Loading...
        </>
      ) : (
        <>
          <LiveIndicator />
          <span>{toAbsHumanDuration(DateTime.fromJSDate(updatedAt), DateTime.now())} ago</span>
        </>
      )}
    </>
  );
};

export default ScheduleViewPageSubTitle;
