import React, { useEffect, useMemo, useState } from "react";
import { Space, Layout, notification, Row } from "antd";
import { startCase, capitalize } from "lodash";
import ProCard from "@ant-design/pro-card";
import { LoadingOutlined } from "@ant-design/icons";
import { LiveIndicator, StatisticCard, Grid } from "../..";
import { Text } from "../../Typography/Text";
import OrderStatsDataGrid from "./DataGrid/OrderStatsDataGrid";
import { IDataGridPlugin, useGridRefPlugin, useSetColumnSortPlugin, useSizeColumnsToFitPlugin } from "../../DataGrid/plugins";
import { ApplyColumnStateParams, ColumnState, GridOptions } from "ag-grid-community";
import { ErrorPayload, GetOrderStatsVariables, OrdersApi } from "../../../api/core";
import { toAbsHumanDuration } from "../../../util";
import { DateTime } from "luxon";

const { Col } = Grid;

const orderStatuses = ["active", "pending", "complete", "canceled", "invalid"];

export interface OrderDashboardProps {
  filter: GetOrderStatsVariables & { from: Date, to: Date}; // require from & to
}

const LastUpdatedAtLabel = (props: { lastUpdatedAt:Date }) => {
  const [/*timer*/, setTimer] = useState(new Date());

  // Force a re-render every 1 second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Text small>
      <strong>Updated </strong>
      {toAbsHumanDuration(DateTime.fromJSDate(props.lastUpdatedAt), DateTime.now())}
    </Text>
  );
};

const SummaryStats = (props: OrderDashboardProps) => {
  const { filter } = props;
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  const { data, error, isFetching: loading } = OrdersApi.useGetOrderStatsQuery({
    ...filter
  }, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 5000,
  });

  useEffect(() => {
    if (data) {
      setLastUpdatedAt(new Date());
    }
  }, [data]);

  if (error) {
    const e = error as ErrorPayload;
    notification.open({
      key: "error",
      message: e.statusText ?? e.status.toString(),
      description: e.message,
      duration: 0,
    });
  }

  const statisticCards = ["orders", "lineItems"]
    .map((status) => (
      <Col key={status}>
        <StatisticCard
          title={`Total ${capitalize(startCase(status))}`}
          value={
            data ? (data as any)[`${status}Count`] : 0
          }
          series={(data?.series || [])}
          field={`${status}Count`}
        />
      </Col>
    )).concat(orderStatuses.map(status => (
      <Col key={status}>
        <StatisticCard
          title={`Total ${capitalize(startCase(status))}`}
          value={data ? (data.statusCount as any)[status] : 0}
          series={(data?.series || [])}
          field={['statusCount', status]}
        />
      </Col>
    )));

  return (
    <ProCard.Group
      title="Stats"
      extra={[
        <div key="stats-extras">
          <Space align="center">
            <LastUpdatedAtLabel lastUpdatedAt={lastUpdatedAt} />
            {data?.lastOrderAt && (
              <Text small>
                <b>Last Order</b>:{" "}
                {toAbsHumanDuration(DateTime.fromJSDate(data.lastOrderAt), DateTime.now())}
              </Text>
            )}
            {loading ? <LoadingOutlined /> : <LiveIndicator />}
          </Space>
        </div>,
      ]}
    >
      <Row>{statisticCards}</Row>
    </ProCard.Group>
  );
};

export const OrderDashboard = ({ filter }: OrderDashboardProps) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const gridRefPlugin = useGridRefPlugin();
  const sizeColumnsToFitPlugin = useSizeColumnsToFitPlugin();
  const setColumnSortPlugin = useSetColumnSortPlugin({
    initialState: (prev, colApi) => {
      const updatedState:ColumnState[] = [...prev];

      let ndx = prev!.findIndex(_ => colApi.getColumn(_.colId)?.getColDef().field === "name");  
      updatedState[ndx] = { ...prev[ndx], sort: "asc", sortIndex: 0 }; 

      ndx = prev.findIndex(_ => colApi.getColumn(_.colId)?.getColDef().headerName === "#");
      updatedState[ndx] = { ...prev[ndx], hide: true };

      const applyState:ApplyColumnStateParams = {
        state: updatedState,
        applyOrder: true
      };
      return applyState;
    }
  });

  const orderStatsDataGridOptions = useMemo<GridOptions>(() => ({
    rowModelType: "serverSide",
    pagination: true,
    paginationPageSize: 50,
    domLayout: "autoHeight", // renders all rows to the screen instead of using virtualization,
    statusBar: undefined,
    sideBar: {
      toolPanels: undefined,
      hiddenByDefault: true
    },
    rowSelection: undefined,
    defaultColDef: {
      sortable: true,
      checkboxSelection: false,
      resizable: true,
      filter: false
    },
  }) as GridOptions, []);

  const orderStatsDataGridPlugins = useMemo<IDataGridPlugin[]>(() => {
    return [gridRefPlugin, sizeColumnsToFitPlugin, setColumnSortPlugin];
  }, [gridRefPlugin, sizeColumnsToFitPlugin, setColumnSortPlugin]);

  const style = useMemo<React.CSSProperties>(() => 
    ({ height: '100%', width: '100%', margin: '0px', padding: '0px' }), []);

  return (
    <Layout style={style}>
      <SummaryStats filter={filter} />
      <OrderStatsDataGrid 
        statsFrom={filter?.from} 
        statsTo={filter?.to} 
        pollInterval={5000}
        gridOptions={orderStatsDataGridOptions}
        plugins={orderStatsDataGridPlugins}
      />
    </Layout>
  );
};
