import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Modal, Space } from "antd";
import { Batch, BatchEvent, BatchesApi, TransitionBatchEvent, TransitionBatchEventVariables } from "../../../api/core";

interface BatchStatusButtonProps {
  batch: Batch;
  isFloorView: boolean;
  onTransition?: (updatedBatch: Batch) => void;
}

function useTransitionBatch(
  onfulfilled?: (response: TransitionBatchEvent) => TransitionBatchEvent,
  onrejected?: (reason: any) => TransitionBatchEvent 
) {
  const [__transitionBatch, state] = BatchesApi.useTransitionBatchEventMutation();

  const transition = (arg: TransitionBatchEventVariables) => 
    __transitionBatch(arg).unwrap().then(onfulfilled, onrejected);

  return [transition, state] as const; // the 'as const' is needed to get Typescript to infer the types correctly
}

export const BatchStatusButton = ({
  batch,
  isFloorView, 
  onTransition
}: BatchStatusButtonProps): JSX.Element => {
  const [confirmation, setConfirmation] = useState<any>();

  const [transitionBatch, { isLoading: loading }] = useTransitionBatch(
    (response) => { 
      setConfirmation(undefined); 
      onTransition?.(response);
      return response; 
    },
    (reason) => { setConfirmation(undefined); throw reason; }
  );

  // replace with username from MSAL in the future
  const [initial, setInitial] = useState<string>('');

  const events: { [key in BatchEvent]?: string } = {};

  const canPrint = isFloorView && !batch.lastConfirmDeliveredToPrinterAt;
  if (canPrint) {
    events[BatchEvent.ConfirmDeliveredToPrinter] = "⟶ Confirm Delivered To Printer";
  }

  const canBrand = isFloorView && canPrint && !batch.lastConfirmBrandedAt;
  if (canBrand) {
    events[BatchEvent.ConfirmBrandingComplete] = "⟶ Confirm Branding Complete";
  }

  const canPull = isFloorView && canBrand && !batch.lastConfirmPullUserId && !batch.lastConfirmPullAt;
  if (canPull) {
    events[BatchEvent.ConfirmPull] = "⟶ Confirm Pull";
  }

  // TO DO: fix the following unit testwarning
  // Instance created by `useForm` is not connected to any Form element. Forget to pass `form` prop?
  const [form] = Form.useForm(); 

  useEffect(() => {
    if (!confirmation) form.resetFields();
  }, [confirmation, form]);

  useEffect(() => {
    form.setFieldValue('initial', initial);
  }, [initial, form]);

  const transition = useCallback(
    (event: BatchEvent) => {
      switch (event) {
        case BatchEvent.ConfirmPull:
          setConfirmation({
            footer: [
              <Button key="cancel" onClick={() => setConfirmation(undefined)}>
                Cancel
              </Button>,
              <Button
                type="primary"
                loading={loading}
                form="confirmPull"
                key="submit"
                htmlType="submit"
              >
                Confirm Batch Pulled
              </Button>,
            ],
            content: (
              <Form
                key="confirPullForm"
                id="confirmPull"
                form={form}
                onFinish={(values) => {
                  transitionBatch({
                    id: batch?.id,
                    event,
                    ...values,
                  });
                }}
              >
                <Form.Item
                  key="initial"
                  name="initial"
                  label="Initial"
                  rules={[
                    {
                      required: true,
                      min: 2,
                      message: "Initial must be at least 2 letters.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Initials"
                    onChange={({ target }) => setInitial(target.value)}
                  />
                </Form.Item>
                <Form.Item
                  key="pullBox"
                  name="pullBox"
                  label="Tote Bin"
                >
                  <Input placeholder="enter tote bin number" />
                </Form.Item>
              </Form>
            ),
          });
          break;
        case BatchEvent.ConfirmBrandingComplete:
          setConfirmation({
            footer: [
              <Button key="cancel" onClick={() => setConfirmation(undefined)}>
                Cancel
              </Button>,
              <Button
                type="primary"
                loading={loading}
                form="confirmBrandingComplete"
                key="submit"
                htmlType="submit"
              >
                Confirm Batch Branding Complete
              </Button>,
            ],
            content: (
              <Form
                key="confirmBrandingComplete"
                id="confirmBrandingComplete"
                form={form}
                onFinish={(values) => {
                  transitionBatch({
                    id: batch?.id,
                    event,
                    ...values,
                  });
                }}
              />
            ),
          });
          break;
        case BatchEvent.ConfirmDeliveredToPrinter:
          setConfirmation({
            footer: [
              <Button key="cancel" onClick={() => setConfirmation(undefined)}>
                Cancel
              </Button>,
              <Button
                type="primary"
                loading={loading}
                form="confirmDeliveredToPrinter"
                key="submit"
                htmlType="submit"
              >
                Confirm Batch Delivered To Printer
              </Button>,
            ],
            content: (
              <Form
                key="confirmDeliveredToPrinter"
                id="confirmDeliveredToPrinter"
                form={form}
                onFinish={(values) => {
                  transitionBatch({
                    id: batch?.id,
                    event,
                    ...values,
                  });
                }}
              />
            ),
          });
          break;
        default:
          transitionBatch({ id: batch?.id, event });
      }
    },
    [batch?.id, form, loading, transitionBatch]
  );

  return (
    <>
      <Space>
        {Object.keys(events).map((key) => (
          <Button 
            key={`menu_item_${key}`} 
            onClick={(e) => {
              e.stopPropagation();
              transition(key as BatchEvent);
            }}
          >
            {events[key as BatchEvent]}
          </Button>
        ))}
      </Space>
      <Modal
        key="modal"
        closable={false}
        destroyOnClose
        open={!!confirmation}
        footer={confirmation?.footer}
      >
        <div onClick={(e) => e.stopPropagation()}>{confirmation?.content}</div>
      </Modal>
    </>
  );
};
