import { BatchUnderConstructionEventType } from "./BatchUnderConstructionEventType";

export interface BatchUnderConstructionResetEventArgs {
}

export type BatchUnderConstructionResetEvent = CustomEvent<BatchUnderConstructionResetEventArgs>;

const __type: BatchUnderConstructionEventType = "reset";
export const createBatchUnderConstructionResetEvent = () => 
  new CustomEvent<BatchUnderConstructionResetEventArgs>(__type);

export type BatchUnderConstructionResetEventListener = (e:BatchUnderConstructionResetEvent) => void;

export default BatchUnderConstructionResetEvent;
