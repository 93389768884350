import React, { useMemo } from "react";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import { DatePicker } from "../DatePicker";
import { startOfDayLocal } from "../../helpers";

const { RangePicker } = DatePicker;

export const DateLocalRangePicker = (props: RangePickerProps<Date>) => {
  const cleanValue = useMemo(() => {
    if (props.value === undefined || props.value === null) {
      return props.value;
    }
    const start = props.value[0] as Date | null | undefined;
    const end = props.value[1] as Date | null | undefined;

    const cleanStart = start ? startOfDayLocal(start) : start;
    const cleanEnd = end ? startOfDayLocal(end) : end;

    return [cleanStart, cleanEnd] as [Date, Date];
  }, [props.value]);

  return (
    <RangePicker
      {...props}
      value={cleanValue}
    />
  );
};
