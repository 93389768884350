import { Select } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

export const TagEditor = forwardRef(({ value }: any, ref) => {
  const [tags = value, setTags] = useState<any>();
  const inputRef = useRef(null);

  useEffect(() => {
    // focus on the input
    setTimeout(() => {
      if (inputRef?.current) (inputRef.current as any).focus();
    });
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      return tags;
    },

    isCancelBeforeStart() {
      return false;
    },

    isCancelAfterEnd() {
      return false;
    },

    isPopup() {
      return false;
    },
  }));

  return (
    <Select
      ref={inputRef}
      mode="tags"
      value={tags}
      placeholder="Tags"
      onChange={setTags}
      style={{ width: "100%" }}
    />
  );
});
