import { GetSchedules_AliasMap, Schedule, ScheduleIdentifier, ScheduleStats, deserializeSchedule } from "../Schedules";
import { DeserializationFunc } from "../utils/deserialize";

export interface GetFloorviewSchedule extends Schedule {
  scheduleStats: ScheduleStats; // always present
}

export const deserializeGetFloorviewSchedule = 
  deserializeSchedule as DeserializationFunc<GetFloorviewSchedule>;

export interface GetFloorviewScheduleVariables {
  schedule: ScheduleIdentifier;
}

export const GetFloorviewSchedule_AliasMap = GetSchedules_AliasMap;
