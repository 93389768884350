import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { isEmpty } from "lodash";
import { Store } from "../../../../api/core";

interface StoresCellRendererProps extends ICellRendererParams {
  value: Store[];
}

export const StoresCellRenderer = ({ value }: StoresCellRendererProps) => {
  if (isEmpty(value)) return null;

  const sortedStores = value.slice(0).sort((a, b) => a.name!.localeCompare(b.name!));
  return (
    <div>
      {sortedStores.map((store) => (
        <div key={`${store.name}`} className="ant-tag">
          {store.name}
        </div>
      ))}
    </div>
  );
};
