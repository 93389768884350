import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  findIndex,
  get,
  isEmpty,
  isEqual,
  isNil,
  isUndefined,
  map,
  omitBy,
  sortBy,
  throttle,
  uniq,
} from "lodash";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GetMainMenuItemsParams,
  SortChangedEvent,
  ValueFormatterFunc,
  ValueSetterParams,
  MenuItemDef,
} from "ag-grid-community";
import { Spin } from "antd";
import useLocalStorageState from "use-local-storage-state";
import { gatherKeys } from "./util";
import * as cellRenderers from "./renderers/index";
import { LoadingMessage } from "..";
import "./index.scss";
import * as editors from "./editors";
import { LinkCellRenderer } from "./renderers";
import IntegerFormatter from "./formatters/IntegerFormatter";

export interface TableChangeCallbackParams {
  newData?: any[];
  oldData?: any[];
  changed?: any[];
}

export type TableChangeCallback = {
  (arg: TableChangeCallbackParams): void;
};

export type TableSortCallback = {
  (arg: any): void;
};

/* eslint-disable react/require-default-props */
export interface TableProps {
  id: string;
  data?: any;
  loading?: boolean;
  onChange?: TableChangeCallback;
  onSelect?: Function;
  onSort?: TableSortCallback;
  onRow?: any;
  rowKey?: string;
  defaultColumnOrder?: string[];
  columns?: { [key: string]: any };
  hideColumns?: (string | RegExp)[];
  groupColumns?: string[];
  emptyText?: string | number;
  columnWidth?: { [key: string]: number };
  /** Tally totals for rows with numeric data */
  rowTotals?: boolean;
  /** Tally totals for columns with numeric data */
  colTotals?: boolean;
  render?: { [key: string]: string | { name: string; params: any } };
  formatters?: { [key: string]: ValueFormatterFunc };
  types?: { [key: string]: string };
  styles?: { [key: string]: any };
  components?: { [key: string]: any };
  frameworkComponents?: { [key: string]: any };
  size?: string;
  layout?: any; // TableLayout;
  actions?: any[];
  sort?: any; // TODO BatchSort | LineItemSort | ?, or generic indexed?
  selectable?: boolean;
  sortable?: "client" | boolean;
  // By default, we disable all server side sorting for nested values. Any
  // nested sorting must be explicitly enabled via this map.
  // The key corresponds to the nested data key (e.g. `order.ship_by`), the
  // value to the actual parameter used to sort (e.g. `order_ship_by`)
  //
  // TODO: In the future, make all sorting explicit.
  sortKeys?: { [key: string]: string };
  draggable?: boolean;
  filter?: Function;
  style?: any;
  resizable?: boolean;
  // Show agGrid Sidebar?
  sidebar?: boolean;
}
/* eslint-enable react/require-default-props */

// I have not found a way to overwrite `GridOptions.isExternalFilterPresent`
// on ag-grid. None of their API calls allow us to change that reference, so
// we need to make it static.
const filterPresent = () => {
  const context = JSON.parse(localStorage.getItem("tableContext") || "{}");
  return context?.filter;
};

interface TableContext {
  filter: boolean;
  columnState: any;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @deprecated As of ag-grid version 29, the AgGridColumn component that this Table component uses 
 * has been removed form the library. As such, usage of the Table component is blocking us from
 * upgrading. We need to migrate the remaining Table instances over to the newer DataGrid component.
 */
export function Table(props: TableProps) {
  const {
    id,
    data,
    loading,
    onChange,
    onSelect,
    onSort,
    onRow,
    rowKey,
    defaultColumnOrder,
    columns,
    hideColumns,
    groupColumns,
    emptyText,
    columnWidth,
    rowTotals,
    colTotals,
    render,
    formatters,
    types,
    styles,
    components,
    frameworkComponents,
    size,
    layout,
    actions,
    sort: defaultSort,
    sortKeys,
    selectable,
    sortable,
    draggable,
    filter,
    style,
    resizable,
    sidebar,
  } = props;
  /* eslint-enable @typescript-eslint/no-unused-vars */
  
  const [gridApi, setGridApi] = useState<any>();
  const [context, setContext] = useLocalStorageState<TableContext>(
    `tables:${id}`,
    { filter: false, columnState: null }
  );

  const updateContext = useCallback(
    (updates: any) => {
      setContext((value) => ({
        ...value,
        ...updates,
      }));
    },
    [setContext]
  );

  const onGridReady = useCallback(
    ({ api }: any) => {
      setGridApi(api);
    },
    [setGridApi]
  );

  const externalFilter = useCallback(
    (node: any) => {
      if (filter) return filter(node);
      return true;
    },
    [filter]
  );

  useEffect(() => {
    if (!gridApi) return;
    setContext((value) => ({ ...value, filter: !!filter }));
    setTimeout(() => {
      gridApi.setDoesExternalFilterPass(externalFilter);
      gridApi.onFilterChanged();
      // Need redraw rows to bring draggable back to life
      gridApi.redrawRows();
      gridApi.refreshCells();
    }, 0);
  }, [externalFilter, filter, gridApi, setContext]);

  const actionRenderers = useMemo(
    () =>
      isEmpty(actions) ? [] : actions?.map(({ component }: any) => component),
    [actions]
  );

  const allComponents = useMemo(
    () => ({
      ...components,
      ...cellRenderers,
    }),
    [components]
  );

  const allFrameworkComponents = useMemo(
    () => ({
      ...frameworkComponents,
      ...editors,
      LinkCellRenderer,
      actionsRenderer: (rendererProps: any) =>
        actionRenderers?.map((renderer, index) => {
          const key = `action-${index}`;
          const action: any = React.createElement(renderer, {
            ...rendererProps,
            // eslint-disable-next-line react/no-array-index-key
            key,
          });

          return action;
        }),
    }),
    [actionRenderers, frameworkComponents]
  );

  const hideColumn = useCallback(
    (name: string) =>
      hideColumns &&
      hideColumns.map((hidden) => !!name.match(hidden)).includes(true),
    [hideColumns]
  );

  const tableColumns = (() =>
    isEmpty(data)
      ? []
      : sortBy(uniq(gatherKeys(data, render)), (colId: string) => {
        const defaultIndex = (defaultColumnOrder || []).indexOf(colId);
        let index = findIndex(
          context?.columnState,
          (col: ColDef) => col?.colId === colId
        );
        if (index === -1) index = defaultIndex;
        if (index === -1) index = 9999;
        return index;
      })
        .filter((key) => !(key as string).match(/__/))
        .map((key: any, index: number) => {
          // Get column state from storage
          const columnState = context?.columnState?.find(
            (column: ColDef) => column?.colId === key
          );

          const column = get(columns, key);

          const config: ColDef = {
            hide: hideColumn(key),
            enableRowGroup: true,
            rowGroup: groupColumns?.includes(key),
            width: get(columnWidth, key),
            editable: column?.editable,
            cellEditor: column?.editor,
            valueSetter: ({
              newValue,
              oldValue,
              colDef: { field },
              data: row,
            }: ValueSetterParams) => {
              const newRow = {
                ...row,
                [field as string]: newValue,
              };
              if (!isEqual(row, newRow) && onChange)
                onChange({ changed: [newRow] });
            },
            ...columnState,
          };

          const aggFunc = colTotals ? "sum" : undefined;
          let cellRenderer = get(render, [key, "name"]) || get(render, key);
          const cellRendererParams = get(render, [key, "params"]);
          let valueFormatter: ValueFormatterFunc | undefined;
          let valueGetter;
          let type = get(types, key);
          const cellStyle = get(styles, key) || {};

          // Formatters only get
          if (!isUndefined(formatters)) {
            if (Object.keys(formatters).includes(key)) {
              valueFormatter = formatters[key];
            }
          }

          // Handle custom types
          switch (type) {
            case "Number":
              valueFormatter = IntegerFormatter;
              type = undefined;
              break;
            case "IntegerLink":
              valueFormatter = IntegerFormatter;
              cellRenderer = "LinkCellRenderer";
              type = "numericColumn";
              valueGetter = (params: any) =>
                get(params.data, get(params, "colDef.field"))?.value ||
                    params.value ||
                    0;
              break;
          }

          const sortKey = get(sortKeys, key) || key;
          const sort =
                sortable && defaultSort && defaultSort[sortKey]
                  ? defaultSort[sortKey]?.toLowerCase()
                  : undefined;
          const isSortable =
                sortable === "client"
                  ? true
                  : sortable &&
                    (!key.match(/\./) ||
                      (sortKeys && !isUndefined(sortKeys[key])));

          const isFirstDisplayed = (params: any) => {
            if (!selectable) return false;
            const displayedColumns =
                  params.columnApi.getAllDisplayedColumns();
            return displayedColumns[0] === params.column;
          };

          return (
            <AgGridColumn
              key={key}
              field={key}
              type={type}
              rowGroup={config.rowGroup}
              hide={config.hide}
              pinned={config.pinned}
              width={config.width}
              valueGetter={valueGetter}
              cellRenderer={cellRenderer}
              cellRendererParams={cellRendererParams}
              valueFormatter={valueFormatter}
              rowDrag={
                    !filter && draggable && index === 0 ? true : undefined
                  }
              sortable={isSortable}
              headerCheckboxSelection={isFirstDisplayed}
              checkboxSelection={isFirstDisplayed}
              sort={sort}
              sortingOrder={isSortable ? ["desc", "asc", null] : undefined}
              cellStyle={cellStyle}
              aggFunc={aggFunc}
              filter
              enablePivot
              enableRowGroup
              enableValue
              editable={config.editable}
              cellEditor={config.cellEditor}
              valueSetter={config.valueSetter}
            />
          );
        })
  )();

  const defaultColDef = {
    resizable: (isUndefined(resizable) ? true : resizable)
  };

  const actionColumn = useMemo(
    () =>
      !isEmpty(actions) ? (
        <AgGridColumn field="actions" cellRenderer="actionsRenderer" />
      ) : undefined,
    [actions]
  );

  // TODO:
  // ag-grid calls this event twice, once for any deselection that may have
  // happened, even if none has happened and again for the selection.
  // Not doing any debouncing for now, but if performance gets bad take a look.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRowSelected = useCallback(
    throttle(({ api }: any) => {
      if (onSelect) onSelect(api.getSelectedRows());
    }, 200),
    [onSelect]
  );

  const onRowDragEnd = useCallback(
    ({ api }: any) => {
      if (onChange)
        onChange({
          newData: api
            .getModel()
            .rowsToDisplay.map((row: { data: any }) => row.data),
        });
    },
    [onChange]
  );

  const saveColumnState = useCallback(
    ({ columnApi }: any) => {
      const columnState = map(columnApi?.getColumnState(), (column) =>
        omitBy(column, isNil)
      );
      updateContext({ columnState });
    },
    [updateContext]
  );
  
  // `resetColumnState` doesn't work for us, for whatever reason.
  // That's fine though, we'll simply inject our own that purges the
  // local storage.
  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      const items: (string | MenuItemDef)[] = [...params.defaultItems];
      const resetIndex = items.indexOf("resetColumns");
      items[resetIndex] = {
        name: "Reset Columns",
        action: () => updateContext({ columnState: undefined }),
      };
      return items;
    },
    [updateContext]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSortChanged = useCallback(
    ({ api, columnApi }: SortChangedEvent) => {
      if (onSort) {
        const sortState = columnApi
          .getColumnState()
          .filter((s) => s?.colId && s.sort != null);
        const sortMap = new Map<string, string | null | undefined>();
        sortBy(sortState, "sortIndex").forEach(({ colId, sort }) => {
          const sortKey = get(sortKeys, colId as string) || (colId as string);
          sortMap.set(sortKey, sort?.toUpperCase());
        });
        onSort(sortMap);
      }

      // A sort change also represents a data change
      if (onChange) {
        onChange({
          // `rowsToDisplay` isn't on `RowModel`?
          newData: (api.getModel() as any).rowsToDisplay.map(
            (row: { data: any }) => row.data
          ),
        });
      }
    },
    [onChange, onSort, sortKeys]
  );

  const table = (
    <div className="ag-theme-madengine-client-side" style={style}>
      <AgGridReact
        suppressHorizontalScroll={false}
        onGridReady={onGridReady}
        // Note: the grid should be in a container that sets height.
        // For auto height, see: `domLayout="autoHeight"`
        rowBuffer={50}
        debounceVerticalScrollbar
        rowData={data}
        rowSelection={selectable ? "multiple" : undefined}
        rowDragManaged={!filter && draggable}
        rowDragMultiRow={draggable && selectable}
        enableCellTextSelection={!draggable}
        ensureDomOrder
        components={{ ...allComponents, ...allFrameworkComponents }}
        onRowSelected={onRowSelected}
        onRowDragEnd={onRowDragEnd}
        onSortChanged={onSortChanged}
        suppressColumnVirtualisation={process?.env.NODE_ENV === "test"}
        suppressLoadingOverlay
        doesExternalFilterPass={externalFilter}
        isExternalFilterPresent={filterPresent}
        context={context}
        defaultColDef={defaultColDef}
        suppressDragLeaveHidesColumns
        groupDefaultExpanded={1}
        // Callbacks to persist column state
        onColumnVisible={saveColumnState}
        onColumnRowGroupChanged={saveColumnState}
        onColumnPinned={saveColumnState}
        onColumnResized={saveColumnState}
        onColumnMoved={saveColumnState}
        getMainMenuItems={getMainMenuItems}
        sideBar={
          sidebar
            ? {
              toolPanels: ["columns", "filters"],
            }
            : false
        }
        groupIncludeFooter
        groupIncludeTotalFooter={colTotals}
        statusBar={{
          statusPanels: [
            { statusPanel: "agTotalAndFilteredRowCountComponent" },
            {
              statusPanel: "agAggregationComponent",
              statusPanelParams: { aggFuncs: ["avg"] },
            },
          ],
        }}
        stopEditingWhenCellsLoseFocus
      >
        {tableColumns}
        {actionColumn}
      </AgGridReact>
    </div>
  );

  return (
    <div className="ant-spin-table-wrapper">
      <Spin spinning={loading} size="large" tip={LoadingMessage.random()}>
        {table}
      </Spin>
    </div>
  );
}

Table.defaultProps = {
  loading: false,
  sortable: true,
  resizable: true,
  defaultColumnOrder: [],
};
