import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { coreUrl } from "./common";

// All of the endpoints for Core should be registred under this API.
// We use the injectEndpoints functionality to break-up the definition of the CoreAPI
// across many files.
//
// See https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#injecting-endpoints
export const CoreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: builder => ({
    /* The actual endpoints are added in via other files, but required to put a placeholder here */
  }),
});

export default CoreApi;
