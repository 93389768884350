import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { CalendarOutlined, DeleteOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Form, Space } from "antd";
import { isDate, isNil, map } from "lodash";
import { DateLocalParam, StringParam, useQueryParam } from "../../../api/useQueryParams";
import { FacilitySelect, DateLocalPicker, ScheduleInfoCard, ScheduleInfoCardRef } from "../../../components";
import { testId } from "../../../util/testId";
import { GridApi } from "ag-grid-enterprise";
import { ModelUpdatedEvent, RowNode, SelectionChangedEvent } from "ag-grid-community";
import { BatchScheduleManagerModalState, ScheduleSelectionMode } from "./BatchSchedulerModal/BatchScheduleManagerModalProps";
import BatchScheduleManagerModal from "./BatchSchedulerModal/BatchScheduleManagerModal";
import { Batch, GetSchedulesFilter, Schedule, SchedulesApi } from "../../../api/core";
import { isValidDate } from "../../../helpers";
import { DateTime } from "luxon";

export type BatchScheduleManager_selected_schedule = {
  id: string;
  date?: any;
};

export type BatchScheduleManager_selected_autoFacility = {
  id: string;
};

export type BatchScheduleManager_selected = Pick<
  Batch,
  "id"
> &
  Omit<Partial<Batch>, "schedule" | "autoFacility"> & {
    schedule?: BatchScheduleManager_selected_schedule | null;
    autoFacility?: BatchScheduleManager_selected_autoFacility | null;
  };

export type ScheduleSuccessHandler = (e: CustomEvent<Readonly<BatchScheduleManagerModalState>>) => void;

export interface BatchScheduleManagerProps {
  api: GridApi;
  onScheduleSuccess?: ScheduleSuccessHandler;
  hideSchedule?: boolean;
  hideUnschedule?: boolean;
}

export const BatchScheduleManager = (props: BatchScheduleManagerProps) => {
  const ref = useRef<ScheduleInfoCardRef | null>(null);

  const [facilityId, setFacilityId] = useQueryParam("scheduleForFacilityId", StringParam);
  const [date, setDate] = useQueryParam("scheduleForDate", DateLocalParam);
  const [selectAllCount, setSelectAllCount] = useState<number>(props.api.getDisplayedRowCount() || 0);
  const [selected, setSelected] =
    useState<Batch[]>(props.api.getSelectedRows());

  const [selectedSchedule, setSelectedSchedule] = useState<{facilityId: string, date:Date}>();
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const [scheduleSelectMode, setScheduleSelectMode] = useState<ScheduleSelectionMode>("selected");

  const onSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    setSelected(e.api.getSelectedRows());
  }, [setSelected]);

  const onModelUpdated = useCallback((e: ModelUpdatedEvent) => {
    setSelectAllCount(e.api.getDisplayedRowCount() || 0);
  }, [setSelectAllCount]);

  useEffect(() => {
    setSelected(props.api.getSelectedRows());
    setSelectAllCount(props.api.getDisplayedRowCount() || 0);

    props.api.addEventListener('selectionChanged', onSelectionChanged);
    props.api.addEventListener('modelUpdated', onModelUpdated);

    return () => {
      props.api.removeEventListener('selectionChanged', onSelectionChanged);
      props.api.removeEventListener('modelUpdated', onModelUpdated);
    };
  }, [props.api, onSelectionChanged, onModelUpdated]);

  const filter = useMemo<GetSchedulesFilter>(() => {
    const f:GetSchedulesFilter = {
      facilityId: {
        filterType: "text",
        condition1: {
          type: "equals",
          filter: facilityId
        }
      }
    };

    // TO DO: The page should probably error if the date is invalid...
    if (isDate(date) && isValidDate(date)) {
      const filterDateFrom = DateTime.fromJSDate(date).startOf('day');
      const filterDateTo = filterDateFrom.endOf('day');
      f.date = {
        filterType: "date",
        condition1: {
          type: "inRange",
          dateFrom: filterDateFrom.toISODate(),
          dateTo: filterDateTo.toISODate()
        }
      };
    }

    return f;
  }, [facilityId, date]);

  const { data, isFetching, refetch: refetchSchedules } = SchedulesApi.useGetSchedulesQuery({
    filter,
    options: {
      include: ["facility", "schedule_stats", "stores"],
    }
  }, {
    skip: isNil(date) || isNil(facilityId), 
    refetchOnMountOrArgChange: true
  });

  // destructure the props to satisfy react-hooks/exhaustive-deps lint warning
  const {
    api: props_api,
    onScheduleSuccess: props_onScheduleSuccess
  } = props;

  const scheduledSelected = useMemo(() => {
    return (selected || []).filter(
      (batch: BatchScheduleManager_selected) => batch?.schedule?.id
    );
  }, [selected]);

  const confirm = useCallback((selection: ScheduleSelectionMode,
    schedule?: { facilityId: string, date: Date } | null, note?: string) => {

    setSelectedSchedule(schedule || undefined);
    setScheduleSelectMode(selection);
    setShowScheduleModal(true);

  }, [setSelectedSchedule, setScheduleSelectMode, setShowScheduleModal]);

  const controls = useMemo(() => {
    const scheduleButtons = [];
    const unscheduleButtons = [];

    unscheduleButtons.push(
      <Form.Item key="unschedule-selected-btn">
        <Button
          loading={showScheduleModal}
          disabled={!scheduledSelected.length}
          icon={<DeleteOutlined />}
          onClick={() => confirm("selected", null)}
        >
          Unschedule Selected ({scheduledSelected?.length || 0})
        </Button>
      </Form.Item>
    );

    // TO DO: need to execute a query to detect if there are any scheduled items amongst the
    // batches that match the current filter criteria - and use that to determine if the button
    // should be disabled
    unscheduleButtons.push(
      <Form.Item key="unschedule-select-all-btn">
        <Button
          loading={showScheduleModal}
          disabled={selectAllCount === 0}
          icon={<DeleteOutlined />}
          onClick={() => confirm("all", null)}
        >
          Unschedule All ({selectAllCount})
        </Button>
      </Form.Item>
    );

    scheduleButtons.push(
      <Form.Item key="schedule-selected">
        <Button
          loading={showScheduleModal}
          disabled={!selected.length}
          icon={<DeleteOutlined />}
          onClick={() => confirm("selected", { facilityId: facilityId!, date: date! })}
        >
          Schedule Selected ({selected?.length || 0})
        </Button>
      </Form.Item>
    );

    scheduleButtons.push(
      <Form.Item key="schedule-all">
        <Button
          loading={showScheduleModal}
          disabled={
            selectAllCount === 0 ||
            isNil(facilityId) ||
            isNil(date)
          }
          icon={<CalendarOutlined />}
          onClick={() => confirm("all", { facilityId: facilityId!, date: date! })}
        >
          Schedule All ({selectAllCount})
        </Button>
      </Form.Item>
    );

    return (
      <>
        {!props.hideSchedule && (<Space>{scheduleButtons}</Space>)}
        {!props.hideUnschedule && (<Space>{unscheduleButtons}</Space>)}
      </>
    );
  }, [
    confirm,
    facilityId,
    date,
    showScheduleModal,
    scheduledSelected,
    selected,
    selectAllCount,
    props.hideSchedule,
    props.hideUnschedule
  ]);

  const schedule = useMemo(() => {
    return data?.rows?.[0];
  }, [data?.rows]);

  // TO DO: the date param is local time, but the back-end expects UTC 
  // Currently, what is sent to the server is simply the date converted to ISO8601 with the time component cut off
  // This could result in grabbing the wrong date depending upon the time of day! 
  // If we are going to use local time on the front-end, we need to go through and make sure the usage is consistent
  // and that things are properly converted to UTC when constructing the request.
  const current = useMemo<boolean>(() => {
    if (!schedule?.date || !date) { return !schedule; }
    const selected = schedule?.date?.toISOString().split("T")[0];
    const url = date?.toISOString().split("T")[0];
    return (selected === url || !schedule);
  }, [schedule, date]);

  const newSelected = useMemo(() => {
    return selected?.filter(
      ({ id }: BatchScheduleManager_selected) =>
        !map(schedule?.batches, "id").includes(id as string)
    );
  }, [selected, schedule?.batches]);

  const processModalResults = useCallback((e: CustomEvent<Readonly<BatchScheduleManagerModalState>>) => {
    if (e.detail.result!.cancelled) {
      return;
    }

    const modified:{ [key:string]: boolean } = e.detail.result?.ziftIds
      ?.reduce((result, curr, ndx) => Object.assign(result, { [curr]: true }), {}) || {};

    // update the cached rows for an immediate UI refresh
    const updatedRows: RowNode[] = [];
    props_api.getSelectedNodes().forEach((node, ndx) => {
      if (!node.id || !modified[node.id]) {
        return;
      }

      const oldData = node.data as Batch;
      const newData: Batch = {
        ...oldData,
        schedule: e.detail.result?.schedule as Schedule || null
      };

      node.setData(newData);
      updatedRows.push(node);

      // de-selecting makes it easy to get the BatchSchedulerManager 
      // to update it's display - but we can always revisit this if
      // we decide that keeping the items selected makes for a better
      // user experience
      node.setSelected(false);
    });

    props_api.flashCells({
      rowNodes: updatedRows
    });

    // let the parent component respond to the event
    props_onScheduleSuccess?.(e);

    refetchSchedules();
    ref.current?.refetch();

    // follow up with a refresh from the server to ensure the data being displayed is correct
    props_api.refreshServerSide({ purge: false });

  }, [props_api, props_onScheduleSuccess, refetchSchedules]);

  return (
    <>
      <Form>
        <Form.Item label="Facility">
          <FacilitySelect
            value={facilityId}
            onChange={(value: string) => {
              setFacilityId(value);
            }}
          />
        </Form.Item>
        <Form.Item label="Date">
          <DateLocalPicker
            placeholder="Select date"
            value={date}
            allowClear
            onChange={(value: Date | null) => {
              setDate(value);
            }}
          />
        </Form.Item>
        {controls}
        {!date || !facilityId ? (
          <Alert
            type="info"
            showIcon
            message="Select a facility and a date to schedule."
            data-testid={testId("alert")}
          />
        ) : undefined}
        <Card
          size="small"
          loading={isFetching}
          hidden={!date || !facilityId}
          data-testid={testId("schedule-info")}
        >
          <ScheduleInfoCard
            ref={ref}
            key={`schedule-${schedule?.id}`}
            facilityId={facilityId ?? undefined}
            date={date ?? undefined}
            schedule={schedule}
            newSelected={current ? newSelected || [] : []}
          />
        </Card>
      </Form>
      <BatchScheduleManagerModal
        show={showScheduleModal}
        hide={() => setShowScheduleModal(false)}
        api={props.api}
        schedule={selectedSchedule}
        selectMode={scheduleSelectMode}
        onMount={(et) => et.addEventListener("result", processModalResults)}
        onUnmount={(et) => et.removeEventListener("result", processModalResults)}
      />
    </>
  );
};

BatchScheduleManager.defaultProps = {
  onScheduleSuccess: () => { },
};
