import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { DetailDemandReportTable } from "../../../components/DemandReport";

export const DetailDemandReportPage = () => {

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <DetailDemandReportTable />
    </ErrorBoundary>
  );
};
