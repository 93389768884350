import { ScansApi, GetScans, AgGridDatasourceProps, GetScansVariables_Options, GetScansVariables, useAgGridDatasource } from "../../api/core";

export interface ScansServerSideDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetScansVariables_Options, GetScansVariables, GetScans>, "endpoint"> {}

export function useScansServerSideDatasource(props?: ScansServerSideDatasourceProps) {
  return useAgGridDatasource({
    ...props,
    endpoint: ScansApi.endpoints.getScans,
    options: {
      count: true, // ??? - very expensive
      ...props?.options
    }
  });
}

export default useScansServerSideDatasource;
