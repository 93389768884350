import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "../utils";

export interface Picksheet {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  pdfUrl: string;
}

export const PicksheetDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate
});

export const deserializePicksheet = deserialize<Picksheet>(PicksheetDeserializationSchema);
export const deserializePicksheetsArray = 
  deserializeArrayOf<Picksheet>(PicksheetDeserializationSchema);
