import CoreApi from '../CoreApi';
import { prepareFilter, prepareSort, transformResponse, transformErrorResponse, snakeCaseKeys } from '../utils';
import { GetStores, GetStoresVariables, deserializeGetStores } from './GetStores';
import { UpdateStores, UpdateStoresVariables, deserializeUpdateStores } from './UpdateStores';

// Extends the CoreApi with /stores endpoints
export const StoresApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({

    getStores: builder.query<GetStores, GetStoresVariables>({
      query: (params) => ({
        url: '/stores',
        method: "POST",
        body: {
          ...snakeCaseKeys({
            ...params,
            options: params.options && {
              ...params.options,
              orderStats: params.options.orderStats && {
                ...params.options.orderStats,
                from: params.options.orderStats.from?.toISOString(),
                to: params.options.orderStats.to?.toISOString(),
              }
            }
          }),
          filter: params.filter && prepareFilter(params.filter, undefined),
          sort: params.sort && prepareSort(params.sort, undefined),
        },
      }),
      transformResponse: transformResponse(deserializeGetStores),
      transformErrorResponse: transformErrorResponse("getStores"),
    }),

    updateStores: builder.mutation<UpdateStores, UpdateStoresVariables>({
      query: (params) => ({
        url: '/stores',
        method: "PATCH",
        body: snakeCaseKeys(params)
      }),
      transformResponse: transformResponse(deserializeUpdateStores),
      transformErrorResponse: transformErrorResponse("updateStores"),
    }),

  }),
});

export default StoresApi;
