import React, { useEffect, useMemo, useRef } from "react";
import { GetRowIdParams, GridApi, GridOptions } from "ag-grid-community";
import { DataGrid, DataGridProps } from "../../../DataGrid";
import orderStatsColumnDefs from "./orderStatsDefaultColumns";
import useOrderStatsServerSideDatasource, { useOrderStatsServerSideDatasourceProps } from "./useOrderStatsServerSideDatasource";
import { Store } from "../../../../api/core/Stores";

export interface OrderStatsDataGridProps extends DataGridProps {
  statsFrom: Date,
  statsTo: Date,
  pollInterval?: number,
}

export const OrderStatsDataGrid = (props: OrderStatsDataGridProps) => {
  const apiRef = useRef<GridApi | null>();

  const datasourceProps = useMemo<useOrderStatsServerSideDatasourceProps>(() => ({
    apiRef,
    pollingInterval: props.pollInterval,
    statsFrom: props.statsFrom,
    statsTo: props.statsTo
  }), 
  /* eslint-disable react-hooks/exhaustive-deps */ 
  [apiRef]
    /* eslint-enable react-hooks/exhaustive-deps */ 
  );

  const datasource = useOrderStatsServerSideDatasource(datasourceProps);

  useEffect(() => {
    apiRef.current?.setServerSideDatasource(datasource);
  }, [datasource]);

  useEffect(() => {
    datasource.setPollInterval(props.pollInterval);
  }, [datasource, props.pollInterval]);

  useEffect(() => {
    datasource.setStatsDateRange(props.statsFrom, props.statsTo);
  }, [datasource, props.statsFrom, props.statsTo]);

  const defaultGridOptions:GridOptions = useMemo(() => {
    return {
      columnDefs: orderStatsColumnDefs,
      serverSideDatasource: datasource,
      serverSideInfiniteScroll: true,
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => (param.data as Store).id
    };
  }, [datasource]);

  const gridOptionsArray:GridOptions[] = useMemo(() => {
    if (props.gridOptions) {
      return Array.prototype.concat(defaultGridOptions, props.gridOptions);
    } 
    return [defaultGridOptions];
  }, [defaultGridOptions, props.gridOptions]);

  return (
    <DataGrid
      apiRef={apiRef}
      gridOptions={gridOptionsArray} 
      plugins={props.plugins}
    />
  );
};

export default OrderStatsDataGrid;
