import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { PullReportTable } from "../../../components/DemandReport/PullTable";

export const PullReportPage = () => {
  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <PullReportTable />
    </ErrorBoundary>
  );
};
