import React, { useMemo } from "react";
import { BatchTable } from "../../../components";
import { ErrorBoundary } from "@sentry/react";
import useGridStateSearchParamPlugin 
  from "../../../components/DataGrid/plugins/useGridStateSearchParamPlugin";
import { GridOptions, GridReadyEvent } from "ag-grid-community";
import { IDataGridPlugin } from "../../../components/DataGrid/plugins";
import { StringParam, useQueryParam } from "../../../api/useQueryParams";
import { BatchStatus, FacilitiesApi, StoresApi } from "../../../api/core";

const statuses = [BatchStatus.New, BatchStatus.InProgress, BatchStatus.Pulled, 
  BatchStatus.BrandingComplete, BatchStatus.DeliveredToPrinter];

export const BatchManagePage = () => {
  const [activeBatchId, setActiveBatchId] = useQueryParam("id", StringParam);
  const gridStateExtension = useGridStateSearchParamPlugin();
  const plugins:IDataGridPlugin[] = useMemo(() => [gridStateExtension], [gridStateExtension]);

  const gridOptions:GridOptions<any> = useMemo(() => {  
    return ({
      onGridReady: (e: GridReadyEvent) => {
        e.api.setFilterModel({
          status: {
            filterType: "set",
            values: statuses
          }
        });

        e.api.onFilterChanged();  
        e.api.refreshHeader();
      }
    } as GridOptions<any>);
  }, []);

  const [facilitiesQueryTrigger] = FacilitiesApi.useLazyGetFacilitiesQuery();
  const facilitiesQuery = useMemo(() => 
    facilitiesQueryTrigger({ limit: 0 }, true).unwrap(), [facilitiesQueryTrigger]);

  const [storesQueryTrigger] = StoresApi.useLazyGetStoresQuery();
  const storesQuery = useMemo(() => 
    storesQueryTrigger({ limit: 0 }, true).unwrap(), [storesQueryTrigger]);

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <BatchTable
        gridOptions={gridOptions}
        plugins={plugins}
        activeBatchId={activeBatchId}
        setActiveBatchId={setActiveBatchId}
        facilities={facilitiesQuery}
        stores={storesQuery}
      />
    </ErrorBoundary>
  );
};
